import Editor from "@monaco-editor/react";
import React, { useMemo, useRef, useState, useEffect } from "react";

function CommonEditiordebug({ code, optionsD, onChange}) {

  const editorRef = useRef(null);

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    editor.onKeyDown((event) => {
      const { keyCode, ctrlKey, metaKey } = event;
      console.log("keycode",keyCode)
      if (((keyCode === 33 || keyCode === 52 )|| keyCode === 54) && (metaKey || ctrlKey)) {
        event.preventDefault();
      }
    });
  }

  function detectLanguage(documentContent) {
    let language = sessionStorage.getItem("language").toLocaleLowerCase();
    if (language == "javascript") {
      if (
        /<html[\s\S]*?>|<!DOCTYPE[\s\S]*?>|<head[\s\S]*?>|<script[\s\S]*?>|<body[\s\S]*?>/i.test(
          documentContent
        )
      ) {
        console.log("%%%language%%HTML");

        return "html";
      }

      else if (
        /function\s*\(|const\s*\w+\s*=\s*|let\s*\w+\s*=\s*|var\s*\w+\s*=\s*|if\s*\(|for\s*\(|while\s*\(|console\.log\(/i.test(
          documentContent
        )
      ) {
        console.log("%%%language%%JavaScript");

        return "javascript";
      }   
      else{
        return 'javascript'
      }
    } else {
      return language;
    }
  }

  return (
    <>
      <Editor
        height="450px"
        width="100%"
        language={detectLanguage(code)}
        theme="vs-dark"
        value={code}
        defaultValue={code}
        options={optionsD}
        onChange={onChange}
        onMount={optionsD.readOnly ? handleEditorDidMount : undefined}
      />
    </>
  );
}

export default CommonEditiordebug;
