import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import DynamicForm from "./Evalindex";
import ApiConfig from "../../../ApiConfig";
import PulseLoader from "react-spinners/PulseLoader";
import axios from "axios";
import { Base64 } from "js-base64";
import getJsonWithValues from "./getJsonWithValues";
import getJson from "../Eval_quiz_utility_functions/JsonUtility";
import './Evalform.scss';
import { getValueFromSession, setValueInSession } from "../../UtilityFunctions/getSetValueSession";
import insertCode from "../Eval_quiz_utility_functions/insertUserCode";

class App extends Component {
  constructor(props) {
    super(props);

    // console.log("this.props", props);
    this.state = {
      questions: [],
      answers: [],
      correct_answers: [],
      loading: true,
      submit_loading: false,
      threshHold: 75,
      isOpen: false,
      percent: null,
      model: {},
      toPrac: false,
      submit_smg: false,
      toTarget: null,
      navigateTo: false,
      quesresp: "",
      setCurrentStepIndex: 0,
      finished: false,
      inputValue: "",
      // ansresp: {}
      notAttempted: 0,
      noQuestions: 0,
      score: 0,
      maxscore: 0,
      navGreet: false,
      navLogin: false,
      key: "",
      value: "",
      coins: 0
    };
  }

  toGetQuestionForexid(BaseURI, exid, userid) {
    // let useRandom = process.env.REACT_APP_USE_RANDOM_QUESTIONS === "true";
    let Api = new ApiConfig();
    let quesUri;
    // if (useRandom) {
    //   quesUri = `${Api.BaseURI}/randomquestions?exercise=${exid}`;
    // } else {
    quesUri = `${Api.BaseURI}/questions?exercise=${exid}`;
    // }
    axios
      .get(Api.addApiKey(quesUri))
      .then((response) => {
        if (
          response.data.status === 406 ||
          response.data.status === 408 ||
          response.data.status === 500 ||
          response.data.status === 503
        ) {
          console.log("in toGetQuestionForexid")
          this.setState({ navigateTo: true, quesresp: response.data });
          axios
            .get(Api.addApiKey(`${Api.BaseURI}/postLogout?username=${userid}`))
            .then((res) => {
              console.log(res);
            });
          sessionStorage.removeItem("username");
          sessionStorage.clear();
          localStorage.clear();
        } else {
          console.log("in toGetQuestionForexid123")
          let answers = [];
          response.data.answers.map((answer) => {
            answers.push({
              key: answer.key,
              answer: Base64.decode(answer.answer),
            });
          });

          this.setState({
            questions: response.data.questions,
            answers: answers,
            loading: false,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  modifyq(q) {

    let temp = [];
    for (let i = 0; i < q.length; i++) {
      console.log("modifiedResults", 'resultarr' in q[i])
      if ('resultarr' in q[i] && 'code' in q[i]) {
        return [{ resultarr: q[i].resultarr, code: q[i].code }]
      } else if ('value' in q[i]) {
        console.log("evalform")
        temp.push({ key: q[i].key, value: q[i].value })
      }
    }
    return temp
  }
  getattempteddata(exid,isattempted){
    let result=JSON.parse(getValueFromSession('attNtAttp'));
    const item = result?.find(obj => obj.exid === exid);
    return item ? isattempted ? item?.count?.attempted : item?.count?.notattempted : null;
    
  }

  modifiedResults(resultarr) {
    console.log("called first", resultarr);
    let result = [];
    for (let i = 0; i < resultarr.length; i++) {
      result.push({
        exercise: resultarr[i].exercise,
        correct: resultarr[i].correct,
        questions: resultarr[i].questions,
        maxscore: resultarr[i].maxscore,
        attempted: this.getattempteddata(resultarr[i].exercise,true),
        notAttempted:this.getattempteddata(resultarr[i].exercise,false),
        answers: this.modifyq(resultarr[i].questionjson)
        // answers: JSON.stringify(resultarr[i].questionjson)
      });
      console.log("modifiedResults", this.modifyq(resultarr[i].questionjson))
    }
    console.log("RESULE will",result);
    return result;
  }

  toSetEvaluationUserScore() {

    this.setState({ submit_loading: true });
    // let evaluationResult = JSON.parse(sessionStorage.getItem('evaluationResult'));
    let evaluationResult = JSON.parse(getValueFromSession("evaluationResult"));
    let score;
    let noOfCorrectAnswers;
    let noOfQuestions;
    let totalmarks;
    let starttime;
    let temp_end_time;
    let end_time;
    let userid;
    let testdetailsjson;
    let percentile = 0
    let scheduleid
    // console.log('evaluationResult.length', evaluationResult != undefined)
    if (evaluationResult != undefined) {
      // console.log("evaluationResult", evaluationResult);
      score = evaluationResult.score;
      noOfCorrectAnswers = evaluationResult.correct;
      noOfQuestions = evaluationResult.questions;
      totalmarks = evaluationResult.maxscore;
      scheduleid = getValueFromSession("scheduleid")
      // attempted = evaluationResult.attempted;
      let results = JSON.parse(getValueFromSession("results"));
      console.log("results", results);
      testdetailsjson = JSON.stringify(this.modifiedResults(results));
      console.log("testdetailsjson", testdetailsjson);
      let fromQuiz = sessionStorage.getItem("fromQuiz")
      if (fromQuiz) {
        starttime = new Date(parseInt(sessionStorage.getItem("time"))).toISOString()
      } else {
        starttime = new Date(parseInt(sessionStorage.getItem("time"))).toISOString()
      }
      temp_end_time = Date.now();
      end_time = new Date(temp_end_time).toISOString();
      // userid = sessionStorage.getItem('username');
      userid = getValueFromSession("username");
      // userid = 'debug1@gmail.com';
      // module_name = sessionStorage.getItem("module_name");
      // coin = 10;
    }

    let data = {
      userid,
      scheduleid,
      score,
      totalmarks,
      percentile,
      starttime,
      testdetailsjson,
      noOfCorrectAnswers,
      noOfQuestions,
    };

    let Api = new ApiConfig();
    let uri = `${Api.BaseURI}/submittestresponse`;
    console.log('before ')


    insertCode().then()

    console.log('after')
    // insertCode().then()

    axios.post(Api.addApiKey(uri), data).then((resp) => {
      if (resp.data.status === 403) {
        this.setState({ navigateTo: true, quesresp: resp.data });
      }
      console.log(resp);
      this.setState({ coins: 10 })
      this.setState({ submit_loading: false });
      this.setState({ submit_smg: true });
      setTimeout(() => {
        this.setState({ navGreet: true });
      }, 1000);
    });
  }

  markComplete = async (exid, level) => {
    let Api = new ApiConfig()
    // let userid = getValueFromSession('username');
    let userid = getValueFromSession('username');
    console.log('data', userid, data)

    let data = { exid, level };
    const markcomplete = await axios.post(
      Api.addApiKey(`${Api.BaseURI}/markcomplete?userid=${userid}`),
      data
    );
    // console.log("markcomplete", markcomplete.data);
    setTimeout(() => {
      this.setState({ submit_loading: false });
    }, 1000);
    this.setState({ submit_smg: true });
  };

  postAnswer = async (exid, model, correct_value, questions, level) => {


    let Api = new ApiConfig()
    // let userid = getValueFromSession('username');
    let userid = getValueFromSession('username');
    let data = { exid, model, correct_value, questions, level }
    console.log('userid', userid)
    const postAnswer = await axios.post(Api.addApiKey(`${Api.BaseURI}/userresponse?userid=${userid}`), data);
    console.log("postAnswer", postAnswer.data);
    setTimeout(() => {
      this.setState({ submit_loading: false });
    }, 1000);
  };

  componentDidUpdate(preProps) {
    let BaseURI = new ApiConfig().BaseURI;
    // let exid = this.props.data.exid;
    let exid = this.props.data.exid;
    let userid = getValueFromSession('username');
    if (preProps.data.exid !== exid) {
      console.log("preState");
      this.toGetQuestionForexid(BaseURI, exid, userid);
    }
  }

  componentDidMount() {
    let BaseURI = new ApiConfig().BaseURI;
    // console.log("world");
    // let exid = this.props.data.exid;
    let exid = this.props.data.exid;
    // console.log("this.props", this.props.data.exid);
    let userid = getValueFromSession('username');
    this.toGetQuestionForexid(BaseURI, exid, userid);
  }



  getMatchingIndex(key) {
    // console.log("key", key);
    const { answers } = this.state;
    for (var i = 0; i < answers.length; i++) {
      console.log("answers[i].key", answers[i].key);
      if (answers[i].key === key) return i;
    }
  }

  toMatchArray = (userAnswers, correctAnswers) => {
    let temparr = [];
    let temp1arr = [];
    let result = false;
    for (let i = 0; i < userAnswers.length; i++) {
      temparr.push(parseInt(userAnswers[i]));
    }

    let answers = correctAnswers.split(",");
    console.log(answers);
    for (let i = 0; i < answers.length; i++) {
      temp1arr.push(parseInt(answers[i]));
    }
    // console.log("answer",checkboxarr)
    // console.log("user res",value)

    if (temparr.length === temp1arr.length) {
      temp1arr.sort();
      temparr.sort();
      let i;
      for (i = 0; i < temparr.length; i++) {
        if (temparr[i] !== temp1arr[i]) break;
      }
      if (i === temparr.length) return true;
    }
    // console.log("lenght", checkboxarr[0])
    return result;
  };

  getJsonFromResults() {
    // let results = JSON.parse(sessionStorage.getItem('results'));
    let results = JSON.parse(getValueFromSession('results'));
    // console.log("inside", results);
    for (let i = 0; i < results?.length; i++) {
      if (results[i].exercise === this.props.data.exid) {
        // console.log("results[i].questionjson", results[i].questionjson);
        // console.log(results[i].questionjson);
        return getJson(JSON.parse(JSON.stringify(results[i].questionjson)));
      }
    }
    console.log("questions", this.state.questions);
    return getJson(JSON.parse(JSON.stringify(this.state.questions)));
  }

  updateExerciseResults(
    exid,
    results,
    numcorrect,
    numquestions,
    exscore,
    total,
    valuejson
  ) {
    if (results.length == 0) {
      results.push({
        exercise: exid,
        correct: numcorrect,
        questions: numquestions,
        score: exscore,
        maxscore: total,
        questionjson: valuejson,
      });
    } else {
      let found = false;
      for (var i = 0; i < results.length && !found; i++) {
        if (results[i].exercise === exid) {
          results[i].correct = numcorrect;
          results[i].questions = numquestions;
          results[i].score = exscore;
          results[i].maxscore = total;
          results[i].questionjson = valuejson;
          found = true;
        }
      }
      if (!found) {
        results.push({
          exercise: exid,
          correct: numcorrect,
          questions: numquestions,
          score: exscore,
          maxscore: total,
          questionjson: valuejson,
        });
      }
    }
    return results;
  }

  getIncorrectAnswersCount(questionsArr, correct) {

    let Attempted = 0
    for (let i = 0; i < questionsArr.length; i++) {
      const element = questionsArr[i];
      // console.log("element",element)
      if (element.value !== undefined) {
        Attempted++
      }
      else if (element.resultarr !== undefined) {

        // correct= element.resultarr.reduce((a,b)=>{ return a+b},0)
        Attempted = element.resultarr.length
        // Attempted = element.resultarr.length +correct
      }

    }

    let incorrect = Math.abs(Attempted - correct)
    // console.log('incorrect $$$$$$$', Attempted, correct, incorrect)
    return incorrect
  }
  getCustomeMarkingSchemeScore(markingschemeObj, data) {

    let maxscore = data.maxscore
    let score = data.score
    if (markingschemeObj !== 'pravidefault' && process.env.REACT_APP_ENV_IS_USE_MARKING_SCHEME === 'true') {


      let incorrect = this.getIncorrectAnswersCount(data.questionjson, data.correct)
      // score = ((data.correct * markingschemeObj.correct) - ((data.questions - data.correct) * markingschemeObj.wrong))
      score = ((data.correct * markingschemeObj.correct) - (incorrect * markingschemeObj.wrong))
      maxscore = (data.questions * markingschemeObj.correct)
      //  console.log("score #######",score, maxscore)
    }
    return { score, maxscore }

  }

  getGrandTotal(results, module) {
    let evalResult = {
      evalName: module,
      correct: 0,
      questions: 0,
      score: 0,
      maxscore: 0,
    };
    let markingscheme = getValueFromSession('markingscheme')
    if (markingscheme !== 'pravidefault') {

      let plus = markingscheme.slice(markingscheme.indexOf("+") + 1, markingscheme.indexOf(","))
      let minus = markingscheme.slice(markingscheme.indexOf("-") + 1, markingscheme.indexOf(")"))
      markingscheme = { correct: plus, wrong: minus }
      console.log(plus, minus)
    }

    for (var i = 0; i < results.length; i++) {
      let { score, maxscore } = this.getCustomeMarkingSchemeScore(markingscheme, results[i], 'custome')
      evalResult.correct += results[i].correct || 0;
      evalResult.questions += results[i].questions || 0;
      evalResult.score += score || 0;
      evalResult.maxscore += maxscore || 0;
      // evalResult.score += results[i].score;
      // evalResult.maxscore += results[i].maxscore;
    }
    return evalResult;
  }
  // console.log("evalResult",getGrandTotal)

  getJsonWithEmptyValueUpdate() {
    // let results = JSON.parse(sessionStorage.getItem('results'));
    let results = JSON.parse(getValueFromSession('results'));
    let { key, value } = this.state;

    if (key !== undefined && value !== undefined) {
      if (key === "") {
        return this.getJsonFromResults();
      }
      for (let i = 0; i < results.length; i++) {
        let updated = false;
        if (
          key.includes(this.props.data.exid) &&
          results[i].exercise === this.props.data.exid
        ) {
          let currentJson = getJson(
            JSON.parse(JSON.stringify(results[i].questionjson))
          );
          for (let j = 0; j < currentJson.length; j++) {
            if (currentJson[j].key === key) {
              currentJson[j].value = "";
              updated = true;
              break;
            }
          }
          if (updated) {
            results[i].questionjson = currentJson;
            // sessionStorage.setItem('results', JSON.stringify(results));
            setValueInSession('results', JSON.stringify(results));
          }
          return currentJson;
        }
      }
    }
    // console.log("outside if");
    return getJson(JSON.parse(JSON.stringify(this.state.questions)));
  }

  tochangevalues = (key, value) => {
    // console.log("key", key, "value", value);
    this.setState({ key: key, value: value });
    // this.getJsonFromResults_copied();
    // this.getJsonWithEmptyValueUpdate();
  };
  clearTimer(timeOutid) {
    while (timeOutid > timeOutid - 3) {
      clearTimeout(timeOutid);
    }
  }

  toEvaluate = (model, finished) => {
    console.log("finished", finished);
    if (finished) {
      this.clearTimer(parseInt(sessionStorage.getItem("timerid")));
    }
    let { answers, questions } = this.state;
    let exid = this.props.data.exid;
    let level = this.props.data.level;
    let newModal = [];
    for (let [k, v] of Object.entries(model)) {
      newModal.push({
        key: k,
        value: v,
      });
    }

    function getAnswerJsonFromStorage() {
      // let results = JSON.parse(sessionStorage.getItem('results'));
      let results = JSON.parse(getValueFromSession('results'));
      let ansArr = [];
      if (results.length === 0) {
        return ansArr;
      } else {
        for (let i = 0; i < results.length; i++) {
          if (results[i].exercise === exid) {
            let currentJson = getJson(
              JSON.parse(JSON.stringify(results[i].questionjson))
            );
            for (let j = 0; j < currentJson.length; j++) {
              if (currentJson[j].value !== undefined) {
                ansArr.push({
                  key: currentJson[j].key,
                  value: currentJson[j].value,
                });
              }
            }
            break;
          }
        }
        return ansArr;
      }
    }

    function containskey(combined, key) {
      for (let i = 0; i < combined.length; i++) {
        if (combined[i].key === key) {
          return true;
        }
      }
      return false;
    }

    function getCombinedValues(currValArray) {
      let combined = getAnswerJsonFromStorage();
      if (combined.length === 0) {
        return currValArray;
      }
      for (let i = 0; i < combined.length; i++) {
        for (let j = 0; j < currValArray.length; j++) {
          if (combined[i].key === currValArray[j].key) {
            combined[i].value = currValArray[j].value;
            break;
          }
        }
      }
      for (let j = 0; j < currValArray.length; j++) {
        if (!containskey(combined, currValArray[j].key)) {
          combined.push({
            key: currValArray[j].key,
            value: currValArray[j].value,
          });
        } else {
        }
      }
      return combined;
    }

    function getMaxScore(level) {
      let totalmarks = 0.0;
      if (level >= 1 && level <= 3) {
        totalmarks = 10.0;
      } else if (level >= 4 && level <= 6) {
        totalmarks = 20.0;
      } else if (level >= 7 && level <= 10) {
        totalmarks = 30.0;
      } else {
        totalmarks = 40.0;
      }
      return totalmarks;
    }

    let newValArr;
    if (newModal.length === 0) {
      let totalmarks = 0;
      let score = 0;
      let no_of_correct_answers = 0;
      let no_of_questions = 0;
      // let results = JSON.parse(sessionStorage.getItem('results'));
      let results = JSON.parse(getValueFromSession('results'));
      totalmarks = getMaxScore(level);
      no_of_questions = questions.length;
      for (let i = 0; i < results.length; i++) {
        if (results[i].exercise === exid) {
          score = results[i].score;
          no_of_correct_answers = results[i].correct;
        }
      }
      newValArr = this.getJsonFromResults();
      let updated = this.updateExerciseResults(exid, results, no_of_correct_answers, no_of_questions, score, totalmarks, newValArr);
      // sessionStorage.setItem('results', JSON.stringify(updated));
      setValueInSession('results', JSON.stringify(updated));
      this.props.handlesetAttNtAttp(updated)
    } else {
      let totalmarks = 0;
      let score = 0;
      let no_of_correct_answers = 0;
      let no_of_questions = 0;
      newValArr = getJsonWithValues(this.getJsonFromResults(), newModal);

      let updatedModel = getCombinedValues(newModal);
      console.log("Updated Model", updatedModel);

      var correct_value = [];
      var wrong_value = [];
      for (var i = 0; i < updatedModel.length; i++) {
        let toMatchingIndex = this.getMatchingIndex(updatedModel[i].key);
        if (Array.isArray(updatedModel[i].value)) {
          if (
            this.toMatchArray(
              updatedModel[i].value,
              answers[toMatchingIndex].answer
            )
          ) {
            correct_value.push({
              key: answers[toMatchingIndex].key,
              value: updatedModel[i].value,
            });
          } else {
            wrong_value.push({
              key: answers[toMatchingIndex].key,
              value: updatedModel[i].value,
            });
          }
        }

        if (answers[toMatchingIndex].answer === updatedModel[i].value) {
          correct_value.push({
            key: answers[toMatchingIndex].key,
            value: updatedModel[i].value,
          });
        } else {
          wrong_value.push({
            key: answers[toMatchingIndex].key,
            value: updatedModel[i].value,
          });
        }
      }


      let results = JSON.parse(getValueFromSession('results'));
      no_of_correct_answers = correct_value.length;
      no_of_questions = questions.length;
      totalmarks = getMaxScore(level);
      score = parseFloat(Math.ceil(no_of_correct_answers * (getMaxScore(level) / questions.length)));
      let updated = this.updateExerciseResults(exid, results, no_of_correct_answers, no_of_questions, score, totalmarks, newValArr);
      // sessionStorage.setItem('results', JSON.stringify(updated));
      setValueInSession('results', JSON.stringify(updated));
      this.props.handlesetAttNtAttp(updated)

    }

    function grand(evaluationResult) {
      setValueInSession('evaluationResult', JSON.stringify(evaluationResult));
    };

    if (finished !== undefined && finished) {
      let results = JSON.parse(getValueFromSession('results'));
      console.log("results", results)
      let module_name = sessionStorage.getItem('module_name');
      var evaluationResult = this.getGrandTotal(results, module_name);
      this.setState({
        notAttempted: evaluationResult.notAttempted,
        noQuestions: evaluationResult.questions,
        score: evaluationResult.score,
        maxscore: evaluationResult.maxscore,
      });

      grand(evaluationResult);
      this.setState({ isOpen: true });
    }
  };

  // canMarkComplete = async () => {
  canMarkComplete = async () => {
    const { correct_answers, model, questions } = this.state;
    let exid = this.props.data.exid;
    let level = this.props.data.level;
    let correct_value_len = correct_answers.length;
    let question_len = questions.length;
    this.setState({ submit_loading: true });
    // setTimeout(() => {
    await this.postAnswer(exid, model, correct_value_len, question_len, level);
    await this.markComplete(exid, level);
  };

  showAttemptedNotAttempted = () => {
    let results = JSON.parse(getValueFromSession('attNtAttp'));
    console.log("showAttemptedNotAttempted", results);

    return (
      <section className="count-table-container">
        <h4>Summary</h4>
        <section className="table-container count-table">
          <table>
            <thead>
              <th>Exercise no</th>
              <th>Attempted</th>
              <th>Not attempted</th>
            </thead>
            <tbody>
              {results.map((row, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td className="attempted">
                    {row.count.attempted}
                  </td>
                  <td className="notattempted">
                    {row.count.notattempted}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </section>
    )

  };

  render() {
    const {
      questions,
      correct_answers,
      answers,
      toTarget,
      navigateTo,
      quesresp,
      finished,
      key,
      value,
    } = this.state;
    // console.log("key", key);
    let exid = this.props.data.exid;
    // let level = this.props.data.level;
    let fromEval = this.props.fromEval;
    let isLastStep = this.props.isLastStep;
    let isFirstStep = this.props.isFirstStep;
    let setCurrentStepIndex = this.props.setCurrentStepIndex;
    let next = this.props.next;
    let back = this.props.back;
    let clearTimer = this.props.clearTimer;
    let showResult = this.props.showResult
    let statenp = this.props.statenp
    let npvalue = this.props.npvalue
    let setKey = this.props.setKey
    let setValue = this.props.setValue
    // console.log(clearTimer)
    // console.log("next", next);
    // console.log("back", back);
    const override = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "20px",
      // borderColor: "olive",
    };
    // console.log("showResult", showResult)
    // console.log("this.state.navGreet", this.state.navGreet)
    return (
      <div className="pyui_questions-form-container">
        {navigateTo && (
          <Navigate
            to={"/login"}
            state={{ response: quesresp, type: "error" }}
          />
        )}
        {/* {finished && <Navigate to={'/greeting'} />} */}

        {this.state.navGreet && (<Navigate to="/assessment/result"
          state={{ showResult: showResult }}
        />)}
        {/* {this.state.navLogin && (<Navigate to="/login" />)} */}


        {this.state.isOpen && (
          <>
            <div id="simplemodal" className="pyui_modal">
              <div className="pyui_modal-content">
                {this.state.submit_loading ? (
                  <>
                    <header className="pyui_modal-header">
                      <h4 className="pyui_modal-header-title">
                        Assessment</h4>
                      <span
                        className="closebtn"
                        onClick={() => {
                          this.setState({ isOpen: false });
                        }}
                      >
                        &times;
                      </span>
                    </header>

                    <div id="modalloader-spinner">
                      <p>
                        Marking as complete
                        <PulseLoader
                          color="black"
                          loading={this.state.submit_loading}
                          cssOverride={override}
                          size={3}
                          className="pulse-loader"
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          speedMultiplier={0.5}
                        />
                      </p>
                    </div>
                  </>
                ) : this.state.submit_smg ? (
                  <>
                    <header className="pyui_modal-header">
                      <h4 className="pyui_modal-header-title">
                        Assessment</h4>
                      <span
                        className="closebtn"
                        onClick={() => {
                          this.setState({ isOpen: false });
                        }}
                      >
                        &times;
                      </span>
                    </header>
                    <div className="pyui_modal-body">
                      <div className="pyui_modal-body-container">
                        <div className="marked-completed-msg">
                          <i class="fa-solid fa-check"></i>
                          <div>Marked as Completed</div>
                        </div>
                        <div className="modal-footer-msg">
                          {/* <button className="primary-button" onClick={() => {
                        }} >Mark Complete</button> */}
                          {/* <button className="primary-button" onClick={() => { this.setState({ isOpen: false }) }}>Close</button> */}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <header className="pyui_modal-header">
                      <h4 className="pyui_modal-header-title">Assessment</h4>
                      <span
                        className="closebtn"
                        onClick={() => {
                          this.setState({ isOpen: false });
                        }}
                      >
                        &times;
                      </span>
                    </header>
                    <div className="pyui_modal-body">
                      <div className="pyui_modal-body-container">
                        <div className="modalUserScore">
                          {this.showAttemptedNotAttempted()}
                          <section>
                            {`This action will submit your response. You may want to review any unanswered questions. Proceed?`}
                          </section>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          className="button primary small"
                          onClick={() => {
                            this.toSetEvaluationUserScore();
                            clearTimer();
                          }}>
                          Complete
                        </button>

                        <button
                          className="button  small"
                          onClick={() => {
                            this.setState({ isOpen: false });
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {this.state.loading ? (
          <div className="formloader-spinner">
            <p>
              Loading
              <PulseLoader
                color="black"
                loading={this.state.loading}
                cssOverride={override}
                size={3}
                className="pulse-loader"
                aria-label="Loading Spinner"
                data-testid="loader"
                speedMultiplier={0.5}
              />
            </p>
          </div>
        ) : (
          <>
            <DynamicForm
              exid={exid}
              // level={level}
              setKey={setKey}
              setValue={setValue}
              key={10}
              correct_answers={correct_answers}
              answers={answers}
              questions={questions}
              question_len={questions.length}
              status={this.props.status}
              fromEval={fromEval}
              setCurrentStepIndex={setCurrentStepIndex}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              next={next}
              back={back}
              statenp={statenp}
              npvalue={npvalue}
              className="form"
              title="Answer the following"
              // model={() => { this.getJsonFromResults() }}
              model={this.getJsonWithEmptyValueUpdate()}

              tochangevalues={(key, value) => {
                this.tochangevalues(key, value);
              }}
              toEvaluate={(model, finished) => {
                this.toEvaluate(model, finished);
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default App;
