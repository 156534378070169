import { useEffect, useState } from "react";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { fetchData } from "../ApiUtils/fetchData";

function useLibraryCardsData() {
  const userid = getValueFromSession('username')
  const uri = `gettesttemplate?userid=${userid}`;
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueTracks, setUniqueTracks] = useState([]);
  const [uniqueLeagues, setUniqueLeagues] = useState([]);
  const [archivedTests, setArchivedTests] = useState([])
  const [templateBundleNames, setTemplateBundle] = useState([])
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState([]);

  useEffect(() => {
    fetchLibraryCards()
  }, []);

  const fetchLibraryCards = async () => {
    setLoader(true)
    try {
      const myData = await fetchData(uri)
      console.log("data loaded", myData)
      const tracks = [...new Set(myData.data.map((item) => item.track))];
      const leagues = [...new Set(myData.data.map((item) => item.league))];
      const bundleNames = [...new Set(myData.data.filter(item => item.isinbundle === true).map(item => JSON.parse(item.bundle_details).title))]
      // console.log('tracks', tracks);
      // console.log('leagues', leagues);
      let temp = [...new Set(myData.data.filter(item => item.isinbundle === true).map(item => JSON.parse(item.bundle_details).title))]
      console.log('temp', temp);

      setFilteredData(myData?.data)
      setUniqueTracks(tracks);
      setArchivedTests(myData?.archivedTest)
      setUniqueLeagues(leagues);
      setTemplateBundle(bundleNames)
      console.log("in try")
    } catch (error) {
      setError(error)
      console.log("in catch")
    } finally {
      setLoader(false)
      console.log("in finally")
    }
  }
  // console.log("filteredData", filteredData)
  return {
    filteredData, uniqueTracks, uniqueLeagues, loader, error, setFilteredData, archivedTests, setArchivedTests, fetchLibraryCards, templateBundleNames
  }
}

export default useLibraryCardsData