import React from 'react'
// import libraries
import './notify.scss'

const SendNotification = ({ setToNotify, isToNotify }) => {
  const handleCheckboxChange = () => {
    setToNotify(!isToNotify);
  };
  return (
    <section className='schedule_notify'>
      <input
        id='notify'
        type="checkbox"
        checked={isToNotify}
        onChange={handleCheckboxChange}
      />
      <label htmlFor='notify'>
        Notify Participants
      </label>
    </section>
  )
}

export default SendNotification