import axios from "axios";
import ApiConfig from "../../../ApiConfig";
import { getValueFromSession } from "../../UtilityFunctions/getSetValueSession";
export default async function insertCode() {
  if (process.env.REACT_APP_IS_STORE_USER_CODE === 'true') {


    let Api = new ApiConfig()
    let results = JSON.parse(getValueFromSession('results'))
    let userid = getValueFromSession('username')
    let scheduleid = getValueFromSession("scheduleid");
    let newCode = []
    for (let i = 0; i < results.length; i++) {
      // console.log("results[i].exercise === exid", results[i].exercise, exid)
      // if (results[i].exercise === randomexe[currentStepIndex].exid) {
      let codejson = results[i].questionjson;
      for (let j = 0; j < codejson.length; j++) {
        if (codejson[j].code !== undefined)
          newCode.push({ code: codejson[j].code, exid: results[i].exercise });

      }
      // }
    }
    let data = { userid, codeExidArr: newCode, scheduleid }
    console.log('result #######',newCode,results,JSON.stringify(newCode))
    axios.post(Api.addApiKey(`${Api.BaseURI}/saveassessmentusercode`), data)
    return
  }
  return
}