import { useState } from 'react';
import { toast } from 'react-toastify';
import ApiConfig from '../../ApiConfig';
import axios from "axios"
import { useRef } from 'react';
const useTestStart = () => {
  const [testloading, setLoading] = useState(false);
  const toastid=useRef();
  const startTest = async (sid,status1) => {
    try {
      setLoading(true);
    //   setError(null);

      if (!sid) {
        if(!toast.isActive(toastid.current)){
          toast.error("Please Provide a Schedule id")
        }
     
      }
      
      const Api = new ApiConfig();
      const response = await axios.post(Api.addApiKey(`${Api.BaseURI}/starttest`), {
        scheduleid: sid,
        status: status1
      });
      
      const { status, data: { message } } = response;
      if (status === 200) {
        if(!toast.isActive(toastid.current)){
          return toastid.current= status1=='canceled'?toast.success('Test canceled successfully.'):toast.success(message);
        }
      } else {     if(!toast.isActive(toastid.current)){
       return  toastid.current= toast.error("Oops something went wrong!");
      }
      }
    } catch (error) {
      if(!toast.isActive(toastid.current)){
      return  toastid.current=toast.error("An error occurred while processing your request.");
      }
    //   console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { startTest, testloading };
};


const useCancelTest = () => {
  const [cancleTestloading, setLoading] = useState(false);
  const toastid=useRef();
  const cancelTest = async (sid,status1) => {
    try {
      setLoading(true);
    //   setError(null);

      if (!sid) {
        if(!toast.isActive(toastid.current)){
          toast.error("Please Provide a Schedule id")
        }
     
      }
      
      const Api = new ApiConfig();
      const response = await axios.get(Api.addApiKey(`${Api.BaseURI}/cancelassessment?scheduleid=${sid}`))
      
      const { status, data: { message } } = response;
      if (status === 200) {
        if(!toast.isActive(toastid.current)){
          return toastid.current= status1=='canceled'?toast.success('Test canceled successfully.'):toast.success(message);
        }
      } else {     if(!toast.isActive(toastid.current)){
       return  toastid.current= toast.error("Oops something went wrong!");
      }
      }
    } catch (error) {
      if(!toast.isActive(toastid.current)){
      return  toastid.current=toast.error("An error occurred while processing your request.");
      }
    //   console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { cancelTest };
};
export default useTestStart;
export  {useCancelTest}
