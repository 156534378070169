import React, { useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import './customnode.css';
import { BsDiamond } from 'react-icons/bs'
import { FiCircle } from 'react-icons/fi'
const Whilenode = ({ data }) => {
  const [contentDimensions, setContentDimensions] = useState({ width: 80, height: 45 });

  const { label, shape, deletenode, id } = data;


  useEffect(() => {
    updateRectangleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  const updateRectangleSize = () => {
    const content = document.getElementById(id);
    if (content) {
      // Get the content width and height
      const contentWidth = content.offsetWidth;
      const contentHeight = content.offsetHeight;
      console.log("contentWidth", contentWidth)
      console.log("contentHeight", contentHeight)
      // Update the state with content dimensions
      setContentDimensions({ width: contentWidth, height: contentHeight });
    }
  };
  const labelStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    whiteSpace: 'nowrap',
    fontSize: '12px',
  };


  const btnStyle = {
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: "14px",
    height: "14px",
    backgroundColor: 'white',
    border: '1px solid gray',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <div className={`${shape}`}
        style={{
          width: `${contentDimensions.width > 80 ? contentDimensions.width : 80}px`,
          height: `${contentDimensions.height > 45 ? contentDimensions.height : 45}px`,
          // height: `45px`,
        }}
      >
        {console.log("shape", shape)}
        <Handle type="source" position="bottom" id="a" style={{ background: '#555' }} />
        <Handle type="target" position="top" id="b" style={{ background: '#555' }} />
        <Handle type="source" position="right" id="k" style={{ background: '#555', marginRight: '-1.6rem' }} />

        <div id={`${id}`} style={labelStyles}>
          {label}
        </div>
        <button
          // onClick={() => deletenode()}
          title="Delete Node" style={btnStyle}>
          X
        </button>
      </div>
    </div>
  );
};

export default Whilenode;
