import PulseLoader from "react-spinners/PulseLoader";
import CommonEditiordebug from "./commonEditor";
import { BiArrowToRight, BiArrowToLeft } from "react-icons/bi";
import { ImZoomIn, ImZoomOut, ImCancelCircle } from "react-icons/im";
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from "react-icons/fa";
import TextEditor from "./TextEditor";
import { useState } from "react";

function LeftHandside({
  isModal,
  setIsMOdal,
  code,
  optionsD,
  submiited,
  submitting,
  override,
  coinMsg,
  isDebug,
  onChange,
  showImage,
  imageType,
  handleZoom,
  zoom,
  isSlide,
  setIsSlide,
  isFullScreen,
  setIsFullScreen,
  imageDivRef,
  imgRef,
  imgDimensions,
  showlefthandside,
  handleEditorDidMount,
  isNormal,
  title,
  isTextEditor, isEvaluation
}) {
  console.log(
    "isNormal",
    isNormal,
    "showImage",
    showImage,
    "imagetype",
    imageType
  );
  function Imgsidepanelbutton() {
    return (
      <>
        <div className="image-action-btn-div">
          {imageType !== "pdf" && (
            <>
              <button
                title="Zoom in"
                style={{
                  marginLeft: "1rem",
                  background: "transparent",
                  backgroundColor: "transparent",
                  border: "0px ",
                }}
                onClick={() => handleZoom(0.5)}
              >
                <ImZoomIn size={20} />
              </button>
              <button
                title="Zoom out"
                style={{
                  marginLeft: "1rem",
                  background: "transparent",
                  backgroundColor: "transparent",
                  border: "0px ",
                }}
                onClick={() => handleZoom(-0.5)}
                disabled={zoom <= 0.5}
              >
                <ImZoomOut size={20} />
              </button>
            </>
          )}
          <button
            title={isSlide ? "Expand" : "Collapse"}
            className="sliderBtn"
            style={{
              marginLeft: ".9rem",
              background: "transparent",
              backgroundColor: "transparent",
              border: "0px ",
            }}
            onClick={() => setIsSlide(!isSlide)}
          >
            {isSlide ? (
              <BiArrowToRight size={30} />
            ) : (
              <BiArrowToLeft size={30} />
            )}
          </button>
          <button
            className="sliderBtn"
            style={{
              marginLeft: "1rem",
              background: "transparent",
              backgroundColor: "transparent",
              border: "0px ",
            }}
            title={isFullScreen ? "Close" : "Full screen"}
            onClick={() => {
              (!isEvaluation && setIsSlide(isFullScreen ? true : false))
              setIsFullScreen(!isFullScreen);
            }}
          >
            {isFullScreen ? (
              <ImCancelCircle size={20} />
            ) : (
              <FaExpandArrowsAlt size={20} />
            )}
          </button>
        </div>
      </>
    );
  }

  function Debugsolvermodal() {
    return (
      <>
        <div id="simplemodal" className="pyui_modal">
          <div className="pyui_modal-content">
            <header className="pyui_modal-header">
              <h4 className="pyui_modal-header-title">
                <i class="fa-solid fa-circle-info"></i> Your Score
              </h4>
              <span
                className="closebtn"
                onClick={() => {
                  setIsMOdal(false);
                }}
              ></span>
            </header>

            {submitting && !submiited && (
              <div id="modalloader-spinner">
                <p>
                  Marking as complete
                  <PulseLoader
                    color="black"
                    loading={submitting}
                    cssOverride={override}
                    size={3}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </p>
              </div>
            )}
            {submiited && (
              <div className="pyui_modal-body">
                <div className="pyui_modal-body-container">
                  <div className="marked-completed-msg">
                    <i class="fa-solid fa-check"></i>
                    <div>{coinMsg}</div>
                  </div>
                  <div className="modal-footer-msg">
                    <button
                      className="button primary small"
                      onClick={() => {
                        setIsMOdal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  function ImagEditor() {
    const [isPDFLoading, setIsPDFLoading] = useState(imageType === 'pdf');
    return (
      <>
        <div className="code-editor">
          <div
            id="zoom"
            ref={imageDivRef}
            className={"image-div"}
            style={{ scrollSnapType: "x mandatory" }}
          >
            {isPDFLoading && (
              <div
                style={{
                  position: "absolute",
                  alignSelf: "center",
                  zIndex: "2",
                  justifyItems: "center",
                  color: "whitesmoke",
                }}
              >
                Loading
                <span
                  style={{
                    position: 'relative',
                    top: '.25rem'
                  }}
                >
                  <PulseLoader
                    // color="white"
                    color="#7b1fa2"
                    loading={isPDFLoading}
                    cssOverride={override}
                    size={10}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </span>
              </div>
            )}
            {imageType === "pdf" ? (
              <iframe
                onLoad={() => {
                  setIsPDFLoading(false);
                }}
                src={`${code}#toolbar=0&navpanes=0&scrollbar=0`}
                width={`${imgDimensions.width * zoom}%`}
                height={`${imgDimensions.height * zoom}%`}
              />
            ) : (
              <img
                ref={imgRef}
                onLoad={() => {
                  imageDivRef.current.scrollLeft =
                    (imageDivRef.current.scrollWidth -
                      imageDivRef.current.scrollWidth / 3 -
                      100) /
                    2;
                }}
                src={code}
                style={{
                  width: `${((imgDimensions.width * 10) / 21) *
                    window.devicePixelRatio *
                    zoom
                    }px`,
                  height: `${((imgDimensions.height * 10) / 28) *
                    window.devicePixelRatio *
                    zoom
                    }px`,
                  margin: "auto",
                }}
                alt="code image"
              />
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="editor-debug">
      <div className="editor-lable">
        {title ? title : "Code Viewer"}
        {showImage && Imgsidepanelbutton()}
      </div>
      <div className="code-editor">
        {isDebug && isModal && Debugsolvermodal()}

        {isNormal && (
          <CommonEditiordebug
            code={code}
            optionsD={optionsD}
            onChange={onChange}
            handleEditorDidMount={handleEditorDidMount}
          />
        )}
        {isTextEditor && (
          <TextEditor
            text={code}
            optionsD={optionsD}
            onChange={onChange}
            handleEditorDidMount={handleEditorDidMount}
          />
        )}
        {showImage && ImagEditor()}
      </div>
    </div>
  );
}

export default LeftHandside;
