// import react components or files
import React, { useContext, useState } from 'react'

import AppContext from '../AppContext/AppContext';
import { getValueFromSession, setValueInSession } from '../UtilityFunctions/getSetValueSession';
// import libraries
import moment from 'moment'
import { SlCalender } from "react-icons/sl";
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth';
import { fetchData } from '../ApiUtils/fetchData';
import OutsideClickHandler from './Handleclickoutside';
import { useRef, useEffect } from 'react';
// import useEvalFormutils from '../Editor/Evaluation/PaginationEditor/useEvalFormutils';
//import styles
import './Cards.scss'
import axios from 'axios';
import ApiConfig from '../../ApiConfig';
// import './librarycards.scss'

let reset = false
let session = false;
const Cards = ({ data }) => {

  const { setTestStarted } = useAuth()
  const context = useContext(AppContext);
  const [modal, setmodal] = useState({ id: null, condition: false })
  const [menu, setMenu] = useState({ top: '0px', left: '0px' })
  const [modalOpen, setModalOpen] = useState(false);
  let userid = getValueFromSession('username')
  const buttonRef = useRef(null);

  // console.log("exidCount", exidCount)
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handledata = (e) => {

    if (e.pageX + 600 > windowSize[0]) {
      setMenu({
        top: e.pageY,
        left: e.pageX - 400
      })
    }
    else {
      setMenu({
        top: e.pageY,
        left: e.pageX - 20
      })
    }


  }
  const handleButtonClick = (e, scheduleId) => {
    e.preventDefault();
    console.log("click firet")
    setmodal(prevModal => ({
      id: scheduleId,
      condition: !prevModal.condition || prevModal.id !== scheduleId // Toggle condition if the id is different
    }));
    setModalOpen(true);

    handledata(e);
  };
  function getMaxScore(level) {
    let message;
    let code;
    let totalmarks = 0.0;
    if (level >= 1 && level <= 3) {
      totalmarks = 10.0;
    } else if (level >= 4 && level <= 6) {
      totalmarks = 20.0;
    } else if (level >= 7 && level <= 10) {
      totalmarks = 30.0;
    } else {
      totalmarks = 40.0;
    }
    return totalmarks;
  }

  function GetTotalMarks(selectedexids) {
    let totalscore = 0;
    for (let i = 0; i < selectedexids.length; i++) {
      totalscore += getMaxScore(selectedexids[i].level);
    }
    return totalscore;
  }
  const getTotalMark1 = (questions) => {
    let totalmarks;

    switch (questions.markingscheme) {
      case 'pravidefault':
        totalmarks = GetTotalMarks(questions.json_agg);
        break;
      case 'marking scheme(+2, -1)':
        totalmarks = questions.totalquestions * 2;
        break;
      case 'marking scheme(+4, -2)':
        totalmarks = questions.totalquestions * 4;
        break;
      default:
        totalmarks = questions.totalquestions;
        break;
    }
    console.log("totalmarks", totalmarks)
    return totalmarks;
  };
  const handleCloseModal = (e, scheduleId) => {
    setmodal(prevModal => ({
      id: scheduleId,
      condition: false
    }));
    setModalOpen(false);


  };
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handlestart = async (card) => {
    let userid = getValueFromSession('username')
    let result = await fetchData(`/updateusertestmapstatus?userid=${userid}&scheduleid=${card.scheduleid}&status=inprogress`)
    console.log("result ##########", result.data)
    context.setTimer(true)
  }

  // useEffect(() => {



  // }, []);
  // useEffect(() => {
  //   let questionstemp = []

  //   async function toGetQuestionForexid() {
  //     let Api = new ApiConfig();
  //     let quesUri;

  //     for (let i = 0; i < exidarr.length; i++) {
  //       console.log("exidarr[i]", exidarr[i])
  //       quesUri = `${Api.BaseURI}/questions?exercise=${exidarr[i]}`;
  //       try {
  //         let response = await axios.get(Api.addApiKey(quesUri))
  //         console.log("response", response)
  //         questionstemp.push(response.data.questions)
  //       } catch (error) {
  //         console.log(error)
  //       }
  //     }
  //     setQuestionnp(questionstemp)
  //   }

  //   toGetQuestionForexid()

  // }, [check]);
  function getMaxScore(level) {
    let totalmarks = 0.0;
    if (level >= 1 && level <= 3) {
      totalmarks = 10.0;
    } else if (level >= 4 && level <= 6) {
      totalmarks = 20.0;
    } else if (level >= 7 && level <= 10) {
      totalmarks = 30.0;
    } else {
      totalmarks = 40.0;
    }
    return totalmarks;
  }

  const handlePreLoadData = async (card) => {
    let Api = new ApiConfig();
    let quesUri;
    let exidarr = card.json_agg?.map(i => i.exid)
    let questionstemp = []
    for (let i = 0; i < exidarr.length; i++) {
      console.log("exidarr[i]", exidarr[i])
      quesUri = `${Api.BaseURI}/questions?exercise=${exidarr[i]}`;
      let response = await axios.get(Api.addApiKey(quesUri))
      console.log("response", response)
      questionstemp.push(response.data.questions)
    }
    // let levelexid = card.json_agg?.map(i => {
    //   return {
    //     level: i.level,
    //     exid: i.exid
    //   }
    // })

    console.log("exidarr ######", exidarr)
    // console.log("levelexid ######", levelexid)
    const uri = `getquestionforexids?exidarr=${JSON.stringify(exidarr)}`
    const result = await fetchData(uri)
    console.log("result ######", result)

    console.log("questionstemp ####", questionstemp)
    let temp = []
    let temp1 = []

    for (let i = 0; i < exidarr.length; i++) {
      console.log("getMaxScore", getMaxScore(result?.data[exidarr[i]].level))
      let maxscore = getMaxScore(result?.data[exidarr[i]].level)
      let questions = result?.data[exidarr[i]].count
      temp.push({
        exercise: exidarr[i], correct: 0, maxscore: maxscore, questions: questions, attNtAttp: [],
        questionjson: questionstemp[i], score: 0
      })
      temp1.push({ exid: exidarr[i], count: { attempted: 0, notattempted: questions } })
    }

    setValueInSession("results", JSON.stringify(temp));
    setValueInSession("attNtAttp", JSON.stringify(temp1));
  }


  const renderedCards = data
    ?.map((card, i) => (
      <div key={i} className={`testlibrarycard ${card.league}`} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className='testlibrarycard_header'>{card.name}</span>
        <span className='testlibrarycard_time' style={{ display: 'flex', alignItems: 'center' }}>
          <SlCalender /> &nbsp;
          {moment(card.scheduletime).format('DD MMM, YYYY   hh:mm A')} | {card.duration} minutes
        </span>
        <span>{card.noofexids} Exercises</span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <OutsideClickHandler onOutsideClick={(e) => handleCloseModal(e, card.scheduleid)}>


            <button
              className='link-button'
              style={{ position: 'relative' }}
              onClick={(e) => handleButtonClick(e, card.scheduleid)}
            >
              More details
            </button>
            {modal.id === card.scheduleid && modal.condition && modalOpen && (
              <div className='modal_C11w1' style={menu}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', maxWidth: '440px' }}>
                  <span className='header_CS22'>Assessment Details </span>
                  <span className="name"> Name  : {card.name}</span>
                  <span className="name"> Description  : {card.description}</span>
                  <span className="name"> Maximum duration: :  {card.duration} minutes</span>
                  <span className="name"> Start time  :         {moment(card.scheduletime).format('DD MMM, YYYY   hh:mm A')}</span>
                  <span className="name"> Number of Exercises  : {card.noofexids}</span>
                  <span className='name'>Number of Questions : {card.totalquestions}</span>
                  <span className="name"> Total marks  : {getTotalMark1(card)} </span>
                  <span className="name"> Marking scheme  : {card.markingscheme} </span>
                  <span className="name" style={{ fontWeight: 550 }}>
                    Once you start the test, you must complete it. Please make sure that your internet connection is stable and available during the test duration. Please do not close the tab in case the connection is temporarily lost. The test will restart automatically once the connection is available.Wish you all the best!
                  </span>
                </div>
              </div>
            )}

          </OutsideClickHandler>

          {card.teststatus === 'completed' ?
            <button className='completed-button'
              state={{ testCardData: card }}>
              Completed
            </button>
            :
            <Link className='button primary small'
              onClick={async () => {

                handlestart(card)
                sessionStorage.setItem("duration", Math.ceil(parseInt(card.duration) / 5) * 5)
                sessionStorage.setItem("time", Date.now());
                sessionStorage.setItem('randomexe', JSON.stringify(card.json_agg))
                sessionStorage.setItem("module_name", card.json_agg[i].module);
                sessionStorage.setItem('language', card.track)
                reset = true
                // let results = [];
                let newModel = [];
                setValueInSession('markingscheme', card.markingscheme)
                // setValueInSession("results", JSON.stringify(results));
                setValueInSession("scheduleid", card.scheduleid);
                // session = true
                sessionStorage.setItem("model", JSON.stringify(newModel));
                setTestStarted(true)
                handlePreLoadData(card)
              }}
              state={{ testCardData: card }}
              to={'editor'}
            >
              Start Test
            </Link>
          }

        </div>

      </div>
    ))


  return (
    <section className='assessmentcards_main'>
      <section className='assessmentcards_main_grid'>
        {data.length > 0 ? renderedCards :
          <span className='label_no'>There are no assessments to display.</span>
        }
      </section>
      {/* {modalData.modal && <ViewDetails modalData={modalData} setModalData={setModalData} />} */}
    </section>
  );
}

export default Cards
export { reset, session };