import React from 'react'
import './MEditor.scss'

export default function TextEditor({ text }) {


  return (
    <textarea

      onCopy={(e) => { e.preventDefault() }}
      onCut={(e) => { e.preventDefault() }}
      className='desc-textarea'
      style={{
        // resize:'none',
        // fontSize:'15px',
        // width:'100%',
        // height:'100%'
      }}
      cols={110}
      readOnly={true}

      rows={17}
    >{text}</textarea>
  )
}
