import Cards from "./Cards";
import LoaderUtility from "../Loader/Loader";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { usePastAssessment } from "../hooks/useUserAssessment";

function Past() {
  let userid = getValueFromSession('username')
  const { status, data } = usePastAssessment(userid, 'completed', 'completed')

  return <>{status == "success" ? <Cards data={data} /> : <LoaderUtility size={15} loading={status == 'pending'} />}</>;
}

export default Past;
