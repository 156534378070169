import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ApiConfig from '../../ApiConfig';
import './ErrorWrappers.scss';
import { getValueFromSession, setValueInLoaclStorage, setValueInSession, validateUser } from '../UtilityFunctions/getSetValueSession';
export const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);
  const navigate = useNavigate();
  const [logout,setLogout]=useState('')
  const {pathname} =useLocation()
  const [isValidUser,setIiValidUser]=useState(true)
  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])
  useMemo(()=>{
    setIiValidUser(validateUser())
  },[pathname])

  // event listeners to update the state 
  window.addEventListener('online', () => {
    setOnline(true)
  });

  window.addEventListener('offline', () => {
       setOnline(false)
  });
  let BaseUri = new ApiConfig().BaseURI;
  let Api=new ApiConfig()
  const location=useLocation()
  
  let username = getValueFromSession('username')
  
  window.addEventListener('beforeunload', function (e) {
  
    e.preventDefault();
    // e.returnValue = '';
  // console.log(username);
  // sessionStorage.removeItem('username');
  // sessionStorage.clear();
  // localStorage.clear();
  // navigate('/login');
  // console.log("location ",location.pathname)
  // localStorage.setItem("reload",window.screenTop)
  // console.log("username condition ",sessionStorage.getItem('username')===undefined)
  if(getValueFromSession('username')!=null && getValueFromSession('username')!==undefined && getValueFromSession('reload')=='true')
  {
    // console.log("called user log out")

    // axios.get(Api.addApiKey(`${BaseUri}/postlogout?username=${sessionStorage.getItem('username')}&time=${Date.now()}&role=${sessionStorage.getItem('role')}`)).then(res => {
    //   console.log(res)
    //   sessionStorage.setItem('logout',res.data.logout)
    // }); 
    // axios.get(Api.addApiKey(`${BaseUri}/userlogout?username=${username}&role=${sessionStorage.getItem('role')}&usergroup=${sessionStorage.getItem('usergroup')}`))
    setValueInSession('reload','false')
    setValueInSession('load','true')
    axios.get(Api.addApiKey(`${BaseUri}/userlogout?username=${username}&loginTime=${this.sessionStorage.getItem('loginTime')}&usergroup=${sessionStorage.getItem('usergroup')}`)).then(res=>{
      sessionStorage.setItem('loginTime',Date.now())
      
    })

  }
    // setLogout('true')
      // sessionStorage.removeItem('logout');
    // sessionStorage.setItem('logout',true);
  // sessionStorage.clear();
  // localStorage.clear()
  })
let reload=true
  window.addEventListener('load',function(e){
  e.preventDefault()
  // let username = sessionStorage.getItem('username')

  if(username!==undefined && username!=='null' && reload && getValueFromSession('load')=='true')
  {
    setValueInSession('reload','true')
    // axios.get(`${BaseUri}/postlogin?username=${username}&logout=${sessionStorage.getItem('logout')}&reload=true`).then(res=>{
    //   console.log("res",res.data)
    //   if(sessionStorage.getItem('session')==='true')
    //   {
        //  sessionStorage.setItem('sessionTime',res.data.session)
    //   }
    // })

    setValueInSession('load','false')
    axios.get(Api.addApiKey(`${BaseUri}/postlogout?username=${getValueFromSession('username')}&reload=true&loginTime=${this.sessionStorage.getItem('loginTime')}&logout=${sessionStorage.getItem('logout')}`)).then(res => {
      console.log(res)
      sessionStorage.setItem('loginTime',Date.now())
      // sessionStorage.setItem('sessionTime',)
    });
    reload=false
  }
})
  // window.onbeforeunload=(e)=>{
  //   e.preventDefault();
  //       e.returnValue = '';
  //       navigate('/login', { state: { response: ' ', type: 'error' } })
  //       axios.get(`${BaseUri}/postLogout?username=${username}`).then(res => {
  //         console.log(res)
  //       });
  //       sessionStorage.removeItem('username');
  //       sessionStorage.clear();
  //       localStorage.clear();
  // }
  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div className="pyui_error-wrapper">
        <h1 className="pyui_error-msg-title"> Oops!</h1>
        <p className="pyui_error-msg">
          No Internet Connection... Please try again later!
        </p>
      </div>
    );
  }
}

export default NoInternetConnection;
