import { useState } from "react";
import Available from "./Availabletext";
import Past from "./Pastest";
import Tabs from "../CustomTabs/Tabs";


function Userassessment() {

    const myTabs = [
        { key: "available", name: "Available Assessment", component: <Available /> },
        { key: "past", name: "Past Assessment", component: <Past /> },

    ];

    const [activeTab, setActiveTab] = useState(myTabs[0].name);

    return (
        <>
            <Tabs
                tabs={myTabs}
                activeTab={activeTab}
                handleTabChange={setActiveTab}
            />
            {/* <Sidebar tabs={tabs} /> */}
        </>
    );
}

export default Userassessment;