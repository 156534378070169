import React from 'react'
// import libraries
import { motion } from 'framer-motion';
import { IoChevronForwardOutline } from "react-icons/io5";
// import styles
import './banner.scss'
const Banner = ({ name, latestCompleted, latestUpcoming }) => {
  const sliceAndCapFirst = (name) => name.charAt(0).toUpperCase()
  const capFirstLetter = (name) => sliceAndCapFirst(name) + name.slice(1)
  const chars = name.split('');
  const firstLetter = sliceAndCapFirst(name)
  const size = 20
  const cursor = 'pointer'

  return (
    <div className='dashboard_banner'>
      <section className='banner_profile_avatar_section'>
        <div className="avatar_section">
          <motion.p
            whileHover={{ scale: 1.3 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            {firstLetter}
          </motion.p>
        </div>
        <header className='profile_name'>
          <motion.p className='initials'
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // transition={{ duration: 0.2, ease: "easeInOut", delay: 0.1 }}
          >
            Welcome,
          </motion.p>
          <section className='name'>
            {chars.map((char, index) => (
              <motion.span
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: "easeOut", delay: index * 0.1 }}>
                {char}
              </motion.span>
            ))}
          </section>
          {/* <motion.p className='name'
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.7, ease: "easeOut" }}>
            {capFirstLetter(name)}
          </motion.p> */}
        </header>
      </section>
      {process.env.REACT_APP_SHOW_LATEST === 'true' && (
        <section className='banner_show_latest'>
          <section className='uplatest'>
            <section className='uplatest_details'>
              <header>Upcoming</header>
              {latestUpcoming.length === 0 ? (
                <p className='no'>No upcoming test</p>
              ) : (
                <>
                  <section className='details'>
                    <p>Schedule Id</p>
                    <p>{latestUpcoming?.scheduleid}</p>
                  </section>
                </>
              )}
            </section>
            <button className='button small '>
              <IoChevronForwardOutline size={size} cursor={cursor} />
            </button>
          </section>
          <section className='uplatest'>
            <section className='uplatest_details'>
              <header>Completed</header>
              {latestCompleted.length === 0 ? (
                <p className='no'>No completed</p>
              ) : (
                <>
                  <section className='details'>
                    <p>Schedule Id</p>
                    <p>{latestCompleted?.scheduleid}</p>
                  </section>
                </>
              )}
            </section>
            <button className='button small '>
              <IoChevronForwardOutline size={size} cursor={cursor} />
            </button>
          </section>
        </section>
      )}
    </div>
  )
}

export default Banner