import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import './customnode.css';
import {BsDiamond} from 'react-icons/bs'
import {FaCircle} from 'react-icons/fa'
const Condtionnode = ({ data,id }) => {

  const { width, height, color, borderColor, shape, label } = data;
  let defaultcolor='orange'
  
  const labelStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display:  'block',
  };



  return (
    <div className={`${shape}`}>
    <FaCircle size={40} style={{ fill: color?color:defaultcolor }} />
    <Handle type="target" id='b' position="left" style={{ background: '#555' }} />
    <Handle type="target" id='c' position="right" style={{ background: '#555' }} />
    <Handle type="source" id='d' position="bottom" style={{ background: '#555' }} />
    <div style={labelStyles}>
      {label}
    </div>

  </div>
  );
};

export default Condtionnode;