import React, { useEffect, useMemo } from 'react'
import Session from "react-countdown";
import { useLocation } from 'react-router-dom';
export default function TimerUtility(props) {
  const { pathname } = useLocation()
  console.log("time", props.time)
  let tid = 0
  let user = useMemo(() => {

    return <Session date={Date.now() + parseInt(props.time)} />;
  }, [props.time,pathname])
  useEffect(() => {

    if (!(pathname.includes("editor"))) {

      tid = setTimeout(props.callback, props.time);
    }
    sessionStorage.setItem('timeid', tid)
    return () => clearTimeout(tid);

  }, [props.time]);
  return (
    <span>
      {user}
    </span>
  )
}
