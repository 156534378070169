import React from 'react'
import Banner from './Banner/Banner'
import Dashboardtitle from './Dashboardtitle'
import Cards from './Cards/Cards'
import { getValueFromSession } from '../UtilityFunctions/getSetValueSession'
import { checkAndReturnName } from './dashboardutils'
import useGetLatest from '../hooks/useGetLatest'
//import styles 
import './dashboard.scss'

const Dashboard = () => {
  const { latestUpcoming, latestCompleted, loading } = useGetLatest()
  const fname = getValueFromSession('firstname')
  const lname = getValueFromSession('lastname')
  let uname = getValueFromSession('username')
  console.log("fname", fname)
  console.log("lname", lname)
  const name = checkAndReturnName(fname, lname, uname)

  return (
    <section className='pravi_dashboard_main'>
      <Banner name={name} latestUpcoming={latestUpcoming}
        latestCompleted={latestCompleted} />
      {/* <Dashboardtitle /> */}
      <Cards />
      {/* <div className='dashboard_cards'></div> */}
    </section>
  )
}

export default Dashboard