import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { useState } from "react";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import '../../ManageSchedule/TableData/tabledata.scss'
function ViewDetails({ modalData, setModalData }) {
  const [collapse, setCollapse] = useState(false);
  const styleobj = {
    style: {
      borderRadius: 14,
      maxHeight: "40rem",
      minWidth: "25rem",
      overflow: "hidden",
    },
  };
  const handleClose = () => {
    setModalData({ ...modalData, modal: false, item: null });
  };

  function checkdata(item) {
    if (item.length > 0 && item[0]) {
      return true;
    }
    return false;
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <Dialog open={modalData.modal} PaperProps={styleobj}>
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "6px",
        }}
      >
        <span style={{ marginLeft: "1.5rem" }}> Details</span>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {checkdata(modalData.item.json_agg) ? (
        <DialogContent>
          <div className="assement-main">
            <label>Test name :</label>
            <span className="input">{modalData.item.name}</span>
            <label>Duration :</label>
            {console.log("modal data", modalData)}
            <span className="input">{modalData.item.duration} minutes</span>
            <label>Difficulty level :</label>
            <span className="input">{capitalizeFirstLetter(modalData.item.league)}</span>
       
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#fbfafc",
              }}
              onClick={() => setCollapse(!collapse)}
            >
              <label>{`Exercises(${modalData.item.noofexids})`}</label>
              <span style={{ marginTop: "0.3rem" }}>
                {collapse ? (
                  <MdOutlineKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </span>
            </div>
            <Collapse in={collapse}>
              <section className="table-container display_data">
                <table>
                  <thead>
                    <th>#</th>
                    {process.env.REACT_APP_SHOW_EXID_CREATE === 'true' && <th>Exercise Id</th>}
                    <th>Description</th>
                    <th>Category</th>
                    <th>Subcategory</th>
                    <th>Level</th>
                    {/* <th>operation</th> */}
                  </thead>
                  <tbody>
                    {modalData.item.json_agg.map((item, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        {process.env.REACT_APP_SHOW_EXID_CREATE === 'true' && <td>{item.exid}</td>}
                        <td>{item.description}</td>
                        <td>{item.category}</td>
                        <td>{item.subcategoryid}</td>
                        <td>{item.level}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </section>
            </Collapse>
          </div>
        </DialogContent>
      ) : (
        <span style={{ display: "flex", justifyContent: "center" }}>
          No Data Available.
        </span>
      )}
      <DialogActions sx={{ marginRight: "1rem" }}>
        <button
          className="button primary"
          style={{ marginBottom: "1rem" }}
          onClick={handleClose}
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewDetails;
