import React, { useContext, useMemo, useState } from 'react'
import pravnyamlogo from "../../assets/Pravi Logo_2.png";
import TimerUtility from '../UtilityFunctions/TimerUtility'
import AppContext from '../AppContext/AppContext';
import { reset } from '../UserAssessment/Cards';
import Profile from './Profile';
import { useAuth } from '../hooks/useAuth';
// import libraries
import { useLocation } from 'react-router';
import Session from "react-countdown";
// import styles
import './navbar.scss'



const Navbar = () => {
  const { pathname } = useLocation();
  const {
    assessmentAuth,
    isAuthenticated,
    logout,
    testStarted } = useAuth()
  const timer = useContext(AppContext)
  // console.log("in navbar")
  const [time, setTime] = useState(
    () => {
      let time;
      // console.log("in usestate")
      if (pathname.includes("editor")) {
        // console.log("useState if")

        time = (parseInt(sessionStorage.getItem("duration"))) * 60 * 1000 - (Date.now() - parseInt(sessionStorage.getItem('time')))
      }
      else {
        // console.log("useState else")
        // console.log("time else", ((60000 * 90) - (Date.now() - parseInt(sessionStorage.getItem('time')))))
        time = (60000 * 90) - (Date.now() - parseInt(sessionStorage.getItem('time')))

      }
      return time
    }
  );
  console.log("time", time,parseInt(sessionStorage.getItem("duration")),parseInt(sessionStorage.getItem('time')))
  console.log("timer", timer.timer)
  // console.log("duration", parseInt(sessionStorage.getItem("duration")))
  // let session = true
  const TimeOutId = useMemo(() => {


    if (pathname.includes("editor")) {
      console.log("memo st if")
      if (timer.timer && pathname.includes("editor")) {
        if (sessionStorage.getItem("duration") !== undefined) {
          setTime(parseInt(sessionStorage.getItem("duration")) * 60 * 1000 - (Date.now() - parseInt(sessionStorage.getItem('time'))));
        } else {
          setTime(60000 * 90 - parseInt(Date.now() - parseInt(sessionStorage.getItem("time"))));
        }
      }
      timer.setTimer(true);

      return <Session date={Date.now() + time} />;
    }
    timer.setTimer(true)
  }, [timer.timer, pathname]);


  return (
    <section className="pyui_main-appbar">
      <img src={pravnyamlogo} className="pyui_logo" alt='pyui_logo' title="Pravi" />

      <h1 className="pyui_title">Pravi assessment</h1>

      <ul className="pyui_navlinks">
        {(pathname.includes("/editor")) ? (
          <li style={{ color: "#f58634" }}>Time Remaining : <TimerUtility time={time} callback={logout} reset={reset} /> </li>
        ) : (
          <></>
        )}
      </ul>
      {!testStarted && <Profile />}
    </section>
  )
}

export default Navbar