import { useNavigate } from "react-router";
import { useState } from "react";
import useLibraryCardsData from "../../hooks/useLibraryCardsData";
import useTemplateBundle from "../../hooks/useTemplateBundle";
import LoaderUtility, { LoaderUtility1 } from "../../UtilityFunctions/Loader_utility";
import { FaCheckCircle } from "react-icons/fa";
import './bundle.scss'

const Tracks = ({ selectedTrack, setSelectedTrack, tracks }) => {


  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const renderOptions = (options) => (
    <>
      <option value="all">All</option>
      {options
        .filter((item) => item !== "all")
        .map((option) => (
          <option key={option} value={option}>
            {capitalizeFirstLetter(option)}
          </option>
        ))}
    </>
  );

  return (
    <label className="bundle-tracks">
      <span>Select track:</span>
      <select value={selectedTrack} onChange={e => setSelectedTrack(e.target.value)}>
        {renderOptions(tracks)}
      </select>
    </label>
  )
}

const TemplateBundle = () => {
  const [selectedTrack, setSelectedTrack] = useState("all");
  const [noOfAssessment, setNoOfAssessment] = useState(4);
  const [bundleName, setBundleName] = useState('');
  const [visibility, setVisibility] = useState("private");
  const [description, setDescription] = useState('')
  const { uniqueTracks, loader } = useLibraryCardsData();
  const { generateAssessment, generateLoader } = useTemplateBundle()
  let navigate = useNavigate();

  function generate(e) {
    e.preventDefault()
    generateAssessment(selectedTrack, noOfAssessment, bundleName, visibility, description)
  }

  function ShowBdlGeneratedButton() {
    return (
      <>
        <label className="bundle-generated-text">
          <span>Bundle generated</span>
          <FaCheckCircle size={15} color="white" />
        </label>
      </>
    )
  }


  return (
    <section className="bundle">
      <section>
        <button
          className="button link-button"
          onClick={(e) => {
            navigate(-1);
          }}>
          Cancel creation
        </button>
      </section>
      <section className='bundle-main'>
        <form className='bundle-form' onSubmit={generate}>
          <h3 className='title'>Template Bundle</h3>
          <section>
            <label>Enter template bundle name:</label>
            <input type="text" placeholder='Enter template bundle name' className='input' value={bundleName} onChange={e => setBundleName(e.target.value)} />
          </section>

          <section className="bundle-track-assessment">
            <Tracks selectedTrack={selectedTrack}
              tracks={uniqueTracks}
              setSelectedTrack={setSelectedTrack} />
            <section>
              <label>Enter no of Assessment:</label>
              <input type="number" min={0} value={noOfAssessment} defaultValue={noOfAssessment} className='input' onChange={e => setNoOfAssessment(e.target.value)} />
            </section>
          </section>

          <section>
            <label>Enter description:</label>
            <input type="text" placeholder='Enter description' className='input' onChange={e => setDescription(e.target.value)} />
          </section>

          <section className="bundle-markprivate">
            <input type="checkbox"
              defaultChecked
              onChange={e => setVisibility(e.target.checked ? "private" : "public")} />
            <label>Mark this template as private. </label>
          </section>

          <footer>
            <button type="submit"
              className={`button small ${generateLoader.state ? 'complete' : 'primary'} `}>
              {generateLoader.loader
                ? <LoaderUtility loadingMsg="Generating bundle" loading={generateLoader.loader} loaderColor='#fff' />
                : generateLoader.state
                  ? <ShowBdlGeneratedButton />
                  : 'Generate bundle'}
            </button>
          </footer>
        </form>
      </section>
    </section>
  )
}

export default TemplateBundle