import React, { useRef, useState } from "react";
import { renderSortIcon, sortData, toggleSelectAll } from "./tableDataUtils";
import { useCheckCheckBoxHeader, useSortTableOnce } from "./useTableUtils";
import { checkIfDataExists } from "./checkIfDataExists";
import useTestStart, { useCancelTest } from "../../hooks/useTestStart";
import Drawer from "../../CustomDrawer/Drawer";
// import TableSearch from './TableSearch';
import useUpdateScheduleTest from "../../hooks/useUpdateScheduleTest";
// import Pagination from './Pagination/Pagination';
// import PinnedRows from './PinnedRows/TablePinnedRows'
import { Tooltip } from "react-tooltip";
import ExpandableRow from "./ExpandableRow/ExpandableRow";
import { fetchData } from "../../ApiUtils/fetchData";
// import libraries
import { MdOutlineArchive } from "react-icons/md";
import { IoMdArchive } from "react-icons/io";
import { Link } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { RiCloseCircleLine } from "react-icons/ri";
// import styles
import "./tabledata.scss";
import ViewUserDetails from "./ViewUserDetails";
import { setValueInSession } from "../../UtilityFunctions/getSetValueSession";
import CombinedResult from "../../CombinedResult/CombinedResult";
import {CustmizeConfirmDialog as ConfirmDialog} from "../../UtilityFunctions/ConfirmDialog";

const TableData = ({
  selectAll,
  testData,
  setTestData,
  setSelectedRows,
  selectedRows,
  setSelectAll,
  loading,
  title,
  archiveUnarchive,
  handleDrawer,
  drawerOpen,
  drawerType,
  handleFilterArchiveRows,
  handleToggleSelectAll,
  setArchiveIds,
  archiveIds,
  isCheckBox,
}) => {
  let { startTest, testloading } = useTestStart();
  let { cancelTest } = useCancelTest();
  const { UpdateScheduleTest, upsloading } = useUpdateScheduleTest();
  const [started, setStarted] = useState([]);
  const [cellId, setCellId] = useState("");
  const [testName, setTestName] = useState("");
  const [pinnedRows, setPinnedRows] = useState([]);
  const [sortDirection, setSortDirection] = useState({
    column: "scheduleid",
    direction: "asc",
  });
  const [searchQueries, setSearchQueries] = useState({
    scheduleId: "",
    testName: "",
    scheduledBy: "",
    scheduleTime: "",
    valiTill: "",
    markingScheme: "",
    showResult: "",
    status: "",
    actions: "",
    // Add search queries for other columns
  });
  const [userDetails, setUserDetails] = useState([]);
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [selectedSchedule, setSelectedSchedule]= useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isShowConfirmBox, setIsShowConfirmBox]= useState(false)
  const headers2 = [
    { id: "1", field: "userid", headerName: "Participants " },
    { id: "2", field: "isattempted", headerName: "Attempted", isSort: false },
  ];
  const getUserdetails = async (selectedRows) => {
    setValueInSession("selectedscheduleId", selectedRows);
    setIsLoading(true);
    setIsShowDetails(true);

    // let userDetails= await fetchData(`getscheduleuserlist?scheduleid=${selectedRows[selectedRows?.length-1]}`)
    let userDetails = await fetchData(
      `getscheduleuserlist?scheduleid=${selectedRows}`
    );
    console.log("userdetails ####", userDetails);
    setUserDetails(
      userDetails.data?.map((item) => {
        return {
          userid: item.userid,
          isattempted: item.isattempted ? (
            <TiTick color="#4de04d" size={22} />
          ) : (
            <RiCloseCircleLine color="red" size={22} />
          ),
        };
      })
    );
    setIsLoading(false);
  };
  const headers = [{ id: "1", field: "userid", headerName: "Participants " }];
  // const getUserdetails=async(scheduleId)=>{

  //   setIsShowDetails(!isShowDetails)
  //   let userDetails= await fetchData(`getscheduleuserlist?scheduleid=174`)
  //   console.log('userdetails ####', userDetails)
  //   setUserDetails(userDetails.data)
  // }
  let isOnlyOneSelect = title === "Ongoing Test";
  const [filteredData, setFilteredData] = useState(testData);
  const [testTitle, setTestTitle] = useState("");
  const size = 20;
  const cursor = "pointer";
  const deleteIconRef = useRef(null);

  const handleSort = (columnKey) => {
    sortData(
      columnKey,
      sortDirection,
      setSortDirection,
      setFilteredData,
      filteredData
    );
  };

  const handleSortIconRender = (columnKey) => {
    return renderSortIcon(columnKey, sortDirection);
  };

  console.log("archiveIds", archiveIds);

  // useIconVisibility(deleteIconRef, selectedRows, filteredData);

  // useSearchTableQueries(searchQueries, testData, setFilteredData)

  const handleSearchChange = (column, value) => {
    setSearchQueries((prevQueries) => ({
      ...prevQueries,
      [column]: value,
    }));
  };

  // const handlePagination = () => {
  //   return (
  //     <Pagination
  //       currentPage={currentPage}
  //       pageCount={pageCount}
  //       nextPage={nextPage}
  //       prevPage={prevPage}
  //       goToPage={goToPage}
  //       goToFirstPage={goToFirstPage}
  //       goToLastPage={goToLastPage}
  //     />
  //   )
  // }

  const handleDelete = (scheduleId) => {
    alert(`Test with schedule ID ${scheduleId} would be deleted`);
  };

  const handleRowExpand = (scheduleId) => {
    setCellId(scheduleId);
  };
  const handleTestName = (testName) => {
    setTestName(testName);
  };

  const handleSetScheduleId = (scheduleId) => {
    setCellId(scheduleId);
  };

  const handleTesttitle = (title) => {
    setTestTitle(title);
  };

  const handleCheckIfDataExists = () => {
    return checkIfDataExists(
      filteredData,
      selectedRows,
      setSelectedRows,
      setSelectedSchedule,
      selectedSchedule,
      size,
      cursor,
      handleDelete,
      title,
      startTest,
      testloading,
      started,
      setStarted,
      cellId,
      handleRowExpand,
      pinnedRows,
      setPinnedRows,
      setTestData,
      handleTestName,
      testName,
      handleDrawer,
      drawerOpen,
      handleMarkTestComplete,
      showConfirmDialogBox,
      upsloading,
      handleSetScheduleId,
      cancelTest,
      setArchiveIds,
      archiveIds,
      handleTesttitle,
      isOnlyOneSelect,
      getUserdetails,
      isCheckBox
    );
  };

  const cancelCallback=()=>{
    setIsShowConfirmBox(false)
  }
function showConfirmDialogBox(){
  setIsShowConfirmBox(true)
}
  const handleMarkTestComplete = async(status, scheduleid) => {
   await UpdateScheduleTest(
      scheduleid,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      status
    );
    setIsShowConfirmBox(false)
  };

  useSortTableOnce(handleSort);

  return (
    <section>
      {/* {loading ? (
        <LoaderUtility loading={loading} />
      ) : ( */}
      <Tooltip
        style={{ zIndex: 1 }}
        anchorSelect="#archive"
        content="Archive selected"
        place="left"
      />
      <Tooltip
        style={{ zIndex: 1 }}
        anchorSelect="#show"
        content="Show archived"
        place="top"
      />
      <section className="display_data_test">
        <ConfirmDialog
          msg={'This action will mark the test as complete. No new results can be submitted.'}
          cancelCallback={cancelCallback}
          isLoading={upsloading}
          isDisableBackGround={true}
          isShowConfirmBox={isShowConfirmBox}
          sucessCallback={()=>handleMarkTestComplete('completed',cellId)}
        />
        {/* <div className='display_data_test_back_data'> */}

        <div className="display_data_title_table">
          <div className="display_data_title_rcount">
            <Link to={"/"} className="link-button">
              Back
            </Link>
            {/* <h3 className='display_data_title'>{title}</h3> */}
            <p className="display_data_rows_count">
              {selectedRows?.length} row(s) selected of {filteredData?.length}{" "}
              row(s)
            </p>
          </div>
          {title == "UpcomingTest" && (
            <div style={{ justifySelf: "flex-end", marginRight: "0.8rem" }}>
              <Link
                className="button primary"
                style={{ maxWidth: "7rem" }}
                to={"/testlibrary"}
              >
                Add{" "}
              </Link>
            </div>
          )}
          {title === "PastTest" && (
            <section className="display_data_icon">
              <div className="archive_button">
                <span id="archive">
                  <button
                    className="left-half"
                    disabled={archiveIds?.length === 0}
                    onClick={async () => {
                      handleFilterArchiveRows(
                        archiveIds,
                        filteredData,
                        setFilteredData
                      );
                      await archiveUnarchive(archiveIds, true);
                    }}
                  >
                    <IoMdArchive cursor={cursor} size={size} />
                  </button>
                </span>
                <button
                  className="right-half"
                  id="show"
                  disabled={archiveIds?.length > 0}
                  onClick={() => handleDrawer("archive")}
                >
                  <MdOutlineArchive cursor={cursor} size={size} />
                </button>
              </div>
              <CombinedResult
                data ={testData}
                selectedScheduleid={selectedRows}
                />
            </section>
          )}
        </div>
        {/* </div> */}
        <section className="table-container display_data ">
          {/* <section className='sticky-header'> */}
          <table>
            <thead>
              {isCheckBox && (
                <th>
                  <input
                    disabled={loading || testData?.length === 0}
                    type="checkbox"
                    checked={selectAll}
                    onChange={() => handleToggleSelectAll(filteredData)}
                  />
                </th>
              )}
              <th>
                <span
                  onClick={() => handleSort("scheduleid")}
                  className="input-search"
                >
                  <header>Schedule Id</header>
                  {handleSortIconRender("scheduleid")}
                  {/* <input
                    type="text" className='search-input'
                    value={searchQueries.scheduleId}
                    onChange={e => handleSearchChange('scheduleId', e.target.value)}
                    placeholder=""
                  /> */}
                </span>
              </th>
              <th>
                <span
                  onClick={() => handleSort("name")}
                  className="input-search"
                >
                  <header>Test Name</header>
                  {handleSortIconRender("name")}
                  {/* <input
                    type="text" className='search-input'
                    value={searchQueries.testName}
                    onChange={e => handleSearchChange('testName', e.target.value)}
                    placeholder=""
                  /> */}
                </span>
              </th>
              <th>
                <span
                  onClick={() => handleSort("title")}
                  className="input-search"
                >
                  <header>Title</header>
                  {handleSortIconRender("title")}
                </span>
              </th>
              <th>
                <span
                  onClick={() => handleSort("scheduledby")}
                  className="input-search"
                >
                  <header>Scheduled by</header>
                  {handleSortIconRender("scheduledby")}
                  {/* <input
                    type="text" className='search-input'
                    value={searchQueries.scheduledBy}
                    onChange={e => handleSearchChange('scheduledBy', e.target.value)}
                    placeholder=""
                  /> */}
                </span>
              </th>
              <th>
                <span
                  onClick={() => handleSort("scheduletime")}
                  className="input-search"
                >
                  <header>Schedule time</header>
                  {handleSortIconRender("scheduletime")}
                  {/* <input
                    type="text" className='search-input'
                    disabled
                    value={searchQueries.scheduleTime}
                    onChange={e => handleSearchChange('scheduleTime', e.target.value)}
                    placeholder=""
                  /> */}
                </span>
              </th>
              <th>
                <span
                  onClick={() => handleSort("validtill")}
                  className="input-search"
                >
                  <header>Valid till</header>
                  {handleSortIconRender("validtill")}
                  {/* <input
                    type="text" className='search-input'
                    disabled
                    value={searchQueries.valiTill}
                    onChange={e => handleSearchChange('valiTill', e.target.value)}
                    placeholder=""
                  /> */}
                </span>
              </th>

              <th>
                <span
                  onClick={() => handleSort("markingscheme")}
                  className="input-search"
                >
                  <header>Marking scheme</header>
                  {handleSortIconRender("markingscheme")}
                  {/* <input
                    type="text" className='search-input'
                    disabled
                    value={searchQueries.markingScheme}
                    onChange={e => handleSearchChange('markingScheme', e.target.value)}
                    placeholder=""
                  /> */}
                </span>
              </th>
              <th>
                <span
                  onClick={() => handleSort("showresult")}
                  className="input-search"
                >
                  <header>Show result</header>
                  {handleSortIconRender("showresult")}
                  {/* <input
                    type="text" className='search-input'
                    disabled
                    value={searchQueries.showResult}
                    onChange={e => handleSearchChange('showResult', e.target.value)}
                    placeholder=""
                  /> */}
                </span>
              </th>
              {title === "PastTest" && (
                <th>
                  <span
                    onClick={() => handleSort("status")}
                    className="input-search"
                  >
                    <header>Status</header>
                    {handleSortIconRender("status")}
                    {/* <input
                    type="text" className='search-input'
                    value={searchQueries.status}
                    onChange={e => handleSearchChange('status', e.target.value)}
                    placeholder=""
                  /> */}
                  </span>
                </th>
              )}
              <th>
                <span className="input-search">
                  <header>Actions</header>
                </span>
              </th>
            </thead>
            {/* </table> */}

            {/* </section> */}
            {/* <table> */}
            {/* <tbody> */}
            {handleCheckIfDataExists()}
            {/* </tbody> */}
          </table>
          {drawerOpen && drawerType === "expandablerow" && (
            <Drawer isOpen={drawerOpen} onClose={handleDrawer}>
              <ExpandableRow
                cellId={cellId}
                testName={testName}
                title={title}
                handleMarkTestComplete={handleMarkTestComplete}
                upsloading={upsloading}
                testTitle={testTitle}
              />
            </Drawer>
          )}
          <ViewUserDetails
            setIsShowDetails={setIsShowDetails}
            isShowDetails={isShowDetails}
            headers={headers2}
            // selectedRows={cell.scheduleid}
            userDetails={userDetails}
            isLoading={isLoading}
            title={title}
            getUserdetails={getUserdetails}
          />
        </section>
        {/* Pagination here */}
        {/* {handlePagination()} */}
      </section>
      {/* )} */}
    </section>
  );
};

export default TableData;
