
import axios from "axios";
// import ApiConfig from "../ApiConfig";
import ApiConfig from "../../ApiConfig";
export const fetchData = async (apiUrl) => {
  const Api = new ApiConfig();

  try {
    const response = await axios.get(Api.addApiKey(`${Api.BaseURI}/${apiUrl}`));
    return response.data;
  } catch (error) {
    console.log("error", error.message)
    // throw new Error(`Error fetching data from ${apiUrl}: ${error.message}`);
  }
};