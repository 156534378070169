// import react comp or files
import { fetchData } from '../ApiUtils/fetchData';
// import libraries
import { useQuery } from '@tanstack/react-query';

const useFetchWithCacheData = (apiUrl) => {
  return useQuery({
    queryKey: ['myData', apiUrl],
    queryFn: () => fetchData(apiUrl),
    // Include any desired query options here (e.g., staleTime, refetchInterval)
  });
}

export default useFetchWithCacheData