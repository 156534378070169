import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import './customnode.css';
import { BsDiamond } from 'react-icons/bs'

import { FiCircle } from 'react-icons/fi'
const Startnode = ({ data, id }) => {


  const { width, height, color, borderColor, shape, label } = data;


  const labelStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    fontSize: '12px',
  };

  return (
    <div className={`${shape}`}
    style={{background:`${color?color:'#fbd086'}`}}
    >
      <Handle type="source" id='a' position="bottom" style={{ background: '#555' }} />
      <div style={labelStyles}>
        {label}
      </div>
    </div>
  );
};

export default Startnode;