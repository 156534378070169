import React from "react";
import { IoMdClose } from "react-icons/io";
import PulseLoader from "react-spinners/PulseLoader";
import "./ConfirmDialog.scss";

export default function ConfirmDialog({
  msg,
  cancelCallback,
  sucessCallback,
  isShowConfirmBox,
  showCancelBtn,
}) {
  return (
    isShowConfirmBox && (
      <>
        <div className="modal-backlayer"></div>
        <div className="container">
          <h3>Confirmation</h3>
          <div className="confirmation-text">{msg}</div>
          <div className="button-container">
            {showCancelBtn !== undefined && showCancelBtn && (
              <button
                className="button link-button"
                onClick={() => cancelCallback()}
              >
                Cancel
              </button>
            )}
            {showCancelBtn === undefined && (
              <button
                className="button link-button "
                onClick={() => cancelCallback()}
              >
                Cancel
              </button>
            )}
            <button className="button primary" onClick={sucessCallback}>
              Proceed
            </button>
          </div>
        </div>
        {/* <div 
      className="confirm-bg">
      onClick={() => handleConfirmationBox()}
    </div> */}
      </>
    )
  );
}




export  function CustmizeConfirmDialog({msg, cancelCallback, sucessCallback,isShowConfirmBox, showCancelBtn, successBtnName, isDisableBackGround, isLoading, loadingMsg, headerName}) {
  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0%",
    zIndex: "10",
  };
  return (
    (isShowConfirmBox) && 
    <div className={isDisableBackGround && 'container-wrapper'}>
   <div className="modal-backlayer"></div>
    <div className="container">
      <div className='dialog-title'>

        <span className='confirmation-title'><h3>{headerName?headerName:'Confirmation'}</h3>
        </span>
      <span>

      <IoMdClose size={20} onClick={cancelCallback} />
     
      </span>
      </div>
{/* <hr/> */}
      <div className="confirmation-text">
        {isLoading ?  
                <div className='loader-div'>
                 {loadingMsg?loadingMsg:'Loading'} <PulseLoader
                    color="#7b1fa2"
                    loading={isLoading}
                    cssOverride={override}
                    size={10}
                    className="pulse-loader"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                  </div>
                
             :
              msg}
      </div>
      <hr/>
      {!isLoading&&<div className="button-container">
        {(showCancelBtn !== undefined && showCancelBtn ) &&
        <button 
          className="button " 
          onClick={() => cancelCallback()}>
            Cancel
        </button>
        
        }
        {
          (showCancelBtn === undefined ) &&
          <button 
            className="button " 
            onClick={() => cancelCallback()}>
              Cancel
          </button>
        }
        <button 
          className="button primary"
          onClick={sucessCallback}>
            {(successBtnName !== undefined) ? successBtnName:'Proceed'}
          </button>
      </div>}
    </div>
    {/* <div 
      className="confirm-bg">
      onClick={() => handleConfirmationBox()}
    </div> */}
  </div>
  

  )
}
