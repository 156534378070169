import React from 'react'
import useFetchWithCacheData from './useFetchData';

export const useGetAvailable = (userid, schedulestatus, teststatus) => {
  const uri = `getAvailable?userid=${userid}&teststatus=${teststatus}&schedulestatus=${schedulestatus}`;
  const { data, status } = useFetchWithCacheData(uri);

  return { data, status }

}


export const usePastAssessment = (userid, schedulestatus, teststatus) => {
  const uri = `getAvailable?userid=${userid}&teststatus=${teststatus}&schedulestatus=${schedulestatus}`;
  const { data, status } = useFetchWithCacheData(uri);

  return { data, status }
}