import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
export default function AssignDialog({onClose,onNodeSelect,codedata,nodeid,updateData}) {
console.log("codedata",codedata);
// console.log("localdata23",localdata)
    const [variable,setvariable]=useState('')
    const [value,setValue]=useState('')
    const [open,setOpen]=useState(true)

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handlevariable(e){
    
    setvariable(e)
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        variable:e
      }
    }
console.log("codedata22",codedata)
  }

  
  function handlevalue(e){
    
    setValue(e)
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        variable:variable,
        value:e
      }
    }
console.log("codedata23",codedata)
  }
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
 
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Assign</DialogTitle>
        <DialogContent>
          <DialogContentText>
        
          </DialogContentText> 
          <div style={{display:'flex',gap:'9.3rem',marginBottom:'0.5rem'}}>
            <label>Variable:</label>
            <label>Expression/value:</label>
          </div>
        <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
       <input   type='text' className='input' placeholder='enter variable' style={{width:'50%'}}value={variable} onChange={(e)=>{handlevariable(e.target.value)}}></input> <label> = </label><input  className='input' type='text' placeholder='enter expression' style={{width:'50%'}} value={value} onChange={(e)=>{handlevalue(e.target.value)}}></input>
       </div>
        </DialogContent>
        <DialogActions>
          <div
         className='flowchart_btn' >
                     <button className='button secondary' onClick={onClose}>close</button>

        <button className='button primary' onClick={(e)=>{
        let newlabel=variable+'='+value
         onNodeSelect(newlabel,codedata)
        }}>ok</button>
        </div>
           </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}