import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './Components/redux/store';
import Layout from './Layout';
import { AppContextProvider } from './Components/AppContext/AppContext';
import { AuthProvider } from './Components/hooks/useAuth';
// import libraries
import { Provider } from 'react-redux';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { BrowserRouter as Router } from 'react-router-dom';
// import styles
import './index.scss';
import axios from './Components/decryptfunction'
import NoInternetConnection from './Components/ErrorWrappers/NoInternetWrapper';
import ErrorHandler2 from '../src/Components/ErrorWrappers/ErrorHandler2';

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));
if (process.env.REACT_APP_SHOW_CONSOLE_LOGS === 'true') {
  console.log = () => { };
}
root.render(

  <React.StrictMode>
 
    <Router>
    <NoInternetConnection>
      <ErrorHandler2>
      <AuthProvider >
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <AppContextProvider>
              <Layout />
            </AppContextProvider>
          </QueryClientProvider>
        </Provider>
      </AuthProvider>
      </ErrorHandler2>
    </NoInternetConnection>
    </Router>
  </React.StrictMode>

);
