import { getValueFromSession } from "../../UtilityFunctions/getSetValueSession";

export const filtered = (query, onFilterChange, filteredData) => {
  let userid = getValueFromSession('username')
  let filter = [];
  if (filteredData) {
    filter = filteredData
      .filter((item) =>
        item.name.match(new RegExp(query, 'i')) &&
        (onFilterChange.selectedLeague === 'all' || item.league === onFilterChange.selectedLeague) &&
        (onFilterChange.selectedTrack === 'all' || item.track === onFilterChange.selectedTrack) &&
        ((onFilterChange.selectedTemplate === 'all') || (item.name.match(new RegExp(onFilterChange.selectedTemplate, 'i')) && item.isinbundle)) &&
        (
          onFilterChange.selectcreate === 'All templates' ||
          (item.createdby === userid)
        )
      );
  }


  return filter
}
