import React, { useRef } from "react";
import { GrGroup } from "react-icons/gr";
// import { BsThreeDotsVertical } from "react-icons/bs";
export default function MoreOption({ optionsArr, cell, getUserdetails }) {
  // const [isShowOptions, setShowOptions] = useState(false);
  let menuRef = useRef();
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //       setShowOptions(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [menuRef]);
  return (
    <>
      <ul>
        <li ref={menuRef} style={{ position: "relative" }}>
          <button
            className=" icon-button small"
            // ref={menuRef}
            title="Participants"
            // onClick={() => setShowOptions(!isShowOptions)}
            onClick={() => {
              getUserdetails(cell);
              // setShowOptions(false);
              console.log("onclick");
            }}
          >
            <GrGroup size={17} />
          </button>

          {/* {isShowOptions && (
            <>
              <div className="pyui_dropdown-profile" style={{ top: "1.75rem" }}>
                <ul className="pyui_dropdown-userlist">
                  <li>
                    <div
                      tabIndex={2}
                      className="pyui_profile"
                      title="view participants details"
                      onClick={() => {
                        getUserdetails(cell);
                        // setShowOptions(false);
                        console.log("onclick");
                      }
                    }
                    >
                      <GrGroup />
                    </div>
                  </li>
                </ul>
              </div>
            </>
          )} */}
        </li>
      </ul>
    </>
  );
}
