import { useState } from 'react';
import TableData from '../TableData/TableData';
import LoaderUtility from '../../UtilityFunctions/Loader_utility';
import useManageTest from '../../hooks/useManageTest';
import { useCheckCheckBoxHeader } from '../TableData/useTableUtils';
import { toggleSelectAll } from '../TableData/tableDataUtils';
// import styles
import './ongoingtest.scss'


const OngoingTest = () => {
  const { ongoingtest, setOngoingtest, loading } = useManageTest()
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [sortDirection, setSortDirection] = useState({ column: 'scheduleid', direction: 'asc' });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');
  const [archiveIds, setArchiveIds] = useState([]);
  console.log("ongoingtest", ongoingtest)
  console.log("ongoingtest", ongoingtest)

  useCheckCheckBoxHeader(selectedRows, setSelectAll, archiveIds)

  const handleToggleSelectAll = (rows) => {
    toggleSelectAll(selectAll, rows, setSelectedRows, setSelectAll, setArchiveIds)
  }

  const handleFilterArchiveRows = (rowsIds, alldata, setFilter) => {
    const updatedData = alldata.filter(row => {
      return !rowsIds.includes(row.scheduleid);
    });
    setFilter(updatedData);
    setSelectedRows([])
    setArchiveIds([])
  }

  const handleDrawer = (drawerType) => {
    setDrawerType(drawerType)
    setDrawerOpen(prev => !prev)
  }

  // const handleSort = (columnKey) => {
  //   sortData(columnKey, sortDirection, setSortDirection, setOngoingtest, ongoingtest)
  // }

  // const handleSortIconRender = (columnKey) => {
  //   return renderSortIcon(columnKey, sortDirection)
  // }

  // useSortTableOnce(handleSort)



  return (
    <section className='ongoingtest_main'>
      {loading ? (
        <LoaderUtility loading={loading} />
      ) : (
        <>
          <TableData
            selectAll={selectAll}
            testData={ongoingtest}
            setTestData={setOngoingtest}
            // testDataHeaders={upcomingTestHeaders}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            handleDrawer={handleDrawer}
            handleFilterArchiveRows={handleFilterArchiveRows}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            setArchiveIds={setArchiveIds}
            archiveIds={archiveIds}
            drawerType={drawerType}
            setDrawerType={setDrawerType}
            setSelectAll={setSelectAll}
            loading={loading}
            title={"Ongoing Test"}
            handleToggleSelectAll={handleToggleSelectAll}
            isCheckBox={false}
          // handleSort={handleSort}
          // handleSortIconRender={handleSortIconRender}
          />
        </>
      )}
    </section>
  )
}

export default OngoingTest