import React, { useState } from 'react'
import { FaUserMinus, FaUserPlus } from "react-icons/fa";
import useManageUsers from './useManageUsers';
import { date_helper } from '../../UtilityFunctions/dateHelper';
import { postData } from '../../ApiUtils/postData'
import FormUI from './FormUI';
import { useNavigate } from 'react-router';
import { getValueFromSession } from '../../UtilityFunctions/getSetValueSession';
import { LoaderUtility1 } from '../../UtilityFunctions/Loader_utility'
import './manageuser.scss'

const ManageUsers = () => {
  const { users, loading } = useManageUsers()
  const navigate = useNavigate()
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  // const [group, setGroup] = useState("");
  const [role, setRole] = useState("");
  const [tags, setTag] = useState("");
  const [firstname, setFname] = useState("");
  const [lastname, setLname] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const group = getValueFromSession('group_name')
  const handleCheckData = () => {
    if (users) {
      if (users.length > 0) {
        return (
          <>
            {users.map(user => (
              <tbody>
                <tr>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>{user.userid}</td>
                  <td>{user.role}</td>
                  <td>{date_helper(user.date_created)}</td>
                </tr>
              </tbody>
            ))}
          </>
        )
      } else {
        <tbody>
          <td colSpan="3">
            <p className="pyyi_table-zerostate">No schedule found!</p>
          </td>
        </tbody>
      }
    }
  }
  const handleFormDate = async (e) => {
    e.preventDefault();
    const uri = `createassessmentuser`

    const data = { userid, password, role, group, tags, firstname, lastname }
    let response = await postData(uri, data)
    console.log("post", response.data.status)
  }
  return (
    <>
      {loading ? (
        <LoaderUtility1 loading={loading} />
      ) : (
        <section className='manage-users-main'>
          <section className='heading'>
            <button title='Back' aria-label='Back' className="link-button small"
              onClick={() => navigate(-1)}>
              Back
            </button>
            <h4>User List</h4>
          </section>
          <section className='action'>
            <button title='Create User' aria-label='Create User'
              className="icon-button small"
              onClick={handleOpen}>
              <FaUserPlus />
            </button>
            <button title='Remove user' aria-label='Remove User'
              className="icon-button small">
              <FaUserMinus />
            </button>
          </section>
          <section className='table'>
            <table>
              <thead>
                <th>
                  <input type="checkbox" />
                </th>
                <th>UserId</th>
                <th>Role</th>
                <th>Date created</th>
              </thead>
              {handleCheckData()}
            </table>
          </section>

          {open && (
            <FormUI Close={handleClose} open={open}
              handleFormDate={handleFormDate}
              setPassword={setPassword} setRole={setRole} setTag={setTag}
              setUserid={setUserid} setLname={setLname} setFname={setFname} />
          )}
        </section>
      )}
    </>
  )
}

export default ManageUsers