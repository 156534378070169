import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItemAvatar } from '@mui/material';
export default function ForDialog({onClose,onNodeSelect,codedata,nodeid,updateData}) {
console.log("codedata",codedata);
// console.log("localdata23",localdata)
    const [value,setValue]=useState('')
    const [open,setOpen]=useState(true)

    const [direction,setdirection]=useState('increasing');
    const [stepby,setStepby]=useState(1);
    const [startvalue,setStartvalue]=useState(0);
    const [endvalue,setEndvalue]=useState(0);
    const [variable,setvariable]=useState('')


React.useEffect(()=>{

    codedata[nodeid.id]={
        id:nodeid.id,
        type:nodeid.type,
        data:{
          variable:variable,
          direction:direction,
          stepby:stepby,
          startvalue:startvalue,
          endvalue:endvalue
        }
      }
    console.log("changes code",codedata)
},[direction,stepby,startvalue,endvalue,variable])

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handlevariable(e){
    
    setvariable(e)
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        variable:e
      }
    }
console.log("codedata22",codedata)
  }

  
//   function handlevalue(e){
    
//     setValue(e)
//     codedata[nodeid.id]={
//       id:nodeid.id,
//       type:nodeid.type,
//       data:{
//         variable:variable,
//         value:e
//       }
//     }
// console.log("codedata23",codedata)
//   }
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
 
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>For</DialogTitle>
        <DialogContent>
          <DialogContentText>
        
          </DialogContentText> 
          {/* <div style={{display:'flex',gap:'9.3rem',marginBottom:'0.5rem'}}>
            <label>Variable:</label>
          </div>
        <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}> */}
         

       {/* <input   type='text' className='input' placeholder='enter variable' style={{width:'50%'}}value={variable} onChange={(e)=>{handlevariable(e.target.value)}}></input> <label> = </label><input  className='input' type='text' placeholder='enter expression' style={{width:'50%'}} value={value} onChange={(e)=>{handlevalue(e.target.value)}}></input> */}
       {/* </div> */}

       <div style={{display:'block',gap:'0.5rem'}}>

        <div style={{display:'flex',gap:'0.5rem',flexDirection:'column'}}>
            <label className='label'>Variable:</label>
            <input className="input" value={variable} onChange={(e)=>setvariable(e.target.value)} placeholder='Enter variable name'></input>
        </div>
        <div style={{display:'flex',width:'100%',gap:'1rem'}}>
            <div style={{display:'flex',gap:'0.5rem',flexDirection:'column'}}>
            <label className='label'>start value:</label>
            <input className='input' value={startvalue} onChange={(e)=>setStartvalue(e.target.value)} placeholder=''></input>
             </div>
             <div style={{display:'flex',gap:'0.5rem',flexDirection:'column'}}>
              <label className='label'>End value:</label>
                <input className='input' value={endvalue} onChange={(e)=>setEndvalue(e.target.value)} placeholder=''></input>
             </div>
         </div>
         <div style={{display:'flex',alignItems:"center",gap:'0.5rem'}}>
            
<div style={{display:'flex',flexDirection:'column',width:'65%',marginTop:'1rem',gap:'0.5rem'}}>
   <label>Direction:</label>

            <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}> 
            <input type='radio' value={direction} checked={direction=='increasing'} onChange={()=>setdirection('increasing')}></input>
            <label>increasing</label>
            </div>

            <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}> 
            <input type='radio' value={direction} checked={direction=='decreasing'} onChange={()=>setdirection('decreasing')}></input>
            <label>decreasing</label>
            </div>
  </div>
          <div>
            <label style={{marginBottom:'0'}}>Step by:</label>
            <input  className="input" type='number'value={stepby} onChange={(e)=>setStepby(e.target.value)} placeholder=''></input>
            </div>
         </div>
         <div>

         </div>
       </div>

        </DialogContent>
        <DialogActions>
          <div
         className='flowchart_btn' >
                     <button className='button secondary' onClick={onClose}>close</button>

        <button className='button primary' onClick={(e)=>{
        let newlabel=variable+'='+startvalue+'; '+variable+'<'+endvalue+'; '+variable+(direction=='increasing'?'++':'--')
         onNodeSelect(newlabel,codedata)
        }}>ok</button>
        </div>
           </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}