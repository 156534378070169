import React from 'react';
import { setResult } from './SetResult'

const BackButton = ({ currentStepIndex,
  setCurrentStepIndex,
  setIsLoading,
  exdatarr, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output, npvalue, step, evaluate, handlesetAttNtAttp }) => {

  const isFirstStep = currentStepIndex <= 0;

  // const back = () => {
  //   if (!isFirstStep) {
  //     setCurrentStepIndex((index) => {

  //       if(setIsLoading){
  //       setIsLoading(true);
  //       }

  //       if(setResult){
  //           setResult()
  //       }

  //       return index - 1;
  //     });
  //   }
  // };

  function back() {
    setCurrentStepIndex((index) => {
      if (index <= 0) {
        return index;
      }

      if (setIsLoading) {
        setIsLoading(true);
      }
      if (setResult) {
        setResult(exdatarr, currentStepIndex, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output)
      }

      return index - 1;
    });
    let results = evaluate(npvalue, step.exid, step.level)
    if (results) {
      handlesetAttNtAttp(results)
    }
  }


  return (
    <button
      className={`link-button ${isFirstStep ? 'disabled' : ''}`}
      onClick={back}
      disabled={isFirstStep}
    >
      <i className="fa-solid fa-chevron-left"></i>
      <span style={{ marginLeft: '5px' }}>Previous</span>
    </button>
  );
};

export default BackButton;