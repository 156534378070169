import React from 'react'
import { dateTimeHelper } from '../../../UtilityFunctions/dateHelper';
import LoaderUtility from '../../../UtilityFunctions/Loader_utility';
import useManageTest from '../../../hooks/useManageTest';
import { toggleRowSelection } from '../tableDataUtils';
// import libraries
import { MdOutlineUnarchive } from "react-icons/md";
import { Tooltip } from 'react-tooltip';
// import styles
import './archive.scss'

const Archived = ({ title, size, cursor, archiveUnarchive, handleFilterArchiveRows, archiveIds, selectedRows, setSelectedRows, setArchiveIds, selectAll, handleFetchAgain, handleToggleSelectAll }) => {
  const { loading, archivedTests, setArchivedTests } = useManageTest()
  return (
    <>
      <section className="archiverow">
        <Tooltip
          style={{ zIndex: 1 }}
          anchorSelect="#unarchive"
          content="Unarchive"
          place="top"
        />
        {loading ? (
          <LoaderUtility loading={loading} />
        ) : (
          <section className="archiverow_table">
            <section className='archiverow_details_actions'>
              <section className='archiverow_details'>
                {archiveIds?.length} row(s) selected of {archivedTests?.length} row(s)
              </section>
              <section className='archiverow_actions'>
                {/* unarchive tests and get new data */}
                <span id='unarchive'>

              
                <button className='unarchive' 
                  disabled={archiveIds?.length === 0}
                  onClick={async () => {
                    handleFilterArchiveRows(archiveIds, archivedTests, setArchivedTests)
                    await archiveUnarchive(archiveIds, false)
                    await handleFetchAgain()
                  }}>
                  <MdOutlineUnarchive color="#fff" size={size}
                    cursor={cursor} />
                </button>
                </span>
              </section>
            </section>

            <section className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>
                      <input type='checkbox'
                        disabled={loading || archivedTests?.length === 0}
                        checked={selectAll}
                        onChange={() =>
                          handleToggleSelectAll(archivedTests)
                        }
                      />
                    </th>
                    <th>Schedule Id</th>
                    <th>Test name</th>
                    <th>Title</th>
                    <th>Scheduled by</th>
                    <th>Scheduled time</th>
                    <th>Valid till</th>
                    <th>Marking scheme</th>
                    <th>Show result</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {checkDataExists(archivedTests, title, archiveIds, selectedRows, setSelectedRows, setArchiveIds)}
                </tbody>
              </table>
            </section>
          </section>
        )}
      </section >
    </>
  );
}

export default Archived

const checkDataExists = (data, title, archiveIds, selectedRows, setSelectedRows, setArchiveIds) => {
  console.log("ExpandableRow", data)
  if (data) {
    if (data.length > 0) {
      {
        return data.map((cell, index) => (
          <tr key={index}>
            <td>
              <input type="checkbox"
                checked={selectedRows?.includes(cell.scheduleid) || archiveIds?.includes(cell.scheduleid)}
                onChange={() =>
                  toggleRowSelection(
                    cell.scheduleid,
                    setSelectedRows,
                    selectedRows,
                    setArchiveIds,
                    archiveIds,
                  )
                }
              />
            </td>
            <td>{cell.scheduleid}</td>
            {/* <td>{cell.testid}</td> */}
            <td>{cell.name}</td>
            <td>{cell.title}</td>
            <td>{cell.scheduledby}</td>
            <td>{dateTimeHelper(cell.localscheduletime, true, true)}</td>
            <td>{dateTimeHelper(cell.localvalidtill, true, true)}</td>
            <td>{cell.markingscheme}</td>
            <td>{cell.showresult + ""}</td>
            {title === 'PastTest' && (
              <td className={`${cell.status}`}>{cell.status}</td>
            )}
          </tr>
        ))
      }
    } else {
      return (
        // <tbody>
        <td colSpan="9">
          <div className="pyyi_table-zerostate">No schedule archived!</div>
        </td>
        // </tbody>
      )
    }
  }
}