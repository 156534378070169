import { useEffect, useState } from "react";
import { fetchData } from "../../../ApiUtils/fetchData";

const useExpandableRowData = (scheduleid) => {
  const [loading, setLoading] = useState(false);
  const [respData, setRespData] = useState([]);
  const uri = `gettestresponse?scheduleid=${scheduleid}`; // Fetch data only for the specific cellId

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      try {
        const data = await fetchData(uri);
        console.log("first response", data)
        setRespData(data.data);
      } catch (error) {
        console.log('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [scheduleid]); // Trigger the effect only when cellId changes


  return { respData, loading }
}

export default useExpandableRowData