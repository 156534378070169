import { ExpandLessRounded } from '@mui/icons-material';
import React from 'react';

const NextButton = ({ currentStepIndex, newcurrent, setCurrentStepIndex, exdatarr, setIsLoading, setResult, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output, npvalue, step, evaluate, handlesetAttNtAttp }) => {
  const isLastStep = currentStepIndex >= exdatarr?.length - 1;
  console.log("resultarr", resultarr)
  // const next = () => {
  //   if (!isLastStep) {
  //     setCurrentStepIndex((index) => {
  //       if(setIsLoading){
  //       setIsLoading(true);
  //       }
  //       if(setResult){
  //           setResult()
  //       }
  //       return index + 1;
  //     });
  //   }
  // };

  function next() {
    setCurrentStepIndex((index) => {
      if (index >= exdatarr.length - 1) {
        return index;
      }
      if (setIsLoading) {
        setIsLoading(true);
      }
      if (setResult) {
        setResult(exdatarr, currentStepIndex, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output)
      }
      return index + 1;
    });
    let results = evaluate(npvalue, step.exid, step.level)
    if (results) {
      handlesetAttNtAttp(results)
    }
  }

  return (
    <button
      className={`link-button ${isLastStep ? 'disabled' : ''}`}
      onClick={next}
      disabled={isLastStep}
    >
      <span style={{ marginRight: '5px' }}>Next</span>
      <i className="fa-solid fa-chevron-right"></i>
    </button>
  );
};

export default NextButton;