export function displayOutput(resp, setOutput) {
  let output = "";
  if (resp.status === 406) {
    output = resp.error;
    // setOutput(resp.error);
  } else if (resp.status === 400) {
    output = resp.error;
    // setOutput(resp.error);
  } else if (resp.status === 417) {
    output = resp.output;
    // setOutput(resp.output);
  } else {
    output = resp.output;
    // setOutput(resp.output);
  }
  console.log("output", output);
  setOutput(output);
}