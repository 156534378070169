import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItemAvatar } from '@mui/material';
export default function Whiledialog({onClose,onNodeSelect,codedata,nodeid,updateData}) {
console.log("codedata",codedata);
// console.log("localdata23",localdata)
    const [value,setValue]=useState('')
    const [open,setOpen]=useState(true)




  const handleClickOpen = () => {
    setOpen(true);
  };

  
  function handlevalue(e){
    
    setValue(e)
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        value:e
      }
    }
console.log("codedata23",codedata)
  }
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
 
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>For</DialogTitle>
        <DialogContent>
          <DialogContentText>
        
          </DialogContentText> 
       {/* <div style={{display:'flex',gap:'9.3rem',marginBottom:'0.5rem'}}>
            <label>Variable:</label>
          </div> */}
        <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}> 
        <label>enter conditional expression</label>
       <input   type='text' className='input' placeholder='' style={{width:'100%'}}value={value} onChange={(e)=>{handlevalue(e.target.value)}}></input>
       </div>

    
    

        </DialogContent>
        <DialogActions>
          <div
         className='flowchart_btn' >
                     <button className='button secondary' onClick={onClose}>close</button>

        <button className='button primary' onClick={(e)=>{
         onNodeSelect(value,codedata)
        }}>ok</button>
        </div>
           </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}