import React from 'react'
import Table from '../UtilityFunctions/Table'
import useGetCombinedRsult from './useGetCombinedResult'
import { Tooltip } from 'react-tooltip'
import { useEffect } from 'react'
import { useMemo } from 'react'
import Drawer from '../CustomDrawer/Drawer'
import { useState } from 'react'
import { toast } from 'react-toastify'
import ApiConfig from '../../ApiConfig'
import axios from 'axios'
import './CombinedResult.scss'
export default function CombinedResult({data, selectedScheduleid,}) {

    // const [userCombinedResult, isLoading, getCombinedResult , setIsLoading] = useGetCombinedRsult()
    const [drawerOpen, setDrawerOpen]=useState(false)
    const [userCombinedResult,setUserCombinedResult] =useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [langArr, setLangArr]= useState([])
    const [percentageLangArr, setPercentageLangArr]= useState([])
    const [defaultPercentage, setDefaultPercentage]= useState(0)
    let totalPercentage=100
    // let percentageLangArr=[]
    // let defaultPercentage=0
    const headers =[
        {id :'1', field:'userid', headerName:'Userid'},
        {id :'2', field:'combinedscore', headerName:'Combined Result'},
    ]
    let languageArr= new Set()
    const handleOpenDrawer=()=>{
        if(selectedScheduleid.length<= 0){
            toast.warn('Please select scheduleid.',{
                position:'top-center'
            })
            return 
        }
        setDrawerOpen(true)
    }
    const handleDrawerClose=()=>{
        setDrawerOpen(false)
        // setSelectedidDetails([])
        // percentageLangArr=[]
        setUserCombinedResult([])
        setPercentageLangArr([])
        setIsLoading(false)
    }
   let range= useMemo(()=>{
    console.log('render')
            return langArr.length > 0 &&<div>
                {langArr?.map((item, index)=>{
                    return <RangeInput  label={item} value={percentageLangArr[item]||defaultPercentage} onChange={ handleLanPercentage} totalPercentage={totalPercentage}/>
              
                })}
                 </div>

    },[percentageLangArr, langArr , defaultPercentage])
 
    const getCombinedResult = async (languageObj, scheduleidArr)=>{
       
        console.log("data 2",scheduleidArr, languageObj)
        languageObj= JSON.stringify(languageObj)
        scheduleidArr = JSON.stringify(scheduleidArr)
        setIsLoading(true)
        let Api= new ApiConfig()
        try {
            const controller = new AbortController();
            let result =await axios.get(Api.addApiKey(`${Api.BaseURI}/getcombindedresult?scheduleidArr=${scheduleidArr}&languageObj=${languageObj}`))
            // let result =await fetchData(`getcombindedresult?scheduleidArr=${scheduleidArr}&languageObj=${languageObj}`)
            setUserCombinedResult(result.data.data)
            console.log('data',result)
            
        } catch (error) {
            console.log(error)
        }
        finally{
            setIsLoading(false)
        }
            
    }
    const handelData=()=>{
        console.log('selectedScheduleid',selectedScheduleid, data)
        let cnt=0
        let selectedScheduleidLen= selectedScheduleid.length
        
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                console.log(selectedScheduleid.includes(element.scheduleid) && !languageArr.has(element.track))
                    if(selectedScheduleid.includes(element.scheduleid) && !languageArr.has(element.track))
                    {
        
                        languageArr.add(element.track)
                        percentageLangArr[element.track]=defaultPercentage
                        // console.log(element.track,100,languageArr.size)
                        // percentageLangArr.push({language:element.track,percentage:100/languageArr.size})
                        // console.log('first,',percentageLangArr)
                        cnt++
                        if(cnt ===selectedScheduleidLen){
                            break
                        }
                    } 
            }

            setDefaultPercentage(100/languageArr.size)
            setLangArr([...languageArr])
            // percentageLangArr[e.target.id]=e.target.value
            languageArr=null
      
    }
    function handleLanPercentage(e,lable){
        // setDefaultPercentage(defaultPercentage)
        console.log(e.target.value,percentageLangArr)
        percentageLangArr[lable]= e.target.value

        console.log(percentageLangArr)
      
    }
    const handleGetResult=()=>{
        let data=[]
                let keys = Object.keys(percentageLangArr)
        for (let i = 0; i < keys.length; i++) {
            let percentage=percentageLangArr[keys[i]]!==0?percentageLangArr[keys[i]]:defaultPercentage
            console.log('percentage',percentage,percentageLangArr[keys[i]])
            data.push({language:keys[i],percentage})
            console.log(percentageLangArr[keys[i]])
        }
        console.log('data',data, selectedScheduleid)
            getCombinedResult(data,selectedScheduleid)
        
        }
        // useEffect(()=>{

        // },[percentageLangArr])
    useMemo(()=>{
        handelData()
    },[drawerOpen])
  return (
    process.env.REACT_APP_SHOW_COMBINED_RESULT === 'true'&&<>
    <Tooltip
    anchorSelect='combined-result '
    place='top'
    content='Combined result'

    
    />
    <span id='combined-result'>
        <button
         className='secondary button small'
         onClick={handleOpenDrawer}
         >
        Combined result
    </button>
   
        </span>
    {drawerOpen && <Drawer
    isOpen={drawerOpen}
    onClose={handleDrawerClose}>

     {range}
     <button
     className='button primary small pos-right'
     onClick={handleGetResult}
     >
        Get Result
     </button>
    <Table
        rows={userCombinedResult}
        headers={headers}
        isCheckBox={false}
        isLoading={isLoading}
        />
    </Drawer>
    }
    
    </>

  )
}

const RangeInput = ({ index, label,value, onChange, totalPercentage }) => {
    const [rangeValue, setRange]=useState(value)
    return (
      <div className='flex '>
        <label htmlFor={label}>{label}</label>
        <input
          type="range"
          id={label}
          min={0}
          max={100}
        //   max={(totalPercentage-rangeValue).toString()}
          value={rangeValue}
          onChange={(e) =>( onChange(e,label), setRange(parseInt(e.target.value)))}
        />
        <input
        type='number'
        value={rangeValue}
        onChange={(e) =>( onChange(e,label), setRange(parseInt(e.target.value)))}
        />
       
      </div>
    );
  };