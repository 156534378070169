import * as React from 'react';
import { useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
export default function Declaredialog({onClose,onNodeSelect,codedata,nodeid,updateData}) {
console.log("codedata",codedata);
// console.log("localdata23",localdata)
    const [variable,setvariable]=useState('')
    const [value,setValue]=useState('')
    const [open,setOpen]=useState(true)
  const [ischecked,setischecked]=useState(false)
  const [arraysize,setarraysize]=useState(0)
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(()=>{
  if(ischecked){
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        variable:variable +` = []`
      }
    }   
  }
  },[ischecked])


  function handlemessage(e){
    setvariable(e)
  if(!ischecked){
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        variable:e
      }
    }
  }
  else{
    setarraysize(e)
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        variable:e +` = []`
      }
    }
  }
  
console.log("codedata22",codedata)
  }

  
  function handlevalue(e){
    
    setValue(e)
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        variable:variable,
        value:e
      }
    }
console.log("codedata23",codedata)
  }
  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
 
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Declare</DialogTitle>
        <DialogContent>
      
          <div style={{display:'flex',marginTop:'0.5rem',flexDirection:'column',gap:'0.5rem'}} >
          Enter variable
          <input  className='input' type='text' style={{width:'22rem'}} placeholder='Enter variable name' value={variable} onChange={(e)=>{handlemessage(e.target.value)}} />
          <span style={{display:'flex',alignItems:'center'}}>
          <input type='checkbox' checked={ischecked} onChange={(e)=>setischecked(!ischecked)} /> &nbsp;&nbsp;isArray &nbsp;&nbsp;
          </span>
          </div>
     
        </DialogContent>
        <DialogActions>
          <div className='flowchart_btn'>
        <button className='button secondary' onClick={onClose}>close</button>
        <button className='button primary'  onClick={(e)=>{
        let newlabel= "Declare "+variable
         onNodeSelect(newlabel,codedata)
        }}>ok</button>
        
        </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}