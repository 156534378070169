import React from 'react';

const ModalComponent = ({
  isModal,
  submit_smg,
  submit_loading,
  setIsMOdal,
  setQuizScore,
  PulseLoader,
  override,
  title,
  show5min,
  setShoe5Min,
  setSubmitMsg,
  showAttemptedNotAttempted
}) => {

  return (
    <>
      {isModal && (
        <div id="simplemodal" className="pyui_modal">
          <div className="pyui_modal-content">
            {submit_smg ? (

              (<>
                <ModalHeader onClose={() => {
                  setShoe5Min(false)
                  setIsMOdal(false)
                  setSubmitMsg(false)
                }}
                  title={title} />
                <div className="pyui_modal-body">
                  <div className="pyui_modal-body-container">
                    <div className="marked-completed-msg">
                      <i className="fa-solid fa-check"></i>

                      {show5min ? <div> The quiz will be over in 5 mins. Please review your responses.</div>
                        : <div>Marked as complete</div>}

                    </div>
                    <div className="modal-footer">
                    </div>
                  </div>
                </div>
              </>)
            ) : submit_loading ? (
              <>
                <ModalHeader onClose={() => setIsMOdal(false)} title={title} />
                <div id="modalloader-spinner">
                  <p>
                    Marking as Complete
                    <PulseLoader
                      color="black"
                      loading={submit_loading}
                      cssOverride={override}
                      size={3}
                      className="pulse-loader"
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      speedMultiplier={0.5}
                    />
                  </p>
                </div>
              </>
            ) : (
              <>
                <ModalHeader onClose={() => setIsMOdal(false)} title={title} />
                <div className="pyui_modal-body">
                  <div className="pyui_modal-body-container">
                    <div className="modalUserScore">
                      {showAttemptedNotAttempted()}
                      <section>
                        {`This action will submit your response. You may want to review any unanswered questions. Proceed?`}
                      </section>
                    </div>

                    <div className="modal-footer">
                      <button
                        className="primary button small"
                        onClick={() => {
                          setQuizScore();
                          // this.props.closeModal();
                        }}
                      >
                        Complete
                      </button>
                      <button
                        className="button small"
                        onClick={() => {
                          setIsMOdal(false);
                        }}
                      >
                        Close
                      </button>


                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const ModalHeader = ({ onClose, title }) => (
  <header className="pyui_modal-header">
    <h4 className="pyui_modal-header-title">{title}</h4>
    <span className="closebtn" onClick={onClose}>&times;</span>
  </header>
);

export default ModalComponent;
