import React, { useEffect, useState } from "react";
import { fetchData } from "../ApiUtils/fetchData";
import { useNavigate } from "react-router";
import { setValueInSession } from "../UtilityFunctions/getSetValueSession";
import { useAuth } from "../hooks/useAuth";
import { postData } from "../ApiUtils/postData";
import { CgAsterisk } from "react-icons/cg";
import useTracks from "../hooks/useTracks";
import ApiConfig from "../../ApiConfig";

import "./Login.scss";
import useAccountSettings from "../hooks/useAccountSettings";
import HandleVerifyWithPravi, { ShowVerifyWithPraviView } from "./HandleVerifyWithPravi";

export default function Login(e) {
  const [userid, setUserid] = useState("");
  const [OTP, setOTP] = useState("");
  const [enterOTP, setEnterOTP] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [message, setMessage] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [isShowMsg, setIsShowMsg] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [isEditable, setisEditable] = useState(false);
  const [OTPmsg, setOTPMsg] = useState("");
  const [isShowResendBtnMsg, setIsShowResendBtnMsg] = useState(true);
  const otpTimerInSeconds = parseInt(process.env.REACT_APP_OTP_TIMER);
  const [counter, setCounter] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isShowResendBtn, setIsShowResendBtn] = useState(false);
  const [changeView, setChangeView] = useState(false);
  const navigate = useNavigate();
  const { login, assessmentSessionAuth } = useAuth();
  const { tracks } = useTracks();
  const { settings,getAccountSettings } = useAccountSettings(false)
  const [activeTab, setActiveTab] = useState("Student login");
  sessionStorage.clear();
  const handleResponse = async(response) => {
    setIsShowMsg(true);

    if (response.status === 200) {
      setMessage({
        message: "OTP sent to your registered mail id",
        color: "",
      });
      setCounter(otpTimerInSeconds);
      setOTPMsg("OTP sent to your mail id.");
      setIsShowResendBtn(false);
      setIsShowResendBtnMsg(false);
      setShowOTP(true);
      setOTP(response.otp);

      if (response?.userDetails.firstname !== undefined) {
        setFirstName(response.userDetails.firstname);
        setLastName(response.userDetails.lastname);
        setMobileNo(response.userDetails.mobileno);
        setisEditable(false);
      } else {
        setisEditable(true);
      }
    } else {
      setOTP(0);
      setMessage({
        message: response.message,
        color: "red",
      });
      setShowOTP(false);
    }
    setisLoading(false);
    setTimeout(() => {
      setIsShowMsg(false);
    }, 2000);
  };

  const getAdminUserDetails = async () => {
    let result = await fetchData(`/getadminuserdetails?userid=${userid}`);
    if (result.staus === 200) {
      setFirstName(result.data.firstName);
      setLastName(result.data.lastName);
    }
  };

  const checkLogin = async (e, from) => {
    if (isLoading) {
      return;
    }
    e.preventDefault();
    setIsShowMsg(true);
    setIsShowResendBtn(false);
    setMessage({
      message: "",
      color: "",
    });
    if (userid === "") {
      setMessage({
        message: "Please enter email",
        color: "red",
      });
      return;
    }
    // eslint-disable-next-line
    let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!regex.test(userid)) {
      setMessage({
        message: "Please enter valid email",
        color: "red",
      });
      return;
    }

    setisLoading(true);

    setIsShowMsg(true);

    try {
      let msg =
        from === "resendotp"
          ? "sending OTP"
          : "Checking for assessment & sending OTP ";
      setMessage({
        message: msg,
        color: "",
      });
      let data = await fetchData(`/assessmentlogin?userid=${userid}`);
      console.log(data);

      handleResponse(data);
    } catch (error) {
      console.log(error);
      setIsShowResendBtn(true);
      setIsShowResendBtnMsg(true);
    }
  };

  const checkInputValueForAdmin = () => {
    if (userid === "") {
      setMessage({
        message: "Please enter email",
        color: "red",
      });
      return true;
    }
    if (password === "") {
      setMessage({
        message: "Please enter password",
        color: "red",
      });
      return true;
    }
    // eslint-disable-next-line
    let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    if (!regex.test(userid)) {
      setMessage({
        message: "Please enter valid email",
        color: "red",
      });
      return true;
    }

    return false;
  };

  const checkAdminLogin = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }
    setIsShowMsg(true);
    setMessage({
      message: "",
      color: "",
    });
    if (checkInputValueForAdmin()) {
      return;
    }
    setisLoading(true);
    try {
      setMessage({
        message: "Logging...",
        color: "rgb(75, 243, 187)",
      });
      let data = await fetchData(
        `assessmentadminlogin?userid=${userid}&password=${password}`
      );
      console.log(data);

      // handleResponse(data)
      if (data.status === 200) {
        setMessage({
          message: "",
          color: "",
        });
        let data2=await getAccountSettings(data.data.group_name)
        setValueInSession("username", userid);
        setValueInSession("role", data.data.role);
        setValueInSession("firstname", data.data.firstname);
        setValueInSession("lastname", data.data.lastname);
        setValueInSession("group_name", data.data.group_name);
        setValueInSession("settings", JSON.stringify(data2));
        sessionStorage.setItem("tracks", JSON.stringify(tracks));
        setMessage({
          message: message.message,
          color: "rgb(75, 243, 187)",
        });
        // navigate('/')
        await login(userid, data.role);
        // setTimeout(() => { setIsShowMsg(false) }, 3000)
      } else {
        setMessage({
          message: data.message,
          color: "red",
        });
      }
      setTimeout(() => {
        setIsShowMsg(false);
      }, 3000);
      setisLoading(false);
    } catch (error) {
      console.log(error);
      setisLoading(false);
    }
  };

  const submitUserDetails = async () => {
    if (!isEditable) {
      return;
    }
    let data = {
      userid: userid,
      firstname: firstName,
      lastname: lastName,
      mobileno: mobileNo,
    };
    let result = await postData("/addassessmentuserdetails", data);
    console.log(result.data);
  };

  const checkOTP = (e) => {
    e.preventDefault();
    setIsShowMsg(true);
    if (firstName === "") {
      setMessage({
        message: "Please enter first name",
        color: "red",
      });
      return true;
    }
    if (lastName === "") {
      setMessage({
        message: "Please enter last name",
        color: "red",
      });
      return true;
    }
    if (mobileNo === "") {
      setMessage({
        message: "Please enter mobile number",
        color: "red",
      });
      return true;
    }
    if (enterOTP === "") {
      setMessage({
        message: "Please enter OTP",
        color: "red",
      });
    } else if (enterOTP !== OTP) {
      setMessage({
        message: "Please enter valid OTP",
        color: "red",
      });
    } else if (enterOTP === OTP) {
      setMessage({
        message: "Email verified",
        color: "rgb(75, 243, 187)",
      });
      setCounter(0);

      submitUserDetails();
      setValueInSession("username", userid?.toLowerCase());
      setValueInSession("role", "student");
      sessionStorage.setItem("tracks", JSON.stringify(tracks));
      assessmentSessionAuth(userid);
    }
  };
  useEffect(() => {
    const timer =
      counter >= 0
        ? setInterval(() => {
          setCounter(counter - 1);
          setMinutes(Math.floor(counter / 60));
          setSeconds(Math.floor(counter % 60));
        }, 1000)
        : (setIsShowResendBtn(true), setIsShowResendBtnMsg(true));

    return () => clearInterval(timer);
  }, [counter]);




  function StudentLoginPage() {
    return (
      <>
        {changeView && process.env.REACT_APP_SHOW_PRAVI_LOGIN_BUTTON === 'true'
          ? <ShowVerifyWithPraviView setMessage={setMessage} setisLoading={setisLoading} tracks={tracks} />
          : <>
            <div className="login-input-div">
              <div>
                <label>
                  Email <CgAsterisk style={{ color: "red" }} />
                </label>
                <input
                  id="email"
                  placeholder="Email"
                  type="email"
                  className={showOTP ? "input login-disabled" : "input"}
                  style={{ width: "100%" }}
                  disabled={showOTP}
                  value={userid}
                  onChange={(e) => {
                    setUserid(e.target.value);
                    setShowOTP(false);
                  }}
                />
              </div>
            </div>

            {showOTP && (
              <>
                {isEditable && (
                  <div className="login-input-div">
                    <div className="div-name-field">
                      <div>
                        <label>
                          First name <CgAsterisk style={{ color: "red" }} />
                        </label>
                        <input
                          id="firstname"
                          placeholder="First name"
                          type="text"
                          className={!isEditable ? "input login-disabled" : "input"}
                          style={{ width: "100%" }}
                          disabled={!isEditable}
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label>
                          Last name <CgAsterisk style={{ color: "red" }} />
                        </label>
                        <input
                          id="lastname"
                          placeholder="Last name"
                          type="text"
                          className={!isEditable ? "input login-disabled" : "input"}
                          style={{ width: "100%" }}
                          disabled={!isEditable}
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label>
                        Contact <CgAsterisk style={{ color: "red" }} />
                      </label>
                      <input
                        id="mobileno"
                        placeholder="Enter mobile number"
                        type="number"
                        className={!isEditable ? "input login-disabled" : "input"}
                        style={{ width: "100%" }}
                        disabled={!isEditable}
                        value={mobileNo}
                        onChange={(e) => {
                          setMobileNo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="otp-wrapper">
                  <div>
                    <p className="pyui-msg success">{OTPmsg}</p>
                    <input
                      id="otp"
                      placeholder="Enter OTP"
                      style={{ width: "100%" }}
                      type="number"
                      className="input"
                      onChange={(e) => {
                        setEnterOTP(e.target.value);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      float: "right",
                    }}
                  >
                    <span className="text-msg">
                      {isShowResendBtnMsg
                        ? `Didn't receive the code?`
                        : `Resend OTP in  ${minutes < 10 ? "0" + minutes : minutes
                        } : ${seconds < 10 ? "0" + seconds : seconds} ${minutes > 0 ? "minutes" : "seconds"
                        }`}
                    </span>
                    <button
                      className={
                        isShowResendBtn ? "link-button" : "link-button disabled"
                      }
                      style={{ width: "fit-content" }}
                      onClick={(e) => isShowResendBtn && checkLogin(e, "resendotp")}
                    >
                      {" "}
                      &nbsp;Resend OTP
                    </button>
                  </div>
                </div>
              </>
            )}

            {showOTP ? (
              <button
                className={isLoading ? "button primary disabled" : "button primary"}
                style={{ width: "100%" }}
                onClick={checkOTP}
              >
                Verify OTP
              </button>
            ) : (
              //   isLoading?
              //   <button
              //    className='button primary'
              //    >Sending...</button>
              //   :
              <section style={{ display: 'grid', gridTemplateColumns: process.env.REACT_APP_SHOW_PRAVI_LOGIN_BUTTON === 'true' ? 'repeat(2,1fr)' : '1fr', width: '100%', gap: '0.5rem' }}>
                <button
                  className="button primary "
                  // style={{ width: "100%", color: "white" }}
                  disabled={isLoading}
                  onClick={checkLogin}
                >
                  Verify email
                </button>
                {process.env.REACT_APP_SHOW_PRAVI_LOGIN_BUTTON === 'true' &&
                  <HandleVerifyWithPravi setChangeView={setChangeView} />}
              </section>
            )}
          </>
        }
      </>
    );
  }

  function AdminLogin() {
    return (
      <>
        <div className="login-input-div">
          <div>
            <label>
              Username <CgAsterisk style={{ color: "red" }} />
            </label>
            <input
              // id='email'
              style={{ width: "100%" }}
              placeholder="Email"
              type="email"
              className="input"
              onChange={(e) => {
                setUserid(e.target.value);
              }}
            />
          </div>

          <div>
            <label>
              Password <CgAsterisk style={{ color: "red" }} />
            </label>
            <input
              // id='email'
              style={{ width: "100%" }}
              placeholder="Password"
              type="password"
              className="input"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          {/*<div > 
            <label>First name <CgAsterisk style={{ color: "red" }} /></label>
             <input
                    id='firstname'
                    placeholder='First name'
                    type='text'
                    className={!isEditable?'input login-disabled':'input'}
                    style={{ width: '100%' }}
                    disabled={!isEditable}
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                        
                    }}
                />
                </div>
                <div > 
           
                <label>Last name <CgAsterisk style={{ color: "red" }} /></label> 
                <input
                id='lastname'
                placeholder='Last name'
                type='text'
                className={!isEditable?'input login-disabled':'input'}
                style={{ width: '100%' }}
                disabled={!isEditable}
                value={lastName}
                onChange={(e) => {
                    setLastName(e.target.value)
                   
                }}
            />
            </div>
            */}
        </div>

        <button
          className="button primary"
          style={{
            width: "100%",
            cursor: `${isLoading ? "not-allowed" : "pointer"}`,
          }}
          disabled={isLoading}
          onClick={checkAdminLogin}
        >
          {"Login"}
        </button>
      </>
    );
  }

  function handleTabSelection(tab) {
    if (!isLoading && !showOTP) {
      setUserid("");
      setLastName("");
      setFirstName("");
      setIsShowMsg(false);
      setMessage({
        message: "",
        color: "",
      });
      setActiveTab(tab);
    }
  }

  const tabsFunction = () => {
    return (
      <>
        <ul class="tabs">
          <li
            disabled={isLoading || showOTP}
            className={activeTab === "Admin login" ? "tab" : "tab active"}
            onClick={() => {
              handleTabSelection("Student login");
            }}
          >
            Candidate login
          </li>
          <li
            className={
              activeTab === "Admin login"
                ? "tab active "
                : `${showOTP || isLoading ? "login-disabled" : "tab"} `
            }
            disabled={isLoading || showOTP}
            onClick={() => {
              handleTabSelection("Admin login");
            }}
          >
            Admin login
          </li>
        </ul>
        {activeTab === "Admin login" ? AdminLogin() : StudentLoginPage()}
      </>
    );
  };

  return (
    <div className="login-page">
      <div action="" className="login-form">
        {isShowMsg && (
          <div
            className="msg-div"
            style={{
              backgroundColor: message.color,
              color: "white",
              fontWeight: "600",
              textShadow: "2px 2px 2px 2px currentColor",
            }}
          >
            <p>{message.message}</p>
          </div>
        )}
        <h3>Assessment Login</h3>

        {tabsFunction()}
      </div>
    </div>
  );
}
