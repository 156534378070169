import React, { useEffect, useMemo, useState } from "react";
import { prevSection } from "../../scheduleutils";
import { useSearchParams } from "react-router-dom";
// import styles
import "./datatable.scss";
import Table from "../../../UtilityFunctions/Table";
import ConfirmDialog from "../../../UtilityFunctions/ConfirmDialog";

const DataTable = ({
  data,
  title,
  setCurrentSection,
  currentSection,
  handleOnClick,
  buttonText,
  setShowMsg,
  setSelectedUserids,
  selectedUserids,
  filename,
  setUseridArr2,
  isCheckBox,
  showRemoveUserBtn,
}) => {
  const [data1, setData1] = useState(data);
  const [isShowConfirmBox, setIsShowConfirmDialogBox] = useState(false);
  const headers = [{ id: "1", field: "userid", headerName: "Participants " }];

  const checkIfDataExists = () => {
    console.log("data", data);
    if (data) {
      if (data.length > 0) {
        return (
          <tbody>
            {data?.map((cell, i) => (
              <tr key={i}>
                <td>{cell?.userid}</td>
              </tr>
            ))}
          </tbody>
        );
      } else {
        return (
          <tbody>
            <td colspan="1">
              <div className="pyyi_table-zerostate">No participants found!</div>
            </td>
          </tbody>
        );
      }
    }
  };
  const handleRemoveUser = () => {
    console.log("userids selected", selectedUserids);
    let selectedUserids2 = selectedUserids.map((item) => {
      return item.userid;
    });
    data = data.filter((item) => {
      return !selectedUserids2.includes(item.userid);
    });
    setData1(data);
    setUseridArr2(
      data.map((item) => {
        return item.userid;
      })
    );

    console.log(data);
  };
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const checker = from === "testlibrary";
  const handelScheduleTest = () => {
    console.log("data.length   ", data.length <= 0);
    if ((data.length <= 0 || selectedUserids.length <= 0) && checker) {
      setIsShowConfirmDialogBox(true);
      return;
    }
    setIsShowConfirmDialogBox(false);
    setShowMsg(true);

    handleOnClick();
  };
  // if(data.length>0){

  //   setData1(data)
  // }
  useEffect(() => {
    setData1(data);
  }, [filename, data]);
  return (
    <>
      <div>
        <div className="title-header-container">
          <p className="title-header">
            {selectedUserids.length} row(s) selected of {data1.length} row(s)
          </p>
        </div>
        <section className="table-container datatable_display_data">
          {/* <table>
          <thead>
            <th>{title}</th>
          </thead>
          {checkIfDataExists()}
        </table> */}

          {/* {tableMemo} */}
          <Table
            rows={data1}
            headers={headers}
            //  isLoading={isLoading}
            setSelected={setSelectedUserids}
            data={selectedUserids}
            isCheckBox={isCheckBox}
            noDataMsg={"No data available"}
          />
        </section>
      </div>
      {checker && (
        <ConfirmDialog
          msg={"No users added, proceed?"}
          cancelCallback={() => {
            setIsShowConfirmDialogBox(false);
          }}
          sucessCallback={() => {
            setShowMsg(true);
            handleOnClick();
          }}
          isShowConfirmBox={isShowConfirmBox}
        />
      )}
      <footer className="schedule_datatable_footer">
        <button
          className="button link-button"
          onClick={() => prevSection(setCurrentSection, currentSection)}
        >
          Back
        </button>
        {handleOnClick && (
          <button
            className={`button primary`}
            // <button className={`button primary small ${(data.length <=0 && buttonText!='Update & Save') ?'disabled':''}`}
            onClick={() => {
              handelScheduleTest();

              // setTimeout(() => {
              //   setShowModal(true);
              // }, 2000);
            }}
          >
            {buttonText}
          </button>
        )}
      </footer>
    </>
  );
};

export default DataTable;
