import { useState } from 'react';
import TableData from '../TableData/TableData';
import LoaderUtility from '../../UtilityFunctions/Loader_utility';
import useManageTest from '../../hooks/useManageTest';
// import styles
import './upcomingtest.scss'



const UpcomingTest = () => {
  const { upcomingTest, setUpcomingTest, loading } = useManageTest()
  // const [testData, setTestData] = useState(upcomingTest);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  return (
    <section className='upcomingtest_main'>
      {loading ? (
        <LoaderUtility loading={loading} />
      ) : (
        <>
          <TableData
            selectAll={selectAll}
            testData={upcomingTest}
            setTestData={setUpcomingTest}
            // testDataHeaders={upcomingTestHeaders}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            setSelectAll={setSelectAll}
            loading={loading}
            title={"UpcomingTest"}
            isCheckBox={false}
          // handleSort={handleSort}
          // handleSortIconRender={handleSortIconRender}
          />
        </>
      )}
    </section>
  );
};

export default UpcomingTest