import { createContext, useContext, useMemo, useState } from "react";
// import libraries
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUser] = useState('');
  const [assessmentAuth, setAssessmentAuth] = useState(false);
  const [testStarted, setTestStarted] = useState(false);

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    setIsAuthenticated(true);
    navigate("/");
  };

  // call this function to sign out logged in user
  const logout = (e) => {
    e?.preventDefault()
    setUser(null);
    setIsAuthenticated(false);
    setAssessmentAuth(false)
    setTestStarted(false)
    sessionStorage.removeItem('username')
    sessionStorage.clear()
    navigate("/login", { replace: true });
  };

  const assessmentSessionAuth = (data) => {
    setUser(data)
    setIsAuthenticated(true);
    setAssessmentAuth(true)
    navigate("/assessment");
  }

  const value = useMemo(
    () => ({
      // variables
      username,
      isAuthenticated,
      assessmentAuth,
      testStarted,
      // functions
      login,
      logout,
      assessmentSessionAuth,
      setTestStarted
    }),
    [username, testStarted, isAuthenticated, assessmentAuth]
  );
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
};

export const useAuth = () => {
  return useContext(AuthContext);
};