import React from "react";
import { PulseLoader } from "react-spinners";

function LoaderUtility({ loading, size }) {
  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".5rem",
  };

  return (
    <>
      <p className="utility-spinner" style={{left:'50%',right:'50%'}}>
     
        <PulseLoader
          color="#9c27b0"
          loading={loading}
          cssOverride={override}
          size={size}
          className="pulse-loader"
          aria-label="Loading Spinner"
          data-testid="loader"
          speedMultiplier={0.5}
        />
      </p>
    </>
  );
}

export default LoaderUtility;
