import { useState } from "react";
import { postData } from "../ApiUtils/postData"
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";

const useArchiveSchedule = () => {
  const [arLoading, setArLoading] = useState(false);
  const uri = `markarchiveschedule`
  const archiveSchedule = async (scheduleid, ismark) => {
    try {
      setArLoading(true)
      let data = { scheduleid, ismark }
      const response = await postData(uri, data)
      console.log("archive resp", response?.data)
    } catch (error) {
      console.log("error", error.message)
    } finally {
      setTimeout(() => {
        setArLoading(false)
      }, 1000);
    }
  }

  return { archiveSchedule, arLoading }
}

export default useArchiveSchedule

export const useArchiveTemplate = () => {
  const [arLoading, setArLoading] = useState(false);
  const userid = getValueFromSession('username')
  const uri = `markarchivetest?userid=${userid}`

  const archiveTemplate = async (testid, mark) => {
    try {
      setArLoading(true)
      let data = { testid, mark }
      const response = await postData(uri, data)
      console.log("archive resp", response?.data)
    } catch (error) {
      console.log("error", error.message)
    } finally {
      setTimeout(() => {
        setArLoading(false)
      }, 1000);
    }
  }

  return { archiveTemplate, arLoading }
}