// import react components or files

// import libraries
import { createSlice } from "@reduxjs/toolkit";
import { MdLibraryBooks, MdPostAdd } from "react-icons/md";
import { MdOutlineViewTimeline } from "react-icons/md";
import { MdOutlineTimer } from "react-icons/md";
import { MdDashboard } from "react-icons/md";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";

const size = 40
const initialState = process.env.REACT_APP_SHOW_EVENT_MANAGE == 'true' ? [
  {
    title: 'Test Library',
    icon: <MdLibraryBooks size={size} />,
    button_title: 'Browse',
    link: 'testlibrary/',
    isShow: true,
  },
  {
    title: 'New Test Template',
    icon: <MdPostAdd size={size} />,
    button_title: 'Create',
    link: 'Createtest/',
    isShow: true
  },
  {
    title: 'Test Templates',
    icon: <MdOutlineViewTimeline size={size} />,
    button_title: 'Manage',
    link: 'template/',
    isShow: true
  },
  {
    title: 'Test Template Bundle',
    icon: <HiOutlineSquare3Stack3D size={size} />,
    button_title: 'Create',
    link: 'templatebundle/',
    isShow: process.env.REACT_APP_SHOW_TEMPLATE_BUNDLE === 'true'
  },
  {
    title: 'Test Schedule',
    icon: <MdOutlineTimer size={size} />,
    button_title: 'Manage',
    link: 'manageschedule/',
    isShow: true,
  },

  {
    title: 'Event',
    icon: <MdOutlineTimer size={size} />,
    button_title: 'Manage',
    link: 'manageevent/',
    isShow: true,
  },
  {
    title: 'Admin Dashboard',
    icon: <MdDashboard size={size} />,
    button_title: 'View',
    link: 'admidashboard/',
    isShow: process.env.REACT_APP_IS_SHOW_ADMIN_DASHBOARD === 'true'
  },
] : [{
  title: 'Test Library',
  icon: <MdLibraryBooks size={size} />,
  button_title: 'Browse',
  link: 'testlibrary/',
  isShow: true,
},
{
  title: 'New Test Template',
  icon: <MdPostAdd size={size} />,
  button_title: 'Create',
  link: 'Createtest/',
  isShow: true,
},
{
  title: 'Test Templates',
  icon: <MdOutlineViewTimeline size={size} />,
  button_title: 'Manage',
  link: 'template/',
  isShow: true,
},
{
  title: 'Test Schedule',
  icon: <MdOutlineTimer size={size} />,
  button_title: 'Manage',
  link: 'manageschedule/',
  isShow: true,
},
{
  title: 'Admin Dashboard',
  icon: <MdDashboard size={size} />,
  button_title: 'View',
  link: 'admidashboard/',
  isShow: process.env.REACT_APP_IS_SHOW_ADMIN_DASHBOARD === 'true'
},
]

const dashBoardData = createSlice({
  name: 'dashBoardData',
  initialState,
  reducers: {},
})

export const allDashBoardData = state => state.dashBoardData

export default dashBoardData.reducer