import { updateExerciseResults } from "../Evaluation/useToEvaluate";
import { getValueFromSession, setValueInSession } from "../../UtilityFunctions/getSetValueSession";
import modifyQuesJson from "./modifyQuesJson";

export async function setResult(randomexe, currentStepIndex, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output, fromTimer) {

  let exid = randomexe[currentStepIndex].exid;
  let level = randomexe[currentStepIndex].level;
  console.log("LEVe", level)
  console.log("currIndex", currentStepIndex)
  console.log("resultarr", resultarr)
  let module = randomexe[currentStepIndex].module;
  let summOfArr = resultarr?.reduce((sum, a) => sum + a, 0);
  console.log("exid", exid)
  console.log("level", level)
  console.log("module", module)

  console.log("summOfArr", summOfArr)
  // let results = JSON.parse(sessionStorage.getItem('results'));
  let results = JSON.parse(getValueFromSession("results"));

  no_of_questions = numTests;
  // no_of_questions = resultarr.length
  no_of_correct = resultarr?.length === summOfArr ? summOfArr : 0;
  totalMarks = getMaxScore(level);
  console.log("no_of_questions ", no_of_questions);
  console.log("no_of_correct ", no_of_correct);
  console.log("totalMarks ", totalMarks);
  // console.log("no_of_questions ", no_of_questions);
  score =
    no_of_correct === 0
      ? 0
      : parseFloat(
        Math.ceil(no_of_correct * (getMaxScore(level) / resultarr?.length))
      );

  console.log("score", score);
  let modified = modifyQuesJson(exid, debugcode, resultarr, output);

  // console.log("modified", modified);
  if (module === "debug" || module === "solver") {
    no_of_questions = numTests;
    totalMarks = getMaxScore(level);
  }
  let updated = updateExerciseResults(
    exid,
    results,
    no_of_correct,
    no_of_questions,
    score,
    totalMarks,
    modified
  );
  console.log("updated", updated);
  // sessionStorage.setItem('results updated', JSON.stringify(updated));
  if (!fromTimer) {
    console.log("!fromTimer")
    setValueInSession("results", JSON.stringify(updated));
  }
}

function getMaxScore(level) {
  let maxScore = 0.0;
  if (level >= 1 && level <= 3) {
    maxScore = 10.0;
  } else if (level >= 4 && level <= 6) {
    maxScore = 20.0;
  } else if (level >= 7 && level <= 10) {
    maxScore = 30.0;
  } else {
    maxScore = 40.0;
  }
  return maxScore;
}
