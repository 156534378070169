import { useEffect, useState } from "react";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { postData } from "../ApiUtils/postData";

function useTemplateBundle() {
  const [generateLoader, setLoader] = useState({ loader: false, state: false })
  const userid = getValueFromSession('username')
  const uri = `generateassessment`;

  async function generateAssessment(track, noOfAssessments, title, visibility, description) {
    setLoader({ loader: true })
    const body = { userid, track, noOfAssessments, title, visibility, description }
    try {
      let response = await postData(uri, body)
      console.log('generateassessment resp', response);

    } catch (error) {
      console.log('generateassessment err', error);
    } finally {
      setLoader({ loader: false, state: true })
    }


  }

  return { generateAssessment, generateLoader }
}

export default useTemplateBundle