
// const useToEvaluate = () => {

//   //states
//   const [questions, setQuestions] = useState([]);
//   const [answers, setAnswers] = useState([]);
//   const [navigateTo, setNavigateTo] = useState(false);
//   const [quesresp, setQuesResp] = useState('');
//   const [isLoading, setIsLoading] = useState(true);
//   const [isModal, setIsMOdal] = useState(false);
//   const [key, setKey] = useState("");
//   const [value, setValue] = useState("");
//   const [state, setState] = useState({});
//   // const [data, setData] = useState();
//   // const [index, setIndex] = useState(0);
//   // let state={}

//   let currentStepIndex = JSON.parse(sessionStorage.getItem('index'));
//   let data = JSON.parse(sessionStorage.getItem('randomexe'))
//   //variable definition
//   let Api = new ApiConfig();
//   let navigate = useNavigate();
//   // let { data, userid } = props;
//   // let data = JSON.parse(sessionStorage.getItem('data'));
//   // console.log("exercise", exercise);
//   // console.log("currentStepIndex", currentStepIndex);
//   let exid = data[currentStepIndex].exid;
//   let level = data[currentStepIndex].exid;
//   let userid = sessionStorage.getItem('username')

//   useEffect(() => {


//     const quesans = async () => {

//       await axios.get(Api.addApiKey(`${Api.BaseURI}/questions?exercise=${data[currentStepIndex].exid}`)).then(response => {
//         if (response.status === 406 || response.status === 408 || response.status === 500 || response.status === 503) {
//           // setNavigateTo(true);
//           navigate('/login', { state: { response: quesresp, type: 'error' } })
//           setQuesResp(response);
//           // this.setState({ navigateTo: true, quesresp: response })
//           axios.get(Api.addApiKey(`${Api.BaseURI}/postLogout?username=${userid}`)).then(res => {
//             console.log(res)
//           });
//           sessionStorage.removeItem('username');
//           sessionStorage.clear();
//           localStorage.clear();
//         } else {
//           let answers = []
//           console.log("response", response);
//           response.data.answers.map(answer => {
//             answers.push({ key: answer.key, answer: Base64.decode(answer.answer) })
//           })
//           setQuestions(response.data.questions);
//           setAnswers(answers);
//           // setIsLoading(false);
//           // this.setState({ questions: response.questions, answers: answers, loading: false })
//         }
//       })
//     }
//     quesans();

//   }, [currentStepIndex])
//   // console.log("questions", questions);
//   function getJsonFromResults() {
//     // console.log("questions", questions);
//     let results = JSON.parse(sessionStorage.getItem('results'));
//     for (let i = 0; i < results.length; i++) {
//       if (results[i].exercise === data[currentStepIndex].exid) {
//         return getJson(JSON.parse(JSON.stringify(results[i].questionjson)));
//       }
//     }
//     return getJson(JSON.parse(JSON.stringify(questions)))
//   }

//   let tochangevalues = (key, value) => {
//     console.log("key", key);
//     console.log("value", value);
//     console.log();
//     setKey(key);
//     setValue(value);
//   }

//   function getJsonWithEmptyValueUpdate() {
//     let results = JSON.parse(sessionStorage.getItem('results'));
//     // let { key, value } = this.state;

//     if (key !== undefined && value !== undefined) {
//       if (key === "") {
//         return getJsonFromResults();
//       }
//       for (let i = 0; i < results.length; i++) {
//         let updated = false;
//         if (key.includes(exid) && results[i].exercise === exid) {
//           let currentJson = getJson(JSON.parse(JSON.stringify(results[i].questionjson)));
//           for (let j = 0; j < currentJson.length; j++) {
//             if (currentJson[j].key === key) {
//               currentJson[j].value = '';
//               updated = true;
//               break;
//             }
//           }
//           if (updated) {
//             results[i].questionjson = currentJson;
//             sessionStorage.setItem('results', JSON.stringify(results));
//           }
//           return currentJson;
//         }
//       }
//     }
//     // console.log("outside if");
//     console.log("questions", questions);

//     return getJson(JSON.parse(JSON.stringify(questions)))
//   }

//   // Dynamic form

//   let onChange = (e, key, type = "single") => {
//     // console.log(`${e.target.value} type ${type}`);
//     // if(props)
//     // this.tochangevalues(key, e.target.value);
//     if (type === "single") {
//       // console.log(key, e.target.value);
//       if (e.target.value === "") {
//         tochangevalues(key, e.target.value)
//       }
//       setState({

//         [key]: e.target.value,

//       })


//     } else {
//       // Array of values (e.g. checkbox): TODO: Optimization needed.
//       let found = state[key]
//         ? state[key].find(d => d === e.target.value)
//         : false;
//       if (found) {
//         let data = state[key].filter(d => {
//           return d !== found;
//         });
//         setState({
//           [key]: data
//         });
//       } else {
//         console.warn("found", key, state[key]);
//         // this.setState({
//         //   [key]: [e.target.value, ...this.state[key]]
//         // });
//         let others = state[key] ? [...state[key]] : [];
//         setState({
//           [key]: [e.target.value, ...others]
//         });
//       }
//     }
//   };

//   let renderForm = () => {
//     // console.log("inside render");
//     // let model = props.model;
//     let model = getJsonWithEmptyValueUpdate();
//     console.log("model", model);
//     // let defaultValues = this.props.defaultValues;
//     let formUI = model.map(m => {
//       let key = m.key;
//       let type = m.type || "text";
//       let props = m.props || {};
//       let name = m.name;
//       let value = m.value;
//       console.log("value", value);

//       let target = key;
//       value = state.key || value;

//       console.log("value", value);
//       let input = (
//         <input
//           {...props}
//           className="form-input"
//           type={type}
//           key={key}
//           name={name}
//           value={value}
//           onWheel={e => e.currentTarget.blur()}
//           onChange={e => {
//             onChange(e, target);
//           }}
//         />
//       );

//       if (type == "radio") {
//         // console.log("m.options", m.options);
//         input = m.options.map((o) => {
//           let checked = o.value == value;
//           return (
//             <React.Fragment key={"fr" + o.key}>
//               <input
//                 {...props}
//                 className="form-input"
//                 type={type}
//                 key={o.key}
//                 name={o.name}
//                 checked={checked}
//                 value={o.value}
//                 onChange={e => {
//                   onChange(e, o.name);
//                 }}
//               />
//               <label key={"ll" + o.key}>{o.label}</label>
//             </React.Fragment>
//           );
//         });
//         input = <div className="form-group-radio">{input}</div>;
//       }

//       if (type == "select") {
//         input = m.options.map(o => {
//           let checked = o.value == value;
//           return (
//             <option
//               {...props}
//               className="form-input"
//               key={o.key}
//               value={o.value}
//             >
//               {o.value}
//             </option>
//           );
//         });

//         //console.log("Select default: ", value);
//         input = (
//           <select
//             value={value}
//             onChange={e => {
//               onChange(e, m.key);
//             }}
//           >
//             {input}
//           </select>
//         );
//       }

//       if (type == "checkbox") {
//         input = m.options.map(o => {
//           //let checked = o.value == value;
//           let checked = false;
//           if (value && value.length > 0) {
//             checked = value.indexOf(o.value) > -1 ? true : false;
//           }
//           //console.log("Checkbox: ", checked);
//           return (
//             <React.Fragment key={"cfr" + o.key}>
//               <input
//                 {...props}
//                 className="form-input"
//                 type={type}
//                 key={o.key}
//                 name={o.name}
//                 checked={checked}
//                 value={o.value}
//                 onChange={e => {
//                   onChange(e, m.key, "multiple");
//                 }}
//               />
//               <label key={"ll" + o.key}>{o.label}</label>
//             </React.Fragment>
//           );
//         });

//         input = <div className="form-group-checkbox">{input}</div>;
//       }

//       return (
//         <div key={"g" + key} className="form-group">
//           <label className="form-label" key={"l" + key} htmlFor={key}>
//             {`${key.replace(exid, '')})  ${m.label}`}
//           </label>
//           {input}
//         </div>
//       );
//     });
//     return formUI;
//   };



//   function DynamicForm() {
//     const override = {
//       display: "flex",
//       alignItems: 'center',
//       justifyContent: 'center',
//       marginTop: "20px",
//       // borderColor: "olive",
//     };


//     return (
//       <>
//         <div className="form-render" >
//           <div className="form">
//             <form
//               id="my-form"
//               className="dynamic-form"
//             // onSubmit={(e) => {
//             //   this.onSubmit(e);
//             // }}
//             >
//               {renderForm()}
//             </form>
//           </div>
//         </div>
//         {/* <div className="form-actions">
//           <div className="action-buttons">
//             {this.evaluationSubmit()}
//           </div>
//         </div> */}
//       </>
//     )
//   }

//   function getMatchingIndex(key) {
//     // console.log("key", key);
//     // const { answers } = this.state
//     for (var i = 0; i < answers.length; i++) {
//       console.log("answers[i].key", answers[i].key);
//       if (answers[i].key === key) return i;
//     }
//   };

//   const toMatchArray = (userAnswers, correctAnswers) => {

//     let temparr = [];
//     let temp1arr = [];
//     let result = false;
//     for (let i = 0; i < userAnswers.length; i++) {
//       temparr.push(parseInt(userAnswers[i]))
//     }

//     let answers = correctAnswers.split(',');
//     // console.log(answers)
//     for (let i = 0; i < answers.length; i++) {
//       temp1arr.push(parseInt(answers[i]))
//     }
//     // console.log("answer",checkboxarr)
//     // console.log("user res",value)

//     if (temparr.length === temp1arr.length) {
//       temp1arr.sort();
//       temparr.sort();
//       let i;
//       for (i = 0; i < temparr.length; i++) {
//         if (temparr[i] !== temp1arr[i]) break;
//       }
//       if (i === temparr.length) return true;
//     }
//     // console.log("lenght", checkboxarr[0])
//     return result;
//   };




//   function updateExerciseResults(exid, results, numcorrect, numquestions, exscore, total, valuejson) {
//     // console.log("results", results)
//     if (results.length == 0) {
//       results.push({ exercise: exid, correct: numcorrect, questions: numquestions, score: exscore, maxscore: total, questionjson: valuejson });
//     }
//     else {
//       let found = false;
//       for (var i = 0; i < results.length && !found; i++) {
//         if (results[i].exercise === exid) {
//           results[i].correct = numcorrect;
//           results[i].questions = numquestions;
//           results[i].score = exscore;
//           results[i].maxscore = total;
//           results[i].questionjson = valuejson;
//           found = true;
//         }
//       }
//       if (!found) {
//         results.push({ exercise: exid, correct: numcorrect, questions: numquestions, score: exscore, maxscore: total, questionjson: valuejson });
//       }
//     }
//     return results;
//   }

//   function getGrandTotal(results, module) {
//     let evalResult = { evalName: module, correct: 0, questions: 0, score: 0, maxscore: 0 }
//     for (var i = 0; i < results.length; i++) {
//       evalResult.correct += results[i].correct;
//       evalResult.questions += results[i].questions;
//       evalResult.score += results[i].score;
//       evalResult.maxscore += results[i].maxscore;
//     }
//     return evalResult;
//   }

//   function grand(evaluationResult) {
//     sessionStorage.setItem('evaluationResult', JSON.stringify(evaluationResult));
//     setIsMOdal(true);
//   };

//   function toFinishEvaluate() {
//     let results = JSON.parse(sessionStorage.getItem('results'));
//     let module_name = sessionStorage.getItem('module_name');
//     var evaluationResult = getGrandTotal(results, module_name);
//     console.log("evaluationResult", evaluationResult);
//     console.log("results", results);

//     grand(evaluationResult);
//   }


//   function toEvaluate(finished) {
//     // console.log("state", state);
//     let newModal = []
//     for (let [k, v] of Object.entries(state)) {
//       newModal.push({
//         key: k,
//         value: v
//       });
//     };


//     function getAnswerJsonFromStorage() {
//       let results = JSON.parse(sessionStorage.getItem('results'));
//       let ansArr = [];
//       if (results.length === 0) {
//         return ansArr;
//       } else {
//         for (let i = 0; i < results.length; i++) {
//           if (results[i].exercise === exid) {
//             let currentJson = getJson(JSON.parse(JSON.stringify(results[i].questionjson)));
//             for (let j = 0; j < currentJson.length; j++) {
//               if (currentJson[j].value !== undefined) {
//                 ansArr.push({ key: currentJson[j].key, value: currentJson[j].value });
//               }

//             }
//             break;
//           }
//         }
//         return ansArr;
//       }
//     }

//     function containskey(combined, key) {
//       for (let i = 0; i < combined.length; i++) {
//         if (combined[i].key === key) {
//           return true;
//         }

//       }
//       return false;
//     }

//     function getCombinedValues(currValArray) {
//       let combined = getAnswerJsonFromStorage();
//       if (combined.length === 0) {
//         return currValArray;
//       }
//       for (let i = 0; i < combined.length; i++) {
//         for (let j = 0; j < currValArray.length; j++) {
//           if (combined[i].key === currValArray[j].key) {
//             combined[i].value = currValArray[j].value;
//             break;
//           }
//         }
//       }
//       for (let j = 0; j < currValArray.length; j++) {
//         if (!containskey(combined, currValArray[j].key)) {
//           combined.push({ key: currValArray[j].key, value: currValArray[j].value });
//         } else {

//         }
//       }
//       return combined;
//     }



//     function getMaxScore(level) {
//       let totalScore = 0.0
//       if (level >= 1 && level <= 3) {
//         totalScore = 10.0
//       }
//       else if (level >= 4 && level <= 6) {
//         totalScore = 20.0
//       }
//       else if (level >= 7 && level <= 10) {
//         totalScore = 30.0
//       } else {
//         totalScore = 40.0
//       }
//       return totalScore
//     }

//     let newValArr;
//     if (newModal.length === 0) {
//       let totalmarks = 0;
//       let score = 0;
//       let no_of_correct_answers = 0;
//       let no_of_questions = 0;
//       let results = JSON.parse(sessionStorage.getItem('results'));
//       totalmarks = getMaxScore(level);
//       no_of_questions = questions.length;
//       for (let i = 0; i < results.length; i++) {
//         if (results[i].exercise === exid) {
//           score = results[i].score;
//           no_of_correct_answers = results[i].correct;
//         }
//       }
//       newValArr = getJsonFromResults();
//       let updated = updateExerciseResults(exid, results, no_of_correct_answers, no_of_questions, score, totalmarks, newValArr);
//       sessionStorage.setItem('results', JSON.stringify(updated));

//     } else {
//       let totalmarks = 0;
//       let score = 0;
//       let no_of_correct_answers = 0;
//       let no_of_questions = 0;
//       newValArr = getJsonWithValues(getJsonFromResults(), newModal);

//       let updatedModel = getCombinedValues(newModal);
//       console.log("Updated Model", updatedModel);

//       var correct_value = [];
//       var wrong_value = [];
//       for (var i = 0; i < updatedModel.length; i++) {
//         let toMatchingIndex = getMatchingIndex(updatedModel[i].key);
//         if (Array.isArray(updatedModel[i].value)) {
//           if (toMatchArray(updatedModel[i].value, answers[toMatchingIndex].answer)) {
//             correct_value.push({ key: answers[toMatchingIndex].key, value: updatedModel[i].value });
//           } else {
//             wrong_value.push({ key: answers[toMatchingIndex].key, value: updatedModel[i].value });
//           }
//         }

//         if (answers[toMatchingIndex].answer === updatedModel[i].value) {
//           correct_value.push({ key: answers[toMatchingIndex].key, value: updatedModel[i].value });
//         } else {
//           wrong_value.push({ key: answers[toMatchingIndex].key, value: updatedModel[i].value });
//         }
//       };


//       let results = JSON.parse(sessionStorage.getItem('results'));
//       no_of_correct_answers = correct_value.length;
//       no_of_questions = questions.length;
//       totalmarks = getMaxScore(level);
//       score = parseFloat(Math.ceil(no_of_correct_answers * (getMaxScore(level) / questions.length)));
//       let updated = updateExerciseResults(exid, results, no_of_correct_answers, no_of_questions, score, totalmarks, newValArr);
//       sessionStorage.setItem('results', JSON.stringify(updated));

//     }

//     if (finished !== undefined && finished) {
//       toFinishEvaluate();
//       setIsMOdal(true);
//     }
//   }


//   return {
//     updateExerciseResults
//   }



// }
function updateExerciseResults(exid, results, numcorrect, numquestions, exscore, total, valuejson) {
  // console.log("results", results)
  if (results.length == 0) {
    results.push({ exercise: exid, correct: numcorrect, questions: numquestions, score: exscore, maxscore: total, questionjson: valuejson });
  }
  else {
    let found = false;
    for (var i = 0; i < results.length && !found; i++) {
      if (results[i].exercise === exid) {
        results[i].correct = numcorrect;
        results[i].questions = numquestions;
        results[i].score = exscore;
        results[i].maxscore = total;
        results[i].questionjson = valuejson;
        found = true;
      }
    }
    if (!found) {
      results.push({ exercise: exid, correct: numcorrect, questions: numquestions, score: exscore, maxscore: total, questionjson: valuejson });
    }
  }
  return results;
}

// function grand(evaluationResult) {
//   sessionStorage.setItem('evaluationResult', JSON.stringify(evaluationResult));
//   setIsMOdal(true);
// };

// function toFinishEvaluate() {
//   let results = JSON.parse(sessionStorage.getItem('results'));
//   let module_name = sessionStorage.getItem('module_name');
//   var evaluationResult = getGrandTotal(results, module_name);
//   console.log("evaluationResult", evaluationResult);
//   console.log("results", results);

//   grand(evaluationResult);
// }


export {
  // useToEvaluate,
  updateExerciseResults,
  // toFinishEvaluate
}