import React, { useState, useRef } from "react";
import LeftHandside from "./Lefthandside";
import gettitleLHS from "./GetTitleforeditor";
import "./form.css";
const RenderImageEditor = ({
  code, module, language,
  isImageEditor,
  isSlide,
  setIsSlide,
  isFullScreen,
  setIsFullScreen,
  handleEditorDidMount,
  ShowConfetti,
  coinMsg,
  ConfettiExplosion,
  Congrats,
  Confetti,
  imgDimensions,
  imageType,
  status,
  initialData,
  originalData,
  exdatarr,
  currentStepIndex,
  QuesForm,
  fullSizeImage,
  clearTimer,
  back,
  next,
  isFirstStep,
  isLastStep, showResult,
  setCurrentStepIndex, randomexe, step, isEvaluation, DynamicForm,setPrevIndex, prevIndex, npvalue, statenp, setValue,
  setKey, handlesetAttNtAttp
}) => {
  const [zoom, setZoom] = useState(1);
  const imageDivRef = useRef(null);
  const imgRef = useRef(null);
  console.log("RenderImageEditor showResult", showResult);
  const options = {
    readOnly: true,
    domReadOnly: true,
    minimap: { enabled: false },
    wordWrap: "on",
    fontSize: "14rem",
    mouseWheelZoom: true,
    fontFamily: "open sans",
    contextmenu: false,
  };
  // const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  console.log(
    "$$language",
    sessionStorage.getItem("language").toLocaleLowerCase(),
    "Ced",
    code
  );



  const handleZoom = (amount) => {
    setZoom((prevZoom) => Math.max(0.5, prevZoom + amount));
  };
  const options1 = {
    readOnly: true,
    domReadOnly: true,
    minimap: { enabled: false },
    wordWrap: "on",
    fontSize: "14rem",
    mouseWheelZoom: true,
    fontFamily: "open sans",
    contextmenu: false,
  };

  function rendercodeViewer() {
    return (
      <>
        <LeftHandside
          showImage={true}
          imageType={imageType}
          handleZoom={handleZoom}
          zoom={zoom}
          isSlide={isSlide}
          setIsSlide={setIsSlide}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          imageDivRef={imageDivRef}
          code={code}
          imgRef={imgRef}
          imgDimensions={imgDimensions}
          showlefthandside={true}
          isEvaluation={isEvaluation}
          title={gettitleLHS(language, module, imageType)}
          isNormal={false}
        />
      </>
    );
  }

  return (
    <>
      {!isFullScreen ? rendercodeViewer() : fullSizeImage()}

      <div
        className="pyui_code-viewer-questions questions"
        style={{ display: isSlide ? "" : "none" }}
      >
        {!isEvaluation && (
          <h4 className="pyui_code-viewer-questions-title">
            {Object.keys(originalData).length === 1
              ? "Answer the following"
              : "Fill the missing values"}
          </h4>
        )}

        <div className="pyui_code-viewer-questions-form question-form">
          {!isEvaluation ? (
            <QuesForm
              status={status}
              data={exdatarr[currentStepIndex]}
              index={currentStepIndex}
              originalData={originalData}
              initialData={initialData}
              ShowConfetti={ShowConfetti}
              coinMsg={coinMsg}
              showResult={showResult}
              prevIndex={prevIndex}
              setPrevIndex={setPrevIndex}
              npvalue={npvalue}
              statenp={statenp}
              setValue={setValue}
              setKey={setKey}
              handlesetAttNtAttp={handlesetAttNtAttp}
            />
          ) : (
            <DynamicForm
              exid={step.exid}
              randomexe={randomexe}
              data={randomexe[currentStepIndex]}
              setCurrentStepIndex={setCurrentStepIndex}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              next={next}
              back={back}
              clearTimer={clearTimer}
              showResult={showResult}
              prevIndex={prevIndex}
              setPrevIndex={setPrevIndex}
              npvalue={npvalue}
              statenp={statenp}
              setValue={setValue}
              setKey={setKey}
              handlesetAttNtAttp={handlesetAttNtAttp}
            />
          )}
        </div>
      </div>
      {(!isEvaluation && ShowConfetti) && (
        <>
          {coinMsg.includes("coins") && coinMsg.includes("badge points") && (
            <div>
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={500}
              />
            </div>
          )}
          <div className="celebDiv">
            <div className="message-container">
              {coinMsg.includes("coins") &&
                coinMsg.includes("badge points") && (
                  <ConfettiExplosion zIndex={12} />
                )}
              <Congrats msg={coinMsg} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RenderImageEditor;
