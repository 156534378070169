export default function getJsonWithValues(questions, values) {
  let newValArr = [];
  let found;
  for (let i = 0; i < questions.length; i++)
  {
    found = false;
    for (let j = 0; j < values.length && !found; j++)
    {
          if (questions[i].key === values[j].key)
          {
             newValArr.push(replace(values[j], questions[i]));
             found = true;
          }
    }
    if (!found)
    {
        newValArr.push(questions[i]); 
    }
  }
  return newValArr;
}

function replace(val, question)
{
  let tempobj = {};
  tempobj = { key: question.key, label: question.label, type: question.type, options: question.options, value: val.value };
  return tempobj;
}
