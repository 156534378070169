import React from "react";
// import libraries
import { motion, AnimatePresence } from "framer-motion";
import { MdClose } from "react-icons/md";
// import styles
import "./drawer.scss";

const Drawer = ({ isOpen, onClose, children }) => {
  return (
    <AnimatePresence>
      {/* {isOpen && ( */}
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        variants={{
          open: { y: 0 },
          closed: { y: 100 },
        }}
        className="custom-drawer"
      >
        <section className="closeButton">
          <button className="button link-button" onClick={onClose}>
            <MdClose fontSize={"2rem"} />
          </button>
        </section>
        <section>{children}</section>
      </motion.div>
      {/* )} */}
    </AnimatePresence>
  );
};

export default Drawer;
