import { Link } from "react-router-dom";
import DialogAdd from "./CreateEventdialog";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import ApiConfig from "../../ApiConfig";
import ModalComponent from "../Modals/Modal_component";
import { Navigate } from "react-router-dom";
import { MdOutlinePreview } from "react-icons/md";
import PreviewDialog from "./PreviewDialog";
import {
  getValueFromSession,
  setValueInSession,
} from "../UtilityFunctions/getSetValueSession";
import ViewUserDetails from "../ManageSchedule/TableData/ViewUserDetails";
import { fetchData } from "../ApiUtils/fetchData";
import { GrGroup } from "react-icons/gr";
import { toast } from "react-toastify";
import { BsCalendar4Event } from "react-icons/bs";
import { combineDateTimeAsISO } from "../Schedule/scheduleutils";
import { dateTimeHelper } from "../UtilityFunctions/dateHelper";
import { MdOutlineArchive } from "react-icons/md";
import { IoMdArchive } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { toggleRowSelection } from "../ManageSchedule/TableData/tableDataUtils";
import {
  toggleSelectAllEvents,
  useArchiveEvent,
  useGetArchivedEvents,
} from "./useEvent";
import Drawer from "../CustomDrawer/Drawer";
import { useCheckCheckBoxHeader } from "../ManageSchedule/TableData/useTableUtils";
import Archived from "./archivedEvent";
import LoaderUtility from "../UtilityFunctions/Loader_utility";
// import {combineDateTimeISO} from '../Schedule/scheduleutils'

export default function ManageEvent() {
  const [open, setOpen] = useState(false);
  const [eventdata, seteventdata] = useState({
    name: "",
    date: "",
    start_time: "",
    end_time: "",
    Registration_start: "",
    Registration_end: "",
    description: "",
    Registration_start_time: "",
    Registration_end_time: "",
  });
  const [isloading, setIsLoading] = useState(false);
  const [dataloader, setDataloader] = useState(false);
  const [submit_smg, setSubmitmsg] = useState("");
  const [status, setstatus] = useState(null);
  const [msg, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [navigate, setNaviagate] = useState(false);
  const [data, setdata] = useState([]);
  const [PreviewOpen, setPreviewOpen] = useState({
    condition: false,
    eventid: null,
  });
  const [userDetails, setUserDetails] = useState([]);
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [archiveIds, setArchiveIds] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState("");

  const { archiveEvent, arloading } = useArchiveEvent();
  const { getEvents } = useGetArchivedEvents();
  //check the checkbox in the header even if one is selccted from the row
  useCheckCheckBoxHeader(selectedRows, setSelectAll, archiveIds);
  const toastRef = useRef();
  // const [eventdata,seteventdata]=useState('');
  async function getdata() {
    setDataloader(true);
    let api = new ApiConfig();
    let userid = getValueFromSession("username");
    let data = await axios.get(
      api.addApiKey(`${api.BaseURI}/getevents?createdby=${userid}`)
    );
    console.log("getevents", data?.data);
    setdata(data?.data);
    setDataloader(false);
  }

  useEffect(() => {
    getdata();
  }, []);
  const headers = [{ id: "1", field: "userid", headerName: "Userid" }];
  const handleCreate = () => {
    // console.log(eventdata);
    if (eventdata?.name === "") {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(`Please enter event name.`, {
          position: "top-center",
        });
      }
      return true;
    } else if (eventdata?.date === "") {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(`Please enter event start date.`, {
          position: "top-center",
        });
      }
      return true;
    } else if (eventdata?.date < new Date().toISOString().split("T")[0]) {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(`Please enter valid event start date.`, {
          position: "top-center",
        });
      }
      return true;
    } else if (eventdata?.end_time === "" || eventdata?.start_time === "") {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(
          `Please enter event ${eventdata?.start_time === "" ? "start" : "end"
          }  time.`,
          {
            position: "top-center",
          }
        );
      }
      return true;
    } else if (eventdata.end_time <= eventdata.start_time) {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(
          "Event end time should be occur later than start time",
          {
            position: "top-center",
          }
        );
      }
      return true;
    } else if (
      eventdata.Registration_start === "" ||
      eventdata.Registration_end === ""
    ) {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(
          `Please enter registration ${eventdata.Registration_start === "" ? "start" : "end"
          } date`,
          {
            position: "top-center",
          }
        );
      }
      return true;
    } else if (
      eventdata.Registration_start_time === "" ||
      eventdata.Registration_end_time === ""
    ) {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(
          `Please enter registration ${eventdata.Registration_start_time === "" ? "start" : "end"
          } time`,
          {
            position: "top-center",
          }
        );
      }
      return true;
    } else if (eventdata.date <= eventdata.Registration_start) {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(
          "The event start date should occur later than the start date of registration.",
          {
            position: "top-center",
          }
        );
      }
      return true;
    } else if (eventdata.date <= eventdata.Registration_end) {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(
          "The event should occur later than the registration deadline.",
          {
            position: "top-center",
          }
        );
      }
      return true;
    } else if (eventdata.Registration_start >= eventdata.Registration_end) {
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error(
          "The time at which event registration ends should be later than the time at which it begins.",
          {
            position: "top-center",
          }
        );
      }
      return true;
    }
    return false;
  };

  const getUserDeatils = async (selectedRows) => {
    setValueInSession("selectedscheduleId", selectedRows);
    setIsLoading(true);
    setIsShowDetails(true);

    // let userDetails= await fetchData(`getscheduleuserlist?scheduleid=${selectedRows[selectedRows?.length-1]}`)
    let userDetails = await fetchData(`geteventusers?eventid=${selectedRows}`);
    // console.log("userdetails ####", userDetails);
    setUserDetails(
      userDetails.data?.map((item) => {
        return { userid: item.userid };
      })
    );
    setIsLoading(false);
  };

  // console.log("event data", eventdata);
  function EventChageinput(e) {
    let { name, value } = e.target;
    if (
      name === "Registration_end_time" ||
      name === "Registration_start_time"
    ) {
      let date = new Date();
      // console.log(value.slice(0, 1), value.slice(2));
      date.setHours(value.slice(0, 2), value.slice(3));
      // console.log("date", date);
      value = date;
      if (name === "Registration_end_time") {
        eventdata["Registration_end"] = combineDateTimeAsISO(
          value,
          eventdata["Registration_end"]
        );
      } else {
        eventdata["Registration_start"] = combineDateTimeAsISO(
          value,
          eventdata?.Registration_start
        );
      }
    }

    seteventdata({
      ...eventdata,
      [name]: value,
    });
  }
  // let data=[{
  //   Eventid:12,
  //   Name:'Walk In Recruitment Drive for Pravi',
  //   Description:'Please register during available window. Bring your resume, transcripts, ... for validation.',
  //   Date: '25 th April 2024',
  //   Start_time: '10 am',
  //   End_time:'5 pm',
  //   Resgistration_start:'25 th April 2024',
  //   Resgistration_end:'26 th April 2024'

  // }]

  async function handlecreateevent() {
    let Api = new ApiConfig();
    let userid = getValueFromSession("username");
    if (handleCreate()) {
      return;
    }

    let data = {
      eventName: eventdata.name,
      description: eventdata.description,
      startDate: eventdata.date,
      startTime: eventdata.start_time,
      endTime: eventdata.end_time,
      registrationStartDate: combineDateTimeAsISO(
        eventdata.Registration_start_time,
        eventdata.Registration_start
      ),
      registrationEndDate: combineDateTimeAsISO(
        eventdata.Registration_end_time,
        eventdata.Registration_end
      ),
      createdBy: userid,
    };
    setModal(true);
    setIsLoading(true);
    try {
      setSubmitmsg(true);
      const res = await axios.post(
        Api.addApiKey(`${Api.BaseURI}/createevent/`),
        data
      );
      if (res.data.status == 201) {
        setIsLoading(false);
        setstatus(201);
        setMessage(res.data.message);
        getdata();
      } else {
        setIsLoading(false);
        setstatus(200);
        setMessage(res.data.message);
        getdata();
      }
      setSubmitmsg(false);
    } catch (error) {
      // console.log("error", error);
      setMessage("Oops something wrong! please try again.");
    } finally {
      setOpen(false);
      seteventdata({
        name: "",
        date: "",
        start_time: "",
        end_time: "",
        Registration_start: "",
        Registration_end: "",
        description: "",
        Registration_start_time: "",
        Registration_end_time: "",
      });
    }
  }
  const archiveUnarchive = async (rowsIds, isArchived) => {
    await archiveEvent(rowsIds, isArchived);
    // update the main table to remove the archived rows
  };

  const handleDrawer = (drawerType) => {
    setDrawerType(drawerType);
    setDrawerOpen((prev) => !prev);
  };

  const handleFetchAgain = async () => {
    await getdata();
  };

  const handleFilterArchiveRows = (rowsIds, alldata, setFilter) => {
    console.log("rowsids", rowsIds);
    console.log("alldata", alldata);
    const updatedData = alldata.filter((row) => {
      return !rowsIds.includes(row.eventid);
    });
    console.log("updatedData", updatedData);
    setFilter(updatedData);
    setSelectedRows([]);
    setArchiveIds([]);
  };

  const handleToggleSelectAll = (rows) => {
    toggleSelectAllEvents(
      selectAll,
      rows,
      setSelectedRows,
      setSelectAll,
      setArchiveIds
    );
  };
  return (
    <section style={{ margin: "1rem" }}>
      <Tooltip
        style={{ zIndex: 50 }}
        anchorSelect="#archive"
        content="Archive selected"
        place="top"
      />
      <Tooltip
        style={{ zIndex: 50 }}
        anchorSelect="#show"
        content="Show archived"
        place="top"
      />
      <section className="display_data_test">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="display_data_title_table"
        >
          <div className="display_data_title_rcount">
            <Link to={"/"} className="link-button">
              Back
            </Link>
            <p className="display_data_rows_count">
              {selectedRows?.length} row(s) selected of {data?.length} row(s)
            </p>
          </div>
          <section className="display_data_icon">
            <div style={{ marginRight: "0.5rem" }} className="archive_button">
              <span id="archive">
                <button
                  className="left-half"
                  disabled={archiveIds?.length === 0}
                  onClick={async () => {
                    handleFilterArchiveRows(archiveIds, data, setdata);
                    await archiveUnarchive(archiveIds, true);
                  }}
                >
                  <IoMdArchive size={20} cursor={"pointer"} />
                </button>
              </span>

              <button
                className="right-half"
                id="show"
                disabled={archiveIds?.length > 0}
                onClick={() => handleDrawer("archive")}
              >
                <MdOutlineArchive size={20} cursor={"pointer"} />
              </button>
            </div>
            <button
              className="icon-button"
              style={{ marginRight: "1rem" }}
              onClick={(e) => setOpen(true)}
              title="Create new event"
            >
              <BsCalendar4Event size={18} />
            </button>
          </section>
        </div>
        <div className="table-container display_data ">
          {dataloader ? (
            <LoaderUtility loading={dataloader} />
          ) : (
            <table>
              <thead>
                <tr>
                  <th>
                    <input
                      disabled={isloading || data?.length === 0}
                      type="checkbox"
                      checked={selectAll}
                      onChange={() => handleToggleSelectAll(data)}
                    />
                  </th>
                  <th>Event_id</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Start_time</th>
                  <th>End_time</th>
                  <th>Resgistration_start</th>
                  <th>Resgistration_end</th>
                  <th>Preview</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data?.map((item, i) => (
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          checked={
                            selectedRows?.includes(item.eventid) ||
                            archiveIds?.includes(item.eventid)
                          }
                          onChange={() =>
                            toggleRowSelection(
                              item.eventid,
                              setSelectedRows,
                              selectedRows,
                              setArchiveIds,
                              archiveIds
                            )
                          }
                        />
                      </td>
                      <td>{item.eventid}</td>
                      <td>{item.name}</td>
                      <td>{item.description}</td>
                      <td>{item.event_date?.slice(0, 10)}</td>
                      <td>{item.start_time}</td>
                      <td>{item.end_time}</td>
                      <td>
                        {dateTimeHelper(item.localtimeregstart, true, true)}
                      </td>
                      <td>
                        {dateTimeHelper(item.localtimeregend, true, true)}
                      </td>
                      <td>
                        <div className="action-btn">
                          <button className="icon-button small">
                            <MdOutlinePreview
                              onClick={() =>
                                setPreviewOpen({
                                  condition: true,
                                  eventid: item,
                                })
                              }
                              size={22}
                            />
                          </button>
                          <button
                            tabIndex={2}
                            className="icon-button small"
                            title="view registered participants"
                            onClick={() => {
                              getUserDeatils(item.eventid);
                            }}
                          >
                            <GrGroup />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <td colSpan={10}>
                    <div className="pyyi_table-zerostate">No Events!</div>
                  </td>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div>
          {modal && (
            <ModalComponent
              setModal={setModal}
              msg={msg}
              submit_smg={submit_smg}
              setSubmitmsg={setSubmitmsg}
              title={"Event"}
              status={status}
              onBack={() => setNaviagate(true)}
              eventdata={eventdata}
            />
          )}
          {navigate && <Navigate to={"/"} />}
        </div>
      </section>
      {open && (
        <DialogAdd
          open={open}
          onClose={() => {
            setOpen(false);
            seteventdata({
              name: "",
              date: "",
              start_time: "",
              end_time: "",
              Registration_start: "",
              Registration_end: "",
              Registration_start_time: "",
              Registration_end_time: "",
              description: "",
            });
          }}
          onChange={EventChageinput}
          onClick={handlecreateevent}
        />
      )}
      {PreviewOpen.condition && (
        <PreviewDialog
          open={PreviewOpen}
          onClose={() => setPreviewOpen({ condition: false, eventid: null })}
          eventdata={PreviewOpen.eventid}
        />
      )}
      <ViewUserDetails
        userDetails={userDetails}
        getUserdetails={getUserDeatils}
        isLoading={isloading}
        isShowDetails={isShowDetails}
        setIsShowDetails={setIsShowDetails}
        title={"manageevent"}
        headers={headers}
      />
      {drawerOpen && drawerType === "archive" && (
        <Drawer isOpen={drawerOpen} onClose={handleDrawer}>
          <Archived
            archivedTests={[]}
            size={20}
            cursor={"pointer"}
            title={"PastTest"}
            archiveUnarchive={archiveUnarchive}
            handleFilterArchiveRows={handleFilterArchiveRows}
            handleToggleSelectAll={handleToggleSelectAll}
            selectAll={selectAll}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setArchiveIds={setArchiveIds}
            archiveIds={archiveIds}
            handleFetchAgain={handleFetchAgain}
            setPreviewOpen={setPreviewOpen}
            getUserDeatils={getUserDeatils}
          />
        </Drawer>
      )}
    </section>
  );
}
