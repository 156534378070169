import { useEffect, useState } from "react";
import { postData } from "../ApiUtils/postData";
import { fetchData } from "../ApiUtils/fetchData";

export const useArchiveEvent = () => {
  const [arloading, setArLoading] = useState(false);

  const uri = `archiveevent`;

  const archiveEvent = async (eventidArr, isarchived) => {
    console.log("eventidArr", eventidArr);
    console.log("isarchived", isarchived);
    try {
      setArLoading(true);
      let data = { eventidArr, isarchived };
      const response = await postData(uri, data);
      console.log("archiveEvent", response?.data);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setArLoading(false);
    }
  };

  return { archiveEvent, arloading };
};

export const useGetArchivedEvents = () => {
  const [archivedEvents, setArchivedEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    setLoading(true);
    const uri = `getarchiveevent`;
    try {
      let data = await fetchData(uri);
      console.log("getarchiveevent", data);
      setArchivedEvents(data);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }
  };
  return { archivedEvents, loading, getEvents, setArchivedEvents };
};

export const toggleSelectAllEvents = (
  selectAll,
  data,
  setSelectedRows,
  setSelectAll,
  setArchiveIds
) => {
  console.log(selectAll);
  if (selectAll) {
    setSelectedRows([]);
    setArchiveIds([]);
  } else {
    const allScheduleIds = data.map((cell) => cell.eventid);
    const allTestIds = data.map((cell) => cell.eventid);
    console.log("allScheduleIds", allScheduleIds);
    console.log("allScheduleIds", allTestIds);
    setSelectedRows(allScheduleIds);
    setArchiveIds(allTestIds);
  }
  setSelectAll(!selectAll);
};
