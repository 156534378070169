// import react components and files
import React, { useContext, useMemo, useState } from "react";
import SectionOne from "./SectionOne/SectionOne";
import { getISOString } from "./TimePicker/timePickerUtils";

import useScheduleTest, {
  useGetScheduleUsersList,
} from "../hooks/useScheduleTest";
import {
  ScheduleTheTest,
  currDateChecker,
  handleFileUpload,
  markingSchemeChecker,
  showResultChecker,
  toNotifyCheckerChecker,
} from "./scheduleutils";
import SectionTwo from "./SectionTwo/SectionTwo";
import useUpdateScheduleTest from "../hooks/useUpdateScheduleTest";
// import libraries
import { useNavigate } from "react-router";

import { useParams } from "react-router";
import { useSearchParams, useLocation } from "react-router-dom";
// import styles
import "./schedule.scss";

const Schedule = () => {
  const { state } = useLocation();
  let navigate = useNavigate();
  const { date, showtime, time } = currDateChecker(state);
  // const currTime = currDateChecker(state)
  const markingScheme = markingSchemeChecker(state);
  const showResult = showResultChecker(state);
  const toNotify = toNotifyCheckerChecker(state);
  const status = state?.currentTestData?.status;
  const [dateValue, setDateValue] = useState(date);
  const [selectedTime, setSelectedTime] = useState(getISOString(time));
  const [toShowTime, setToShowTime] = useState(showtime);
  const [selectMarkingScheme, setSelectMarkingScheme] = useState(markingScheme);
  const [isChecked, setIsChecked] = useState(showResult);
  const [isToNotify, setToNotify] = useState(toNotify);
  const [currentSection, setCurrentSection] = useState(1);
  const [filename, setFilename] = useState("File name");
  const [data, setData] = useState([]);
  const [FileTypeError, setFileTypeError] = useState(null);
  const [showMsg, setShowMsg] = useState(false);
  const [title, settitle] = useState(
    state?.currentTestData?.title || "Title for new schedule "
  );
  const [selectedUserids, setSelectedUserids] = useState([]);
  const [useridArr2, setUseridArr2] = useState([]);
  const [removeUseridArr, setRemoveUseridArr] = useState([]);

  const { testId } = useParams();
  const [searchParams] = useSearchParams();

  // console.log("currDate", currDate)
  // console.log("currTime", currTime)
  console.log("markingScheme", markingScheme);
  console.log("showResult", showResult);
  console.log("toNotify", toNotify);
  console.log("selectedTime", selectedTime);
  const { ScheduleTest, schloading, schMessage } = useScheduleTest();
  const { UpdateScheduleTest, upsloading, updateMsg } = useUpdateScheduleTest();
  const from = searchParams.get("from");
  const checker = from !== "testlibrary";
  const { scheduleUsers, setScheduleUsers, isLoading } =
    useGetScheduleUsersList(checker ? testId : -1);
  // const { navigate, previousRoute } = useCustomNavigate();
  // const { fromUpcomingTest } = useContext(ManageScheduleContext);

  const cancelBtnText = checker ? "Cancel editing" : "Cancel scheduling";

  const setHeader = checker ? "Edit Schedule" : "Schedule Test";
  let subHeader = currentSection === 1 ? "Schedule details" : "User details";

  const fileUpload = (file) => {
    handleFileUpload(file, setFilename, setFileTypeError, setData);
  };

  const extractObjectValues = () => {
    const arrayOfUserids = data.map((obj) => obj.userid);
    return arrayOfUserids;
  };

  let useridArr = extractObjectValues();
  // console.log("useridArr", useridArr2)
  // console.log("scheduleUsers", scheduleUsers)
  const scheduelTest = () => {
    let data = selectedUserids?.map((item) => {
      return item.userid;
    });

    ScheduleTheTest(
      selectedTime,
      dateValue,
      ScheduleTest,
      testId,
      selectMarkingScheme,
      isChecked,
      data,
      title
    );
  };

  const updateScheduleTest = () => {
    let data = selectedUserids?.map((item) => {
      return item.userid;
    });

    if (selectedUserids.length === 0) {
      ScheduleTheTest(
        selectedTime,
        dateValue,
        UpdateScheduleTest,
        testId,
        selectMarkingScheme,
        isChecked,
        undefined,
        isToNotify,
        status,
        selectedUserids,
        removeUseridArr,
        title
      );
    } else {
      ScheduleTheTest(
        selectedTime,
        dateValue,
        UpdateScheduleTest,
        testId,
        selectMarkingScheme,
        isChecked,
        data,
        isToNotify,
        status,
        selectedUserids,
        removeUseridArr,
        title
      );
    }
  };
  const headerMsg = useMemo(() => {
    let message = checker ? updateMsg : schMessage;
    let includemsg = checker
      ? "Schedule updated successfully "
      : "Test scheduled successfully";
    let confirmationHeader = message?.includes("successfully")
      ? "Successful"
      : "Unsuccessful";
    let headerMsg = "";
    if (
      showMsg &&
      (from === "testlibrary" || from === "upcomingtest") &&
      !upsloading &&
      !schloading
    ) {
      headerMsg = setHeader + "  -  " + confirmationHeader;
    } else headerMsg = setHeader + "  -  " + subHeader;

    return headerMsg;
  }, [schloading, upsloading, updateMsg, schMessage, currentSection]);

  return (
    <main className="schedule_main">
      <header className="assement-header">
        {/* { setHeader + '  -  ' + subHeader } */}
        {headerMsg}
        {!showMsg && (
          <button
            onClick={(e) => {
              navigate(-1);
            }}
            className="button link-button"
          >
            {cancelBtnText}
          </button>
        )}
      </header>
      {currentSection === 1 && (
        <SectionOne
          setDateValue={setDateValue}
          dateValue={dateValue}
          selectedTime={selectedTime}
          setSelectMarkingScheme={setSelectMarkingScheme}
          selectMarkingScheme={selectMarkingScheme}
          setIsChecked={setIsChecked}
          isChecked={isChecked}
          title={title}
          settitle={settitle}
          setSelectedTime={setSelectedTime}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          setToShowTime={setToShowTime}
          toShowTime={toShowTime}
          setToNotify={setToNotify}
          isToNotify={isToNotify}
        />
      )}
      {currentSection === 2 && (
        <SectionTwo
          dateValue={dateValue}
          selectedTime={selectedTime}
          selectMarkingScheme={selectMarkingScheme}
          isChecked={isChecked}
          setCurrentSection={setCurrentSection}
          currentSection={currentSection}
          filename={filename}
          data={data}
          FileTypeError={FileTypeError}
          handleFileUpload={fileUpload}
          ScheduleTheTest={scheduelTest}
          setToNotify={setToNotify}
          isToNotify={isToNotify}
          updateScheduleTest={updateScheduleTest}
          schloading={schloading}
          upsloading={upsloading}
          scheduleUsers={scheduleUsers}
          setShowMsg={setShowMsg}
          showMsg={showMsg}
          setSelectedUserids={setSelectedUserids}
          selectedUserids={selectedUserids}
          setScheduleUsers={setScheduleUsers}
          setUseridArr2={setUseridArr2}
          message={checker ? updateMsg : schMessage}
          isLoading={isLoading}
          setRemoveUseridArr={setRemoveUseridArr}
          useridArr2={useridArr2}
        />
      )}
    </main>
  );
};

export default Schedule;
