import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./event.scss";
import {CgAsterisk} from 'react-icons/cg'
import BasicDateTimePicker from "./Datetimepicker";
// import Basicdat from "./Datetimepicker";
// import DateTimePicker from "@mui/lab/DateTimePicker";
// import MyDateTimePicker from "./Datetimepicker";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAdd({ open, onClose, onChange, onClick, eventdata }) {
  const toastRef = React.useRef()
  
  return (
    <React.Fragment>
      {/* <ToastContainer /> */}

      <Dialog
        open={open}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        style={{ minWidth: "40%" }}
      >
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
          />
        <DialogTitle>{"Create Event"}</DialogTitle>

        <DialogContent>
          <section
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <label>Event Name :<CgAsterisk color="red"/></label>
            <input
              type="text"
              name="name"
              className="input"
              placeholder="Enter the event name"
              onChange={onChange}
            />

            <label>Event Date :<CgAsterisk color="red"/></label>
            <input
              name="date"
              className="input"
              style={{ width: "49%" }}
              type="date"
              onChange={onChange}
              min={new Date().toISOString().split('T')[0]}
            />

            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label>Start Time :<CgAsterisk color="red"/></label>
                <input
                  name="start_time"
                  className="input"
                  type="time"
                  onChange={onChange}
                  // min={new Date().toTimeString().slice(0,5)}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label>End Time :<CgAsterisk color="red"/></label>
                <input
                  name="end_time"
                  className="input"
                  type="time"
                  onChange={onChange}
                />
              </div>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label>Registration start date:<CgAsterisk color="red"/></label>
                {/* {<BasicDateTimePicker/>} */}
                <input
                  name="Registration_start"
                  className="input"
                  type="date"
                  onChange={onChange}
                  min={new Date().toISOString().split('T')[0]}

                />
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label>Registration start Time :<CgAsterisk color="red"/></label>
                <input
                  name="Registration_start_time"
                  className="input"
                  type="time"
                  onChange={onChange}
                  // min={new Date().toTimeString().slice(0,5)}
                />
              </div>
              </div>
              <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label>Registration end date :<CgAsterisk color="red"/></label>
                <input
                  name="Registration_end"
                  className="input"
                  type="date"
                  onChange={onChange}
                  min={new Date().toISOString().split('T')[0]}

                />
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label>Registration end Time :<CgAsterisk color="red"/></label>
                <input
                  name="Registration_end_time"
                  className="input"
                  type="time"
                  onChange={onChange}
                  // min={new Date().toTimeString().slice(0,5)}
                />
              </div>
            </div>

            <label>Event Description :</label>
            <textarea
              name="description"
              cols={50}
              rows={5}
              onChange={onChange}
            ></textarea>
          </section>
        </DialogContent>
        <DialogActions>
          <button className="button secondary" onClick={onClose}>
            Close
          </button>
          <button className="button primary" onClick={onClick}>
            Create
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
