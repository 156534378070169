
import React, { useEffect, useMemo, useState } from 'react'
import ApiConfig from '../../ApiConfig'
import axios from 'axios'
import useFetchWithCacheData from './useFetchData'
import { fetchData } from '../ApiUtils/fetchData'

export default function useGetMarkingScheme() {
    const [markingSchemeArr, setMarkingSchemeArr] = useState([])
    const [isLoading , setIsLoading]=useState(false)
const getMarkingScheme=async ()=>{
    setIsLoading(true)
    let Api= new ApiConfig()
   let result = await fetchData('getmarkingscheme')
   //    const { data: myData } = useFetchWithCacheData('getmarkingscheme');
   setMarkingSchemeArr(result?.data)
   setIsLoading(false)
    console.log('marking', result.data)
  }
  useMemo(()=>{
    getMarkingScheme()
  },[])
  return { markingSchemeArr, isLoading }
}

