import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FaCheck } from "react-icons/fa";
import './formui.scss'

const FormUI = ({ open, Close, handleFormDate, setTag, setRole, role, setGroup, setPassword, setUserid, setLname, setFname, response }) => {
  console.log("response?.data?.status", response?.data)
  return (
    <Dialog open={open}
      onClose={Close}>
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        <>
          {/* {response?.data?.status === 201 ? (
            <section className='success-message'>
              <span className='form-icon'>
                <FaCheck color='green' size={20} />
              </span>
              <span className='message1'>
                User created successfully
              </span>
            </section>
          ) : ( */}
          <form className='formui' onSubmit={handleFormDate}>
            <section className='formui-data'>
              <label>UserID:</label>
              <input className='input finput' type="text" placeholder='Enter UserId' required
                onChange={(e) => setUserid(e.target.value)} />
            </section>
            <section className='formui-data'>
              <label>Password:</label>
              <input className='input finput' type="password" placeholder='Enter password' required
                onChange={(e) => setPassword(e.target.value)} />
            </section>
            <section className='formui-data'>
              <label>Role</label>
              <select className='sinput finput' value={role}
                onChange={(e) => setRole(e.target.value)} required>
                <option selected>Select</option>
                <option value="QuizMatser">Quiz Master</option>
                <option value="Admin">Admin</option>
                <option value="Proctor">Proctor</option>
              </select>
            </section>
            {/* <section className='formui-data'>
            <label>Group</label>
            <input className='input finput' type="text" placeholder='Enter group'
              onChange={(e) => setGroup(e.target.value)} />
          </section> */}
            {/* <section className='formui-data'>
            <label>Tag</label>
            <input className='input finput' type="text" placeholder='Enter tag'
              onChange={(e) => setTag(e.target.value)} />
          </section> */}
            <section className='formui-data'>
              <label>Firstname</label>
              <input className='input finput' type="text" placeholder='Enter firstname' required
                onChange={(e) => setFname(e.target.value)} />
            </section>
            <section className='formui-data'>
              <label>Lastname</label>
              <input className='input finput' type="text" placeholder='Enter lastname' required
                onChange={(e) => setLname(e.target.value)} />
            </section>
            <footer className='formui-footer'>
              <button type='submit'
                onClick={handleFormDate}
                className='button small primary'>
                Create
              </button>
              <button onClick={Close}
                className='button small secondary'>
                Cancel
              </button>
            </footer>
          </form>
          {/* )} */}
        </>
      </DialogContent>
    </Dialog>
  )
}

export default FormUI