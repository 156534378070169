import React, {
  useCallback,
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import Assignnode from "./Nodes/assignnode";
import Ifnode from "./Nodes/ifnode";
import Outputnode from "./Nodes/outputnode";
import Endnode from "./Nodes/endnode";
import Inputnode from "./Nodes/Inputnode";
import Startnode from "./Nodes/startnode";
import Condtionnode from "./Nodes/conditionnode";
import Declarenode from "./Nodes/declarenode";
import Declaredialog from "./Dialogs/DeclareDialog";
import FormDialog from "./Nodes/dialog";
import FormDialog1 from "./Nodes/customnodedialog";
import AssignDialog from "./Dialogs/AssignDialog";
import ConsolelogDialog from "./Dialogs/ConsoleLog";
import IfnodeDialog from "./Dialogs/IfnodeDialog";
import InputDialog from "./Dialogs/InputDialog";
import Fornode from "./Nodes/Fornode";
import { v4 as uuidv4 } from "uuid";
import ForConditionnode from "./Nodes/Forconditionnode";
import ForDialog from "./Dialogs/ForDialog";
import Whilenode from "./Nodes/Whilenode";
import Whiledialog from "./Dialogs/Whiledialog";
import Whileconditionnode from "./Nodes/WhileConditionnode";
import ContextMenu from "./Nodes/ContextMenu";
import forN from '../../../assets/forN.png'
// import parallelogramN from '../../assets/parallelogramN.png'
// default styling
import "reactflow/dist/style.css";
import { PiDiamondThin } from "react-icons/pi";
import "reactflow/dist/base.css";
import "./flowchart.css";
import {
  PiRectangleLight,
  PiParallelogramLight,
  PiDiamond,
} from "react-icons/pi";
import { MdOutlineHexagon } from "react-icons/md";
import ReactFlow, {
  Panel, Controls, Background, addEdge, getIncomers, getOutgoers, getConnectedEdges,
  applyEdgeChanges, applyNodeChanges, MarkerType, useReactFlow,
} from "reactflow";

const nodeTypes = {
  // customNode: CustomShapeNode,
  start: Startnode,
  assign: Assignnode,
  if: Ifnode,
  log: Outputnode,
  end: Endnode,
  condition: Condtionnode,
  inputtype: Inputnode,
  declare: Declarenode,
  for: Fornode,
  forcondition: ForConditionnode,
  while: Whilenode,
  whiledcondition: Whileconditionnode,
};

const onDragStart = (event, nodeType) => {
  event.dataTransfer.setData("application/reactflow", JSON.stringify(nodeType));
  event.dataTransfer.effectAllowed = "move";
};

function Sidebar() {
  const availableShapes = [
    { type: "assign", label: "assign", shape: "rectangle" },
    { type: "if", label: "Decision", shape: "diamond" },
    { type: "log", label: "Output", shape: "parallelogram" },
    { type: "inputtype", label: "input", shape: "inputrectangle" },
    { type: "declare", label: "declare", shape: "declare" },
    { type: "for", label: "for", shape: "hexagon" },
  ];
  let size = 28;

  function functionhandleShapes(node) {
    if (node.shape == "rectangle") {
      return <PiRectangleLight size={size} />;
    } else if (node.shape == "diamond") {
      return <PiDiamond size={size} />;
    } else if (node.shape == "parallelogram") {
      return <PiParallelogramLight size={size} />;
    } else if (node.shape == "inputrectangle") {
      return <PiParallelogramLight size={size} />;
    } else if (node.shape == "hexagon") {
      return <MdOutlineHexagon size={size} />;
      // return <Ifstsvg />;
    } else if (node.type == "declare") {
      return <div className="declare1"></div>;
    }
  }

  return (
    <>
      <div>
        <div className="tool-bar-wrapper">
          {availableShapes.map((node) => (
            <div
              key={node.id}
              className="react-flow__controls_icons"
            // style={{ cursor: 'move' }}
            >
              <div
                className="tooltip-content"
                style={{
                  top: `${node.shape == "diamond"
                    ? "-190%"
                    : node.shape == "hexagon"
                      ? "-105%"
                      : "-120%"
                    }`,
                }}
              >
                {node.shape == "diamond" ? (
                  <PiDiamondThin size={130} />
                ) : node.shape == "hexagon" ? (
                  // <div className="hexagon1"></div>
                  <img src={forN} alt="forN" />
                ) : (
                  <div
                    className={`${node.shape}`}
                    style={{
                      backgroundColor: "white",
                      border: "3px solid black",
                      display: "inline-block",
                    }}
                  ></div>
                )}

                <div
                  className="tooltiplabel"
                  style={{
                    left: `${node.shape == "hexagon"
                      ? "40%"
                      : node.shape == "diamond"
                        ? "33%"
                        : "35%"
                      }`,
                  }}
                >
                  {node.label}
                </div>
              </div>
              <div
                draggable
                onDragStart={(event) => {
                  onDragStart(event, node);
                }}
              >
                {functionhandleShapes(node)}
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

const Flowchart = forwardRef(({ fitViewCallback, isslidefullview }, _ref) => {

  const initialNodes = [
    {
      id: "1",
      type: "start",
      position: { x: 350.1255336587858, y: 30.34774052740444 },
      data: { label: "Start", width: 200, height: 80, shape: "oval" },
    },
    {
      id: "5",
      type: "end",
      position: { x: 350.1255336587858, y: 230.97357913406782 },
      data: { label: "End", width: 200, height: 80, shape: "ovalEnd" },
    },
  ];
  const initialEdges = [
    {
      id: "new" + 1 + "edge" + 5,
      source: "1",
      target: "5",
      type: "smoothstep",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      style: { strokeWidth: 4 },
    },
  ];

  const { fitView } = useReactFlow();

  useEffect(() => {
    setTimeout(() => {
      fitView({ maxZoom: 1 });
    }, 1);
  }, [fitViewCallback, isslidefullview]);



  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [codedata, setcodedata] = useState({
    1: { data: {}, id: "1", type: "start" },
    2: { data: {}, id: "2", type: "end" },
  });
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpen1, setIsDialogOpen1] = useState(false);
  const [editid, setEditnode] = useState(null);
  const [fordialog, setFordialog] = useState(false);
  const [whiledialog, setWhiledialog] = useState(false);


  const [assigndialog, setAssignDialog] = useState(false);
  const [ifdialog, setIfdialog] = useState(false);
  const [logdialog, setLogdilog] = useState(false);
  const [inputdialog, setInputDialog] = useState(false);
  const [declaredialog, setDecalaredialog] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [menu, setMenu] = useState(null);
  const ref = useRef(null);

  const onNodeContextMenu = useCallback(
    (event, node) => {
      event.preventDefault();
      const pane = ref.current.getBoundingClientRect();

      let obje = !fitViewCallback
        ? {
          id: node.id,
          top: event.pageY < pane.height - 100 && event.pageY - 300,
          left: event.pageX < pane.width - 100 && event.pageX - 50,
          right:
            event.clientX >= pane.width - 100 &&
            pane.width - event.pageX + 90,
          bottom:
            event.pageY >= pane.height - 100 &&
            pane.height - event.pageY + 300,
        }
        : {
          id: node.id,
          top: event.clientY < pane.height && event.clientY - 30,
          left: event.clientX < pane.width && event.clientX,
          right: event.clientX >= pane.width && pane.width - event.clientX,
          bottom:
            event.clientY >= pane.height && pane.height - event.clientY + 30,
        };
      setMenu(obje);
    },
    [fitViewCallback, setMenu]
  );

  useImperativeHandle(_ref, () => ({
    getnodesedges: () => {
      return { nodes: nodes, edges: edges, codedata: codedata };
    },
  }));

  const onEdgeMouseEnter = (event, edge) => {
    const updatedEdges = edges.map((item) => {
      if (item.id === edge.id) {
        return {
          ...item,
          markerEnd: {
            ...item.markerEnd,
            color: "red",
          },
          style: {
            ...item.style,
            stroke: "red",
          },
        };
      }
      return item;
    });
    setEdges(updatedEdges);
  };

  const onEdgeMouseLeave = (event, edge) => {
    const updatedEdges = edges.map((item) => {
      if (item.id === edge.id) {
        return {
          ...item,
          markerEnd: {
            ...item.markerEnd,
            color: "#adadac",
          },
          style: {
            ...item.style,
            stroke: "#adadac",
          },
        };
      }
      return item;
    });
    setEdges(updatedEdges);
  };
  function getnewPosition(sourceNode, targetNode) {
    const sourceX = sourceNode.position.x;
    const sourceY = sourceNode.position.y;
    const targetX = targetNode.position.x;
    const targetY = targetNode.position.y;

    const centerX = (sourceX + targetX) / 2;
    const centerY = (sourceY + targetY) / 2;

    let newPosition = {
      x: centerX,
      y: centerY + 50,
    };
    return newPosition;
  }
  useEffect(() => { }, [nodes]);
  console.log("nodes", nodes);

  const onNodesDelete = useCallback(
    (deleted) => {
      setNodes((prevNodes) =>
        prevNodes.filter(
          (node) => !deleted.some((deletedNode) => deletedNode?.id === node?.id)
        )
      );

      setEdges(
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, edges);
          const outgoers = getOutgoers(node, nodes, edges);
          const connectedEdges = getConnectedEdges([node], edges);
          codedata[node.id] = {};


          let firstedge = edges.find(
            (item) => item.source == incomers[0]?.id && item.target == node?.id
          );
          let secondege = edges.find(
            (item) => item.source == node?.id && item.target == outgoers[0]?.id
          );

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge)
          );
          const createdEdges = incomers.flatMap(
            ({ id: source, sourceHandle: sh }) =>
              outgoers.map(({ id: target, targetHandle: th }) => ({
                id: `${source}->${target} ${Date.now().toString()}`,
                source,
                target,
                type: "smoothstep",
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                  width: 10,
                  height: 10,
                },
                sourceHandle: firstedge.sourceHandle,
                targetHandle: secondege.targetHandle,
                style: { strokeWidth: 4 },
              }))
          );
          return [...remainingEdges, ...createdEdges];
        }, edges)
      );
    },
    [nodes, edges]
  );

  const deleteAllnodesandEdge = (array_of_nodes, nodeid, node1id) => {
    if (array_of_nodes.length == 1) {
      const remainingnodes = nodes.filter(
        (node) => !array_of_nodes.includes(node)
      );
      setNodes(remainingnodes);
    } else {
      let source = nodes.find((item) => item.id == nodeid);
      let target = nodes.find((item) => item.id == node1id);

      const incomers = getIncomers(source, nodes, edges);
      const outgoers = getOutgoers(target, nodes, edges);

      let firstedge = edges.find(
        (item) => item.source == incomers[0]?.id && item.target == source?.id
      );
      let secondege = edges.find(
        (item) => item.source == target?.id && item.target == outgoers[0]?.id
      );

      let connectedEdges = getConnectedEdges(array_of_nodes, edges);
      const remainingEdges = edges.filter(
        (edge) => !connectedEdges.includes(edge)
      );
      const remainingnodes = nodes.filter(
        (node) => !array_of_nodes.includes(node)
      );
      // console.log("remainingnodes", remainingnodes);
      let createdEdges = {
        id: `${incomers[0].id}->${outgoers[0].id}+${Date.now().toString()}`,
        source: incomers[0].id,
        target: outgoers[0].id,
        type: "smoothstep",
        sourceHandle: firstedge?.sourceHandle,
        targetHandle: secondege?.targetHandle,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 10,
          height: 10,
        },
        style: { strokeWidth: 4 },
      };

      setEdges([...remainingEdges, createdEdges]);
      setNodes(remainingnodes);
      codedata[nodeid] = {};
    }
  };

  function onNodesifdelete(startNodeId, endNodeId) {
    const visitedNodes = {};
    const resultNodes = [];

    const getNodeById = (nodeId) => nodes.find((node) => node.id === nodeId);

    const dfs = (currentNodeId) => {
      visitedNodes[currentNodeId] = true;
      resultNodes.push(getNodeById(currentNodeId));

      if (currentNodeId === endNodeId) {
        return;
      }

      const outgoingEdges = edges.filter(
        (edge) => edge.source === currentNodeId
      );
      for (const edge of outgoingEdges) {
        const nextNodeId = edge.target;
        if (!visitedNodes[nextNodeId]) {
          dfs(nextNodeId);
        }
      }
    };

    dfs(startNodeId);
    return resultNodes;
  }

  function openNodeEditDialog(event, node) {
    setEditnode(node);
    if (event.target.title == "Delete Node") {
      if (node.type == "if" || node.type == "for" || node.type == "while") {
        let node1 = {
          ...node,
          id: node.id + "1",
        };

        // let Array_nodes = onNodesifdelete(node.id, node1.id);
        deleteAllnodesandEdge(
          onNodesifdelete(node.id, node1.id),
          node.id,
          node1.id
        );
      } else {
        onNodesDelete([node]);
      }
    } else {
      if (node.type == "assign") {
        setAssignDialog(true);
      } else if (node.type == "if") {
        // console.log("log 1");

        setIfdialog(true);
      } else if (node.type == "log") {
        // console.log("log inside");
        setLogdilog(true);
      } else if (node.type == "inputtype") {
        setInputDialog(true);
      } else if (node.type == "declare") {
        setDecalaredialog(true);
      } else if (node.type == "for") {
        setFordialog(true);
      } else if (node.type == "while") {
        setWhiledialog(true);
      }
    }
  }
  const onNodesChange = useCallback(
    (changes) => {
      const updatedNodes = applyNodeChanges(changes, nodes);
      setNodes(updatedNodes);
    },
    [nodes]
  );

  const onEdgesChange = useCallback(
    (changes) => {
      const updatedEdges = applyEdgeChanges(changes, edges);
      setEdges(updatedEdges);
    },
    [edges]
  );

  const onConnect = useCallback(
    (params) => {
      // params.type = "step";
      params.arrowHeadType = "arrowclosed";

      if (params.sourceHandle === "true") {
        params.label = "TRUE";
      } else if (params.sourceHandle === "false") {
        params.label = "FALSE";
      }

      params.style = {
        strokeWidth: "3px",
      };

      const newEdge = addEdge({ ...params }, edges);

      setEdges(newEdge);
    },
    [edges]
  );

  const handleEdgeClick = (event, edge) => {
    if (edge.label !== "No action") {
      setSelectedEdge(edge);
      setIsDialogOpen(true);
    }
  };

  function Extraxvalue(targetNode, isOnLeftSide) {

    if ((targetNode && targetNode.type == 'if') && isOnLeftSide) {
      return -100;
    }
    else if ((targetNode && targetNode.type == 'if') && !isOnLeftSide) {
      return 100;
    }
    else if ((targetNode && (targetNode.type == 'while' || targetNode.type == 'for'))) {
      return 200
    }

    return 0;

  }


  function supportfunction(targetNode, isOnLeftSide, newShapeNode) {

    let targetEdge = edges.filter(
      (element) => element.source === targetNode.id
    );

    if (targetEdge && targetEdge.length >= 2) {
      if (targetNode.type == "if") {
        if (isOnLeftSide) {
          targetEdge = targetEdge.filter(
            (item) => item.sourceHandle == "true"
          );
        } else {
          targetEdge = targetEdge.filter(
            (item) => item.sourceHandle == "false"
          );
        }
      } else if (targetNode.type == "for" || targetNode.type == "while") {
        targetEdge = targetEdge.filter((item) => item.label != "No action");
      }
    }
    // important
    if (targetNode) {
      const edgeExists = edges.some(
        (edge) =>
          (edge.source === newShapeNode.id &&
            edge.target === targetNode.id) ||
          (edge.source === targetNode.id && edge.target === newShapeNode.id)
      );

      if (!edgeExists) {
        Handledragaddedges(targetEdge[0], newShapeNode);
      }
    }
  }

  const onDrop = useCallback(
    (event) => {
      const newNodeId = Date.now().toString();
      event.preventDefault();
      const data = event.dataTransfer.getData("application/reactflow");
      const shape = JSON.parse(data);
      if (typeof shape.type === "undefined" || !shape.type) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const targetNode = nodes.find(
        (n) =>
          position.x > n.position.x &&
          position.x < n.position.x + n.width &&
          position.y > n.position.y &&
          position.y < n.position.y + n.height
      );



      const isOnLeftSide = position.x < targetNode.position.x + targetNode.width / 2;

      const x1 = Extraxvalue(targetNode, isOnLeftSide)
      const x = (shape.type == 'if' || shape.type == 'for') ? (targetNode.width / 2) - 40 : (targetNode.width / 2) - 50
      const newShapeNode = {
        id: newNodeId,
        type: shape.type,
        position: targetNode
          ? { x: targetNode.position.x + x + x1, y: targetNode.position.y + 100 }
          : position,
        data: {
          label: shape.label,
          shape: shape.shape,
          id: newNodeId,
        },
      };

      supportfunction(targetNode, isOnLeftSide, newShapeNode)

      codedata[newNodeId] = {
        id: newNodeId,
        type: shape.type,
        data: {},
      };

      setcodedata(codedata);
      setNodes((prevNodes) => {
        const updatedNodes = prevNodes.map((node) => {
          if (targetNode && node.id === targetNode?.id) {
            return {
              ...node,
              data: {
                ...node.data,
                color: "#fbd086",
              },
            };
          }
          return node;
        });

        return [...updatedNodes];
      });
    },
    [reactFlowInstance, nodes, edges, codedata, setNodes, setEdges, setcodedata]
  );

  const onDragOver = useCallback((event, node) => {
    event.preventDefault();

    const position = reactFlowInstance.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    const targetNode = nodes.find(
      (n) =>
        position.x > n.position.x &&
        position.x < n.position.x + n.width &&
        position.y > n.position.y &&
        position.y < n.position.y + n.height
    );

    const updatedNodes = nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        color: targetNode && node.id === targetNode.id ? "red" : "#fbd086",
      },
    }));

    setNodes(updatedNodes);

    event.dataTransfer.dropEffect = "move";
  }, [reactFlowInstance, nodes, setNodes]);



  const handelnewlabel = (label, codedata) => {
    // console.log("label", label);
    const updatedNodes = nodes.map((node) => {
      if (
        node.id === editid.id &&
        node.data.label !== "Start" &&
        node.data.label !== "End"
      ) {
        return {
          ...node,
          data: {
            ...node.data,
            label: label,
          },
        };
      }
      return node;
    });
    setNodes(updatedNodes);
    if (codedata) {
      // console.log("main", codedata);
      setcodedata(codedata);
    }
    setAssignDialog(false);
    setIfdialog(false);
    setLogdilog(false);
    setInputDialog(false);
    setDecalaredialog(false);
    setIsDialogOpen1(false);
    setFordialog(false);
    setWhiledialog(false);
  };

  const handleNodeSelection = (shape, index) => {
    const newNodeId = Date.now().toString();

    const sourceNode = nodes.find((node) => node.id === selectedEdge.source);
    const targetNode = nodes.find((node) => node.id === selectedEdge.target);
    let newPosition = { x: 100, y: 100 };

    if (sourceNode && targetNode) {
      let newPosition = getnewPosition(sourceNode, targetNode);

      // updateState({ nodes:, edges: });
      if (shape.shape == "diamond") {
        let newPosition = getnewPosition(sourceNode, targetNode);
        const newShapeNode = {
          id: newNodeId.toString(),
          type: shape.type,
          position: newPosition,
          data: {
            label: shape.label,
            shape: shape.shape,
            deletenode: deletenode,
            id: newNodeId.toString(),
          },
        };

        const newShapeNode1 = {
          id: newNodeId.toString() + "1",
          type: "condition",
          position: { x: newPosition.x, y: newPosition.y + 150 },
          data: {
            label: "c",
            shape: "circle",
          },
        };

        let sourceid = selectedEdge.source;
        let dimondtopid = newShapeNode.id;
        let circletraget = newShapeNode1.id;
        let target = selectedEdge.target;

        const newEdge1 = {
          id: "new" + sourceid + "edge" + dimondtopid,
          source: sourceid,
          type: "smoothstep",
          arrowHeadType: "arrowclosed",
          sourceHandle: selectedEdge.sourceHandle,
          target: dimondtopid,
          targetHandle: "b",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          style: { strokeWidth: 4 },
        };

        const newEdge2 = {
          id: "new" + dimondtopid + "edge" + circletraget,
          type: "smoothstep",
          arrowHeadType: "arrowclosed",
          source: dimondtopid,
          sourceHandle: "true",
          label: "true",
          target: circletraget,
          targetHandle: "b",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          style: { strokeWidth: 4 },
        };

        const newEdge3 = {
          id: uuidv4(),
          source: dimondtopid,
          type: "smoothstep",
          arrowHeadType: "arrowclosed",
          sourceHandle: "false",
          target: circletraget,
          targetHandle: "c",
          label: "false",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          style: { strokeWidth: 4 },
        };

        const newEdge4 = {
          id: "new" + circletraget + "edge" + target,
          type: "step",
          arrowHeadType: "arrowclosed",
          source: circletraget,
          sourceHandle: "d",
          target: target,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          targetHandle: selectedEdge.targetHandle,
          style: { strokeWidth: 4 },
        };

        const updatedEdges = edges.filter(
          (edge) => edge.id !== selectedEdge.id
        );

        const updatedNodes = nodes.map((node) => {
          // console.log("indede", node.position.y > newShapeNode.position.y);
          if (node.position.y > newShapeNode.position.y) {
            return {
              ...node,
              position: { x: node.position.x, y: node.position.y + 300 },
            };
          }
          return node;
        });
        setNodes([...updatedNodes, newShapeNode, newShapeNode1]);
        setEdges([...updatedEdges, newEdge1, newEdge2, newEdge3, newEdge4]);
        codedata[newNodeId] = {
          id: newNodeId,
          type: shape.type,
          data: {},
        };
        setcodedata(codedata);
      } else if (shape.shape == "hexagon") {
        let newPosition = getnewPosition(sourceNode, targetNode);
        const newShapeNode = {
          id: newNodeId.toString(),
          type: shape.type,
          position: newPosition,
          data: {
            label: shape.label,
            shape: shape.shape,
            deletenode: deletenode,
            id: newNodeId.toString(),
          },
        };

        const newShapeNode1 = {
          id: newNodeId.toString() + "1",
          type: shape.type == "for" ? "forcondition" : "whiledcondition",
          position: { x: newPosition.x, y: newPosition.y + 200 },
          data: {
            label: "c",
            shape: "circle",
          },
        };

        let sourceid = selectedEdge.source;
        let Forshapeid = newShapeNode.id;
        let circletraget = newShapeNode1.id;
        let target = selectedEdge.target;

        const newEdge1 = {
          id: Date.now().toString() + "1",
          source: sourceid,
          type: "smoothstep",
          arrowHeadType: "arrowclosed",
          sourceHandle: selectedEdge.sourceHandle,
          target: Forshapeid,
          targetHandle: "b",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          style: { strokeWidth: 4 },
        };
        // ss
        const newEdge2 = {
          id: "Edge" + Date.now().toString() + "2",
          source: Forshapeid,
          type: "smoothstep",
          arrowHeadType: "arrowclosed",
          sourceHandle: "a",
          target: circletraget,
          targetHandle: "b",
          label: "No action",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          style: { strokeWidth: 4 },
        };

        const newEdge3 = {
          id: "Edge" + Date.now().toString() + "3",
          source: Forshapeid,
          type: "smoothstep",
          arrowHeadType: "arrowclosed",
          sourceHandle: "k",
          label: "Next",
          target: circletraget,
          targetHandle: "k",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          style: { strokeWidth: 4 },
        };

        const newEdge4 = {
          id: "Edge" + Date.now().toString() + "4",
          source: circletraget,
          type: "smoothstep",
          arrowHeadType: "arrowclosed",
          sourceHandle: "a",
          target: target,
          targetHandle: selectedEdge.targetHandle,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          style: { strokeWidth: 4 },
        };

        const updatedEdges = edges.filter(
          (edge) => edge.id !== selectedEdge.id
        );

        const updatedNodes = nodes.map((node) => {
          // console.log("indede", node.position.y > newShapeNode.position.y);
          if (node.position.y > newShapeNode.position.y) {
            return {
              ...node,
              position: { x: node.position.x, y: node.position.y + 200 },
            };
          }
          return node;
        });

        setNodes([...updatedNodes, newShapeNode, newShapeNode1]);
        setEdges([...updatedEdges, newEdge1, newEdge2, newEdge3, newEdge4]);
        codedata[newNodeId] = {
          id: newNodeId,
          type: shape.type,
          data: {},
        };
        setcodedata(codedata);
      } else {
        const newShapeNode = {
          id: "" + newNodeId,
          type: shape.type,
          position: newPosition,
          data: {
            label: shape.label,
            shape: shape.shape,
            deletenode: deletenode,
            id: "" + newNodeId,
          },
        };

        let source = selectedEdge.source;
        let target = newShapeNode.id;
        let source1 = newShapeNode.id;
        let target1 = selectedEdge.target;

        const newEdge = {
          id: uuidv4(),
          source: source,
          type:
            selectedEdge.sourceHandle == "true" ||
              selectedEdge.sourceHandle == "false"
              ? "step"
              : "smoothstep",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          // label:selectedEdge.sourceHandle=='true'?'true':selectedEdge.sourceHandle=='false'?'false':'',

          arrowHeadType: "arrowclosed",
          sourceHandle: selectedEdge.sourceHandle,
          target: newShapeNode.id,

          targetHandle: "b",
          style: { strokeWidth: 4 },
        };

        const newEdge1 = {
          id: uuidv4(),
          type:
            selectedEdge.sourceHandle == "true" ||
              selectedEdge.sourceHandle == "false"
              ? "step"
              : "smoothstep",

          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
          },
          // label:selectedEdge.sourceHandle=='true'?'true':selectedEdge.sourceHandle=='false'?'false':'',

          source: source1,
          sourceHandle: "true",
          target: target1,
          targetHandle: selectedEdge.targetHandle,
          style: { strokeWidth: 4 },
        };
        const updatedEdges = edges.filter(
          (edge) => edge.id !== selectedEdge.id
        );

        const updatedNodes = nodes.map((node) => {
          // console.log("indede", node.position.y > newShapeNode.position.y);
          if (node.position.y > newShapeNode.position.y) {
            return {
              ...node,
              position: { x: node.position.x, y: node.position.y + 100 },
            };
          }
          return node;
        });
        // console.log("new added Edge", newEdge, "new add Edge 2", newEdge1);
        setNodes([...updatedNodes, newShapeNode]);
        setEdges([...updatedEdges, newEdge, newEdge1]);
        codedata[newNodeId] = {
          id: newNodeId,
          type: shape.type,
          data: {},
        };
        setcodedata(codedata);
      }
    }
    setIsDialogOpen(false);
  };

  const deletenode = () => {
    setAssignDialog(false);
    // console.log("deleted node is");
  };

  const pointToLineDistance = (x, y, x1, y1, x2, y2) => {
    if (x1 > x2 || (x1 === x2 && y1 > y2)) {
      [x1, y1, x2, y2] = [x2, y2, x1, y1];
    }
    const A = x - x1;
    const B = y - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const lenSq = C * C + D * D;
    const param = dot / lenSq;

    let xx, yy;

    if (param < 0 || (x1 === x2 && y1 === y2)) {
      xx = x1;
      yy = y1;
    } else if (param > 1) {
      xx = x2;
      yy = y2;
    } else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    const dx = x - xx;
    const dy = y - yy;

    return Math.sqrt(dx * dx + dy * dy);
  };

  function handlenonifnode(targetedge, targetnode) {
    console.log("target$$", targetedge);
    // let sourcnode=getEdge(targetedge.source)
    // console.log("targe", targetedge);
    let source = targetedge.source;
    let target = targetnode.id;
    let source1 = targetnode.id;
    let target1 = targetedge.target;

    const newEdge = {
      id: uuidv4(),
      source: source,
      type:
        targetedge.sourceHandle == "true" || targetedge.sourceHandle == "false"
          ? "step"
          : "smoothstep",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      label: targetedge?.label ? targetedge.label : "",

      arrowHeadType: "arrowclosed",
      sourceHandle: targetedge.sourceHandle,
      target: targetnode.id,

      targetHandle: "b",
      style: { strokeWidth: 4 },
    };

    const newEdge1 = {
      id: uuidv4(),
      type:
        targetedge.sourceHandle == "true" || targetedge.sourceHandle == "false"
          ? "step"
          : "smoothstep",

      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },

      source: source1,
      sourceHandle: "true",
      target: target1,
      targetHandle: targetedge.targetHandle,
      style: { strokeWidth: 4 },
    };

    const updatedEdges = edges.filter((edge) => edge.id !== targetedge.id);
    let consty = 100
    const updatedNodes = nodes.map((node) => {
      console.log("target Position", node.position.x, targetnode.position.x)
      if ((node.position.y >= targetnode.position.y)) {

        return {

          ...node,
          position: { x: node.position.x, y: node.position.y + 100 },
        };

      }

      return node;
    });
    setNodes([...updatedNodes, targetnode]);
    setEdges([...updatedEdges, newEdge, newEdge1]);
  }

  function handlediamondnode(selectedEdge, newShapeNode) {
    // let newPosition=getnewPosition(sourceNode,targetNode)
    // const newShapeNode = {
    //   id:  newNodeId.toString(),
    //   type: shape.type,
    //   position: newPosition,
    //   data: {
    //     label: shape.label,
    //     shape: shape.shape,
    //   },
    // };

    const newShapeNode1 = {
      id: newShapeNode.id.toString() + "1",
      type: "condition",
      position: {
        x: newShapeNode.position.x + 20,
        y: newShapeNode.position.y + 100,
      },
      data: {
        label: "c",
        shape: "circle",
      },
    };

    let sourceid = selectedEdge.source;
    let dimondtopid = newShapeNode.id;
    let circletraget = newShapeNode1.id;
    let target = selectedEdge.target;

    const newEdge1 = {
      id: "new" + sourceid + "edge" + dimondtopid,
      source: sourceid,
      type: "step",
      arrowHeadType: "arrowclosed",
      sourceHandle: selectedEdge.sourceHandle,
      target: dimondtopid,
      targetHandle: "b",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      style: { strokeWidth: 4 },
    };

    const newEdge2 = {
      id: "new" + dimondtopid + "edge" + circletraget,
      type: "smoothstep",
      arrowHeadType: "arrowclosed",
      source: dimondtopid,
      sourceHandle: "true",
      label: "true",
      target: circletraget,
      targetHandle: "b",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      style: { strokeWidth: 4 },
    };

    const newEdge3 = {
      id: uuidv4(),
      source: dimondtopid,
      type: "smoothstep",
      arrowHeadType: "arrowclosed",
      sourceHandle: "false",
      target: circletraget,
      targetHandle: "c",
      label: "false",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      style: { strokeWidth: 4 },
    };

    const newEdge4 = {
      id: "new" + circletraget + "edge" + target,
      type: "step",
      arrowHeadType: "arrowclosed",
      source: circletraget,
      sourceHandle: "d",
      target: target,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      targetHandle: selectedEdge.targetHandle,
      style: { strokeWidth: 4 },
    };

    const updatedEdges = edges.filter((edge) => edge.id !== selectedEdge.id);
    const updatedNodes = nodes.map((node) => {
      // console.log("indede", node.position.y > newShapeNode.position.y);
      if (node.position.y > newShapeNode.position.y) {
        return {
          ...node,
          position: { x: node.position.x, y: node.position.y + 100 },
        };
      }
      return node;
    });
    setNodes([...updatedNodes, newShapeNode, newShapeNode1]);
    setEdges([...updatedEdges, newEdge1, newEdge2, newEdge3, newEdge4]);
    // codedata[newNodeId] = {
    //   id: newNodeId,
    //   type: shape.type,
    //   data: {},
    // };
    // setcodedata(codedata);
  }
  function handleforloopdragedges(selectedEdge, newShapeNode) {
    const newShapeNode1 = {
      id: newShapeNode.id.toString() + "1",
      type: "forcondition",
      position: {
        x: newShapeNode.position.x + 20,
        y: newShapeNode.position.y + 100,
      },
      data: {
        label: "C",
        shape: "circle",
      },
    };

    let sourceid = selectedEdge.source;
    let Forshapeid = newShapeNode.id;
    let circletraget = newShapeNode1.id;
    let target = selectedEdge.target;

    const newEdge1 = {
      id: Date.now().toString() + "1",
      source: sourceid,
      type: "smoothstep",
      arrowHeadType: "arrowclosed",
      sourceHandle: selectedEdge.sourceHandle,
      target: Forshapeid,
      targetHandle: "b",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      style: { strokeWidth: 4 },
    };

    const newEdge2 = {
      id: "Edge" + Date.now().toString() + "2",
      source: Forshapeid,
      type: "smoothstep",
      arrowHeadType: "arrowclosed",
      sourceHandle: "a",
      target: circletraget,
      targetHandle: "b",
      label: "No action",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      style: { strokeWidth: 4 },
    };

    const newEdge3 = {
      id: "Edge" + Date.now().toString() + "3",
      source: Forshapeid,
      type: "smoothstep",
      arrowHeadType: "arrowclosed",
      sourceHandle: "k",
      label: "Next",
      target: circletraget,
      targetHandle: "k",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      style: { strokeWidth: 4 },
    };

    const newEdge4 = {
      id: "Edge" + Date.now().toString() + "4",
      source: circletraget,
      type: "smoothstep",
      arrowHeadType: "arrowclosed",
      sourceHandle: "a",
      target: target,
      targetHandle: selectedEdge.targetHandle,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
      },
      style: { strokeWidth: 4 },
    };

    const updatedEdges = edges.filter((edge) => edge.id !== selectedEdge.id);
    const updatedNodes = nodes.map((node) => {
      if (node.position.y > newShapeNode.position.y) {
        return {
          ...node,
          position: { x: node.position.x, y: node.position.y + 100 },
        };
      }
      return node;
    });
    setNodes([...updatedNodes, newShapeNode, newShapeNode1]);
    setEdges([...updatedEdges, newEdge1, newEdge2, newEdge3, newEdge4]);
  }

  const getLayoutedElements = (nodes, edges) => {
    return { nodes, edges };
  };

  const onLayout = useCallback(() => {
    const layouted = getLayoutedElements(nodes, edges);

    setNodes([...layouted.nodes]);
    setEdges([...layouted.edges]);

    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [nodes, edges]);

  function Handledragaddedges(targetedge, targetnode) {
    // console.log("dfd");
    if (targetedge && targetnode) {
      if (targetnode.type == "if") {
        handlediamondnode(targetedge, targetnode);
      } else if (targetnode.type == "for") {
        handleforloopdragedges(targetedge, targetnode);
      } else {
        handlenonifnode(targetedge, targetnode);
      }

      // console.log("targetedge", targetedge, "targetnode", targetnode);
    }
  }

  const onLoad = (reactFlow) => {
    reactFlow.fitView();
  };
  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  return (
    <>
      <ReactFlow
        ref={ref}
        nodes={nodes}
        edges={edges}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        onEdgesChange={onEdgesChange}
        onEdgeClick={handleEdgeClick}
        onNodeClick={openNodeEditDialog}
        onInit={setReactFlowInstance}
        onDrop={onDrop}
        onPaneClick={onPaneClick}
        onDragOver={onDragOver}
        onLoad={onLoad}
        fitView
        maxZoom={1}
        onNodeContextMenu={onNodeContextMenu}
        connectionLineStyle={{
          strokeWidth: "40rem",
        }}
      >
        <Background />
        <Controls />
        {menu && (
          <ContextMenu
            onClick={onPaneClick}
            {...menu}
            onNodesDelete={onNodesDelete}
            onNodesifdelete={onNodesifdelete}
            deleteAllnodesandEdge={deleteAllnodesandEdge}
          />
        )}

        <Panel position="top-left">
          {/* {Sidebar()} */}
          <Sidebar />
        </Panel>

        {/* <MiniMap /> */}
      </ReactFlow>

      {assigndialog && (
        <AssignDialog
          onClose={() => setAssignDialog(false)}
          codedata={codedata}
          nodeid={editid}
          onNodeSelect={handelnewlabel}
        />
      )}

      {ifdialog && (
        <IfnodeDialog
          onClose={() => setIfdialog(false)}
          codedata={codedata}
          nodeid={editid}
          onNodeSelect={handelnewlabel}
        />
      )}
      {logdialog && (
        <ConsolelogDialog
          onClose={() => setLogdilog(false)}
          codedata={codedata}
          nodeid={editid}
          onNodeSelect={handelnewlabel}
        />
      )}

      {inputdialog && (
        <InputDialog
          onClose={() => setInputDialog(false)}
          codedata={codedata}
          nodeid={editid}
          onNodeSelect={handelnewlabel}
        />
      )}

      {declaredialog && (
        <Declaredialog
          onClose={() => setDecalaredialog(false)}
          codedata={codedata}
          nodeid={editid}
          onNodeSelect={handelnewlabel}
        />
      )}

      {isDialogOpen && (
        <FormDialog
          onClose={() => setIsDialogOpen(false)}
          onNodeSelect={handleNodeSelection}
        />
      )}

      {fordialog && (
        <ForDialog
          onClose={() => setFordialog(false)}
          codedata={codedata}
          nodeid={editid}
          onNodeSelect={handelnewlabel}
        />
      )}

      {whiledialog && (
        <Whiledialog
          onClose={() => setWhiledialog(false)}
          codedata={codedata}
          nodeid={editid}
          onNodeSelect={handelnewlabel}
        />
      )}
    </>
  );
});

export default React.memo(Flowchart);