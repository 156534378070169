import React, { useState } from 'react'
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import "react-toastify/dist/ReactToastify.css";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { FaDownload } from "react-icons/fa6";
import {MdGroupAdd } from 'react-icons/md'
import xlfile from './sample.xlsx'
import './selectuserids.scss'
import Table from '../../../UtilityFunctions/Table';
import {handleFileUpload } from '../../scheduleutils'
export default function SelectFromFile({setFilenameError,   addUsers}) {
    const [selectedUserids2,setselectedUserids2]=useState([])
    const [data,setData]=useState([])
    const [filename, setFilename] = useState('File name')
    const [showModal, setShowMoal]=useState(false)
  const [FileTypeError, setFileTypeError] = useState(null);
    const headers = [
        { id: "1", field: "userid", headerName: "Participants " },
        
      ];
    // const handleRemoveUser=()=>{
    //     console.log("userids selected",selectedUserids2)
    //    let  selectedUserids22=selectedUserids2.map((item)=>{
    //       return item.userid
    //     })
    //     data = data.filter((item)=>{
          
    //      return  !selectedUserids22.includes(item.userid)
    //     })
    //     setData1(data)
    //     setUseridArr2(data.map((item)=>{
    //       return item.userid
    //     }))
        
    //     console.log(data)
       
    //   }
    // useEffect(()=>{
    //     // setData1([...scheduleUsers,...data])
    //     // console.log("data1",data1)
    //     setUseridArr2(data.map((item)=>{
    //       return item.userid
    //     }))
    //      },[filename])
  return (
    <>
    {showModal?
   
        <>
        <Dialog
          open={showModal}
          maxWidth={"lg"}
          PaperProps={{
            style: {
              borderRadius: 14,
              minHeight: "80vh",
              maxHeight: "fit-content",
              minWidth: "74%",
              overflow: "hidden",
            },
          }}
        >
          <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '6px' }}>
            <span
              style={{ marginLeft: '1.5rem' }}
            >Add Users</span>
            <IconButton >
              <CloseIcon onClick={()=>{setShowMoal(false)}} />
            </IconButton>

          </DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
      <div 
        style={{
            display:'flex',
            flexDirection:'column',
            gap:'2rem'
        }}
      >
           <section className='schedule_selectuserids'>
          <section className='schedule_selectuserids_input_samplefile'>
  
          <section className='schedule_selectuserids_input' >

            <label htmlFor='selectuserids_li' className='selectuserids_label'>Select CSV or Excel File </label>

            <div className='selectuserids_input_filename' >
              <input id='selectuserids_li'
                type="file" accept=".csv, .xlsx, .xls"
                onChange={(e) => handleFileUpload(e.target.files[0],setFilename, setFileTypeError, setData)}
                title={`${filename}`} />
              <div className='selectuserids_filename'>{filename}</div>
            </div>


          </section>

          <a
            href={xlfile}
            download="Sample_userids"
            target="_blank"
            rel="noreferrer"
            >
            <button
            className="link-button small selectuserids_samplefile">
              Download sample file
              <FaDownload size={15} />
            </button>
          </a>

        </section>
        </section>
        <div>
        <div
        className='title-header-container'
       >

          
       <p
       className='title-header'
       > {selectedUserids2.length} row(s) selected of {data.length} row(s)</p>
        </div>
        <section className='table-container'>
       

                <Table
                    rows={data}
                    headers={headers}
                    //  isLoading={isLoading}
                    setSelected={setselectedUserids2}
                    data={selectedUserids2}
                    //    isCheckBox={false}
                    noDataMsg={'No data available'}
                    
                    />
          </section>
          </div>
       </div> 
          </DialogContent>

          <DialogActions sx={{ marginRight: '1.3rem' }}>


            <button
              style={{ marginBottom: "1rem", marginRight: "0.5rem" }}
              className="button  primary"
              onClick={(e) => {
                addUsers(selectedUserids2);
                setShowMoal(false)
              }}
            >
             Select and Add 
            </button>
            <button
              className="button "
              style={{ marginBottom: "1rem" }}
              onClick={()=>{setShowMoal(false)}}
            >
              Cancel
            </button>
          </DialogActions>
        </Dialog>
       
            
            </>
        :
        

        <button 
        className="icon-button"
        title='Add users from file'
        onClick={()=>{setShowMoal(true)}}
        >
            <MdGroupAdd size={15} />
        </button>
            
            }
        
                  </>
  )
}
