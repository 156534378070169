export function date_helper1(date) {
  const d = new Date(date);
  return (
    d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + (d.getDate())).slice(-2)
  );
}
export function date_helper(date) {
  const d = new Date(date);
  return (
    ("0" + (d.getDate())).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear()
  );
}

export const dateTimeHelper = (date, includeTime = false, to12HourFormat = false) => {
  // const d =new   Date(date);
  // const d2= Date(date)
  // console.log("d ###",d+'\n',d2+"\n",date)
  // const dateString = ("0" + (d.getDate())).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + d.getFullYear();
  const dateString = date.slice(0,10)

  if (includeTime) {
    // let hours = d.getHours();
    let hours = date.slice(11,13)
    const minutes = date.slice(14,16)
    // const minutes = ("0" + d.getMinutes()).slice(-2); // Get minutes
    // console.log(hours,minutes)
    // Convert to 12-hour format
    if (to12HourFormat) {
      const ampm = hours >= 12 ? 'PM' : 'AM'; // Get AM/PM
      hours = ("0" + (hours % 12 || 12)).slice(-2); // Get hours in 12-hour format
      return dateString + " -- " + hours + ":" + minutes + " " + ampm;
    }

    // Convert to 24-hour format
    hours = ("0" + hours).slice(-2); // Pad with leading zero if needed
    return dateString + " -- " + hours + ":" + minutes;
  }

  return dateString;
}

export const getUpcomingTest = (schedules) => {
  const currentDate = new Date();
  // Arrays to hold future and past schedules
  const upcomingTest = [];
  schedules.forEach(schedule => {
    // Convert scheduletime to Date object
    const scheduleTime = new Date(schedule.scheduletime);

    // Compare with current date
    if (scheduleTime > currentDate) {
      upcomingTest.push(schedule);
    }
  });

  return upcomingTest
}
