export const calculateStep = (time, stepValue, unit = 'minutes') => {
  const newTime = new Date(time);
  const currentValue = newTime.getHours() * 60 + newTime.getMinutes(); // Get total minutes
  const closestStep = Math.floor(currentValue / stepValue) * stepValue;
  newTime.setMinutes(closestStep);
  return newTime; // Return Date object directly
};

export const calculateValidTillTime = (time, stepValue) => {
  const newTime = new Date(time);
  newTime.setMinutes(newTime.getMinutes() + stepValue);
  return newTime;
};

export const handleCalculateValidTillTime = (stepValue, selectedTime, setValidTillTime) => {
  if (stepValue !== null && selectedTime !== null) {
    setValidTillTime(calculateValidTillTime(selectedTime, stepValue));
  } else {
    // Consider handling cases where both aren't selected (e.g., show a message)
  }
};

const getCurrentDate = (time) => {
  const currentDate = new Date();
  // console.log("time", time)
  // Extract hours, minutes, and seconds from the time string
  const [hours, minutes, seconds] = time.split(':');
  // Set the time on the existing date object
  currentDate.setHours(hours, minutes, seconds || 0, 0);
  // console.log("currentDate else", currentDate.getHours())
  return currentDate;
}

export const getISOString = (time, toAddHrs) => {
  const adjustedTime = getCurrentDate(time, toAddHrs).toISOString()
  return adjustedTime
}

