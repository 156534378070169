import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToEvaluateGrandTotal } from "./useToEvaluateGrandTotal";
import { setResult } from '../SetResult'
import ApiConfig from '../../../../ApiConfig'
import { getValueFromSession } from "../../../UtilityFunctions/getSetValueSession";
import { modifiedResults } from '../modifiedResults'
import insertCode from '../insertUserCode'


const useSetEvaluationQuizScore = (randomexe, currentStepIndex, resultarr, numTests, debugcode, output, setQuesResp, quesresp, username, setSubmitLoading, setSubmitMsg, scheduleid, showResult) => {

  const { toFinishEvaluate } = useToEvaluateGrandTotal()

  // const [submit_loading, setSubmitLoading] = useState(false);


  let navigate = useNavigate();

  async function toSetEvaluationQuizScore(fromTimer) {

    setSubmitLoading(true);
    console.log("fromTimer", fromTimer)
    console.log("inside toSetEvaluationQuizScore")
    // console.log("setSubmitLoading", setSubmitLoading)

    // if (fromTimer === 'notFromTimer') {
    let noOfQuestions;
    let no_of_correct;
    let totalMarks;
    let score;

    await setResult(randomexe, currentStepIndex, resultarr, no_of_correct, noOfQuestions, totalMarks, score, numTests, debugcode, output, fromTimer);
    await toFinishEvaluate(randomexe, setQuesResp, quesresp, username);

    // }
    // let results = JSON.parse(getValueFromSession("results"));
    // console.log("results", results);
    let evaluationResult = JSON.parse(getValueFromSession("evaluationResult"));
    // let score;
    
    let noOfCorrectAnswers;
    // let noOfQuestions;
    let totalmarks;
    let starttime;
    let temp_end_time;
    let end_time;
    let userid;
    let testdetailsjson;
    let percentile = 0
    // console.log('evaluationResult.length', evaluationResult != undefined)
    if (evaluationResult != undefined) {
      // console.log("evaluationResult", evaluationResult);
      score = evaluationResult.score;
      noOfCorrectAnswers = evaluationResult.correct;
      noOfQuestions = evaluationResult.questions;
      totalmarks = evaluationResult.maxscore;
      scheduleid= getValueFromSession("scheduleid")
      // attempted = evaluationResult.attempted;
      let results = JSON.parse(getValueFromSession("results"));
      testdetailsjson = JSON.stringify(modifiedResults(results));
      let fromQuiz = sessionStorage.getItem("fromQuiz")
      if (fromQuiz) {
        starttime = new Date(parseInt(sessionStorage.getItem("time"))).toISOString()
      } else {
        starttime = new Date(parseInt(sessionStorage.getItem("time"))).toISOString()
      }
      temp_end_time = Date.now();
      end_time = new Date(temp_end_time).toISOString();
      // userid = sessionStorage.getItem('username');
      userid = getValueFromSession("username");
      // userid = 'debug1@gmail.com';
      // module_name = sessionStorage.getItem("module_name");
      // coin = 10;
    }

    let data = {
      userid,
      scheduleid,
      score,
      totalmarks,
      percentile,
      starttime,
      testdetailsjson,
      noOfCorrectAnswers,
      noOfQuestions,
    };
    let Api = new ApiConfig();
    let uri = `${Api.BaseURI}/submittestresponse`;

    // if (fromTimer === 'notFromTimer') {
    insertCode().then()
    // }

    // setSubmitMsg(false);
    // Api.addApiKey(`${Api.BaseURI}/evalresponse`)
    await axios.post(Api.addApiKey(uri), data).then((resp) => {
      // console.log(resp.data);
      // this.setState({ submit_loading: false });
      // this.setState({ submit_smg: true });
      if(resp.data.status === 403)
      {
        this.setState({ navigateTo: true, quesresp: resp.data });
      }
      setSubmitLoading(false);
      // setSubmitMsg(true)
      // if (fromTimer) {
      //   setSubmitMsg(true);
      //   // setIsTimer(false);
      //   setTimeout(() => {
      //     navigate("/result", { state: { showHomeBtn: false } });
      //   }, 2000);
      // } else {
      //   setSubmitMsg(false);
      //   navigate("/result", {
      //     state: { showHomeBtn: true, coins: 10 },
      //   });
      // }
      if (fromTimer) {
        setSubmitMsg(false);
        navigate("/assessment/result", {
          state: {
            showHomeBtn: true, coins: 10,
            showResult: showResult
          },
        });
      } else {
        navigate("/assessment/result", {
          state: {
            showHomeBtn: true, coins: 10,
            showResult: showResult
          },
        });
      }
      // setTimeout(() => {
      // this.setState({ navGreet: true })
      // }, 500)
    });
  }

  return { toSetEvaluationQuizScore }
}

export default useSetEvaluationQuizScore;