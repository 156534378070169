import { saveAs } from 'file-saver'

const handleDownload = (report, fileName) => {
  // const generateExcelBtn = document.querySelector("#generateExcelBtn");
  const formattedData = report.map(item => {
    let formattedItem = []
    formattedItem.push(item.userid)
    return formattedItem;
  });
  // generateExcelBtn.addEventListener("click", event => {
  import("exceljs").then(Excel => {
    console.log(Excel);

    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet("Daily Report");

    const formatDate = date => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };


    const dates = [...Array(7)].map((_, i) => {
      const d = new Date();
      d.setDate(d.getDate() - (i + 1));
      return formatDate(d);
    });

    const header = [];
    header[0] = "userid";
   
    // console.log(header)
    sheet.addRow(header);
   
    console.log(formattedData)
    sheet.addRows(formattedData.map((item, i) => {
      return item
    }))

    // import("file-saver").then(fs => {
    // console.log(fs);
    workbook.xlsx.writeBuffer().then(data => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      // fs.saveAs(blob, "Data.xlsx");
      saveAs(blob, fileName)
    });
  });
  // });
  // });
}

export default handleDownload