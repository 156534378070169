import axios from "axios";
import ApiConfig from '../../../../ApiConfig'
import { getValueFromSession, setValueInSession } from "../../../UtilityFunctions/getSetValueSession";
import { useNavigate } from "react-router-dom";

export const useToEvaluateGrandTotal = () => {

  let Api = new ApiConfig();
  let navigate = useNavigate();


  async function toFinishEvaluate(randomexe, setQuesResp, quesresp, username) {
    let results = JSON.parse(getValueFromSession("results"));
    console.log("results22", results);
    let module_name = sessionStorage.getItem("module_name");
    var evaluationResult = await getGrandTotal(results, module_name, randomexe, setQuesResp, quesresp, username);
    console.log("evaluationResult", evaluationResult);
    // console.log("results", results);

    grand(evaluationResult);
  }
  function getIncorrectAnswersCount(questionsArr, correct) {

    let Attempted = 0
    for (let i = 0; i < questionsArr.length; i++) {
      const element = questionsArr[i];
      // console.log("element",element)
      if (element.value !== undefined) {
        Attempted++
      }
      else if(element.resultarr !== undefined){

        // correct= element.resultarr.reduce((a,b)=>{ return a+b},0)
        Attempted = element.resultarr.length 
        // Attempted = element.resultarr.length +correct
      }

    }

    let incorrect = Math.abs(Attempted - correct)
    // console.log('incorrect $$$$$$$', Attempted, correct, incorrect)
    return incorrect
  }
  function getCustomeMarkingSchemeScore(markingschemeObj, data) {

    let maxscore = data.maxscore || 0
    let score = data.score || 0
    let correct=data.correct || 0
    console.log("outestid",score);
    if (markingschemeObj !== 'pravidefault' && process.env.REACT_APP_ENV_IS_USE_MARKING_SCHEME === 'true') {


      let incorrect =getIncorrectAnswersCount(data.questionjson, data.correct)
      // console.log("data",data)
      // console.log("margchieme scheme",markingschemeObj)
      // score = ((correct * markingschemeObj.correct) - ((data.questions - correct) * markingschemeObj.wrong))
       score=((data.correct * markingschemeObj.correct)- (incorrect* markingschemeObj.wrong))
      maxscore = (data.questions * markingschemeObj.correct)
       console.log("score #######",score, maxscore)
    }
    return { score, maxscore }

  }

  async function getGrandTotal(results, module, randomexe, setQuesResp, quesresp, username) {
    let evalResult = {
      evalName: module,
      correct: 0,
      questions: 0,
      score: 0,
      maxscore: 0,
    };
 
    let markingscheme = getValueFromSession('markingscheme')
    if (markingscheme !== 'pravidefault') {

      let plus = markingscheme.slice(markingscheme.indexOf("+") + 1, markingscheme.indexOf(","))
      let minus = markingscheme.slice(markingscheme.indexOf("-") + 1, markingscheme.indexOf(")"))
      markingscheme = { correct: plus, wrong: minus }
      console.log(plus, minus)
    }


    if (results.length < randomexe.length) {
      let maxscore = calcMaxScoreSum(randomexe);
      let questions = await calQuesSum(randomexe, setQuesResp, quesresp, username);
      console.log("maxscore", maxscore);
      console.log("questions", questions);
      for (var i = 0; i < results.length; i++) {
        evalResult.correct += results[i]?.correct;
        evalResult.score += results[i].score;
      }
      evalResult.maxscore = maxscore;
      evalResult.questions = questions;
    } else {
      for (var i = 0; i < results.length; i++) {
        let { score, maxscore } = getCustomeMarkingSchemeScore(markingscheme, results[i])

        evalResult.correct += results[i]?.correct||0;
        evalResult.questions += results[i].questions || 0;
        evalResult.score += score|| 0;
        evalResult.maxscore += maxscore || 0;
      }
    }
    console.log("evalResult", evalResult);

    return evalResult;
  }

  async function calQuesSum(randomexe, setQuesResp, quesresp, username) {
    let no_of_questions = 0;

    for (let i = 0; i < randomexe.length; i++) {
      await axios
        .get(
          Api.addApiKey(
            `${Api.BaseURI}/questions?exercise=${randomexe[i].exid}`
          )
        )
        .then((response) => {
          if (
            response.status === 406 ||
            response.status === 408 ||
            response.status === 500 ||
            response.status === 503
          ) {
            // setNavigateTo(true);
            navigate("/login", {
              state: { response: quesresp, type: "error" },
            });
            setQuesResp(response);
            // this.setState({ navigateTo: true, quesresp: response })
            axios
              .get(
                Api.addApiKey(`${Api.BaseURI}/postLogout?username=${username}`)
              )
              .then((res) => {
                // console.log(res)
              });
            // sessionStorage.removeItem('username');
            sessionStorage.clear();
            localStorage.clear();
          } else {
            console.log("response.data.questions.length", response);
            no_of_questions += response.data.questions.length;
          }
        });
    }
    // console.log("no_of_questions", no_of_questions)
    // setSumques(no_of_questions)
    return no_of_questions;
  }

  function calcMaxScoreSum(randomexe) {
    function getMaxScore(level) {
      let maxScore = 0.0;
      if (level >= 1 && level <= 3) {
        maxScore = 10.0;
      } else if (level >= 4 && level <= 6) {
        maxScore = 20.0;
      } else if (level >= 7 && level <= 10) {
        maxScore = 30.0;
      } else {
        maxScore = 40.0;
      }
      return maxScore;
    }
    let sum = 0;
    for (let i = 0; i < randomexe.length; i++) {
      sum = sum + getMaxScore(randomexe[i].level);
    }

    // setSummarks(sum);
    return sum;
  }

  function grand(evaluationResult) {
    setValueInSession("evaluationResult", JSON.stringify(evaluationResult));
  }

  return { toFinishEvaluate }
}

