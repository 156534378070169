import React from "react";
import LoaderUtility from "../../../UtilityFunctions/Loader_utility";
// import libraries
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaHome } from "react-icons/fa";
// import styles
import "./testgreeting.scss";

const ScheduleTestGreeting = ({ loading, message, upsloading }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const check = from === "testlibrary";
  const successMessage = check
    ? "The test is scheduled, and participants have been informed."
    : "The schedule has been updated, and participants have been informed.";
  // const errorMessage = message?.includes('Test scheduled successfully') ?  successMessage:'The test is not scheduled.'
  const errorMessage = message?.includes("successfully")
    ? successMessage
    : "The test is not scheduled.";

  const loader = loading ? loading : upsloading;
  const loadingMsg = loading
    ? "The test is being scheduled"
    : "The schedule is being updated";

  return (
    <section className="test_greeting">
      {loader ? (
        <LoaderUtility loading={loader} loadingMsg={loadingMsg} />
      ) : (
        <>
          <p className="test_greeting_msg">{errorMessage}</p>
          <footer className="test_greeting_buttons">
            <button
              onClick={() => {
                navigate("/");
              }}
              className="button primary  btn_greet"
            >
              Home <FaHome size={20} color="#fff" cursor={"pointer"} />
            </button>
          </footer>
        </>
      )}
    </section>
  );
};

export default ScheduleTestGreeting;
