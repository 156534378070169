export const toggleRowSelection = (testid, setSelectedRows, selectedRows, setArchiveIds, archiveIds) => {
  const isSelected = selectedRows.includes(testid);
  if (isSelected) {
    setSelectedRows(selectedRows.filter(id => id !== testid));
  } else {
    setSelectedRows([...selectedRows, testid]);
  }

  console.log("archiveIds", archiveIds)
  const isArchived = archiveIds.includes(testid)
  if (isArchived) {
    setArchiveIds(archiveIds.filter(id => id !== testid));
  } else {
    setArchiveIds([...archiveIds, testid])
  }
};


export const toggleSelectAll = (selectAll, data, setSelectedRows, setSelectAll, setArchiveIds) => {
  if (selectAll) {
    setSelectedRows([]);
    setArchiveIds([]);
  } else {
    const allScheduleIds = data.map(cell => cell.testid);
    const allTestIds = data.map(cell => cell.testid);
    setSelectedRows(allScheduleIds);
    setArchiveIds(allTestIds)
  }
  setSelectAll(!selectAll);
};