import { useEffect, useState } from "react";
import { fetchData } from "../ApiUtils/fetchData";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
// import libraries

const useManageTest = () => {
  const [upcomingTest, setUpcomingTest] = useState([]);
  const [pastTest, setPastTest] = useState([]);
  const [ongoingtest, setOngoingtest] = useState([]);
  const [archivedTests, setArchivedTests] = useState([]);
  const [loading, setLoading] = useState(false);
  const userid = getValueFromSession('username')
  const uri = `getschedule?userid=${userid}`;


  useEffect(() => {
    getSchedule()
  }, []);

  async function getSchedule() {
    setLoading(true);

    try {
      const data = await fetchData(uri);
      console.log('data #####', data)
      if (data.status === 404) {
        setUpcomingTest([])
        setPastTest([])
        setOngoingtest([])
        setArchivedTests([])
      } else {
        setUpcomingTest(data?.upcommingTest);
        setPastTest(data?.pastTest);
        setOngoingtest(data?.availableTest)
        setArchivedTests(data?.archivedTest)
      }
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }

  }
  // console.table(upcomingTest)

  // async function fetchDataAndUpdateCache() {
  //   try {
  //     const data = await fetchData(uri);
  //     cache[uri] = data.data;
  //   } catch (error) {
  //     console.log("error", error.message);
  //   }
  // }

  return {
    pastTest, upcomingTest, ongoingtest, archivedTests,
    loading, setUpcomingTest, setPastTest, setOngoingtest, setArchivedTests, getSchedule
  };
}

export default useManageTest;
