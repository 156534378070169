import { useEffect } from 'react';

export const useIconVisibility = (deleteIconRef, selectedRows, testData) => {
  useEffect(() => {
    if (deleteIconRef.current) {
      deleteIconRef.current.style.visibility =
        selectedRows.length >= 1 ? 'visible' : 'hidden';
    }
  }, [selectedRows, testData]);
};

export const useCheckCheckBoxHeader = (selectedRows, setSelectAll, archiveIds) => {
  useEffect(() => {
    // Check if any checkboxes in the rows are selected
    const anySelected = archiveIds.length > 0 || selectedRows.length > 0;
    // Update the selectAll state accordingly
    setSelectAll(anySelected);
  }, [archiveIds, selectedRows]);
}

export const useSortTableOnce = (handleSort) => {
  useEffect(() => {
    // Sort the table data when the component mounts
    // console.log("sort once")
    handleSort('scheduleid');
  }, []); // Empty dependency array ensures this effect runs only once
}


export const useSearchTableQueries = (searchQueries, testData, setFilteredData) => {
  useEffect(() => {
    const filtered = testData?.filter(row => {
      // Check if row exists and has valid properties before accessing them
      if (row && typeof row === 'object') {
        // console.log("row", row.scheduleid.toString())
        const scheduleId = row.scheduleid ? row.scheduleid.toString() : '';
        const testName = row.name ? row.name.toString().toLowerCase() : '';
        const scheduledBy = row.scheduledby ? row.scheduledby.toString().toLowerCase() : '';
        const showResult = row.showresult ? row.showresult.toString().toLowerCase() : '';
        const markingScheme = row.markingscheme ? row.markingscheme.toString().toLowerCase() : '';
        const status = row?.status ? row.status.toString().toLowerCase() : '';

        return (
          scheduleId.includes(searchQueries.scheduleId) &&
          testName.includes(searchQueries.testName.toLowerCase()) &&
          scheduledBy.includes(searchQueries.scheduledBy.toLowerCase()) &&
          showResult.includes(searchQueries.showResult.toLowerCase()) &&
          markingScheme.includes(searchQueries.markingScheme.toLowerCase()) &&
          status?.includes(searchQueries.status.toLowerCase())
          // Add conditions for other columns
        );
      }
      return false;
    });
    setFilteredData(filtered);
  }, [searchQueries, testData]);
}