import { PulseLoader, ClipLoader, FadeLoader, MoonLoader } from "react-spinners";
const styles = {
  "textAlign": "center",
  "fontSize": "0.875rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",

}
function LoaderUtility({ loading, loadingMsg = 'Loading', showMsgText = true, showLoader = true, loaderColor = "#9c27b0", speedMultiplier = 0.5, size = 3 }) {
  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: ".5rem",
    // borderColor: "olive",
    // backgroundColor: "red",
    // width: "100%",
  };


  return (
    <>
      <p style={styles}>
        {showMsgText && loadingMsg}
        {showLoader &&
          <PulseLoader
            color={loaderColor}
            loading={loading}
            cssOverride={override}
            size={size}
            className="pulse-loader"
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={speedMultiplier}
          />
        }
      </p>
    </>
  );
}

function LoaderUtility1({ loading, size = 20, margintop = '.5rem', color = '#9c27b0' }) {
  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: `${margintop}`,
    // borderColor: "olive",
    // backgroundColor: "red",
    // width: "100%",
  };
  return (
    <>
      <p style={styles}>
        {/* Loading */}
        <PulseLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={size}
          className="pulse-loader"
          aria-label="Loading Spinner"
          data-testid="loader"
          speedMultiplier={0.5}
        />
      </p>
    </>
  );
}

export function ClipLoaders({ loading, size = 20, color = '#9c27b0' }) {
  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: `#fff`,
    backgroundColor: "transparent",
    // width: "100%",
  };
  return (
    <>
      <p style={styles}>
        {/* Loading */}
        <ClipLoader color={color}
          loading={loading}
          cssOverride={override}
          size={size}
          className="pulse-loader"
          aria-label="Loading Spinner"
          data-testid="loader"
          speedMultiplier={0.5}
        />
      </p>
    </>
  );
}

export default LoaderUtility;
export { LoaderUtility1 };
