import { useEffect, useState } from "react";
import { fetchData } from "../../ApiUtils/fetchData";
import { getValueFromSession } from "../../UtilityFunctions/getSetValueSession";


const useManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const groupName = getValueFromSession('group_name')
  const uri = `getassessmentusers?groupName=${groupName}`

  useEffect(() => {
    setLoading(true)
    handleManageUsers()
    setLoading(false)
  }, []);


  const handleManageUsers = async () => {
    const response = await fetchData(uri)
    setUsers(response?.data)
  }

  return { users, loading }
}

export default useManageUsers