import { useRef } from 'react';
const Checkbox = ({ id, type, name,index,value,handleClick, isChecked,disabled1 ,indeterminate  }) => {
const ref = useRef([]);

    return (
      <input
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={handleClick}
        ref={(element) => { 
          ref.current[index] = element
          if(element){
            if(isChecked){

              element.indeterminate=false 
            }else if(indeterminate){

              element.indeterminate=indeterminate
            }
            else{
              element.checked=false
              element.indeterminate=false
            }
          }
         }}
        checked={isChecked}
        disabled={disabled1}
        
        
      />
    );
  };
  
  export default Checkbox;