import React from 'react';
import { FaCircle } from 'react-icons/fa';
import PulseLoader from 'react-spinners/PulseLoader';

const TabsComponent = ({
  currentStepIndex,
  setActiveTab,
  activeTab,
  coins,
  exdatarr, 
  codedisplayac,
  testLoader,
  statusCode,
  output,
  resultarr,
  markComplete,
  isMarkComplete,
  override,
}) => {
  if (currentStepIndex === undefined) {
    currentStepIndex = 0;
  }

  const handleTab1 = () => {
    setActiveTab('tab1');
  };

  const handleTab2 = () => {
    setActiveTab('tab2');
  };

  return (
    <div className="split right">
      <div className="nav-debug-tabs">
        <ul className="nav-debug-list">
          <li className={activeTab === 'tab1' ? 'tab active' : 'tab'} onClick={handleTab1}>
            Description
          </li>
          <li className={activeTab === 'tab2' ? 'tab active' : 'tab'} onClick={handleTab2}>
            Result
          </li>
          {coins.selected === 'Visual Programming' && (
            <li className={activeTab === 'tab3' ? 'tab active' : 'tab'} onClick={() => setActiveTab('tab3')}>
              Code
            </li>
          )}
        </ul>

        {activeTab === 'tab1' ? (
          <TabDescription exdatarr={exdatarr} currentStepIndex={currentStepIndex} />
        ) : activeTab === 'tab3' ? (
          <TabGeneratedCode codedisplayac={codedisplayac} />
        ) : !testLoader && activeTab === 'tab2' ? (
          <TabResult
            statusCode={statusCode}
            output={output}
            resultarr={resultarr}
            testLoader={testLoader}
            isMarkComplete={isMarkComplete}
            markComplete={markComplete}
            override={override}
          />
        ) : (
          <PulseLoader
            color="#7b1fa2"
            loading={testLoader}
            cssOverride={override}
            size={10}
            className="pulse-loader"
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        )}
      </div>
    </div>
  );
};

const TabDescription = ({ exdatarr, currentStepIndex }) => (
  <div className="tab-description">
    <h4>Problem Statement :</h4>
    <div className="problem-description">
      <textarea rows={10} cols={90} readOnly className="debug-textarea">
        {exdatarr[currentStepIndex]?.description}
      </textarea>
    </div>
  </div>
);

const TabGeneratedCode = ({ codedisplayac }) => (
  <div className="tab-description">
    <h4> Generated code </h4>
    <div className="problem-description">
      <pre style={{ display: 'flex', width: '22rem', height: '20rem', background: '' }}>{codedisplayac}</pre>
    </div>
  </div>
);

const TabResult = ({ statusCode, output, resultarr, testLoader, isMarkComplete, markComplete, override }) => (
  <div className="tab-description">
    <div className="problem">
      <h4>{statusCode === 200 || statusCode === 417 ? 'Output' : 'Errors'}:</h4>
      <div>
        <textarea
          className="debug-textarea"
          readOnly
          id="story"
          name="story"
          rows={10}
          cols={100}
          value={statusCode !== 200 ? output : 'Program compiled successfully'}
        ></textarea>
      </div>
      <div className="testcase">
        {(statusCode === 200 || statusCode === 417) && (
          <h4 className="testcase-title">
            Test Run Result:
            <span>
              {resultarr?.reduce((sum, a) => sum + a, 0)} out of {resultarr?.length} test cases passed{' '}
            </span>
          </h4>
        )}
      </div>
    </div>
    <div>
      <h3 className="scroller1" style={{ color: 'red' }}>
        <pre>
          {statusCode === 200 || statusCode === 417 ? (
            <table className="result-table">
              <tr>
                <th>Test</th>
                {resultarr?.map((item, i) => (
                  <td key={i}>{i + 1}</td>
                ))}
              </tr>
              <tr>
                <th>Result</th>
                {resultarr?.map((item, i) => (
                  <td key={i}>
                    {item === 1 ? (
                      <FaCircle style={{ color: 'green', fontSize: '15px' }} />
                    ) : (
                      <FaCircle style={{ color: 'red', fontSize: '15px' }} />
                    )}
                  </td>
                ))}
              </tr>
            </table>
          ) : (
            <h4>No test executed</h4>
          )}
        </pre>
      </h3>
    </div>
  </div>
);

export default TabsComponent;