import React, { useEffect, useState } from 'react'
import HorizontalBarWithLine from './HorizontalBarWithLine'
import sampleImage from './image1.png'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { fetchData } from '../ApiUtils/fetchData'
import { IoIosArrowUp } from "react-icons/io";
import  './Report.scss'
import LoaderUtility from '../UtilityFunctions/Loader_utility'


export default function InsideReport({scheduleid}) {
  const headers=[
    {id:'1',field:'exid', headerName:'Exercise'},
    {id :'2', field :'description', headerName :'Description'},
    {id :'3', field :'level', headerName :'Level' },
    {id :'4', field :'category', headerName :'Category' },
    {id :'5', field :'subcategoryid', headerName :'Subcategory' },
    {id :'6', field :'image', headerName :'Perfomance graph', isSort:false, onClick:showGraph },
    {id :'7', field :'correctmin', headerName :'Correct min ' },
    {id :'8', field :'correctmax', headerName :'Correct max' },
    {id :'9', field :'correctavg', headerName :'Correct avg' },
    
  ]
const [isShowGraph, setIsShowGraph]=useState(false)
const [yData,setYData]=useState([])
const [xData,setXData]=useState([])
const [MinMaxAvg,setMinMaxAvg]=useState({min:0,max:0,avg:0})
const [tableData,setTableData]=useState([])
const [searchParams] = useSearchParams()
const [isLoading, setIsLaoding]=useState(false)
const [selectedExid,setSelectedExid]=useState('')
const [tableDataLoading,setTableDataLoading]=useState(false)
const [exercisedata,setExercisetabledata]=useState([])
const [colors, setColors]=useState([])
const [sorted,setSorted]=useState({sorted:'id',reversed:true})
const navigate = useNavigate()
// let colors=[]
   function showGraph (item){
    const scheduleid= searchParams.get('scheduleid')
  //  console.log('e',item)
    setIsLaoding(true)
  
    setIsShowGraph(!isShowGraph)
    setSelectedExid(item.exid)
    fetchData(`getexidwisescoreforschedule?scheduleid=${scheduleid}&exid=${item.exid}`) .then(result=>{
      setYData(result.data.map((item)=> {return item.userid}))
      setXData(result.data.map((item)=> {return parseInt(item.correct)}))
      
      setMinMaxAvg({min:item.correctmin,max:item.correctmax,avg:item.correctavg})
      // setXData([...xData])
      setIsLaoding(false)
      
    })

    fetchData(`getAveragefortest?scheduleid=${scheduleid}&exerciseid=${item.exid}`).then((result)=>
  {
   setExercisetabledata(result);
  })

  }
 const getReportData=async()=>{
  const scheduleid= searchParams.get('scheduleid')
//  console.log('scheduleid',scheduleid)
  setTableDataLoading(true)
  let data= await fetchData(`getminmaxavgforschedule?scheduleid=${scheduleid}`)
//  console.log('data ###', data)
  setTableData(data.data.map((item)=>{
    // item['image']=<Graph />
    item['image']=<img style={{    width: '3rem',
      height: '1.5rem'}} src={sampleImage} />
    return item

  }))
  setTableDataLoading(false)

 }

 
 function Table2 (){
  const sortData = (column) => {
    setSorted({ sorted: column, reversed: !sorted.reversed });
    const newData = [...tableData];
    newData.sort((a, b) => {
      const lastA = a[column];
      const lastB = b[column];
  
      if (sorted.reversed) {
  
        return lastA?.toString()?.localeCompare(lastB);
      }
    
      return lastB?.toString()?.localeCompare(lastA);
  
      // console.log(emailA)
      // console.log(emailB)
    });
    // setUserResponse(activeusers);
    // console.log(newData,rows)
    // rows=newData
    setTableData(newData)
  
  };
  
  return (
    <div className='table-box1'>

<table>
  <thead>
    <tr
   
    >
      {headers.map((item)=>(

        <th  onClick={()=>{
          if(item.isSort !== undefined && !item.isSort) return
          else sortData(item.field)
         
         }}>{item.headerName}</th>
      ))}
    </tr>
  </thead>
 {tableDataLoading?
 <tbody>
<tr>
<td colSpan={headers.length+1}>

 <LoaderUtility
 loading={tableDataLoading}
 loadingMsg='Loading'
 />
 </td>
 </tr>
 </tbody>
 : <tbody>
   {console.log('firtableData.lengthst',tableData.length)}
    {
      tableData.length > 0 ?
    tableData.map((item=>(
      <>
      <tr>
        {headers.map((header)=>(
          <td 
          onClick={()=>{ 
            if(header.onClick !==undefined){
              
              header.onClick(item)
            }
          }}> {item[header.field]}</td>
    ))}
       
      </tr>
       {(isShowGraph && item.exid === selectedExid ) && <tr  className='expanded-row' style={{overflowY:'auto',height:'15rem',}}>
         <td colSpan='4' style={{width:'100%', height:'40%',backgroundColor:'white'}} rowSpan='1'>
         
       <div>
<IoIosArrowUp onClick={()=>{setIsShowGraph(false)}} />
        <HorizontalBarWithLine
       yData={yData}
       xData={xData}
       MinMaxAvg={MinMaxAvg}
       isLoading={isLoading}
       />
       </div>
      
       </td>
     
       <td colSpan='5'>
       <div style={{position:'sticky'}}>
        {
       <table border="1">
        <thead>
          <tr>
          <th>
            User above 25%
          </th>
          <th>
            User above 50%
          </th>
          <th>
            User above 75%
          </th>
          <th>
            Top Scorer
          </th>
          </tr>
           
        </thead>
        <tbody>
          {exercisedata?.map((item)=>(
            <tr>
              <td>{item.user_above_25}</td>
              <td>{item.user_above_50}</td>
              <td>{item.user_above_75}</td>
              <td>{item.user_has_100}</td>
            </tr>
          ))}
        </tbody>
    
   
      </table>

       }
       </div>
       </td>
      
    
        </tr>}
       </>
    )))
    :
    <td colSpan={headers.length+1}>

        <p className="pyyi_table-zerostate">No data available</p>
      </td>
  
  }
    
  </tbody>
}
</table>
    </div>
  )
}
  useEffect(()=>{
    getReportData()
  },[])
 
  return (
    <>
<div 
style={{
  margin:'2rem',
  position:'relative',
  // display:'grid'
}}
>


<button style={{margin:'1rem'}} onClick={()=> navigate('/manageschedule',{state:{index:2}})} className='button small'>Back</button>
      <section className='table-container'>

    <Table2/>
      </section>
   
         </div>
    
    </>
  )
}



