import React, { useState } from "react";
import axios from "axios";
import ApiConfig from "../../ApiConfig";
import { useNavigate } from "react-router-dom";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { postData } from "../ApiUtils/postData";
import LoaderUtility from "../UtilityFunctions/Loader_utility";

const ResetPassword = () => {
  const [error, setError] = useState("");
  const [errType, setErrType] = useState("error");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const BaseURI = new ApiConfig().getResetPassword();
  // const username = sessionStorage.getItem("username");
  const username = getValueFromSession("username");
  // let history = useHistory();
  const goToPreviousPath = () => {
    navigate(-1)
  }
  async function handleUserPassword(e) {
    e.preventDefault();
    const currentPassword = document.getElementById("current").value;
    const newPassword = document.getElementById("new_password").value;
    const Re_newPassword = document.getElementById("re_new_password").value;
    // console.log("newPassword", !newPassword.replace(/\s/g, '').length > 0, "abscil")
    // console.log("Re_newPassword", Re_newPassword, "abscil")

    let api = new ApiConfig();
    if (
      newPassword === Re_newPassword &&
      newPassword.length >= 8 &&
      !newPassword.includes("#") && !newPassword.includes(' ')
    ) {
      const uri = `resetpassword`;
      const data = { userid: username, currentpassword: currentPassword, newpassword: newPassword }
      console.log("if")
      setError('')
      try {
        setLoader(true)
        const response = await postData(uri, data)
        setLoader(false)
        // const response = await axios.get(
        //   api.addApiKey(
        //     `${BaseURI}?userid=${username}&password=${newPassword}&currentPassword=${currentPassword}&sendmail=true`
        //   )
        // );
        console.log("response", response);
        if (
          response.data.status === 406 ||
          response.data.status === 408 ||
          response.data.status === 500 ||
          response.data.status === 503
        )
          setError(response.data.message);
        else if (response.data.status === 200) {
          setError(response.data.message);
          setErrType('success')
          // navigate("/login", {
          //   state: { response: response.data, type: "success" },
          // });
          // axios
          //   .get(api.addApiKey(`${uri}/postLogout?username=${username}`))
          //   .then((res) => {
          //     console.log(res);
          //   });
          // sessionStorage.removeItem("username");
          // sessionStorage.clear();
          // localStorage.clear();
        }
        // if (response.data.status === 204) navigate('/login', { state: response.data })
      } catch (error) {
        console.log(error);
      }
    } else if (newPassword !== Re_newPassword) {
      setError(
        "Passwords do not match."
      );
    } else if (newPassword.includes("#") || newPassword.includes(' ')) {
      setError(
        "Invalid input provided. Do not use special characters like a '#',white-space in your password."
      );
    } else if (
      !newPassword.replace(/\s/g, "").length <= 7 ||
      !Re_newPassword.replace(/\s/g, "").length <= 7
    )
      setError("Password must be 8 characters or more!");
  }

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleUserPassword(e);
    }
  };
  return (
    <div className='login-page'>
      <div action='' className='login-form'>
        <h3>Reset Password</h3>
        <div className='login-input-div'>
          <div >

            <label>Current password</label>
            <input
              // id='email'
              style={{ width: '100%' }}
              type='password'
              className='input'
              id="current"
              onKeyDown={onKeyDown}
            />
          </div>

          <div >

            <label>New password</label>
            <input
              // id='email'
              style={{ width: '100%' }}

              placeholder='At least 8 characters'
              type='password'
              className='input'
              id="new_password"
              onKeyDown={onKeyDown}
            />
          </div>
          <div >

            <label>Re-enter new password</label>
            <input
              // id='email'
              style={{ width: '100%' }}

              placeholder='At least 8 characters'
              type='password'
              className='input'
              id="re_new_password"
              onKeyDown={onKeyDown}
            />
          </div>
          {error && (
            <div className={`pyui-msg ${errType}`}>
              <label className="pyui-msg-text">{error}</label>
            </div>
          )}
          {loader && (
            <LoaderUtility
              loadingMsg="Resetting"
              loading={loader} />
          )}
        </div>
        <div className="reset-footer">
          <button
            disabled={loader}
            className="link-button small"
            onClick={() => {
              goToPreviousPath();
            }}>
            &lt; Back
          </button>

          <button
            disabled={loader}
            className="button primary small"
            type="submit"
            onClick={(e) => {
              handleUserPassword(e);
            }}>
            Reset
          </button>
        </div>



      </div>
    </div>
  )
}

export default ResetPassword