export default function getJson(questions) {

  // console.log('questions', questions)
  for (let [key, value] of Object.entries(questions)) {
    // console.log(value.key)
    // console.warn("value.options", typeof value.options);

    if (Array.isArray(value.options)) {
      value.options = value.options;
    }
    else if (typeof (value.options) === 'string') {
      // let len = value.options.length;
      let valArray = value.options.split(/\,\s?(?![^\(]*\))/);
      // console.warn("arr", valArray);
      var newOptions = [];

      //var keyVal = "" + (parseInt(key) + 1);
      // var keyVal = "" + (parseInt(key) + 1);
      // console.warn('keyVal', keyVal);
      for (var i = 0; i < valArray.length; i++) {
        var j = i + 1;
        var x = { key: "options" + j, label: "" + valArray[i], name: value.key, value: "" + j }
        newOptions.push(x);
      }
      // console.log('newOptions', newOptions)
      value.options = newOptions;
    }
    else {
      value.options = undefined;
    }
  }
  var newJson = JSON.parse(JSON.stringify(questions));
  // console.log('newJson', newJson);

  return newJson;
};








