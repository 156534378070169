//import react components and files
import React, { useState } from 'react';
import LibraryCards from './LibraryCards/LibraryCards'
import Filters from './Filters/Filters';
import useLibraryCardsData from '../hooks/useLibraryCardsData';
import { filtered } from './LibraryCards/Libraryutility';
import { LoaderUtility1 } from '../UtilityFunctions/Loader_utility';
//import  libraries
//import styles
import './testlibrary.scss';

const TestLibrary = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const [filterOptions, setFilterOptions] = useState({
    selectedLeague: 'all',
    selectedTrack: 'all',
    selectcreate: 'All templates',
    searchQuery: '',
    selectedTemplate: 'all'
  });
  const {
    filteredData,
    uniqueTracks,
    uniqueLeagues,
    loader,
    templateBundleNames
  } = useLibraryCardsData()
  // console.log("filteredData", filteredData)
  const data = filtered(searchQuery, filterOptions, filteredData)

  // console.log("filter data", data)

  const handleFilterChange = (newFilterOptions) => {
    setFilterOptions(newFilterOptions);
  };

  return (
    <main className='testlibrary_main'>
      <Filters
        onFilterChange={handleFilterChange}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        uniqueTracks={uniqueTracks}
        uniqueLeagues={uniqueLeagues}
        templateBundleNames={templateBundleNames}
      />

      <LibraryCards
        data={data}
      />
    </main>
  )
}

export default TestLibrary