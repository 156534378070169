import React from 'react'
import DataTable from '../DataTable/DataTable'
// import styles
import './viewparticipants.scss'
import Table from '../../../UtilityFunctions/Table'
const ViewParticipants = ({ scheduleUsers, setCurrentSection, currentSection, setSelectedUserids, selectedUserids }) => {
  console.log("scheduleUsers", scheduleUsers)
  const headers = [
    { id: "1", field: "userid", headerName: "Participants " },
    
  ];
  const handleRemoveUser=()=>{
    console.log("userids selected",selectedUserids)
  }
  return (
    <section className='schedule_viewparticipants'>
      <DataTable
        data={scheduleUsers}
        buttonText={''}
        title={'Current Participants'}
        setCurrentSection={setCurrentSection}
        currentSection={currentSection}
        isCheckBox={false}
        showRemoveUserBtn={false}
      />
      {/* <div>

       <button style={{
        float:'inline-end'
      }} onClick={handleRemoveUser} className='button secondary'>
        Remove user
        </button>
      </div> */}
      {/* <Table
        rows={scheduleUsers}
        headers={headers}
        // isLoading={isLoading}
        setSelected={setSelectedUserids}
        data={selectedUserids}
        /> */}
    </section>
  )
}

export default ViewParticipants