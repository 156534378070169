// import react component or files
import React from 'react'
import EvalEditor from './Evaluation/EvalEditor'
// import libraries
// import styles

const Editor = () => {
  return (
    <section style={{ margin: '0.5rem 1.25rem' }}>
      <EvalEditor />
    </section>
  )
}

export default Editor