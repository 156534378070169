import React from 'react'
import Navbar from './Components/Navbar/Navbar'
import Footer from './Components/Footer/Footer'
import { AppRoutes } from './Components/Routes/Routes'
// import libraries
//  import styles
import './layout.scss'

const Layout = () => {
  return (
    // <Router>
    <div className="app-container">
      <Navbar />
      <main className="app-content">
        <AppRoutes /> {/* Routes render here */}
      </main>
      <footer className='app-footer'>
        <Footer />
      </footer>
    </div>
    // </Router>
  )
}
export default Layout