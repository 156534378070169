// import react components and files
import React from 'react'
// import styles
import './markingscheme.scss'

const MarkingScheme = ({ selectedValue, setSelectedValue ,markingSchemeArr }) => {

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <section className='schedule_markingscheme'>
      <label htmlFor="markingScheme">Marking Scheme:</label>
      <select id="markingScheme"  style={{padding:'0.5rem'}} value={selectedValue} onChange={handleSelectChange}>
        
        {markingSchemeArr?.map(item=> <option value={item.markingscheme}>{item.markingscheme}</option> )}
        
        {/* <option value="(-1)Negative one">(-1) Negative one</option>
        <option value="(+2)Plus two(-1)Minus one">(+2)Plus two (-1)Minus one</option> */}
        {/* Add more options if needed */}
      </select>
    </section>
  );
}

export default MarkingScheme