import React, { useEffect, useState } from 'react'
import { getValueFromSession } from '../UtilityFunctions/getSetValueSession';
import { fetchData } from '../ApiUtils/fetchData';

const useGetLatest = () => {
  const [latestUpcoming, setLatestUpcoming] = useState([]);
  const [latestCompleted, setLatestCompleted] = useState([]);
  const [loading, setLoading] = useState(false);
  const userid = getValueFromSession('username');
  console.log("userid: " + userid);
  const uri = `getlatestcompleteupcoming?userid=${userid}`

  useEffect(() => {
    getLatest();
  }, []);

  const getLatest = async () => {
    setLoading(true);
    try {
      const data = await fetchData(uri);
      console.log("fetching latest", data)
      setLatestUpcoming(() => {
        return data?.upcommingTest ? data?.upcommingTest : []
      });
      setLatestCompleted(() => {
        return data?.latestCompleted ? data?.latestCompleted : []
      });
    } catch (error) {
      console.log("error fetching latest");
    } finally {
      setLoading(false);
    }
  }

  return { latestUpcoming, latestCompleted, loading };
}

export default useGetLatest;