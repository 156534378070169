import React from "react";
import ConfettiExplosion from "react-confetti-explosion";
import Confetti from "react-confetti";
import { BiPlay, BiCheckDouble } from "react-icons/bi";
import { AiOutlineWarning } from 'react-icons/ai'
import { BiArrowToRight, BiArrowToLeft } from "react-icons/bi";
import { ImZoomIn, ImZoomOut, ImCancelCircle } from "react-icons/im";
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from "react-icons/fa";
import { ReactFlowProvider } from 'reactflow';
import PulseLoader from "react-spinners/PulseLoader";
import Flowchart from "./Flowchart";

const override = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
  // borderColor: "olive",
  // backgroundColor: "red",
  // width: "100%",
};
const RenderVisualsolverEditor = ({
  isModal, setIsMOdal,
  submitting,
  flowchartfull,
  setFlowchartfull,
  isSlide,
  setIsSlide,
  submiited,
  coinMsg,
  isMarkComplete,
  isCompletedExid,
  compileForVisualsolver,
  isLimitExceed,
  Tabs1,
  ShowConfetti,
  Congrats,
  childStateref,
  markComplete, showResult, setPrevIndex, prevIndex, npvalue, statenp, setValue,
  setKey, handlesetAttNtAttp
  
}) => {
  return (
    <>

      <div className="pyui_code-viewer-editor editor">
        <div className={`${flowchartfull ? "full-screen" : "editor-lable"}`}>Code Editor

          {!flowchartfull ?

            <div className="image-action-btn-div">


              <button
                title={isSlide ? "Expand" : "Collapse"}
                className="sliderBtn"
                style={{
                  marginLeft: ".9rem",
                  background: "transparent",
                  backgroundColor: "transparent",
                  border: "0px ",
                }}
                onClick={() => {
                  setIsSlide(!isSlide);
                }}
              >
                {isSlide ? (
                  <BiArrowToRight size={30} />
                ) : (
                  <BiArrowToLeft size={30} />
                )}
              </button>
              <button
                className="sliderBtn"
                title={flowchartfull ? "Close" : "Full screen"}
                style={{
                  marginLeft: "1rem",
                  background: "transparent",
                  backgroundColor: "transparent",
                  border: "0px ",
                }}
                onClick={() => {
                  setFlowchartfull(!flowchartfull);
                }}
              >
                {flowchartfull ? (
                  <ImCancelCircle size={20} />
                ) : (
                  <FaExpandArrowsAlt size={20} />
                )}
              </button>
            </div>
            :
            <div className="full-screen-action-btn-div">
              <button
                className="sliderBtn"
                title={flowchartfull ? "Close" : "Full screen"}
                style={{
                  marginLeft: "1rem",
                  background: "white",
                  // backgroundColor: "white",
                  border: "0px ",
                }}
                onClick={() => {
                  console.log("onclick full size");
                  setFlowchartfull(!flowchartfull);
                }}
              >
                {flowchartfull ? (
                  <ImCancelCircle size={20} />
                ) : (
                  <FaExpandArrowsAlt size={20} />
                )}
              </button>
            </div>

          }
          <div className={`${flowchartfull ? "full-screen-img-div" : "code-editor"}`}>
            {isModal && (
              <div id="simplemodal" className="pyui_modal">
                <div className="pyui_modal-content">

                  <header className="pyui_modal-header">
                    <h4 className="pyui_modal-header-title">
                      <i class="fa-solid fa-circle-info"></i> Your Score
                    </h4>
                    <span
                      className="closebtn"
                      onClick={() => {
                        setIsMOdal(false);
                      }}
                    ></span>
                  </header>

                  {submitting && !submiited && (
                    <div id="modalloader-spinner">
                      <p>
                        Marking as complete
                        <PulseLoader
                          color="black"
                          loading={submitting}
                          cssOverride={override}
                          size={3}
                          className="pulse-loader"
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          speedMultiplier={0.5}
                        />
                      </p>
                    </div>
                  )}
                  {submiited && (
                    <div className="pyui_modal-body">
                      <div className="pyui_modal-body-container">
                        <div className="marked-completed-msg">
                          <i class="fa-solid fa-check"></i>
                          <div>{coinMsg}</div>
                        </div>
                        <div className="modal-footer-msg">
                          <button
                            className="button primary small"
                            onClick={() => {
                              setIsMOdal(false);
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* </div> */}
                </div>
              </div>
            )}

            <section style={{ width: "100%", height: flowchartfull ? "100vh" : '66vh', backgroundColor: '#1a202c', position: 'relative' }}>
              <ReactFlowProvider>
                <Flowchart fitViewCallback={flowchartfull} isslidefullview={isSlide} ref={childStateref} />
              </ReactFlowProvider>
            </section>
          </div>
        </div>

      </div>



      {(isSlide && !flowchartfull) &&
        <div className="questions">
          <div className="btn_msg_container">
            <div className="to-debugger">


              <button
                className={

                  "to-debugger main-link-debug "

                }
                onClick={compileForVisualsolver}

              >
                <BiPlay fontSize={20} />
                Run Tests
              </button>
              {!isCompletedExid &&
                sessionStorage.getItem("status") !== "completed" && (
                  <button
                    type="button"
                    className={
                      isMarkComplete
                        ? "mark-button-debug"
                        : "mark-button-debug disabled"
                    }
                    onClick={() => {
                      // let fromanswers = false;
                      markComplete();

                    }}

                    disabled={!isMarkComplete}
                  >
                    <BiCheckDouble fontSize={20} />
                    Mark Complete
                  </button>

                )}
              {(sessionStorage.getItem("status") === "completed" ||
                isCompletedExid) && (
                  <label className="completed-button disabled" >
                    Completed
                  </label>
                )}
              {/* <button onClick={getchildstate}> inside </button> */}

            </div>

            <div className="run-btn-warning" >

              {isLimitExceed &&
                <><AiOutlineWarning size={42} color="#ffcc00" />
                  <label  >
                    You have reached daily run test limit. Please try again tomorrow.
                  </label>
                </>
              }
            </div>
            {/* {isCompletedExid && <label className='completed-button disabled' id="lable-1">Completed</label>} */}
          </div>

          <div className="tab-debug">{Tabs1()}</div>
          {/* <div className="question-form"> */}
          {/* <div className="form-group"> */}

          {/* <p> {exdatarr[currentStepIndex].description}</p>  */}
          {/* <p>{output}</p> */}

          {/* </div> */}
          {ShowConfetti && (
            <>
              {coinMsg.includes("coins") &&
                coinMsg.includes("badge points") && (
                  <div>
                    <Confetti
                      width={window.innerWidth}
                      height={window.innerHeight}
                      numberOfPieces={500}
                    />
                  </div>
                )}
              <div className="celebDiv">
                <div className="message-container">
                  {coinMsg.includes("coins") &&
                    coinMsg.includes("badge points") && (
                      <ConfettiExplosion zIndex={12} />
                    )}
                  <Congrats msg={coinMsg} />
                </div>
              </div>
            </>
          )}

          {/* <QuesForm status={status} data={exdatarr[currentStepIndex]} /> */}
        </div>
      }



    </>
  );
}

export default RenderVisualsolverEditor;