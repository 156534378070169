import axios from 'axios';
import CryptoJS from 'crypto-js';
import ApiConfig from '../ApiConfig';
let Api = new ApiConfig()

// const api = axios.create({
//   baseURL: Api.BaseURI,
// });

const secretKey = 'don';
axios.interceptors.response.use(
  (response) => {
    
    // console.log("indfdfee", response.data)
    if (response.data.ip !== undefined) {
      return response
    }
    if (process.env.REACT_APP_SHOW_ISENCRYPT === "true") {
      let originalQueryString = decodeURIComponent(response.data);
      const decryptedData = CryptoJS.AES.decrypt((originalQueryString), secretKey).toString(CryptoJS.enc.Utf8);
      const decryptedJSON = JSON.parse(decryptedData);
      response.data = decryptedJSON;
      response.data = JSON.parse(response.data)

      // console.log("resdfer", response.data)
      // console.log("response", response)
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
    // return error
  }
);

export default axios;

export{secretKey}