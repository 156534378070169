export function getLangTitleAndShortName(lang) {
  let tracks = sessionStorage.getItem('tracks')?JSON.parse(sessionStorage.getItem('tracks')) : []
  console.log("track%%", tracks)
  console.log("lang", lang)
  let title = ''
  let shortname = ''
  if (tracks) {

    for (var i = 0; i < tracks.length; i++) {
      if (tracks[i].track === lang) {
        title = tracks[i].title;
        shortname = tracks[i].shortname
      }
    }
  }
  return { title: title, shortname: shortname }
}