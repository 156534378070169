// import react comp and files
import React, { useState } from 'react'
import TableData from '../TableData/TableData';
import LoaderUtility from '../../UtilityFunctions/Loader_utility';
import useManageTest from '../../hooks/useManageTest';
import useArchiveSchedule from '../../hooks/useMarkArchive'
// import usePagination from '../TableData/Pagination/usePagination';
import Drawer from '../../CustomDrawer/Drawer';
import Archived from '../TableData/Archived/Archived';
import { toggleSelectAll } from '../TableData/tableDataUtils';
// import styles
import './pasttest.scss'
import { useCheckCheckBoxHeader } from '../TableData/useTableUtils';

const PastTest = () => {
  const { pastTest, loading, setPastTest, archivedTests, getSchedule } = useManageTest()
  const { archiveSchedule, arLoading } = useArchiveSchedule()
  const pageSize = 6; //rows per page
  // const {
  //   currentData,
  //   currentPage,
  //   pageCount,
  //   nextPage,
  //   prevPage,
  //   goToPage,
  //   goToFirstPage,
  //   goToLastPage,
  // } = usePagination(pastTest, pageSize);
  // const [testData, setTestData] = useState(pastTest);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');
  const [archiveIds, setArchiveIds] = useState([]);

  //check the checkbox in the header even if one is selccted from the row
  useCheckCheckBoxHeader(selectedRows, setSelectAll, archiveIds)

  const archiveUnarchive = async (rowsIds, isArchived) => {
    await archiveSchedule(rowsIds, isArchived)
    // update the main table to remove the archived rows
  }

  const handleDrawer = (drawerType) => {
    setDrawerType(drawerType)
    setDrawerOpen(prev => !prev)
  }

  const handleFetchAgain = async () => {
    await getSchedule()
  }

  const handleFilterArchiveRows = (rowsIds, alldata, setFilter) => {
    const updatedData = alldata.filter(row => {
      return !rowsIds.includes(row.scheduleid);
    });
    setFilter(updatedData);
    setSelectedRows([])
    setArchiveIds([])
  }

  const handleToggleSelectAll = (rows) => {
    toggleSelectAll(selectAll, rows, setSelectedRows, setSelectAll, setArchiveIds)
  }

  return (
    <section>
      <section className='pasttest_main'>
        {loading ? (
          <LoaderUtility loading={loading} />
        ) : (
          <TableData
            selectAll={selectAll}
            testData={pastTest}
            setTestData={setPastTest}
            // testDataHeaders={pastTestHeaders}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            setSelectAll={setSelectAll}
            loading={loading}
            title={"PastTest"}
            archiveSchedule={archiveSchedule}
            archivedTests={archivedTests}
            arLoading={arLoading}
            fetchApiAgain={getSchedule}
            archiveUnarchive={archiveUnarchive}
            handleDrawer={handleDrawer}
            handleFilterArchiveRows={handleFilterArchiveRows}
            handleToggleSelectAll={handleToggleSelectAll}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            drawerType={drawerType}
            setDrawerType={setDrawerType}
            setArchiveIds={setArchiveIds}
            archiveIds={archiveIds}
            isCheckBox={true}
          />
        )}
      </section>
      {(drawerOpen && drawerType === 'archive') && (
        <Drawer
          isOpen={drawerOpen}
          onClose={handleDrawer}>
          <Archived archivedTests={archivedTests}
            size={20} cursor={"pointer"} title={"PastTest"}
            archiveUnarchive={archiveUnarchive}
            handleFilterArchiveRows={handleFilterArchiveRows}
            handleToggleSelectAll={handleToggleSelectAll}
            selectAll={selectAll} selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setArchiveIds={setArchiveIds}
            archiveIds={archiveIds}
            handleFetchAgain={handleFetchAgain}
          />
        </Drawer>
      )}
    </section>
  );
}

export default PastTest