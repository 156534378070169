import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import './customnode.css';
import { BsDiamondFill } from 'react-icons/bs'
import { FiCircle } from 'react-icons/fi'
const Ifnode = ({ data, id }) => {


  const { width, height, color, borderColor, shape, label } = data;
  let defaultcolor='#fbd086'


  const btnStyle = {
    position: 'absolute',
    right: '-14px',
    top: '0px',
    width: "14px",
    height: "14px",
    backgroundColor: 'white',
    border: '1px solid gray',
    fontSize: '12px',
    display: 'flex',
    margin: '0rem 3rem 3rem 0rem ',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }
  const labelStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    color: 'black',
    fontSize: '12px',
  };

  return (
    <div className={`${shape}`}>
      <BsDiamondFill size={80} style={{ fill: color?color:defaultcolor }} />
      <Handle type="source" id='true' position="left" style={{ background: '#555' }} />
      <Handle type="source" id='false' position="right" style={{ background: '#555' }} />
      <Handle type="target" id='b' position="top" style={{ background: '#555' }} />
      <div style={labelStyles}>
        {label}
      </div>
      <button

        title="Delete Node" style={btnStyle}>
        X
      </button>
    </div>
  );
};

export default Ifnode;