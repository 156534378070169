import React, { useEffect } from 'react'
import ApexCharts from 'react-apexcharts'
import LoaderUtility from '../UtilityFunctions/Loader_utility'
export default function ColumnGraph({xData,yData,isLoading,colors}) {
  useEffect(()=>{
console.log('x data',xData,yData,colors)
  },[yData,xData,,colors,isLoading])
    // let colors =['red','green','yellow']
let series= [{
  data: yData.map(item=>item.correct),
  yData
}]
let options= {
  chart: {
    height: 350,
    type: 'bar',
    events: {
      click: function(chart, w, e) {
        // console.log(chart, w, e)
      }
    }
  },
  
tooltip: {
  custom: function({series, seriesIndex, dataPointIndex, w, yData}) {
    return `<div class="arrow_box">
      <span> ${series[seriesIndex][dataPointIndex]} out of ${w.config.chart?.yData[dataPointIndex].questions} question are correct  </span> 
      </div>`
  }
},
 
  plotOptions: {
    bar: {
      columnWidth: '45%',
      distributed: true,
    }
  },
  noData: {
    text: 'No data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    
  },
  chart: {
    yData,
    toolbar: {
      show: false
    },
  },

  colors: colors,
  dataLabels: {
    enabled: false
  },
  legend: {
    show: true
  },
  xaxis: {
    categories: xData,
    labels: {
      style: {
        colors: colors,
        fontSize: '12px'
      }
    }
  }
}
    
    
    
    
  return (
    isLoading?
      <LoaderUtility
      loading={isLoading}
      loadingMsg='Loading'
      />
      :
    <ApexCharts options={options} series={series} type="bar" height={350} width='100%' />
  )
}



