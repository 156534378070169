import { useRef, useState, useEffect } from "react";
import { dateTimeHelper } from "../../UtilityFunctions/dateHelper";
import { toggleRowSelection } from "./tableDataUtils";
import { pinRow } from "./tableDataUtils";
// import ExpandableRow from "./ExpandableRow/ExpandableRow";
// import useUpdateScheduleTest from "../../hooks/useUpdateScheduleTest";
import { ClipLoaders } from "../../UtilityFunctions/Loader_utility";
// import libraries
import { Link } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdPushPin } from "react-icons/md";
import { MdOutlineDataset } from "react-icons/md";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import { BsSkipStartCircle } from "react-icons/bs";
import { VscDebugStart } from "react-icons/vsc";
import { MdOutlineCancel } from "react-icons/md";

import "./tabledata.scss";
import MoreOption from "./MoreOption";
import PDFPreview from "../../PdfGenerator/Questionpaper";
// import PDFPreview from "../../PdfGenerator/Questionpaper";
// import PDFPreview from "../../PdfGenerator/pdfgenerator";

const TableRow = ({
  data,
  selectedRows,
  setSelectedRows,
  size,
  cursor,
  handleDelete,
  title,
  startTest,
  cancelTest,
  started,
  setStarted,
  cellId,
  handleRowExpand,
  cell,
  index,
  pinnedRows,
  setPinnedRows,
  setTestData,
  handleTestName,
  isOnlyOneSelect,
  handleDrawer,
  handleMarkTestComplete,
  showConfirmDialogBox,
  upsloading,
  handleSetScheduleId,
  setArchiveIds,
  archiveIds,
  handleTesttitle,
  getUserdetails,
  isCheckBox,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [cancel, setCanceled] = useState([]);
  const [loading, setLoading] = useState({ condition: false, for: null });
  const handleToggle = () => setIsExpanded((old) => !old);

  const handlePinnedRow = (rowIndex) => {
    console.log("handlePinnedRow", rowIndex);
    pinRow(rowIndex, setPinnedRows, pinnedRows, data, setTestData);
  };
  function cancelbutton(cell) {
    const iscancel = cancel.includes(cell.scheduleid);
    const isstarted = started.includes(cell.scheduleid);

    return (
      <button
        title={iscancel ? "Canceled" : "Cancel"}
        style={{ backgroundColor: "primary" }}
        className={`icon-button cancle small data_actions ${
          iscancel || isstarted ? "disabled" : ""
        }`}
        // style={{justifyContent:'center',alignItems:'center'}}
        onClick={() => {
          setLoading({ condition: true, for: "cancel" });
          if (!iscancel) {
            cancelTest(cell.scheduleid, "canceled").then((res) => {
              setCanceled([...cancel, cell.scheduleid]);
              setLoading({ condition: false, for: "cancel" });
            });
          }
        }}
        disabled={iscancel || isstarted}
      >
        {console.log("loagin", loading)}
        {loading.condition && loading.for == "cancel" ? (
          <div className="spinner"></div>
        ) : (
          <MdOutlineCancel size={21} />
        )}
      </button>
    );
  }

  function Editbutton(cell) {
    const iscancel = cancel.includes(cell.scheduleid);
    const isstarted = started.includes(cell.scheduleid);

    return (
      <Link
        title="Edit"
        className={`icon-button  small data_actions ${
          iscancel || isstarted ? "disabled" : ""
        }`}
        onClick={() => sessionStorage.setItem("puptstcheck", 1)}
        state={{
          currentTestData: cell,
          fromUpcomingTest: true,
        }}
        disabled={iscancel || isstarted}
        to={`/scheduletest/${cell.scheduleid}?from=upcomingtest`}
      >
        <FaRegEdit size={20} />
      </Link>
    );
  }

  const row = (
    <>
      {/* {data?.map((cell, i) => ( */}
      <tr key={index}>
        {isCheckBox && (
          <td>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                checked={
                  selectedRows?.includes(cell.scheduleid) ||
                  archiveIds?.includes(cell.scheduleid)
                }
                onChange={() =>
                  toggleRowSelection(
                    cell.scheduleid,
                    setSelectedRows,
                    selectedRows,
                    setArchiveIds,
                    archiveIds
                  )
                }
              />
              {/* <MdPushPin size={size - 5}
              onClick={() => handlePinnedRow(index)} /> */}
            </span>
          </td>
        )}
        <td>{cell.scheduleid}</td>
        {/* <td>{cell.testid}</td> */}
        <td>{cell.name}</td>
        <td>{cell.title}</td>
        <td>{cell.scheduledby}</td>
        <td>{dateTimeHelper(cell.localscheduletime, true, true)}</td>
        <td>{dateTimeHelper(cell.localvalidtill, true, true)}</td>
        <td>{cell.markingscheme}</td>
        <td>{cell.showresult + ""}</td>
        {title === "PastTest" && (
          <td className={`${cell.status}`}>{cell.status}</td>
        )}

        <td>
          <div
            className={`display_data_actions ${
              title === "UpcomingTest" ? "edit_visible" : "not_visible"
            }`}
          >
            {title === "UpcomingTest" && (
              <>
                {/* start button */}
                {!started.includes(cell.scheduleid) && (
                  <button
                    title="Start"
                    className={`icon-button start small data_actions ${
                      cancel.includes(cell.scheduleid) && "disabled"
                    } ;
                    `}
                    onClick={() => {
                      setLoading({ condition: true, for: "start" });
                      startTest(cell.scheduleid, "started").then((res) => {
                        setStarted([...started, cell.scheduleid]);
                        setLoading({ condition: false, for: "start" });
                      });
                    }}
                  >
                    {loading.condition && loading.for == "start" ? (
                      <div className="spinner"></div>
                    ) : (
                      <VscDebugStart size={20} />
                    )}
                    {/* Start */}
                  </button>
                )}
                {started.includes(cell.scheduleid) && (
                  <button
                    title="Started"
                    className="icon-button small data_actions disabled"
                  >
                    <VscDebugStart size={20} />
                  </button>
                )}
                {cancelbutton(cell)}
                {/* Edit button */}

                {Editbutton(cell)}
              </>
            )}

            <MoreOption
              cell={cell?.scheduleid}
              getUserdetails={getUserdetails}
            />

            {/* result button */}
            {(title === "PastTest" || title === "Ongoing Test") && (
              <button
                id="result"
                onClick={() => {
                  // handleDelete(cell.scheduleid)
                  handleRowExpand(cell?.scheduleid);
                  handleTestName(cell?.name);
                  handleDrawer("expandablerow");
                  handleToggle();
                  handleTesttitle(cell?.title);
                }}
                className="icon-button small data_actions"
              >
                <MdOutlineDataset size={size} cursor={cursor} />
              </button>
            )}

            {process.env.REACT_APP_SHOW_QUESTION_PEPER === "true" &&
              title == "PastTest" && <PDFPreview data={cell} />}
            {/* mark complete button */}
            {title === "Ongoing Test" && (
              <button
                onClick={() => {
                  handleSetScheduleId(cell?.scheduleid);
                  // handleMarkTestComplete("completed", cell?.scheduleid);
                  showConfirmDialogBox()
                }}
                id="complete"
                className="icon-button small complete data_actions"
              >
                {upsloading && cellId === cell?.scheduleid ? (
                  <>
                    <ClipLoaders loading={upsloading} />
                  </>
                ) : (
                  <>
                    <IoCheckmarkDoneSharp size={size} cursor={cursor} />
                  </>
                )}
              </button>
            )}
          </div>
        </td>
      </tr>
      {/* ))} */}
    </>
  );

  // if (isExpanded) {
  //   return (
  //     <>
  //       {row}
  //       <tr className='expanded-row'>
  //         <td colSpan='9'>
  //           <ExpandableRow cellId={cellId} testName={testName} />
  //         </td>
  //       </tr>
  //     </>
  //   );
  // }

  return row;
};

export default TableRow;
