import React, { useState } from 'react'
import SelectUserids from './SelectUserids/SelectUserids'
import Tabs from '../../CustomTabs/Tabs'
import ViewParticipants from './ViewParticipants/ViewParticipants'
import ScheduleTestGreeting from './ScheduleTestGreeting/ScheduleTestGreeting'
// import libraries
import { useSearchParams } from 'react-router-dom'

const SectionTwo = ({ setCurrentSection, currentSection, filename, FileTypeError, handleFileUpload, ScheduleTheTest, data, schloading, updateScheduleTest, scheduleUsers, setShowMsg, showMsg , message, upsloading , selectedUserids, setSelectedUserids, setUseridArr2, isLoading, setScheduleUsers,setRemoveUseridArr,useridArr2}) => {


  const selectuseridprops = {scheduleUsers, setCurrentSection, currentSection, filename, FileTypeError, handleFileUpload, ScheduleTheTest, data, updateScheduleTest, setShowMsg, showMsg,selectedUserids, setSelectedUserids,setUseridArr2 , isLoading, setScheduleUsers,setRemoveUseridArr, useridArr2}
  const viewparticipantsprops = { scheduleUsers, setCurrentSection, currentSection, selectedUserids,  setSelectedUserids,setUseridArr2}
  const myTabs = [
    {
      name: 'View current participants', component: <ViewParticipants {...viewparticipantsprops} />, className: 'small'
    },
    {
      name: 'Select participants', component: <SelectUserids {...selectuseridprops} />, className: 'small'
    }
  ]

  const [activeTab, setActiveTab] = useState(myTabs[0].name);
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
 

  const toSetGreetingsModal = () => {
    if (from === 'testlibrary' || from === 'upcomingtest') {
      if (showMsg) {
        return <ScheduleTestGreeting loading={schloading}
          message={message} upsloading={upsloading} />
      }
    }
    // if (from === 'upcomingtest') {
    //   return (
    //     <section>
    //       <Tabs
    //         tabs={myTabs}
    //         activeTab={activeTab}
    //         handleTabChange={setActiveTab}
    //       />
         
    //     </section>
    //   )
    // } else {
      return <SelectUserids {...selectuseridprops} />
    // }
  }


  return (
    <>
      {toSetGreetingsModal()}
    </>
  )
}

export default SectionTwo