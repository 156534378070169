import axios from "axios";
import ApiConfig from '../../ApiConfig'

export const postData = async (apiUrl, postData) => {
  const Api = new ApiConfig();

  try {
    const data = await axios.post(Api.addApiKey(`${Api.BaseURI}/${apiUrl}`), postData);
    return data;
  } catch (error) {
    console.log("error", error.message)
    // throw new Error(`Error fetching data from ${apiUrl}: ${error.message}`);
  }
};