import { useRef ,useEffect} from "react";
const OutsideClickHandler = ({ children, onOutsideClick }) => {
    const ref = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        console.log("even",event)
        if (ref.current && !ref.current.contains(event.target)) {
            event.stopPropagation(); // Stop event propagation

          onOutsideClick();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onOutsideClick]);
  
    return <div ref={ref}>{children}</div>; // Here, `children` is rendered inside the `div`
  };

  export default OutsideClickHandler;