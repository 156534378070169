import React, { useRef, useState } from "react";
import Drawer from "../../CustomDrawer/Drawer";
import Table from "../../UtilityFunctions/Table";
import { BiReset } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import LoaderUtility from "../../UtilityFunctions/Loader_utility";
import { postData } from "../../ApiUtils/postData";
import { getValueFromSession } from "../../UtilityFunctions/getSetValueSession";
import AddUserSchedule from "../../Event/AddUserToSchedule";


export default function ViewUserDetails({
  userDetails,
  isShowDetails,
  setIsShowDetails,
  headers,
  isLoading,
  title,
  getUserdetails,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [resetLoading, setResetLoading] = useState(false);
  const [scheduleId, setScheduleId] = useState(null)
  let showReset =
    title === "Ongoing Test" && process.env.REACT_APP_USE_RESET_TEST === "true";
  let showAddUsersToSchedule= title === 'manageevent'
    const toastRef = useRef()
  const resetUserTest = async () => {
    if(selectedRows.length <=0){
      
      if (!toast.isActive(toastRef.current)) {
        toastRef.current = toast.error("Please select user", {
          position: "top-center",
        })
      }
      return
    }
    setResetLoading(true);
    let data = {
      useridArr: selectedRows.map((item) => item.userid),
      scheduleid: getValueFromSession("selectedscheduleId"),
      adminuserid:getValueFromSession('username')
    };
    console.log(data);
    let result = await postData("ressetusertest", data);
    console.log(result.data);
    getUserdetails(getValueFromSession("selectedscheduleId"));
    setResetLoading(false);
    setSelectedRows([])
  };

  return (
    <>
      {/* <button className='button secondary' onClick={getUserdetails}> Veiw details </button>  */}

      {isShowDetails && (
        <Drawer
          isOpen={isShowDetails}
          onClose={() => {
            setIsShowDetails(false);
            setSelectedRows([])
          }}
        >
            {/* <ToastContainer /> */}
          <div style={{
            display:'flex',
            flexDirection:'column'
          }}>
          {showReset && (
            <div>

            <button
              style={{
                float: "right",
                margin: "0.0rem .6rem .5rem 0",
              }}
              title="Select and reset user test"
              className="button primary small"
              onClick={resetUserTest}
              >
              <BiReset size={18} />
            </button>
              </div>
          )}
          {showAddUsersToSchedule && (
            <div>
                  
                <AddUserSchedule               
                setScheduleId={setScheduleId}
                selectedUsers={selectedRows}
                setSelectedUsers={setSelectedRows}
                userDetails={userDetails}
                />
              </div>
          )}
          {/* <div
    style={{
      backgroundColor:'white',
      width:'100%',
      height:'50%'
    }}
    > */}
          {isLoading ? (
            <LoaderUtility loading={isLoading} loadingMsg="Loading" />
          ) : (
           < >
           <div
            className='title-header-container'
           >
    
              
           <p
           className='title-header'
           > {selectedRows.length} row(s) selected of {userDetails.length} row(s)</p>
            </div>
            <section className="table-container">


          <div  style={{maxHeight:'25rem'}}>
            <Table
              rows={userDetails}
              headers={headers}
              data={selectedRows}
              setSelected={setSelectedRows}
              isCheckBox={showReset || showAddUsersToSchedule}
              noDataMsg={"No data available"}
              />
              </div>
              </section>
           </>
          )}
    </div>
          {/* </div> */}
        </Drawer>
      )}
    </>
  );
}



