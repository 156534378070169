// import react comp and files
import React, { useState } from 'react';
import UpcomingTest from './UpcomingTest/UpcomingTest';
import PastTest from './PastTest/PastTest';
import OngoingTest from './OngoingTest/OngoingTest'
import Tabs from '../CustomTabs/Tabs'
// import libraries
// import styles
import './manageschedule.scss'
import { useLocation } from 'react-router';

const ManageSchedule = () => {
  const myTabs = [
    { name: 'Upcoming Tests', component: <UpcomingTest /> },
    { name: 'Ongoing Tests', component: <OngoingTest /> },
    { name: 'Past Tests', component: <PastTest /> },
  ]

  const {state} = useLocation()
  const [activeTab, setActiveTab] = useState(state?.index?myTabs[state?.index].name : myTabs[0].name);

  return (
    // <ManageTestProvider> {/* Wrap Outlet with Provider */}
    <Tabs
      tabs={myTabs}
      activeTab={activeTab}
      handleTabChange={setActiveTab}
    />
    // </ManageTestProvider>
  );
};

export default ManageSchedule;
