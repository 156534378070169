import React, { useState } from 'react';
// import styles
import './tabs.scss'

const Tab = ({ name, component, isActive, handleTabChange, className }) => {
  const handleClick = () => handleTabChange(name);

  return (
    <li
      key={name}
      className={`tab ${isActive ? 'active' : ''} ${className || ''}`}
      onClick={handleClick}
    >
      {name}
    </li>
  );
};

const Tabs = ({ tabs, activeTab, handleTabChange }) => (
  <div className="tabs-container">
    <ul className="tabs">
      {tabs.map((tab) => (
        <Tab
          key={tab.name}
          name={tab.name}
          component={tab.component}
          isActive={activeTab === tab.name}
          handleTabChange={handleTabChange}
          className={tab.className}
        />
      ))}
    </ul>
    <TabsPane currentTab={activeTab} tabs={tabs}>
      {/* Content of active tab will be rendered here */}
    </TabsPane>
  </div>
);

const TabsPane = ({ currentTab, tabs }) => {
  const activeTabData = tabs.find((tab) => tab.name === currentTab);

  return <>{activeTabData && activeTabData.component}</>; // Render active component
};

export default Tabs;
