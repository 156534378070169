// import react components or files
import React from "react";
import { allDashBoardData } from "../../redux/DashboardSlice/dashBoardDataSlice";
// import libraries
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import styles
import "./cards.scss";

const Cards = () => {
  const cardsData = useSelector(allDashBoardData);

  const renderedCard = cardsData.map((card, i) => (
    card.isShow&&<div key={i} className="dashboard_card">
      <div className="dashboard_card_icon">{card.icon}</div>
      <header className="dashboard_card_title">{card.title}</header>
      <div className="dashboard_card_button">
        <Link to={`${card.link}`} className="button primary">
          {card.button_title}
        </Link>
      </div>
    </div>
  ));

  return (
    <section className="dashboard_cards_main">
      <div className="dashboard_cards_main_grid">{renderedCard}</div>
    </section>
  );
};

export default Cards;
