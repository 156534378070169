import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchData } from "../ApiUtils/fetchData";
import { useNavigate } from "react-router-dom";
import "./Report.scss";

export default function PercentageMatching() {
  const [searchParams] = useSearchParams();
  const [tableData,setTableData]=useState([])
  const [exercisetabledata,setExercisetabledata]=useState([])

  let navigate=useNavigate()
  const getReportData = async () => {
    const scheduleid = searchParams.get("scheduleid");
    let data = await fetchData(
      `getpercentagematch?scheduleid=${scheduleid}`
    );
    setTableData(data)
    console.log("data$$$",data)
  };

  const getExercisedata = async () => {
    const scheduleid = searchParams.get("scheduleid");
    let data = await fetchData(
      `getschedulesummary?scheduleid=${scheduleid}`
    );
    setExercisetabledata([data])
    console.log("datasetExercisetabledatadata",data)
  };

  useEffect(() => {
    getReportData();
    getExercisedata();
  }, []);

  return (
    <>
      <div
        style={{
          margin: "2rem",
          position: "relative",
        }}
      >
        <button
          style={{ margin: "1rem" }}
          onClick={() => navigate("/manageschedule")}
          className="button small"
        >
          Back
        </button>
        <div style={{display:'flex',flexDirection:'column',gap:'2rem'}}>
        <section className="expandablerow_table">
        <table >
            <thead>
         <tr>
            <th>User id 1</th>
            <th>User id 2</th>
            <th>Percentage of answers matching</th>
         </tr>
         </thead>
          <tbody >
            {tableData?.length>0 ?tableData?.map((item)=>(
             <tr>
                <td>{item.userid1}</td>
                <td>{item.userid2}</td>
                <td>{item.percentage_matching}</td>

             </tr>   
            )): 
            <td colSpan="10">
                <p className="pyyi_table-zerostate">
                   
            No data available
            </p>
            </td>
       }
          </tbody>
        </table>
        </section>
        <section className="expandablerow_table">
        <table >
            <thead>
         <tr>
            <th>Total user</th>
            <th>User above 25%</th>
            <th>User above 50%</th>
            <th>User above 75%</th>
            <th>User above average</th>
            <th>Top scorer</th>
         </tr>
         </thead>
          <tbody >
            {exercisetabledata?.length>0 ?exercisetabledata?.map((item)=>(
             <tr>
                <td>{item.total_sum}</td>
                <td>{item.user_above_25}</td>
                <td>{item.user_above_50}</td>
                <td>{item.user_above_75}</td>
                <td>{item.user_above_average}</td>
                <td>{item.user_has_100}</td>

             </tr>   
            )): 
            <td colSpan="10">
                <p className="pyyi_table-zerostate">
                   
            No data available
            </p>
            </td>
       }
          </tbody>
        </table>
        </section>
        </div>
      </div>
    </>
  );
}
