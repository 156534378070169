import React, { useRef, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import "react-toastify/dist/ReactToastify.css";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import Table from "../UtilityFunctions/Table";
import useManageTest from "../hooks/useManageTest";
import LoaderUtility from "../UtilityFunctions/Loader_utility";
import { ToastContainer, toast } from "react-toastify";
import { Tooltip } from 'react-tooltip';
import { postData } from "../ApiUtils/postData";
import {SiMicrosoftexcel} from 'react-icons/si'
import './AddUserToSchedule.scss'
import handleDownload from "../UtilityFunctions/DownloadCSV";
export default function AddUserSchedule({
  setScheduleId,
  selectedUsers,
  setSelectedUsers,
  userDetails
}) {
  const [isshowModal, setIsshowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toastRef = useRef();
  const { upcomingTest, setUpcomingTest, loading } = useManageTest();
  console.log(selectedRows);
  const headers = [
    { id: "0", field: "scheduleid", headerName: "Schedule Id" },
    { id: "1", field: "name", headerName: "Test name" },
    { id: "2", field: "title", headerName: "Title" },
    // {id:'3',field:'description', headerName:'Description'}
  ];
  const addUsersToSchedule = async (schedule) => {
    if (selectedUsers.length <= 0) {
      if (!toast.isActive(toastRef.current)) {
      toastRef.current = toast.error("Please select user", {
        position: "top-center",
      });
      }
      // setIsshowModal(false)
      return;
    } else if (selectedRows.length <= 0) {
      if (!toast.isActive(toastRef.current)) {
      toastRef.current = toast.error("Please select schedule from table", {
        position: "top-center",
      });
      }

      return;
    }

    let data = {
      useridArr: selectedUsers.map((item) => item.userid),
      scheduleid: schedule.scheduleid,
      scheduletime: schedule.scheduletime,
    };
    console.log(data);
    setIsLoading(true);
    let result = await postData("addscheduleuserid", data);
    if (result.data.status === 200) {
      toast.success("Users added to schedule", {
        position: "top-center",
      });
      setTimeout(()=>{

        setIsshowModal(false)
      },3200)
      setSelectedUsers([]);
    } else {
      toast.error(result.data.message, {
        position: "top-center",
      });
    }
    setIsLoading(false);
  };
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {isshowModal ? (
        <Dialog
          open={isshowModal}
          maxWidth={"lg"}
          PaperProps={{
            style: {
              borderRadius: 14,
              minHeight: "30vh",
              maxHeight: "fit-content",
              minWidth: "30%",
              width: "60%",
              height:'60%',
              overflow: "hidden",
              position: "absolute",
              // zIndex: 10000,
            },
          }}
        >
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
          />
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "6px",
            }}
          >
            <span style={{ marginLeft: "1.5rem" }}>Add Users </span>
            <IconButton>
              <CloseIcon
                onClick={() => {
                  setIsshowModal(false);
                }}
              />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ overflow: "hidden" }}>
            {isLoading && (
              <LoaderUtility loading={isLoading} loadingMsg="Adding" />
            )}
            {upcomingTest.length>0? <div className=" table-container">
              <Table
                rows={upcomingTest}
                headers={headers}
                data={selectedRows}
                setSelected={setSelectedRows}
                isOnlyOneSelect={true}
              />
            </div>:
            <h3>No upcoming schedule</h3>
            
          }
          </DialogContent>

          <DialogActions sx={{ marginRight: "1.3rem" }}>
            <button
              style={{ marginBottom: "1rem", marginRight: "0.5rem" }}
              className={upcomingTest.length>0?"button  primary":'button  primary disabled'}
              onClick={(e) => {
                addUsersToSchedule(selectedRows[0]);
              }}
            >
              Add
            </button>
            <button
              className="button "
              style={{ marginBottom: "1rem" }}
              onClick={() => {
                setIsshowModal(false);
              }}
            >
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      ) : (
        <>
        <Tooltip
        style={{ zIndex: 1 }}
        anchorSelect="#adduserbtn"
        content="Select and add user test"
        place="top"
      />
        <Tooltip
        style={{ zIndex: 1 }}
        anchorSelect="#downloadExcelBtn"
        content="Downlaod users list"
        place="left"
      />
      <div
      style={{
        display:"flex",
        gap:'1rem',
        float: "right",
        margin: "0.0rem .6rem .5rem 0",
      }}
      >
        <span
        id ='downloadExcelBtn'
        >
        <button id='csvlk' onClick={() => handleDownload(userDetails, 'Users.xlsx')} className="icon-button secondary">
          <SiMicrosoftexcel color="white" />
        </button>
        </span>
      <span
      id="adduserbtn"
        >
        <button
        className={selectedUsers.length>0?"button primary small":'button primary small disabled'}
        onClick={() => setIsshowModal(true)}
        >
          <AiOutlineUsergroupAdd size={20} />
        </button>
            </span>
            </div>
          </>
      )}
    </div>
  );
}
