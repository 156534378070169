import React from "react";
import { toggleSelectAllEvents, useGetArchivedEvents } from "./useEvent";
import LoaderUtility from "../UtilityFunctions/Loader_utility";
import { GrGroup } from "react-icons/gr";
// import libraries
import { MdOutlinePreview, MdOutlineUnarchive } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { dateTimeHelper } from "../UtilityFunctions/dateHelper";
import { toggleRowSelection } from "../ManageSchedule/TableData/tableDataUtils";
// import styles

const Archived = ({
  title,
  size,
  cursor,
  archiveUnarchive,
  handleFilterArchiveRows,
  archiveIds,
  selectedRows,
  setSelectedRows,
  setArchiveIds,
  selectAll,
  handleFetchAgain,
  handleToggleSelectAll,
  getUserDeatils,
  setPreviewOpen,
}) => {
  const { archivedEvents, loading, setArchivedEvents } = useGetArchivedEvents();
  console.log(archivedEvents);
  return (
    <>
      <section className="archiverow">
        <Tooltip
          style={{ zIndex: 1 }}
          anchorSelect="#unarchive"
          content="Unarchive"
          place="top"
        />
        {loading ? (
          <LoaderUtility loading={loading} />
        ) : (
          <section className="archiverow_table">
            <section className="archiverow_details_actions">
              <section className="archiverow_details">
                {archiveIds?.length} row(s) selected of {archivedEvents?.length}{" "}
                row(s)
              </section>
              <section className="archiverow_actions">
                {/* unarchive tests and get new data */}
                <span id="unarchive">
                  <button
                    className="unarchive"
                    disabled={archiveIds?.length === 0}
                    onClick={async () => {
                      handleFilterArchiveRows(
                        archiveIds,
                        archivedEvents,
                        setArchivedEvents
                      );
                      await archiveUnarchive(archiveIds, false);
                      await handleFetchAgain();
                    }}
                  >
                    <MdOutlineUnarchive
                      color="#fff"
                      size={size}
                      cursor={cursor}
                    />
                  </button>
                </span>
              </section>
            </section>

            <section className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        disabled={loading || archivedEvents?.length === 0}
                        checked={selectAll}
                        onChange={() => handleToggleSelectAll(archivedEvents)}
                      />
                    </th>
                    <th>Event_id</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Date</th>
                    <th>Start_time</th>
                    <th>End_time</th>
                    <th>Resgistration_start</th>
                    <th>Resgistration_end</th>
                    <th>Preview</th>
                  </tr>
                </thead>
                <tbody>
                  {checkDataExists(
                    archivedEvents,
                    title,
                    archiveIds,
                    selectedRows,
                    setSelectedRows,
                    setArchiveIds,
                    getUserDeatils,
                    setPreviewOpen
                  )}
                </tbody>
              </table>
            </section>
          </section>
        )}
      </section>
    </>
  );
};

export default Archived;

const checkDataExists = (
  data,
  title,
  archiveIds,
  selectedRows,
  setSelectedRows,
  setArchiveIds,
  getUserDeatils,
  setPreviewOpen
) => {
  console.log("ExpandableRow", data);
  if (data) {
    if (data.length > 0) {
      {
        return data.map((item, index) => (
          <tr>
            <td>
              <input
                type="checkbox"
                checked={
                  selectedRows?.includes(item.eventid) ||
                  archiveIds?.includes(item.eventid)
                }
                onChange={() =>
                  toggleRowSelection(
                    item.eventid,
                    setSelectedRows,
                    selectedRows,
                    setArchiveIds,
                    archiveIds
                  )
                }
              />
            </td>
            <td>{item.eventid}</td>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td>{item.event_date?.slice(0, 10)}</td>
            <td>{item.start_time}</td>
            <td>{item.end_time}</td>
            <td>{dateTimeHelper(item.reg_start_date, true, true)}</td>
            <td>{dateTimeHelper(item.reg_end_date, true, true)}</td>
            <td>
              <div className="action-btn">
                <button className="icon-button small">
                  <MdOutlinePreview
                    onClick={() =>
                      setPreviewOpen({
                        condition: true,
                        eventid: item,
                      })
                    }
                    size={22}
                  />
                </button>
                <button
                  tabIndex={2}
                  className="icon-button small"
                  title="view registered participants"
                  onClick={() => {
                    getUserDeatils(item.eventid);
                  }}
                >
                  <GrGroup />
                </button>
              </div>
            </td>
          </tr>
        ));
      }
    } else {
      return (
        // <tbody>
        <td colSpan="10">
          <div className="pyyi_table-zerostate">No events archived!</div>
        </td>
        // </tbody>
      );
    }
  }
};
