import Cards from "./Cards";
import LoaderUtility from "../Loader/Loader";
import { getValueFromSession } from "../UtilityFunctions/getSetValueSession";
import { useGetAvailable } from "../hooks/useUserAssessment";


function Available() {
  let userid = getValueFromSession('username')

  const { data, status } = useGetAvailable(userid, 'started', 'available');

  return <>{status == "success" ? <Cards data={data} /> : <LoaderUtility size={15} loading={status == 'pending'} />}</>;
}

export default Available;
