import React from "react";
import { nextSection } from "../scheduleutils";
import DatePickerComp from "../DatePicker/DatePickerComp";
import TimePickerComp from "../TimePicker/TimePicker";
import MarkingScheme from "../MarkingScheme/MarkingScheme";
import ShowResult from "../ShowResult/ShowResult";
import { useNavigate, useSearchParams } from "react-router-dom";
import SendNotification from "../SendNotification/SendNotification";
import LoaderUtility from '../../UtilityFunctions/Loader_utility'
import useGetMarkingScheme from '../../hooks/useGetMarkingScheme'

// import styles
import "../schedule.scss";

const SectionOne = ({
  setDateValue,
  dateValue,
  selectedTime,
  title,
  settitle,
  setSelectMarkingScheme,
  selectMarkingScheme,
  setIsChecked,
  isChecked,
  setCurrentSection,
  currentSection,
  setSelectedTime,
  toShowTime,
  setToShowTime,
  isToNotify,
  setToNotify,
}) => {
  const [searchParams] = useSearchParams();

  const from = searchParams?.get("from");
  const navigate = useNavigate();
  const plibcheck = sessionStorage.getItem("plibcheck");
  const puptstcheck = sessionStorage.getItem("puptstcheck");
  const check = from !== "testlibrary";
  const toRoute = check ? `/manageschedule` : -1;
  let  {markingSchemeArr, isLoading} = useGetMarkingScheme()
  return (
    <>
    {isLoading?
    <LoaderUtility
    loading={isLoading}
    loadingMsg='Loading'
    />


    :

  
    <>
      <section className="pyui_section_content">
        <span className="schedule_data_picker">
          <label>Title: </label>
          <input
            type="text"
            value={title}
            className="input"
            onChange={(e) => settitle(e.target.value)}
          ></input>
        </span>

        <span
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <DatePickerComp setDateValue={setDateValue} value={dateValue} />
          <TimePickerComp
            setSelectedTime={setSelectedTime}
            selectedTime={selectedTime}
            setToShowTime={setToShowTime}
            toShowTime={toShowTime}
          />
        </span>
        <MarkingScheme
          setSelectedValue={setSelectMarkingScheme}
          selectedValue={selectMarkingScheme}
          markingSchemeArr={markingSchemeArr}
        />
        <ShowResult setIsChecked={setIsChecked} isChecked={isChecked} />
        {check && (
          <SendNotification setToNotify={setToNotify} isToNotify={isToNotify} />
        )}
      </section>
      <footer className="schedule_main_footer">
        {from && (
          <button
            className="button link-button"
            onClick={() => {
              navigate(toRoute);
            }}
          >
            Back
          </button>
        )}
        <button
          className="button primary"
          onClick={() => nextSection(setCurrentSection, currentSection)}
        >
          Next
        </button>
      </footer>
      </>
      }
    </>
  )
}

export default SectionOne;
