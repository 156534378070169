import React from "react";
import ErrorPage from "./ErrorPage";
import { Navigate } from "react-router-dom";
export default class ErrorHandler2 extends React.Component {
    constructor(props) {
        super(props);

   
        this.state = {
            hasError: false,
            error: undefined
        };
    }

 
    static getDerivedStateFromError(error) {
  
        return {
            hasError: true,
            error: error
        };
    }

   
    componentDidCatch(error, errorInfo) {
        // log the error
        this.state.hasError=false
        console.log("Error caught!");
        console.error(error);
        console.error(errorInfo);

    }

    render() {

        if (this.state.hasError) {
            return <>
             {/* {this.state.hasError && (<Navigate
            to={"/login"} */}
            {/* state={{ response: 'something went wrong', type: "error" }} />)} */}
            <ErrorPage />;
            </>
        } else {
          
            return this.props.children;
        }
    }
}