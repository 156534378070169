import React from 'react'
import LoaderUtility from '../../UtilityFunctions/Loader_utility';
import { toggleRowSelection } from '../managetestutils';
import useLibraryCardsData from '../../hooks/useLibraryCardsData';
// import libraries
import { MdOutlineUnarchive } from "react-icons/md";
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
// import styles

const Archived = ({ size, cursor, archiveUnarchive, handleFilterArchiveRows, archiveIds, selectedRows, handleFetchAgain, setSelectedRows, setArchiveIds, selectAll, handleToggleSelectAll, showDetails, tocapitilizde }) => {

  const { archivedTests, setArchivedTests, loader } = useLibraryCardsData()

  return (
    <>
      <section className="archiverow">
        <Tooltip
          style={{ zIndex: 1 }}
          anchorSelect="#unarchive"
          content="Unarchive"
          place="top"
        />
        {loader ? (
          <LoaderUtility loading={loader} />
        ) : (
          <section className="archiverow_table">
            <section className='archiverow_details_actions'>
              <section className='archiverow_details'>
                {archiveIds?.length} row(s) selected of {archivedTests?.length} row(s)
              </section>
              <section className='archiverow_actions'>
                {/* unarchive rows and fetch new data */}
                <button className='unarchive' id='unarchive'
                  disabled={archiveIds?.length === 0}
                  onClick={async () => {
                    handleFilterArchiveRows(archiveIds, archivedTests, setArchivedTests)
                    await archiveUnarchive(archiveIds, false)
                    await handleFetchAgain()
                  }}>
                  <MdOutlineUnarchive color="#fff" size={size}
                    cursor={cursor} />
                </button>
              </section>
            </section>

            <section className="table-container">
              <table>
                <thead>
                  <th>
                    <input type='checkbox'
                      disabled={loader || archivedTests?.length === 0}
                      checked={selectAll}
                      onChange={() =>
                        handleToggleSelectAll(archivedTests)
                      }
                    />
                  </th>
                  <th>Test Id</th>
                  <th>Name</th>
                  <th>Track</th>
                  <th>Duration</th>
                  <th>Number of exercises</th>
                  <th>Total questions</th>
                  <th>Level</th>
                  {/* <th>Actions</th> */}
                </thead>
                <tbody>
                  {checkDataExists(archivedTests, archiveIds, selectedRows, setSelectedRows, setArchiveIds, showDetails, tocapitilizde)}
                </tbody>
              </table>
            </section>
          </section>
        )}
      </section >
    </>
  );
}

export default Archived

const checkDataExists = (data, archiveIds, selectedRows, setSelectedRows, setArchiveIds, showDetails, tocapitilizde) => {
  // console.log("template", data)
  if (data) {
    if (data.length > 0) {
      {
        return data.map((row, index) => (
          <tr key={index}>
            <td>
              <input type="checkbox"
                checked={selectedRows?.includes(row.testid) || archiveIds?.includes(row.testid)}
                onChange={() =>
                  toggleRowSelection(
                    row.testid,
                    setSelectedRows,
                    selectedRows,
                    setArchiveIds,
                    archiveIds,
                  )
                }
              />
            </td>
            <td>{row.testid}</td>
            <td>{row.name}</td>
            <td>{row.track}</td>
            <td>{row.duration}</td>
            <td>{row.noofexids}</td>
            <td>{row.totalquestions}</td>
            <td>{tocapitilizde(row.league)}</td>
            {/* <td>
              <section className='test_template_actions'>
                <button className='button secondary small'
                  onClick={(e) => showDetails(row)}>
                  Details
                </button>
                <Link className='button primary small'
                  to={`/scheduletest/${row.testid}?from=testlibrary`}>
                  Schedule
                </Link>
              </section>
            </td> */}
          </tr>
        ))
      }
    } else {
      return (
        // <tbody>
        <td colSpan="9">
          <div className="pyyi_table-zerostate">No schedule archived!</div>
        </td>
        // </tbody>
      )
    }
  }
}