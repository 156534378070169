import React from "react";
// import libraries
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import styles
const Search = ({ handleSearchChange }) => {
  return (
    <>
      <TextField
        onChange={(e) => handleSearchChange(e)}
        className="pyui_search-filter"
        // color="secondary"
        focused
        sx={{
          width: "350px",
          fontSize: "0.875rem",
          // marginTop: "1rem",
        }}
        placeholder="Search by test name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                fontSize="medium"
                className="pyui_search-filter-icon"
              />
            </InputAdornment>
          ),
        }}
        size="small"
        variant="outlined"
        type="search"
      />
    </>
  );
};

export default Search;
