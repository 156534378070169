import React from "react";
import { ToastContainer } from "react-toastify";
import TableRow from "./TableRow";
import { Tooltip } from "react-tooltip";
// import TableSearch from "./TableSearch";
// import Drawer from "./CustomDrawer/Drawer";
// import PinnedRows from "./PinnedRows/TablePinnedRows";


export const checkIfDataExists = (
  data,
  selectedRows,
  setSelectedRows,
  size,
  cursor,
  handleDelete,
  setSelectedSchedule,
  selectedSchedule,
  title,
  startTest,
  testloading,
  started,
  setStarted,
  cellId,
  handleRowExpand,
  pinnedRows, setPinnedRows, setTestData,
  handleTestName, testName, handleDrawer,
  drawerOpen, handleMarkTestComplete, showConfirmDialogBox,upsloading, handleSetScheduleId, cancelTest, setArchiveIds, archiveIds, handleTesttitle,isOnlyOneSelect,getUserdetails,isCheckBox
) => {

  if (data) {
    if (data.length > 0) {
      return (
        <>
          <ToastContainer
          autoClose={3000}
          />
          <Tooltip
            style={{ zIndex: 99 }}
            anchorSelect="#result"
            content="Result"
            place="top"
          />
          <Tooltip
            style={{ zIndex: 99 }}
            anchorSelect="#complete"
            content="Mark complete"
            place="top"
          />

          <tbody>

            {data?.map((cell, i) => (
              <TableRow
                data={data}
                cell={cell}
                index={i}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                size={size}
                cursor={cursor}
                handleDelete={handleDelete}
                title={title}
                startTest={startTest}
                cancelTest={cancelTest}
                // testloading={testLoading}
                started={started}
                setStarted={setStarted}
                cellId={cellId}
                handleRowExpand={handleRowExpand}
                handleTestName={handleTestName}
                testName={testName}
                pinnedRows={pinnedRows}
                setPinnedRows={setPinnedRows}
                setTestData={setTestData}
                drawerOpen={drawerOpen}
                handleDrawer={handleDrawer}
                handleMarkTestComplete={handleMarkTestComplete}
                showConfirmDialogBox={showConfirmDialogBox}
                upsloading={upsloading}
                handleSetScheduleId={handleSetScheduleId}
                setArchiveIds={setArchiveIds}
                archiveIds={archiveIds}
                handleTesttitle={handleTesttitle}
                isOnlyOneSelect={isOnlyOneSelect}
                getUserdetails={getUserdetails}
                isCheckBox={isCheckBox}
              />
            ))}
          </tbody>

        </>
      );
    } else {
      return (
        <tbody>
        
          <td colSpan={(title === 'PastTest' || title=='UpcomingTest') ? "10" : "9"}>
            <p className="pyyi_table-zerostate">No schedule found!</p>
          </td>
        </tbody>
      );
    }
  }

  // {
  //   drawerOpen && <Drawer
  //     isOpen={drawerOpen}
  //     cellId={cellId}
  //     testName={testName}
  //     onClose={handleDrawerclose} />
  // }
};



