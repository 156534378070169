// import react comp and files
import React, { useState, useEffect } from 'react'
import DataTable from '../DataTable/DataTable';
import LoaderUtility from '../../../UtilityFunctions/Loader_utility';
import SelectFromFile from './SelectFromFile';
import AddSingleUser from './AddSingleUser';
// import libraries
import { FaUserTimes } from "react-icons/fa";
import { useSearchParams } from 'react-router-dom';
// import styles
import './selectuserids.scss'


const SelectUserids = ({ scheduleUsers, setCurrentSection, currentSection, filename, FileTypeError, handleFileUpload, ScheduleTheTest, data, updateScheduleTest, setShowMsg,setSelectedUserids,selectedUserids, setUseridArr2 ,isLoading, setScheduleUsers,setRemoveUseridArr,useridArr2}) => {

  const [searchParams] = useSearchParams();
  const from = searchParams?.get('from');
  const check = from !== 'testlibrary'
  const handleOnClick = check ? updateScheduleTest : ScheduleTheTest
  const [data1, setData1]= useState([...scheduleUsers,...data])

  const setFilenameError = () => {
    if (FileTypeError) return FileTypeError
    else return filename
  }

  const handleRemoveUser=()=>{
    
   let  selectedUserids2=selectedUserids.map((item)=>{
      return item.userid
    })
    // setRemoveUseridArr(selectedUserids2)
    data = useridArr2.filter((item)=>{
      
     return  !selectedUserids2.includes(item.userid)
    })

    setData1(data)
    setRemoveUseridArr(selectedUserids2)
    setUseridArr2(data)
    // setUseridArr2(data.map((item)=>{
    //   return item.userid
    // }))
    setSelectedUserids([])
    console.log(data)
   
  }
  function addUsers(data){
    if(scheduleUsers.length <=0){

      setScheduleUsers(data)
    }
    setData1([...scheduleUsers,...data])
    if(useridArr2.length > 0){
     let values= useridArr2.map((item)=>{
      return item.userid
     })
      data = data.filter((item)=>{
        return !values.includes(item.userid)
      })
      
    } 
    data=[...useridArr2,...data]
 
    // setUseridArr2(data.map((item)=>{
    //   return item.userid
    // }))
    setData1(data)
    setUseridArr2(data)
  }

  useEffect(()=>{
   
    let data2=[]
    if(useridArr2.length > 0){
      
      data2=useridArr2.map((item)=>{
        return item
        
      })
    }
    else if(scheduleUsers.length >0){
      data2=scheduleUsers
    }
    setUseridArr2([...data2,...data])
    // // console.log("data1",data1)
    // setUseridArr2(data.map((item)=>{
    //   return item.userid
    // }))
     },[filename,data,isLoading])
  return (
    <>
      <section className='schedule_selectuserids'>
        {/* <section className='schedule_selectuserids_input_samplefile'>
          <section className='schedule_selectuserids_input'>

            <label htmlFor='selectuserids_li' className='selectuserids_label'>Select CSV or Excel File </label>

            <div className='selectuserids_input_filename'>
              <input id='selectuserids_li'
                type="file" accept=".csv, .xlsx, .xls"
                onChange={(e) => handleFileUpload(e.target.files[0])}
                title={`${filename}`} />
              <div className='selectuserids_filename'>{setFilenameError()}</div>
            </div>


          </section>

          <a
            href={xlfile}
            download="Sample_userids"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className="link-button small selectuserids_samplefile">
              Download sample file
              <FaDownload size={15} />
            </button>
          </a>

        </section> */}
              <div className='action-btn-div'>
                    <SelectFromFile
                    
                    handleFileUpload={handleFileUpload}
                    setFilenameError={setFilenameError}
                    filename={filename}
                    addUsers={addUsers}
                    />
                    <AddSingleUser
                  
                    handleFileUpload={handleFileUpload}
                    setFilenameError={setFilenameError}
                    filename={filename}
                    addUsers={addUsers}
                    />

                    <button
                    title='Remove selected users' 
                    onClick={handleRemoveUser} className='icon-button'>
                    <FaUserTimes size={15} />
                    </button>
        </div>

        {isLoading?
        
        <LoaderUtility
        loading={isLoading}
         loadingMsg={'Loading users'} 
        />
        :
        <DataTable
          data={useridArr2}
          buttonText={check ? 'Update & Save' : 'Schedule Test'}
          title={'Participants'}
          setCurrentSection={setCurrentSection}
          handleOnClick={handleOnClick}
          currentSection={currentSection}
          setShowMsg={setShowMsg}
          setSelectedUserids={setSelectedUserids}
          selectedUserids={selectedUserids}
          filename={filename}
          setUseridArr2={setUseridArr2}
          isCheckBox={true}
          showRemoveUserBtn={true}
          useridArr2={useridArr2}
        />}

      </section>
    </>
  )
}

export default SelectUserids