import { useNavigate } from "react-router-dom";

export default function ErrorPage(props) {
    let navigate=useNavigate()
    return (
        <div className="pyui_error-wrapper">
        <h1 className="pyui_error-msg-title"> Oops!</h1>
        <p className="pyui_error-msg">
         Something went wrong... please login again. <a style={{cursor:'pointer'}} onClick={()=>{navigate('/login')}}>click</a> here to  </p>
        </div>
    );
}