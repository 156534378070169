
import { getValueFromSession } from "../../UtilityFunctions/getSetValueSession";

export function RunTestBtnActivateInactivate(value, testLoader, setTestLoader, setChange, setOutput, setIsLimitExceed) {

  let isRuntest = process.env.REACT_APP_ACTIVATE_RUN_COUNT == 'true' && parseInt(process.env.REACT_APP_MAX_RUN_COUNT) <= parseInt(getValueFromSession('runCount'))

  // console.log(parseInt(process.env.REACT_APP_MAX_RUN_COUNT)<=parseInt(getValueFromSession('runCount')))
  // console.log(isLimitExceed,isRuntest)
  if (isRuntest) {
    setChange(false)
    setIsLimitExceed(true)

    setTestLoader(false)
    setOutput("Your daily limit to run tests is over. Please try tomorrow.");
    return true
  }
  else if (testLoader) {
    setChange(false)
    return false
  }
  else {
    setIsLimitExceed(false)
    setChange(value)
    return false
  }

}