import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiConfig from '../../../../ApiConfig'
import { getValueFromSession } from "../../../UtilityFunctions/getSetValueSession";
import { useEffect } from "react";
import { RunCount } from "../RunCount";
import { Base64 } from "js-base64";
import { RunTestBtnActivateInactivate } from "../RunTestBtnActivateInactivate";


const useQuesAnsAssertTest = (randomexe, currentStepIndex, language, username, setIsLoading, setQuesResp, quesresp, testLoader, setTestLoader, setChange, setOutput, setIsLimitExceed) => {

  // const [quesresp, setQuesResp] = useState("");
  const [answers, setAnswers] = useState([]);
  const [numTests, setNumTests] = useState(0);
  // const [questions, setQuestions] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [imgDimensions, setImgDimensions] = useState([]);
  const [isImageEditor, setIsImageEditor] = useState(false);
  const [flowchartfull, setFlowchartfull] = useState(false);
  const [isShowEditor, setShowEditor] = useState(true);
  const [imageType, setImageType] = useState('')
  const [isTextEditor, setIsTextEditor] = useState(false)
  let checklanguage = ['Visual Programming', 'Communication Skills', 'Basic Electronics']

  const [debugcode, setCode] = useState("");
  const [showRunTestCOunt, setShowRunTest] = useState(false)

  let Api = new ApiConfig();
  const navigate = useNavigate();
  let module = randomexe[currentStepIndex].module;


  useEffect(() => {
// console.log("changes")
    RunTestBtnActivateInactivate(false, testLoader, setTestLoader, setChange, setOutput, setIsLimitExceed);

    const quesans = async () => {
      await axios
        .get(
          Api.addApiKey(
            `${Api.BaseURI}/questions?exercise=${randomexe[currentStepIndex].exid}`
          )
        )
        .then((response) => {
          if (
            response.status === 406 ||
            response.status === 408 ||
            response.status === 500 ||
            response.status === 503
          ) {
            // setNavigateTo(true);
            navigate("/login", {
              state: { response: quesresp, type: "error" },
            });
            // setQuesResp(response);
            // // this.setState({ navigateTo: true, quesresp: response })
            // axios
            //   .get(
            //     Api.addApiKey(`${Api.BaseURI}/postLogout?username=${username}`)
            //   )
            //   .then((res) => {
            //     // console.log(res)
            //   });
            // // sessionStorage.removeItem('username');
            // sessionStorage.clear();
            // localStorage.clear();
          } else {
            let answers = [];
            // console.log("response", response);
            response.data.answers.map((answer) => {
              answers.push({
                key: answer.key,
                answer: Base64.decode(answer.answer),
              });
            });
            // setQuestions(response.data.questions);
            setAnswers(answers);
            // setIsLoading(false);
            // this.setState({ questions: response.questions, answers: answers, loading: false })
          }
        });
    };
    const asserttest = async () => {
      await axios
        .get(
          Api.addApiKey(
            `${Api.BaseURI}/asserts?exercise=${randomexe[currentStepIndex].exid}&track=${language}`
          )
        )
        .then((resp) => {
          // console.log("resp", resp.data.numTests);
          setNumTests(resp.data.numTests);
          // setIsLoading(false);
        });
    };
    function whichEditor(language, type) {
      let langguagesHaveImage = ["Visual Programming", "Basic Electronics", 'Communication Skills', 'Mathematics Skills'];
      setIsTextEditor(false)
      // console.log('type ######$$$$',type,type==='image',langguagesHaveImage.includes(language))
      if (
        langguagesHaveImage.includes(language) &&
        type !== undefined &&
        type === "image"
      ) {
        setIsImageEditor(true);
        setShowEditor(true);
        return true;
      }
      else if (
        langguagesHaveImage.includes(language) &&
        type !== undefined &&
        type === "pdf"
      ) {
        setIsImageEditor(true);
        setShowEditor(true);
        return true;
      }
      else if (
        langguagesHaveImage.includes(language) &&
        type !== undefined &&
        type === "desc"
      ) {
        setIsImageEditor(false)
        setShowEditor(true);
        setIsTextEditor(true)

        return false;
      }
      else if (
        langguagesHaveImage.includes(language) &&
        type !== undefined &&
        type === "nocode"
      ) {
        //  console.log('process.env.REACT_APP_USE_ONLY_QUESTIONS_EDITOR===true',process.env.REACT_APP_USE_ONLY_QUESTIONS_EDITOR==='true')
        if (process.env.REACT_APP_USE_ONLY_QUESTIONS_EDITOR === "true") {
          setShowEditor(false);
        }
        setIsTextEditor(true)
        setIsImageEditor(false);

        return false;
      }
      return false;
    }
    const dataarr = async () => {
      // console.log("Ciuie",execode)

      let execode = await axios.get(
        Api.addApiKey(
          `${Api.BaseURI}/code?exercise=${randomexe[currentStepIndex].exid}`
        )
      );

      if (
        execode.data.status == 406 ||
        execode.data.status == 408 ||
        execode.data.status == 500 ||
        execode.data.status == 503
      ) {
        navigate("/login", {
          state: { response: execode.data, type: "error" },
        });
        // axios
        //   .get(Api.addApiKey(`${Api.BaseURI}/postLogout?username=${username}`))
        //   .then((res) => {
        //     console.log("res", res.data);
        //   });
        // sessionStorage.removeItem('username');
        sessionStorage.clear();
        localStorage.clear();
      }

      if (whichEditor(language, execode.data.type)) {
        setImgDimensions(execode.data.dimensions);
        setImageType(execode.data.type)
        console.log("image tuape", execode.data.type)

        let dataUrl=''
        console.log('execode.data.type === pdf',execode.data.type === 'pdf',execode.data.type)
       if(execode.data.type === 'pdf'){
         const blob =  new Blob( [ new Uint8Array(execode.data.data.data) ], { type: 'application/pdf' } );
         dataUrl = URL.createObjectURL( blob );
         
       }else{
        
         const base64 = Base64.btoa(
           new Uint8Array(execode.data.data.data).reduce(function (data, byte) {
             return data + String.fromCharCode(byte);
           }, "")
         );
 
         dataUrl = `data:${execode.data.fileType};base64,${base64}`;


        }
        setCode(dataUrl);
    }
      else if (execode.data.type !== undefined && execode.data.type === 'nocode') {
        setCode(execode.data?.message)
      }
      else if (language == 'Mathematics Skills') {

        setCode(execode.data?.message)
      } else {
        console.log("code$$",execode)
        setCode(execode.data);
      }
      if(process.env.REACT_APP_ACTIVATE_RUN_COUNT === 'true'){

        await RunCount(username, module, setShowRunTest, setIsLoading);
      }
      setIsLoading(false);
    };
    if (language !== "Visual Programming" && (module === "debug" || module === "solver")) {
      if (process.env.REACT_APP_ACTIVATE_RUN_COUNT === 'true') {
        RunCount(username, module, setShowRunTest, setIsLoading).then();
      }
    
      const results = JSON.parse(getValueFromSession("results"));
      const exidToFind = randomexe[currentStepIndex]?.exid;
      
      const result = results?.find(result => result.exercise === exidToFind);
      
      if (result) {
        const codejson = result.questionjson;
        const newCode = codejson[0]?.code; 
        if (newCode) {
          setCode(newCode);
          setIsLoading(false);
        }
        else{
          dataarr()
        }
      }
      else{
        dataarr()
      }
    
    } else {
      dataarr();
    }
    // dataarr()
    asserttest()
    quesans()
  }, [currentStepIndex])

  return { answers, numTests, imgDimensions, debugcode, showRunTestCOunt, setCode, imageType, isImageEditor, isShowEditor, isTextEditor }
}

export default useQuesAnsAssertTest;
