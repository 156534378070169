export const handleSearchChange = (
  setSearchQuery,
  onFilterChange,
  selectedTrack,
  selectedLeague, selectcreate, selectedTemplate,
  event) => {
  setSearchQuery(event.target.value);
  onFilterChange({ selectedLeague, selectedTrack, searchQuery: event.target.value, selectcreate, selectedTemplate });
};

export const handleLeagueChange = (
  setSelectedLeague,
  onFilterChange,
  searchQuery,
  selectedTrack, selectcreate, selectedTemplate,
  event) => {
  setSelectedLeague(event.target.value);
  onFilterChange({ selectedLeague: event.target.value, selectedTrack, selectcreate, searchQuery, selectedTemplate });
};

export const handleTrackChange = (
  setSelectedTrack,
  onFilterChange,
  searchQuery,
  selectedLeague, selectcreate, selectedTemplate,
  event) => {
  setSelectedTrack(event.target.value);
  onFilterChange({ selectedLeague, selectedTrack: event.target.value, selectcreate, searchQuery, selectedTemplate });
};

export const handlecreatedchange = (
  setSelectedcreate,
  onFilterChange,
  searchQuery,
  selectedLeague, selectedTrack, selectedTemplate,
  event) => {
  console.log("Event$$", event.target.value)
  setSelectedcreate(event.target.value);
  onFilterChange({ selectedLeague, selectedTrack, searchQuery, selectcreate: event.target.value, selectedTemplate });
};


export const handleTemplateChange = (setSelectedBundle,
  onFilterChange,
  searchQuery,
  selectedLeague,
  selectedTrack,
  selectcreate,
  event) => {
  setSelectedBundle(event.target.value)
  onFilterChange({ selectedLeague, selectedTrack, searchQuery, selectcreate, selectedTemplate: event.target.value })
}