import React, { useRef, useEffect, useState } from 'react';
import { ImZoomIn, ImZoomOut, ImCancelCircle } from 'react-icons/im';
import { FaExpandArrowsAlt } from 'react-icons/fa';
import PulseLoader from "react-spinners/PulseLoader";

const FullSizeImage = ({
  imageUrl,
  zoom,
  setZoom,
  isFullScreen,
  setIsFullScreen,
  imgDimensions,
  imageType,
  setIsSlide,
  isSlide
}) => {
  const fullScreenRef = useRef(null);
  const [isScroll,setIsScroll]=useState(false)
  const [isPDFLoading, setIsPDFLoading] = useState(imageType==='pdf');
  useEffect(() => {
    if (fullScreenRef.current) {
      fullScreenRef.current.scrollLeft =
        (fullScreenRef.current.scrollWidth -
          fullScreenRef.current.scrollWidth / 3 -
          100) /
        2;
    }
  }, [imageUrl]);

  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10%",
    zIndex: '10'

  };
  
  return (
    <div className="full-screen">
      <div
        ref={fullScreenRef}
        onScroll={() => {
          setIsScroll(true);
        }}
        className="full-screen-img-div"
        style={{zIndex:1000}}
      >
        <div className="full-screen-action-btn-div">
        {imageType!=='pdf' &&
                <>
          <button
            title="zoom in"
            style={{
              marginLeft: '1rem',
              background: 'transparent',
              backgroundColor: 'transparent',
              border: '0px ',
            }}
            onClick={() => {
              console.log('zoom', zoom);
              if (zoom <= 5) {
                setIsScroll(false);
                setZoom(zoom + 0.5);
              }
            }}
          >
            <ImZoomIn color={zoom >= 5 ? 'grey' : 'black'} size={20} />
          </button>

          <button
            title="zoom out"
            style={{
              marginLeft: '1rem',
              background: 'transparent',
              backgroundColor: 'transparent',
              border: '0px ',
            }}
            onClick={() => {
              console.log('zoom', zoom);
              if (zoom > 0.5) {
                setZoom(zoom - 0.5);
                setIsScroll(false);
              }
            }}
          >
            <ImZoomOut color={zoom <= 0.5 ? 'grey' : 'black'} size={20} />
          </button>
          </>
          }
          <button
            className="sliderBtn"
            title={isFullScreen ? 'Close' : 'Full screen'}
            style={{
              marginLeft: '1rem',
              background: 'transparent',
              backgroundColor: 'transparent',
              border: '0px ',
              marginRight: '1rem',
            }}
            onClick={() => {
              console.log('onclick full size');
              setIsFullScreen(!isFullScreen);
              setIsSlide(true)
            }}
          >
            {isFullScreen ? (
              <ImCancelCircle size={20} />
            ) : (
              <FaExpandArrowsAlt size={20} />
            )}
          </button>
        </div>
        {console.log('imageType===pdf',imageType==='pdf',imageType)}
        {imageType==='pdf'?
        <>
        {isPDFLoading && (
          <div
            style={{
              position: "absolute",
              alignSelf: "center",
              zIndex: "2",
              justifyItems: "center",
              color: "whitesmoke",
            }}
          >
            Loading
            <span
            style={{
              position:'relative',
              top:'.25rem'
            }}
            >
              <PulseLoader
                // color="white"
                color="#7b1fa2"
                loading={isPDFLoading}
                cssOverride={override}
                size={10}
                className="pulse-loader"
                aria-label="Loading Spinner"
                data-testid="loader"
                speedMultiplier={0.5}
              />
            </span>
          </div>
        )}
                <iframe
                onLoad={() => {
                  setIsPDFLoading(false);
                }}
                src={`${imageUrl}#toolbar=0&navpanes=0&scrollbar=0`}

                width='100%'
                height='100%'
                />
                </>
                :
        
        <img
          className="full-screen-img"
          src={imageUrl}
          onLoad={() => {
            fullScreenRef.current.scrollLeft =
              (fullScreenRef.current.scrollWidth -
                fullScreenRef.current.scrollWidth / 3 -
                100) /
              2;
          }}
          style={{
            width: `${imgDimensions.width * 0.6 * zoom}px`,
            height: `${imgDimensions.height * zoom}px`,
            margin: 'auto',
            scrollSnapAlign: isScroll ? 'none' : 'center',
          }}
          alt="code image"
        />
        
       

        }
      </div>
    </div>
  );
};

export default FullSizeImage;