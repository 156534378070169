import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import forN from '../../../../assets/forN.png'
import declareN from '../../../../assets/declareN.png'
import decisionN from '../../../../assets/decisionN.png'
import inputN from '../../../../assets/inputN.png'
import outputN from '../../../../assets/outputN.png'
import assignN from '../../../../assets/assignN.png'


import './customnode.css'
export default function FormDialog({ onClose, onNodeSelect }) {
  const [open, setOpen] = React.useState(true);

  const availableShapes = [
    // { type: "start", label: "Start", shape: "oval" },
    { type: "assign", label: "assign", shape: "rectangle", icon: assignN },
    { type: "if", label: "Decision", shape: "diamond", icon: decisionN },
    { type: "log", label: "Output", shape: "parallelogram", icon: outputN },
    // { type: "condition", label: "c", shape: "circle" },
    { type: "inputtype", label: "input", shape: "inputrectangle", icon: inputN },
    { type: "declare", label: "declare", shape: "declare", icon: declareN },
    { type: "for", label: 'for', shape: 'hexagon', icon: forN },
    { type: "while", label: 'while', shape: 'hexagon', icon: forN }
    // { type: "end", label: "End", shape: "ovalEnd" },
  ];

  const inputOutput = {
    title: 'Input/Output',
    shapes: [{ type: "inputtype", label: "Input", shape: "inputrectangle", icon: inputN },
    { type: "log", label: "Output", shape: "parallelogram", icon: outputN }]
  };
  const variables = {
    title: 'Variables',
    shapes: [{ type: "declare", label: "Declare", shape: "declare", icon: declareN }, { type: "assign", label: "Assign", shape: "rectangle", icon: assignN }]
  };
  const control = {
    title: 'Control',
    shapes: [{ type: "if", label: "Decision", shape: "diamond", icon: decisionN }]
  }
  const looping = {
    title: 'Looping',
    shapes: [{ type: "for", label: 'For', shape: 'hexagon', icon: forN }, { type: "while", label: 'While', shape: 'hexagon', icon: forN }]
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleshapeclass(shape) {
    console.log("shape", shape)
    if (shape) {
      return shape + '';
    }

  }


  function handleshape(type) {
    if (type == 'assign') {
      return 'Assign'
    }

    if (type == 'inputtype') {
      return 'Input'
    }

    if (type == 'if') {
      return 'Decision control'
    }

    if (type == 'log') {
      return 'Output'
    }

    if (type == 'declare') {
      return 'Declare'
    }
    else {
      return type
    }
  }

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={onClose} >
        <DialogTitle>

          Statement


        </DialogTitle>
        <DialogContent>
          {/* <div style={{ display: 'flex', gap: '1.4rem', gridAutoRows: 'auto auto auto' }}>
            {availableShapes.map((shape, index) =>
              <button
                style={{ border: 'none', backgroundColor: 'transparent' }}
                // className={handleshapeclass(shape.shape)}
                key={index}

                onClick={() => onNodeSelect(shape, index + 1)}>
                Add {shape.label}{shape.type}
                {handleshape(shape.type)}
                {shape.icon}
                <img src={shape.icon} alt={shape.icon} />
              </button>

            )}
          </div> */}
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', gap: '2rem' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
              <span style={{ fontWeight: 'bold' }}>{inputOutput.title}</span>
              {inputOutput.shapes.map((shape, index) => (
                <button
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                  // className={handleshapeclass(shape.shape)}
                  key={index}

                  onClick={() => onNodeSelect(shape, index + 1)}>
                  {/* Add {shape.label}{shape.type} */}
                  {/* {handleshape(shape.type)} */}
                  {/* {shape.icon} */}
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={shape.icon} alt={shape.icon} />
                    <span style={{ fontWeight: 'bold', marginTop: '0.25rem' }}>{shape.label}</span>
                  </span>
                </button>
              ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
              <span style={{ fontWeight: 'bold' }}>{variables.title}</span>
              {variables.shapes.map((shape, index) => (
                <button
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                  // className={handleshapeclass(shape.shape)}
                  key={index}

                  onClick={() => onNodeSelect(shape, index + 1)}>
                  {/* Add {shape.label}{shape.type} */}
                  {/* {handleshape(shape.type)} */}
                  {/* {shape.icon} */}
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={shape.icon} alt={shape.icon} />
                    <span style={{ fontWeight: 'bold', marginTop: '0.25rem' }}>{shape.label}</span>
                  </span>
                </button>
              ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
              <span style={{ fontWeight: 'bold' }}>{control.title}</span>
              {control.shapes.map((shape, index) => (
                <button
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                  // className={handleshapeclass(shape.shape)}
                  key={index}

                  onClick={() => onNodeSelect(shape, index + 1)}>
                  {/* Add {shape.label}{shape.type} */}
                  {/* {handleshape(shape.type)} */}
                  {/* {shape.icon} */}
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={shape.icon} alt={shape.icon} />
                    <span style={{ fontWeight: 'bold', marginTop: '0.25rem' }}>{shape.label}</span>
                  </span>
                </button>
              ))}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}>
              <span style={{ fontWeight: 'bold' }}>{looping.title}</span>
              {looping.shapes.map((shape, index) => (
                <button
                  style={{ border: 'none', backgroundColor: 'transparent' }}
                  // className={handleshapeclass(shape.shape)}
                  key={index}

                  onClick={() => onNodeSelect(shape, index + 1)}>
                  {/* Add {shape.label}{shape.type} */}
                  {/* {handleshape(shape.type)} */}
                  {/* {shape.icon} */}
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={shape.icon} alt={shape.icon} />
                    <span style={{ fontWeight: 'bold', marginTop: '0.25rem' }}>{shape.label}</span>
                  </span>
                </button>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>

          <Button onClick={onClose}>Cancel</Button>


        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}