import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import './dialog.css'
export default function ConsolelogDialog({onClose,onNodeSelect,codedata,nodeid,updateData}) {

let condtionsarray=['==','<=','>=','>','<']

const [message,setMessage]=useState('')
const [open,setOpen]=useState(true)

function handlemessage(e){
  setMessage(e)
  codedata[nodeid.id]={
    id:nodeid.id,
    type:nodeid.type,
    data:{
      message:e
    }
  }
}


  const handleClickOpen = () => {
    setOpen(true);
  };


  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
 
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Output</DialogTitle>
        <DialogContent>
          <DialogContentText>
        
          </DialogContentText> 
          <div style={{display:'flex',flexDirection:'column',width:'25rem'}}>
          <label style={{marginBottom:'0.5rem'}}>Enter the Expression:</label>
        <input   type='text' className='input' placeholder='Enter the expression' value={message} onChange={(e)=>{handlemessage(e.target.value)}} />
        </div>
        </DialogContent>
        <DialogActions>

        <div className='flowchart_btn'>
        <button className='button secondary' onClick={onClose}>close</button>
        <button className='button primary' onClick={(e)=>{
         let newlabel="Output "+message
         console.log(newlabel)
         onNodeSelect(newlabel,codedata)
        }}>Ok</button>
        </div>
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}