// import React from 'react';
// import { useAuth } from '../hooks/useAuth';
// // import libraries
// import { Navigate, Outlet, useLocation } from 'react-router-dom';




// const PrivateRoute = ({ allowedRoles }) => {
//     const { isAuthenticated, assessmentAuth } = useAuth();
//     const location = useLocation()

//     return isAuthenticated || assessmentAuth
//         ? <Outlet />
//         : <Navigate to="/login" state={{ from: location }} replace />

// };

// export default PrivateRoute;

import React, { useEffect } from 'react'
import { getValueFromSession } from '../UtilityFunctions/getSetValueSession'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router'

export default function ProtectedRoute({Component, isAdmin}) {
 
    const navigate= useNavigate()
    useEffect(()=>{

    const user=getValueFromSession('username')
        if(user === undefined || user === null)
        {
            
            navigate('/login',{replace:false})
        }
        let role=getValueFromSession('role')
        if(isAdmin && role === 'student'){
            navigate('/login',{replace:false})
        }
    },[navigate])

    return(<div>
        <Outlet />
        {/* <Component/> */}
    </div>
    )
  
}
