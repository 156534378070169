import React, { useEffect } from 'react'
import ApexCharts from 'react-apexcharts'
import LoaderUtility from '../UtilityFunctions/Loader_utility'
export default function HorizontalBarWithLine({xData,yData,MinMaxAvg,isLoading}) {

  useEffect(()=>{
  //  console.log(xData)
  },[xData,yData,MinMaxAvg])
    let series= [{
        // data:  [400, 430, 448, 470, 100,540, 580, 690]
        // data: [4, 2, 3, 1, 0]
        data: xData
      }]
    var options = {
        
        chart: {
        type: 'bar',
        height: 350
      },
      noData: {
        text: 'No data',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        
      },
      annotations: {
        xaxis: [
        {
            x: MinMaxAvg.min,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: '#00E396',
              },
              text: 'min corect',
            }
          },
          {
            x: MinMaxAvg.avg,
            borderColor: 'yellow',
            label: {
              borderColor: 'yellow',
              style: {
                color: 'black',
                background: 'yellow',
              },
              text: 'avg correct',
            }
          },
          {
            x:MinMaxAvg.max,
            borderColor: '#00E396',
            label: {
              borderColor: '#00E396',
              style: {
                color: '#fff',
                background: '#00E396',
              },
              text: 'max correct',
            }
          },
        
    ],
        // yaxis: [{
        //   y: 'July',
        //   y2: 'September',
        //   labels:{
        //     showDuplicates: false
        //   }
        // }]
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
     
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories: yData,
        labels:{
          showDuplicates: false
        },
        
        // categories:  ['June', 'July', 'August', 'September', 'October', 'November', 'December'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          },
          axisTicks: {
            show: true,
            
          }
        }
      },
      yaxis: {
        reversed: false,
        axisTicks: {
          show: true,
          maxTicksLimit: 1,
        },
        
      }
      };
  return (
    isLoading?
      <LoaderUtility
      loading={isLoading}
      loadingMsg='Loading'
      />
      :
    <ApexCharts options={options} series={series} type='bar'  width='50%' height={`${50*yData.length}px`} />
    
  )
}
