import React from "react";
import BackButton from "../Eval_quiz_utility_functions/BackButton";
import NextButton from "../Eval_quiz_utility_functions/NextButton";
import FinishButton from "../Eval_quiz_utility_functions/FinishButton";
// import { useToEvaluate } from './useToEvaluate';
// import { DynamicForm } from './Evalindex';
const PrevNextButton = (props) => {
  console.log("inside the preve next button")
  let {
    npvalue,
    step,
    evaluate,
    handlesetAttNtAttp,
    setResult,
    setIsMOdal,
    setSubmitMsg,
    toFinishEvaluate,
    exdatarr,
    newcurrent,
    currentStepIndex,
    setCurrentStepIndex,
    loading,
    setIsLoading,
    isFirstStep,
    isLastStep,
    toEvaluate,
    clearTimer, randomexe, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output
  } = props;

  console.log("Back button exdatarr", exdatarr)
  console.log("Back button currentStepIndex", currentStepIndex)
  console.log("Back button resultarr", resultarr)
  console.log("Back button no_of_correct", no_of_correct)
  console.log("Back button totalMarks", totalMarks)
  console.log("Back button score", score)
  console.log("Back button numTests", numTests)
  console.log("Back button debugcode", debugcode)
  console.log("Back button output", output)
  // let { toEvaluate, toFinishEvaluate } = useToEvaluate()
  // let states = new DynamicForm().toReturnModel();

  function back1() {
    let props = {
      currentStepIndex,
      setCurrentStepIndex,
      setIsLoading,
      setResult, exdatarr, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output,
      evaluate,
      handlesetAttNtAttp, npvalue,
      step,
    };
    return <BackButton {...props} />;
  }

  function next1() {
    let props = {
      currentStepIndex,
      setCurrentStepIndex,
      exdatarr,
      setIsLoading,
      setResult, exdatarr, resultarr, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output,
      evaluate,
      handlesetAttNtAttp, npvalue,
      step,
    };

    return <NextButton {...props} />
  }

  function finish() {
    let prop = {
      exdatarr,
      setIsMOdal,
      currentStepIndex,
      toEvaluate,
      clearTimer,
      npvalue,
      step,
      evaluate,
      handlesetAttNtAttp,
    }
    return <FinishButton {...prop} />
  }
  function setButtonPerModule() {

    return (
      <>
        {back1()}
        {next1()}
        {finish()}
      </>
    )
  }

  return <>{setButtonPerModule(module)}</>;
};

export default PrevNextButton;
