import axios from "axios";
import ApiConfig from '../../../../ApiConfig'
import { getValueFromSession, setValueInSession } from "../../../UtilityFunctions/getSetValueSession";
import { RunTestBtnActivateInactivate } from "../RunTestBtnActivateInactivate";
import { displayOutput } from "../setDisplayOutput";


export const useCompile = (testLoader, setTestLoader, setChange, setOutput, setIsLimitExceed, setActiveTab, userLang, setUserLang, debugcode, setResultArr, quizexid, currentStepIndex, setStatusCode) => {

  let userInput = "";

  function compile(setResult, randomexe, no_of_correct, no_of_questions, totalMarks, score, numTests, output) {
    setTestLoader(true)
    if (RunTestBtnActivateInactivate(false, testLoader, setTestLoader, setChange, setOutput, setIsLimitExceed)) {
      return 0
    }
    console.log("function compile()");

    setActiveTab("tab2");
    // console.log("code ", code);
    // console.log("language ", typeof language);
    // console.log(exdatarr[currentStepIndex].exid);
    setUserLang(sessionStorage.getItem("language"));
    let flag = false;
    let exid = quizexid[currentStepIndex].exid;
    let Api = new ApiConfig();
    // console.log("compile", code);

    let compileURI = Api.addApiKey(`${Api.BaseURI}/compile`);
    axios
      .post(compileURI, {
        userid: getValueFromSession("username"),
        // userid: 'debug1@gmail.com',
        code: debugcode,
        language: userLang,
        input: userInput,
        exid: exid,
      })
      .then((res) => {
        console.log("result compile", res.data);

        // resultarr=res.data.result

        setResultArr(res.data.result);
        // console.log("first");
        if (setResult) {
          setResult(randomexe, currentStepIndex, res?.data?.result, no_of_correct, no_of_questions, totalMarks, score, numTests, debugcode, output)
        }
        setStatusCode(res.data.status)
        // console.log("first", resultarr);
        // let sumarr = res.data.result.reduce((sum, a) => sum + a, 0);
        // console.log("sum ", sumarr);
        // console.log("length ", res.data.result.length);
        setTestLoader(false)

        // if (
        //   (sumarr === res.data.result.length) &
        //   (res.data.result.length !== 0)
        // ) {
        //   setCorrectAssert(sumarr);
        //   setIsMarkComplete(true);
        //   sumarr = 0;
        // } else {
        //   setIsMarkComplete(false);
        //   sumarr = 0;
        // }

        displayOutput(res.data, setOutput);
        // sessionStorage.setItem("runCount", res.data.runcount);
        console.log("res.data.runcount", res.data.runcount)
        setValueInSession("runCount", res.data.runcount);

      });
  }

  return { compile }
}