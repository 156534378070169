import React, { useEffect, useState } from 'react'
import { getValueFromSession, setValueInSession } from '../UtilityFunctions/getSetValueSession'
import ApiConfig from '../../ApiConfig'
import axios from 'axios'
import LoaderUtility from '../UtilityFunctions/Loader_utility'
import "../TestLibrary/LibraryCards/librarycards.scss";
import Table from '../UtilityFunctions/Table'
import { useNavigate } from 'react-router'
import { SiTicktick } from "react-icons/si";
import { FcExpired } from "react-icons/fc";
import { TbClockCancel } from "react-icons/tb";
import { VscDebugStart } from "react-icons/vsc";
import { GrInProgress } from "react-icons/gr";
import DropDown from '../UtilityFunctions/DropDown'
import DonutChart from '../Charts/DonutChart'
import './AdminDashboard.scss'
import DatePickerComp from '../UtilityFunctions/DatePicker'
import CustomeDatePicker from '../UtilityFunctions/DatePicker'
import DateViseView from './DateViseView/DateViseView'

export default function AdminDashboard() {
  
    const [data , setData]= useState([])
    const [showCards, setShowCards]= useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState('completed')
    const navigate= useNavigate()
    const getAssessmnetCount= async()=>{
        setIsLoading(true)
        try {
        let groupName= getValueFromSession('group_name')
        let Api= new ApiConfig()
        let uri = Api.addApiKey(`${Api.BaseURI}/getassessmentscount?groupName=${groupName}`)
            
        
        let result = await axios.get(uri)
        console.log('assessment count',result.data)
        setData(result.data.data)
    } catch (error) {
        console.log(error)
    }finally{
        setIsLoading(false)
    }
    }

    useEffect(()=>{
        getAssessmnetCount()
    },[])

    function capitalizeFirstLetter(string) {
        return string[0].toUpperCase() + string.slice(1);
    }
    function getColor(status){
    let color;
        switch(status){
          case 'completed': 
             color='#11ff11'
              break;
          case 'expired':
            color='gray'
            break;
          case 'canceled':
            color='red'
            break;
          case 'started':
            color='#feb019'
            break;
          case 'scheduled':
            color='#775dd0'
            break;
          default:
            color='black'
        }
        
      return color
    }
    function getIcon(status){
      let size =25
    let icon;
        switch(status){
          case 'completed': 
             icon=  <SiTicktick size={size} color='#11ff11' />
              break;
          case 'expired':
            icon=<FcExpired size={size} color='gray'/>
            break;
          case 'canceled':
            icon=<TbClockCancel size={size} color='red' />
            break;
          case 'started':
            icon=<VscDebugStart size={size} color='#feb019'/>
            break;
          case 'scheduled':
            icon=<GrInProgress size={22} color='#775dd0'/>
            break;
          default:
            icon='no icon'
        }
      
      return icon
    }

    const handleBack= (e) => {
      // if(showCards) 
      if(isLoading){
        return
      }
      navigate(-1);
      
    }
    const handleDetails = (card)=>{
    
      setValueInSession('status',card.status)
      navigate('/assessmentdashboard')
    }
    

const cards=  <section className="testlibrarycards_main_grid" style={{
  gridTemplateColumns: 'repeat(auto-fit, minmax(9rem, 17rem))',
  
}}>
  {data?.map((card, i) => (
    <div key={i} className={`testlibrarycard`} style={{
      borderRadius:"10px",
  width:'fit-content'
    }}>
      <header className="testlibrarycard_header">{getIcon(card.status)}{capitalizeFirstLetter(card?.status)} Assessments</header>
      <span className="testlibrarycard_time" style={{color:`${getColor(card.status)}`,fontWeight:800,background:'transparent',border:'none'}}>{card.count}</span>

      <div className="testlibrarycard_time_ques">
     
      </div>
      <div className="testlibrarycard_footer_buttons">
        <button
          className="button link-button small"
          onClick={()=>handleDetails(card)}
        >
          Details
        </button> 
        
      </div> 
    </div>
  ))
  }
  
  </section>
if(process.env.REACT_APP_IS_SHOW_ADMIN_DASHBOARD === 'false'){
  return 
}
  return (
    <section className="testlibrarycards_main">
      <section>
        <button
          className={"button link-button "+`${isLoading && 'disabled'}`}
          onClick={handleBack}
        >
          Back
        </button>
      </section>
    {/* <section className="testlibrarycards_main_grid"> */}
    
    {isLoading?
        <LoaderUtility loading={isLoading} loadingMsg='loading' />
    :
    <>
    {cards}
    <div 
    className='graph-wrapper'
    >

    <DonutChart
    data={data.map(item=> parseInt(item.count))}
    customeLables={data.map(item=> item.status)}
    colors={data.map(item=> getColor(item.status))}
    />
    </div>
    <DateViseView/>
    </>
    }
  {/* </section> */}
  </section>
  )
}



export function ShowAssessmentAccordingToStatus(){

    const [data, setData]= useState([])
    const [isLoading, setIsLoading] =useState(false)
    const [options, setOptions]= useState([])
    const [selectedOption,setSlectedOption] =useState('DSL')
    const [selectedAssessment, setSelectedAssessment] = useState([])
    const [testTempalteUsed, setTestTemplateUsed]= useState([])
    const [fromDate, setFromDate] = useState(new Date().toISOString())
    const [toDate, setToDate] = useState(new Date().toISOString())

    const headers =[
      {id:'1',field:'scheduleid',headerName:'Schedule ID'},
      {id:'2',field:'name',headerName:'Test Name'},
      {id:'3',field:'title',headerName:'Title'},
      {id:'4',field:'scheduletime',headerName:'Schedule Date'},
      {id:'5',field:'track',headerName:'Track'},
      {id:'6',field:'scheduledby',headerName:'Scheduled by'},
      {id:'7',field:'enrolleduserscnt',headerName:'Enrolled Users'},
      {id:'8',field:'testsubmiteduserscnt',headerName:'Test Submitted by Users'},
    ]
    const navigate= useNavigate()
    const getAssessmnets= async()=>{
        try {
            setIsLoading(true)
        let Api = new ApiConfig()
        let status= getValueFromSession('status')
        let groupName = getValueFromSession('group_name')
        let uri = Api.addApiKey(`${Api.BaseURI}/getsimillarstatusassessments?groupName=${selectedOption}&status=${status}`)
            
            let result = await axios.get(uri)
        console.log(result.data)
        setData(result.data.data)
    } catch (error) {
        console.log(error)
    }finally{
        setIsLoading(false)
    }
    }
    useEffect(()=>{
      getAssessmnets()
      getTestTemplateUsed()
    },[selectedOption])
    
    const handleBack= (e) => {
      // if(showCards) 
      navigate(-1);
      // else setShowCards(!showCards)
    }

    async function calculateCost(){
      let Api = new ApiConfig()
      let scheduleidArr= selectedAssessment.map(item=> item.scheduleid) 
      console.log(scheduleidArr)
      let uri = Api.addApiKey(`${Api.BaseURI}/getassessmentcost?scheduleid=${scheduleidArr}`)
      let result = await axios.get(uri)
      console.log(result.data)
    }

    async function getTestTemplateUsed(){
      let Api = new ApiConfig()
      let uri = Api.addApiKey(`${Api.BaseURI}/gettesttempletused?groupName=${selectedOption}`)
      let result = await axios.get(uri)
      console.log('result', result.data)
      setTestTemplateUsed(result.data.data)
    }

    function fileteByDate(){
      console.log(data)
     setData( data.filter(item=>{
        if(item.scheduletime>fromDate && item.scheduletime<=toDate)
        console.log('item.scheduletime>fromDate && item.scheduletime<=toDate',item.scheduletime>fromDate ,item.scheduletime,fromDate, item.scheduletime<=toDate, item.scheduletime,toDate)
        return (item.scheduletime>fromDate && item.scheduletime<=toDate)


      }))
    }
    useEffect(()=>{
      fileteByDate()
    },[toDate, fromDate])
    return (
        <> <section className="testlibrarycards_main">
        <section>
          <button
            className="button link-button"
            onClick={handleBack}
          >
            Back
          </button>
        </section>
         {isLoading?
            <LoaderUtility loading={isLoading} loadingMsg='loading' />
        :
        <>
        {/* <div style={{
          position:'absolute',
          zIndex:10
        }}> */}
        
        <section className="testlibrarycards_main_grid" style={{
  gridTemplateColumns: 'repeat(auto-fit, minmax(9rem, 17rem))',
  width:'fit-content',
  borderRadius:'10px',
  }}>
  <div className={`testlibrarycard`}>
      <header className="testlibrarycard_header">Test Template Used</header>
      <span className="testlibrarycard_time"style={{fontWeight:800,background:'transparent',border:'none'}} >{testTempalteUsed?.length}</span>

      {/* <header className="testlibrarycard_subheader">Track: {card.track}</header> */}
      <div className="testlibrarycard_time_ques">
        {/* <span className="testlibrarycard_time" style={{color:`${getColor(card.status)}`,fontWeight:800}}>{card.count}</span> */}
        {/* <span className="testlibrarycard_ques">{card.noofexids} Exercises</span> */}
      </div>
      <div className="testlibrarycard_footer_buttons">
        {/* <button
          className="button link-button small"
          // onClick={()=>{navigate('/assessmentdashboard')}}
          onClick={()=>handleDetails(card)}
        >
          Details
        </button>  */}
        {/* <Link
          className="button primary small"
          onClick={() => sessionStorage.setItem("plibcheck", 1)}
          to={`/scheduletest/${card.testid}?from=testlibrary`}
        >
          Schedule
        </Link> */}
      </div> 
    </div>
      </section>
      <div
      style={{    
        display: 'flex',
        gap: '1rem',
        margin: '1rem',
        
    }}
      >

        <DropDown
          options={['Test Group','DSL']}
          setSlectedOption={setSlectedOption}
          selectedOption={selectedOption}
          
          />
          {/* <button className='button primary small' 
          onClick={calculateCost}
          >
          Calculate Cost
        </button> */}
        <div style={{
          backgroundColor:'#fffffffc',
          display:'flex',
          alignItems:'center',
          padding:'0 1rem',
        }}>
          
          Duration :

         <CustomeDatePicker
        value={fromDate}
        setDateValue={setFromDate}
        label={'From'}
        // min={fromDate}
        />
        

        <CustomeDatePicker
        value={toDate}
        setDateValue={setToDate}
        label={'To'}
        // min={fromDate}
        
        />
     
        </div>
          </div>
          {/* </div> */}
          {console.log('data',selectedAssessment)}
        <Table
                rows={data}
                headers={headers}
                setSelected={setSelectedAssessment}
                data={selectedAssessment}
                
                />
                </>
        }
            
        </section>
        </>
    )
}