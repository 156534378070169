import { useEffect, useState } from "react";
import { RunTestBtnActivateInactivate } from "../RunTestBtnActivateInactivate";
import { getValueFromSession } from "../../../UtilityFunctions/getSetValueSession";


function useSetResultArrOutputVal(testLoader, setTestLoader, setChange, setIsLimitExceed, randomexe, currentStepIndex, setActiveTab) {

  const [resultarr, setResultArr] = useState([]);
  const [output, setOutput] = useState("");

  useEffect(() => {
    RunTestBtnActivateInactivate(false, testLoader, setTestLoader, setChange, setOutput, setIsLimitExceed);

    function setOutputVal() {
      let results = JSON.parse(getValueFromSession("results"));
      let newOutput;
      // let newResult;
      let found = false;
      for (let i = 0; i < results?.length && !found; i++) {
        // console.log("results[i].exercise === exid", results[i].exercise, exid)
        if (results[i].exercise === randomexe[currentStepIndex].exid) {
          let codejson = results[i].questionjson;
          for (let j = 0; j < codejson.length && !found; j++) {
            newOutput = codejson[j].output;
            // newResult = codejson[j].resultarr;
            found = true;
            // console.log("newOutput", newOutput);
            // console.log("codnewcode", newCode);
          }
        }
      }
      if (!found) {
        newOutput = "";
      }
      setOutput(newOutput);
      // console.log("newOutput", newOutput);
      // return newOutput;
    }

    function setResultArray() {
      let results = JSON.parse(getValueFromSession("results"));
      let newResult;
      let found = false;
      for (let i = 0; i < results?.length && !found; i++) {
        if (results[i].exercise === randomexe[currentStepIndex].exid) {
          let codejson = results[i]?.questionjson;
          for (let j = 0; j < codejson?.length && !found; j++) {
            newResult = codejson[j].resultarr;
            found = true;
            // console.log("codnewcode", newCode);
          }
        }
      }
      if (!found) {
        newResult = [];
      }
      setResultArr(newResult);
      // return newResult;
    }
    if (randomexe[currentStepIndex].module === "debug" || randomexe[currentStepIndex].module === "solver") {
      setResultArray();
      // toSetCode();
      setOutputVal();
      // setResultArray();
      // console.log("output", output);
      // console.log("resultarr", resultarr.length === 0 && output === "");
      if (resultarr?.length === 0 && output === "") {
        setActiveTab("tab1");
      } else {
        setActiveTab("tab2");
      }
    }
  }, [randomexe[currentStepIndex].exid, randomexe[currentStepIndex].module]);
  console.log("resultarr", resultarr)
  return { output, setOutput, resultarr, setResultArr }
}

export default useSetResultArrOutputVal