import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { ToastContainer, toast } from "react-toastify";
import { useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import './event.scss';
import { MdContentCopy } from "react-icons/md";
import moment from 'moment'
import encrypt from "../UtilityFunctions/Encryption";
import { dateTimeHelper } from "../UtilityFunctions/dateHelper";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewDialog({
  open,
  onClose,
  onChange,
  onClick,
  eventdata

})


{

  
    const divRef = useRef(null);
    const divRef1 = useRef(null);
  React.useEffect(()=>{
    let event=[{eventid:eventdata.eventid}]
    let ecryptedStr= encrypt(event)
    
    // setLink(`http://localhost:3000/register/${ecryptedStr}`)
    setLink(`${process.env.REACT_APP_LINK}/register/${ecryptedStr}`)
  },[])
    const [copy,setcopy]=React.useState(false)
    const [link, setLink]= React.useState('')
    const copyContent = () => {
      if (divRef.current) {
    
        const content = divRef.current.innerText;
        navigator.clipboard.writeText(content)
          .then(() => {
                  setcopy(true)
            console.log('Content copied to clipboard');
            // You can show a success message or perform any other action here
          })
          .catch((error) => {
            console.error('Failed to copy content: ', error);
          });
          console.log("content",content)
      }
    };
    const copyContent1 = () => {
      if (divRef1.current) {
    
        const content = divRef1.current.innerText;
        navigator.clipboard.writeText(content)
          .then(() => {
                  setcopy(true)
            console.log('Content copied to clipboard');
            // You can show a success message or perform any other action here
          })
          .catch((error) => {
            console.error('Failed to copy content: ', error);
          });
          console.log("content",content)
      }
    };
  return (
    <React.Fragment>
      <ToastContainer />

      <Dialog
        open={open}
      
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        style={{minWidth:'40%'}}
      >
        <DialogTitle>
            <div style={{display:'flex',justifyContent:'space-between'}}>
            <label>
                Preview
            </label>
         
            </div>
            
            </DialogTitle>

        <DialogContent style={{display:'flex'}}>
        <div ref={divRef} style={{display:'flex', flexDirection:'column', gap:'0.5rem'}}>
    <label style={{display: 'flex', }}>
        <span style={{minWidth: '150px'}}>Event Name:</span>
        <span>{`${eventdata.name}`}</span>
    </label>
    <label style={{display: 'flex',}}>
        <span style={{minWidth: '150px',fontWeight:'500'} }>Event Date:</span>
        <span>{moment(eventdata.event_date).format("DD-MM-YYYY")}</span>
    </label>
    <label style={{display: 'flex',}}>
        <span style={{minWidth: '150px'}}> Start time:</span>
        <span>{`${eventdata.start_time}`}</span>
    </label>
    <label style={{display: 'flex',}}>
        <span style={{minWidth: '150px'}}> End time:</span>
        <span>{`${eventdata.end_time}`}</span>
    </label>
    <label style={{display: 'flex',}}>
        <span style={{minWidth: '150px'}}>Event Description:</span>
        <span>{`${eventdata.description}`}</span>
    </label>
    <label style={{display: 'flex', }}>
        <span style={{minWidth: '150px'}}>Registration Start:</span>
        <span>{`${dateTimeHelper(eventdata.localtimeregstart, true, true)}`}</span>
    </label>
    <label style={{display: 'flex'  }}>
        <span style={{minWidth: '150px'}}>Registration End:</span>
        <span>{`${dateTimeHelper(eventdata.localtimeregend, true, true)}`}</span>
    </label>
    <label style={{display: 'flex', justifyContent: 'space-between'}}>
        <span style={{minWidth: '150px'}}>Registration Link:</span>
        <span 
        style={{
          wordBreak: 'break-all',
          textAlign: 'justify',
        }}
        ref={divRef1}>{link}</span>
        <span onClick={()=>copyContent1()} title="Copy">             <MdContentCopy />
</span>
    </label>
</div>
        
        </DialogContent>
        <DialogActions>
        <button className="button primary" onClick={(e)=>copyContent()} title={`${copy ? 'Copied':'Copy'} `}>
            Copy all
            </button>
          <button className="button secondary" onClick={onClose}>
            Close
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
