import React, { useEffect, useState } from "react";
// import libraries
import { Link } from "react-router-dom";
import { toggleRowSelection } from "../managetestutils";
import ShowFloderIcon from "../TestBundle/TestBundle";
import { FaSortDown } from "react-icons/fa6";
import { FaSortUp } from "react-icons/fa6";
const TestTemplateTable = ({
  data,
  showDetails,
  tocapitilizde,
  handleToggleSelectAll,
  selectAll,
  setSelectedRows,
  selectedRows,
  setArchiveIds,
  archiveIds,
  loading,
}) => {
  // console.log("TestTemplateTable", data2);

const [order,setOrder]=useState({name:'testid',reversed:true})
// const [data,setData]=useState(data2)
const [loading1,setLoading]=useState(true)

const sortData=(data,name1,isnumber)=>{
setOrder({ name: name1, reversed: !order.reversed });
data=data.sort((a,b)=>{
  let item1=a[name1];
  let item2=b[name1];
  if(order.reversed){
    return  isnumber ? item1-item2 :item1.localeCompare(item2)

  }
  return isnumber ? item2-item1 :item2.localeCompare(item1)
})
setLoading(false)

}

function renderSortIcon() {
  if (order.reversed) {
    // console.log("sorted1.reversed ", sorted1.reversed)
    return <FaSortDown />;
  } else {
    return <FaSortUp />;
  }
}
// useEffect(()=>{
//   sortData(data,'testid',true)
// },[data2])
  return (
    <>
 {
    <section className="table-container">
      <table>
        <thead>
          <th>
            <input
              type="checkbox"
              disabled={loading || data?.length === 0}
              checked={selectAll}
              onChange={() => handleToggleSelectAll(data)}
            />
          </th>
          <th onClick={()=>sortData(data,'testid',true)} >
            Test Id
            {order.name=='testid' && renderSortIcon()}

          </th>
          <th onClick={()=>sortData(data,'name')}>
            Name
            {order.name=='name' && renderSortIcon()}
          </th>
          <th className="alignCenter"   onClick={()=>sortData(data,'track')}>
          Track
          {order.name=='track' && renderSortIcon()}   
          </th>

          <th className="alignCenter"  onClick={()=>sortData(data,'duration',true)}>
            Duration
            {order.name=='duration' && renderSortIcon()}   
          </th>

          <th className="alignCenter" onClick={()=>sortData(data,'noofexids',true)}>
            Number of exercises
            {order.name=='noofexids' && renderSortIcon()}   
          </th>

          <th className="alignCenter" onClick={()=>sortData(data,'totalquestions',true)}>
            Total questions
            {order.name=='totalquestions' && renderSortIcon()}   
          </th>
          <th className="alignCenter" onClick={()=>sortData(data,'league')}>
            Level
            {order.name=='league' && renderSortIcon()}   

          </th>
          <th className="alignCenter">Actions</th>
        </thead>
        {data?.length > 0 ? (
          <tbody>
            {data.map((row, idx) => (
              <tr key={idx}>
                <td>
                  <input
                    type="checkbox"
                    checked={
                      selectedRows?.includes(row.testid) ||
                      archiveIds?.includes(row.testid)
                    }
                    onChange={() =>
                      toggleRowSelection(
                        row.testid,
                        setSelectedRows,
                        selectedRows,
                        setArchiveIds,
                        archiveIds
                      )
                    }
                  />
                </td>
                <td>{row.testid}</td>
                <td>{row.name}
                    <ShowFloderIcon 
                        isInBundle={row?.isinbundle} 
                        title={(row?.bundle_details!== undefined && row?.bundle_details != null)? JSON?.parse(row?.bundle_details)?.title : ''}
                        index={idx}
                        bundleDetails={row?.bundle_details}
                      />
                  </td>
                <td className="alignCenter">{row.track}</td>
                <td className="alignCenter">{row.duration}</td>
                <td className="alignCenter">{row.noofexids}</td>
                <td className="alignCenter">{row.totalquestions}</td>
                <td className="alignCenter">{tocapitilizde(row.league)}</td>
                <td>
                  <section className="test_template_actions">
                    <button
                      className="button link-button small"
                      onClick={(e) => showDetails(row)}
                    >
                      Details
                    </button>
                    <Link
                      className="button primaryInverse small x-small"
                      to={`/scheduletest/${row.testid}?from=testlibrary`}
                    >
                      Schedule
                    </Link>
                  </section>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <td colSpan="9">
              <div className="pyyi_table-zerostate">
                No templates to display!
              </div>
            </td>
          </tbody>
        )}
      </table>
    </section>}
    </> 
  );
};

export default TestTemplateTable;
