import React, { useState } from 'react'
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import "react-toastify/dist/ReactToastify.css";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { FaDownload } from "react-icons/fa6";
import {HiUserAdd} from 'react-icons/hi'
import xlfile from './sample.xlsx'
import './selectuserids.scss'

export default function AddSingleUser({setFilenameError,  addUsers}) {
    const [data,setData]=useState({})
    const [showModal, setShowMoal]=useState(false)
    const [message, setMessage] = useState({})
    const [isShowMsg, setIsShowMsg] = useState(false)
   function checkEmail (userid){

        let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

                    if (!regex.test(userid)) {
                        setIsShowMsg(true)
                        setMessage({
                            message: "Please enter valid email",
                            color: 'red'

                        })
                        return true
    }
    setIsShowMsg(false)
    return false
    }
    return (
        <>
        {showModal?
       
            <>
            <Dialog
              open={showModal}
              maxWidth={"lg"}
              PaperProps={{
                style: {
                  borderRadius: 14,
                  minHeight: "30vh",
                  maxHeight: "fit-content",
                  minWidth: "30%",
                  overflow: "hidden",
                },
              }}
            >
              <DialogTitle style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '6px' }}>
                <span
                  style={{ marginLeft: '1.5rem' }}
                >Add User</span>
                <IconButton >
                  <CloseIcon onClick={()=>{setShowMoal(false)}} />
                </IconButton>
    
              </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
          
               <section className='schedule_selectuserids'>
              <section className='schedule_selectuserids_input_samplefile'>
      
              <section className='schedule_selectuserids_input'>
    
    
                <div className='selectuserids_input_filename' style={{width:'22rem'}}>
                <label htmlFor='selectuserids_li' className=''>Enter the email id </label>
                <br/>
                  <input id='selectuserids_li'
                  placeholder='Enter the email id'
                    type="email" 
                    className='input'
                    onChange={(e) => setData({userid:e.target.value})}
                    />
                    {isShowMsg && <div
                    // className='msg-div'
                    style={{
                        // backgroundColor: message.color,
                        color: message.color,
                        fontWeight: '600',
                        textShadow: '2px 2px 2px 2px currentColor',

                    }}
                >
                    <p>
                        {message.message}
                    </p>
                </div>}
                 
                </div>
    
    
              </section>
    
             
    
            </section>
            </section> 
           
              </DialogContent>
    
              <DialogActions sx={{ marginRight: '1.3rem' }}>
    
    
                <button
                  style={{ marginBottom: "1rem", marginRight: "0.5rem" }}
                  className="button  primary"
                  onClick={(e) => {
                    if(checkEmail(data.userid)){
                        return
                    }
                    
                    addUsers([data]);
                    setShowMoal(false)
                  }}
                >
                  Add
                </button>
                <button
                  className="button "
                  style={{ marginBottom: "1rem" }}
                  onClick={()=>{setShowMoal(false)}}
                >
                  Cancel
                </button>
              </DialogActions>
            </Dialog>
      
                </>
            :
           
    
            <button 
            className="icon-button"
            title='Add single user'
            onClick={()=>{setShowMoal(true)}}
            > 
                <HiUserAdd size={15} />
            </button>
               
                }
            
                      </>
      )
}
