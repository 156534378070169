import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  pdf,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { Link } from "@react-pdf/renderer";
import ApiConfig from "../../ApiConfig";
import axios from "axios";
import "./loader.scss";
import { IoNewspaperOutline } from "react-icons/io5";

import { useState } from "react";
import { Base64 } from "js-base64";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    padding: 10,
  },
  radioButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  radioButton: {
    width: 10,
    height: 10,
    marginRight: 5,
    borderWidth: 1,
  },
});
const PDFPreview = ({ data }) => {
  const [loading, setLoading] = useState(false);

  async function GetData(testid) {
    try {
      setLoading(true);
      const Api = new ApiConfig();
      const response = await axios.get(
        Api.addApiKey(`${Api.BaseURI}/getQuestionsidfortest?testid=${testid}`)
      );
      console.log("API response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  function getSrccode(srccode) {
    try {
      console.log("Serc", srccode);
      const base64 = btoa(
        new Uint8Array(srccode.data).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, "")
      );
      return `data:image/png;base64,${base64}`;
    } catch (error) {
      console.error("Error converting buffer to base64:", error);
      return null;
    }
  }

  function getSrccode1(srccode) {
    try {
      console.log("Serc", srccode);
      const base64 = btoa(
        new Uint8Array(srccode.data).reduce(function (data, byte) {
          return data + String.fromCharCode(byte);
        }, "")
      );
      console.log("linlk", `data:application/pdf;base64,${base64}`);
      return `data:application/pdf;base64,${base64}`;
    } catch (error) {
      console.error("Error converting buffer to base64:", error);
      return null;
    }
  }

  function getDescription(data) {
    if (data.type === "radio") {
      const options = data.options.split(",");

      return (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          {options.map((option, index) => (
            <View
              key={index}
              style={{
                marginRight: "5px",
                marginBottom: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  border: "1px solid black",
                  marginRight: "4px",
                }}
              ></View>
              <Text numberOfLines={1} style={{ maxWidth: "100px" }}>
                {option}
              </Text>
            </View>
          ))}
        </View>
      );
    } else if (data.type === "checkbox") {
      const options = data.options.split(",");

      return (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          {options.map((option, index) => (
            <View
              key={index}
              style={{
                marginRight: "5px",
                marginBottom: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  width: "17px",
                  height: "17px",
                  border: "1px solid black",
                  marginRight: "4px",
                }}
              ></View>
              <Text numberOfLines={1} style={{ maxWidth: "100px" }}>
                {option}
              </Text>
            </View>
          ))}
        </View>
      );
    } else {
      return (
        <View
          style={{
            height: "25rem",
            width: "93%",
            border: "1px solid black",
            borderRadius: "2px",
            marginBottom: "7px",
          }}
        ></View>
      );
    }
  }

  function renderItemContent(item) {
    if (item.code.type === "image") {
      return (
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            width={20}
            height={40}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxHeight: "70%",
              maxWidth: "60%",
            }}
            src={getSrccode(item.code.data)}
          />
        </View>
      );
    } else if (item.code.type === "desc") {
      return <Text>{item.message}</Text>;
    } else {
      return <Text>{item.code}</Text>;
    }
  }

  const generatePDF = async (testid) => {
    try {
      const dataArr = await GetData(testid);
      const pages = dataArr?.map((item, index) => (
        <Page key={index} size="A4">
          <View
            style={{
              margin: "20px",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
            }}
          >
            <View style={{ display: "flex", gap: "0.5rem" }}>
              <Text>
                {item.exid} {"(" + item.exercise_details[0].category + ")"}
              </Text>
              <Text></Text>
            </View>
            <Text>Description : {item.exercise_details[0].description}</Text>
            <View style={{ marginTop: "5px", marginBottom: "5px" }}>
              {item.code.type === "image" ? (
                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    width={20}
                    height={40}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      maxHeight: "70%",
                      maxWidth: "60%",
                    }}
                    src={getSrccode(item.code.data)}
                  />
                </View>
              ) : item.code.type == "desc" ? (
                <Text>{item.code.message}</Text>
              ) : item.code.type == "pdf" ? (
                <View
                  style={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <Text>Please refer this pdf -</Text>
                  <Text>
                    <Link src={getSrccode1(item.code.data)}>
                      Right click and open link in new tab
                    </Link>
                  </Text>
                </View>
              ) : item.code.type == "nocode" ? (
                <Text>{item.message}</Text>
              ) : (
                <Text>{item.code}</Text>
              )}
            </View>
            <Text style={{ marginBottom: "7px" }}>
              Answer the following Questions :
            </Text>
            {item.questions_array.questions.map((item1, index) => (
              <View
                style={{ display: "flex", flexDirection: "column", gap: "7px" }}
              >
                <Text key={index} wrap="break-word">
                  {index + 1 + ") "}
                  {item1.label}
                </Text>
                {getDescription(item1)}
                {(index + 1) % 5 === 0 && <View break />}
              </View>
            ))}
          </View>
        </Page>
      ));

      const blob = await pdf(<Document>{pages}</Document>).toBlob();

      const url = URL.createObjectURL(blob);

      window.open(url, "_blank");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  return (
    <div>
      {loading && <div className="loader"></div>}
      {loading && <div className="overlay"></div>}
      <button
        onClick={() => generatePDF(data.testid)}
        className="icon-button  small data_actions"
        title="Question paper"
      >
        <IoNewspaperOutline size={20} />
      </button>
    </div>
  );
};

export default PDFPreview;
