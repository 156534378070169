import { FaSortDown } from "react-icons/fa6";
import { FaSortUp } from "react-icons/fa6";

export const toggleRowSelection = (scheduleid, setSelectedRows, selectedRows, setArchiveIds, archiveIds) => {
  const isSelected = selectedRows?.includes(scheduleid);
  console.log("selectedRows", selectedRows)
  if (isSelected) {
    setSelectedRows(selectedRows.filter(id => id !== scheduleid));
  } else {
    setSelectedRows([...selectedRows, scheduleid]);
  }

  console.log("archiveIds", archiveIds)
  const isArchived = archiveIds?.includes(scheduleid)
  if (isArchived) {
    setArchiveIds(archiveIds.filter(id => id !== scheduleid));
  } else {
    setArchiveIds([...archiveIds, scheduleid])
  }
};


export const toggleSelectAll = (selectAll, data, setSelectedRows, setSelectAll, setArchiveIds) => {
  if (selectAll) {
    setSelectedRows([]);
    setArchiveIds([]);
  } else {
    const allScheduleIds = data.map(cell => cell.scheduleid);
    const allTestIds = data.map(cell => cell.scheduleid);
    setSelectedRows(allScheduleIds);
    setArchiveIds(allTestIds)
  }
  setSelectAll(!selectAll);
};


export const sortData = (columnKey,
  sortDirection,
  setSortDirection,
  setTestData,
  testData) => {
  const direction = (sortDirection.column === columnKey && sortDirection.direction === 'asc') ? 'desc' : 'asc';
  const sortedData = [...testData].sort((a, b) => {
    const comparison = direction === 'asc' ? 1 : -1;
    if (a[columnKey] > b[columnKey]) return comparison;
    if (a[columnKey] < b[columnKey]) return -comparison;
    return 0;
  });
  setTestData(sortedData);
  setSortDirection({ column: columnKey, direction });
};

export const renderSortIcon = (columnKey, sortDirection) => {
  if (sortDirection.column === columnKey) {
    if (sortDirection.direction === 'asc') {
      return <FaSortUp className="up" />;
    } else {
      return <FaSortDown />;
    }
  }
  return null;
};

export const pinRow = (rowIndex, setPinnedRows, pinnedRows,
  testData, setTestData) => {
  setPinnedRows([...pinnedRows, testData.find((_, idx) => idx === rowIndex)]);
  setTestData(testData.filter((_, idx) => idx !== rowIndex));
};

export const unpinRow = (rowIndex, setPinnedRows, pinnedRows,
  testData, setTestData) => {
  setPinnedRows(pinnedRows.filter((_, idx) => idx !== rowIndex));
  setTestData([...testData, pinnedRows.find((_, idx) => idx === rowIndex)]);
};
