import React, { useEffect, useState } from 'react'
import { Switch } from "@mui/material"
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { FaUserCog } from "react-icons/fa";
import LoaderUtility from '../../UtilityFunctions/Loader_utility';
import useAccountSettings from '../../hooks/useAccountSettings';
import { FaSave, FaUndoAlt } from "react-icons/fa";
import './settings.scss'
import { getValueFromSession } from '../../UtilityFunctions/getSetValueSession';


export const Settings = () => {
  const { saveSettings, setShowPercentile, setNotificationSettings, showPercentile, notificationSettings, getAccountSettings, loading } = useAccountSettings()
  const [activeTab, setActiveTab] = useState("percentile")

  useEffect(()=>{
    let data = JSON.parse(getValueFromSession('settings'))
    setShowPercentile(data.percentile)
    setNotificationSettings(data.notifications)
  },[])
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const handleSwitchChange = (type) => (event) => {
    if (type === 'percentile') {
      setShowPercentile(event.target.checked);
    } else {
      setNotificationSettings({ ...notificationSettings, [type]: event.target.checked });
    }
  };
  // console.log('cbsakjcbsdj', notificationSettings, showPercentile)

  function options(type) {
    return (
      <div className={`select_options ${type}`}>
        <div>False</div>
        <AntSwitch checked={type === 'percentile' ? showPercentile : notificationSettings[type]} onChange={handleSwitchChange(type)} />
        <div>True</div>
      </div>
    )
  }

  function sidebar() {
    return (

      <section className="pyui_main-content-section">

        <section className="pyui_journey-section">
          <div className='Profile_Lable_section'>
            <div className='settings_title'>
              <FaUserCog size={25} />
              <h3>Accounts Settings</h3>
            </div>
            <div className='settings_actions'>
              <Link className='link-button small' to={"/"}>Back</Link>
              <button className='icon-button small'
                title='save settings'
                onClick={async () => {
                  await saveSettings()
                  await getAccountSettings()
                }}><FaSave /></button>
              <button className='icon-button small'
                title='reset settings'
                onClick={async () => {
                  let reset = true
                  await saveSettings(reset)
                  await getAccountSettings()
                }}><FaUndoAlt /></button>
            </div>
          </div>
          {loading
            ? (
              <LoaderUtility loading={loading} />
            ) : (
              <section className="pyui_journeytabs-container">

                <nav className="pyui_journeytabs-status-tabs">
                  <ul className="pyui_journeytabs-status-tabs-jtlist">
                    <li
                      className={activeTab === "percentile" ? "active" : ""}
                      onClick={(e) => setActiveTab("percentile")}
                    >
                      <i id="available" class={activeTab === "percentile" ? "fa-regular fa-circle-check" : "fa-sharp fa-solid fa-circle"}></i>
                      Show percentile
                      {/* <div className="count">{available.length}</div> */}
                    </li>

                    <li
                      className={activeTab === "notifications" ? "active" : ""}
                      onClick={(e) => setActiveTab("notifications")}
                    >
                      <i id="completed" class={activeTab === "notifications" ? "fa-regular fa-circle-check" : "fa-sharp fa-solid fa-circle"}></i>
                      Notifications
                      {/* <div className="count">{available.length}</div> */}
                    </li>
                  </ul>
                </nav>

                <article className="pyui_journeytabs-status-tab-content" style={{ alignItems: 'center' }}>
                  {/* <section className="pyui_myJourneyTab-container"> */}
                  {/* <div className="title"> Certificates </div> */}

                  {activeTab == "percentile" && (
                    <div className='percentile options'>
                      <h3>Show percentile</h3>
                      {options('percentile')}
                    </div>
                  )}


                  {activeTab == "notifications" && (
                    <section className='notifications subsection'>
                      <div className='options'>
                        <h3>Send email on start </h3>
                        {options('start')}
                      </div>
                      <div className='options'>
                        <h3>Send email on schedule</h3>
                        {options('schedule')}
                      </div>
                      <div className='options'>
                        <h3>Send email on update</h3>
                        {options('update')}
                      </div>
                    </section>
                  )}
                </article>
              </section>
            )}
        </section>
      </section>




    );

  }
  return (
    <section className="pyui_journey-section">
      {sidebar()}
    </section>
  )
}