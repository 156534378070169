// import react component and files
import { useState } from 'react';
// import libraries
import DatePicker from 'react-date-picker';
// import styles
import './datepicker.scss'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const DatePickerComp = ({ value, setDateValue }) => {

  const dateValue = (date) => {
    const adjustedDate = new Date(date)
    adjustedDate.setHours(adjustedDate.getHours() + 5)
    adjustedDate.setMinutes(adjustedDate.getMinutes() + 30)
    // console.log("date val", adjustedDate.toISOString())
    setDateValue(adjustedDate.toISOString())
  }
  return (
    <div className='schedule_data_picker'>
      <label htmlFor="">Select Date:</label>
      <DatePicker
        onChange={dateValue}
        value={value}
        minDate={new Date()}
        maxDate={new Date("02-29-2999")}
        clearIcon={null}
        format='dd-MM-yyyy'
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
      />
    </div>
  )
}

export default DatePickerComp