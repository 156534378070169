import { useMemo, useState } from 'react'
import useFetchWithCacheData from './useFetchData';

const useTracks = () => {
  const [tracks, setTracks] = useState([]);

  const uri = `tracks`;
  const { data: myData } = useFetchWithCacheData(uri);

  useMemo(() => {
    setTracks(myData)
  }, [myData]);

  return { tracks }

}

export default useTracks