import React from 'react';
import { getValueFromSession } from '../../UtilityFunctions/getSetValueSession';

const FinishButton = ({ exdatarr, setIsMOdal, currentStepIndex, toEvaluate, clearTimer, npvalue, step, evaluate, handlesetAttNtAttp, }) => {
  const isLastStep = currentStepIndex >= exdatarr.length - 1;
  const handleFinishClick = () => {
    if (isLastStep) {
      setIsMOdal(true);
      let results = evaluate(npvalue, step.exid, step.level)
      if (results) {
        handlesetAttNtAttp(results)
      }
    }
  };

  return (
    <button
      type="button"
      className={`primary button small ${!isLastStep ? 'disabled' : ''}`}
      onClick={handleFinishClick}
    >
      Finish
    </button>
  );
};

export default FinishButton;