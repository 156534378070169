import React from "react";

import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.pyui_footer}>
      <span className={styles.puyi_powered}></span>
      <span>Crafted in Pune, India </span>
      <span>© 2023 Evolvability Tech Pvt Ltd. </span>
      <span>All Rights Reserved.</span>
      {/* <span className={styles.puyi_powered}>
        Powered by -
        <a
          href="https://datasciencelab.in/"
          target="_blank"
          title="DataScienceLab.in"
        >
          DataScienceLab.in
        </a>
      </span> */}
    </footer>
  );
};

export default Footer;

