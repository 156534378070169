import { secretKey } from '../decryptfunction'
import CryptoJS from 'crypto-js';

function encrypt(str){
   
 
    // const splitUrl = str.split('?');
    const splitUrl = str;
    // const baseUrl = splitUrl[0];
    // const queryString = splitUrl[1];
    const encryptedQueryString = CryptoJS.AES.encrypt(JSON.stringify(splitUrl), 'don').toString();
    const encodedQueryString = encodeURIComponent(encryptedQueryString);
    // const encryptedUrl = `${baseUrl}?q=${encodedQueryString}`;
    console.log('encrypt',encodedQueryString)
    return encodedQueryString
}

export default encrypt