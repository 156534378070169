import React from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import './Modal.scss'
import { FaCheck } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

const ModalComponent = ({

  submit_smg,
  setModal,
  title,
  msg,
  status,
  onBack

}) => {
 

  const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  };
console.log("msg",msg)
  return (
    <>
    
        <div id="simplemodal" className="pyui_modal">
          <div className="pyui_modal-content">
            {submit_smg ? (
              <>
                <ModalHeader onClose={() => setModal(false)} title={title} />
                <div id="modalloader-spinner">
                  <p>
                    Creating a Test
                    <PulseLoader
                      color="black"
                      loading={submit_smg}
                      cssOverride={override}
                      size={3}
                      className="pulse-loader"
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      speedMultiplier={0.5}
                    />
                  </p>
                </div>
              </>
            ) : (
              <>
                <ModalHeader onClose={() => setModal(false)} title={title} />
                <div className="pyui_modal-body">
                  <div className="pyui_modal-body-container" style={{display:'flex',gap:'0.3rem',alignItems:'center',fontSize:'16px'}}>
                    {status==201 ?<FaCheck color='green' /> :<MdCancel color='red'/>}
                    {console.log("stuat",status)}
                  {/* <i className={`${status==201?'fa-solid fa-check':'fa-solid fa-check'}`}></i> */}
                      {msg}
                      </div>
                    <div className="modal-footer">
                      <button
                        className="primary button small"
                        onClick={onBack}
                      >
                        Back to Home
                      </button>

                      <button
                        className="secondary button small"
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                
                </div>
              </>
            )}
          </div>
        </div>
   
    </>
  );
};

const ModalHeader = ({ onClose, title }) => (
  <header className="pyui_modal-header">
    <h4 className="pyui_modal-header-title">{title}</h4>
    <span className="closebtn" onClick={onClose}>&times;</span>
  </header>
);

export default ModalComponent;
