import axios from "axios";
import ApiConfig from "../../../../ApiConfig";
import { getValueFromSession, setValueInSession } from "../../../UtilityFunctions/getSetValueSession";
import getJson from "../../Eval_quiz_utility_functions/JsonUtility";
import getJsonWithValues from "../getJsonWithValues";

import { useEffect, useState } from 'react'
import { Base64 } from "js-base64";

const useEvalFormutils = (exid) => {
  const [questions, setQuestionnp] = useState([]);
  const [answers, setAnswersnnp] = useState([]);
  console.log("answers", answers)
  let userid = getValueFromSession('username')

  useEffect(() => {
    toGetQuestionForexid()
  }, [exid]);

  function toGetQuestionForexid() {
    let Api = new ApiConfig();
    let quesUri;
    quesUri = `${Api.BaseURI}/questions?exercise=${exid}`;
    // }
    axios
      .get(Api.addApiKey(quesUri))
      .then((response) => {
        if (
          response.data.status === 406 ||
          response.data.status === 408 ||
          response.data.status === 500 ||
          response.data.status === 503
        ) {
          console.log("in toGetQuestionForexid")
          // setState({ navigateTo: true, quesresp: response.data });
          axios
            .get(Api.addApiKey(`${Api.BaseURI}/postLogout?username=${userid}`))
            .then((res) => {
              console.log(res);
            });
          sessionStorage.removeItem("username");
          sessionStorage.clear();
          localStorage.clear();
        } else {
          console.log("in toGetQuestionForexid123")
          let answers = [];
          response.data.answers.map((answer) => {
            answers.push({
              key: answer.key,
              answer: Base64.decode(answer.answer),
            });
          });
          setQuestionnp(response.data.questions)
          setAnswersnnp(answers)
        }
      })
      .catch((error) => console.log(error));
  }

  function handleMarkingScheme(markingschemeObj, data) {

    let maxscore = data.maxscore
    let score = data.score
    if (markingschemeObj !== 'pravidefault' && process.env.REACT_APP_ENV_IS_USE_MARKING_SCHEME === 'true') {


      // let incorrect =getIncorrectAnswersCount(data.questionjson, data.correct)
      score = ((data.correct * markingschemeObj.correct) - ((data.questions - data.correct) * markingschemeObj.wrong))
      //  score=((data.correct * markingschemeObj.correct)- (incorrect* markingschemeObj.wrong))
      maxscore = (data.questions * markingschemeObj.correct)
      //  console.log("score #######",score, maxscore)
    }
    return { score, maxscore }
  }

  function updateExerciseResults(
    exid,
    results,
    numcorrect,
    numquestions,
    exscore,
    total,
    valuejson
  ) {
    if (results.length == 0) {
      results.push({
        exercise: exid,
        correct: numcorrect,
        questions: numquestions,
        score: exscore,
        maxscore: total,
        questionjson: valuejson,
      });
    } else {
      let found = false;
      for (var i = 0; i < results.length && !found; i++) {
        if (results[i].exercise === exid) {
          results[i].correct = numcorrect;
          results[i].questions = numquestions;
          results[i].score = exscore;
          results[i].maxscore = total;
          results[i].questionjson = valuejson;
          found = true;
        }
      }
      if (!found) {
        results.push({
          exercise: exid,
          correct: numcorrect,
          questions: numquestions,
          score: exscore,
          maxscore: total,
          questionjson: valuejson,
        });
      }
    }
    return results;
  }
  function getGrandTotal(results, module) {
    let evalResult = {
      evalName: module,
      correct: 0,
      questions: 0,
      score: 0,
      maxscore: 0,
    };
    let markingscheme = getValueFromSession('markingscheme')
    if (markingscheme !== 'pravidefault') {

      let plus = markingscheme.slice(markingscheme.indexOf("+") + 1, markingscheme.indexOf(","))
      let minus = markingscheme.slice(markingscheme.indexOf("-") + 1, markingscheme.indexOf(")"))
      markingscheme = { correct: plus, wrong: minus }
      console.log(plus, minus)
    }

    for (var i = 0; i < results.length; i++) {
      let { score, maxscore } = handleMarkingScheme(markingscheme, results[i], 'custome')
      evalResult.correct += results[i].correct;
      evalResult.questions += results[i].questions;
      evalResult.score += score;
      evalResult.maxscore += maxscore;
      // evalResult.score += results[i].score;
      // evalResult.maxscore += results[i].maxscore;
    }
    return evalResult;
  }

  function getJsonFromResults() {
    // let results = JSON.parse(sessionStorage.getItem('results'));
    let results = JSON.parse(getValueFromSession('results'));
    for (let i = 0; i < results.length; i++) {
      if (results[i].exercise === exid) {
        // console.log("results[i].questionjson", results[i].questionjson);
        // console.log("inside");
        // console.log(results[i].questionjson);
        return getJson(JSON.parse(JSON.stringify(results[i].questionjson)));
      }
    }
    // console.log("outside");
    return getJson(JSON.parse(JSON.stringify(questions)));
  }

  function getMatchingIndex(key) {
    // console.log("key", key);
    for (var i = 0; i < answers.length; i++) {
      console.log("answers[i].key", answers[i].key);
      if (answers[i].key === key) return i;
    }
  }
  function clearTimer(timeOutid) {
    while (timeOutid > timeOutid - 3) {
      clearTimeout(timeOutid);
    }
  }

  const toMatchArray = (userAnswers, correctAnswers) => {
    let temparr = [];
    let temp1arr = [];
    let result = false;
    for (let i = 0; i < userAnswers.length; i++) {
      temparr.push(parseInt(userAnswers[i]));
    }

    let answers = correctAnswers.split(",");
    console.log(answers);
    for (let i = 0; i < answers.length; i++) {
      temp1arr.push(parseInt(answers[i]));
    }
    // console.log("answer",checkboxarr)
    // console.log("user res",value)

    if (temparr.length === temp1arr.length) {
      temp1arr.sort();
      temparr.sort();
      let i;
      for (i = 0; i < temparr.length; i++) {
        if (temparr[i] !== temp1arr[i]) break;
      }
      if (i === temparr.length) return true;
    }
    // console.log("lenght", checkboxarr[0])
    return result;
  };
  // function getJsonWithEmptyValueUpdate() {
  //   // let results = JSON.parse(sessionStorage.getItem('results'));
  //   let results = JSON.parse(getValueFromSession('results'));
  //   let { key, value } = this.state;

  //   if (key !== undefined && value !== undefined) {
  //     if (key === "") {
  //       return this.getJsonFromResults();
  //     }
  //     for (let i = 0; i < results.length; i++) {
  //       let updated = false;
  //       if (
  //         key.includes(this.props.data.exid) &&
  //         results[i].exercise === this.props.data.exid
  //       ) {
  //         let currentJson = getJson(
  //           JSON.parse(JSON.stringify(results[i].questionjson))
  //         );
  //         for (let j = 0; j < currentJson.length; j++) {
  //           if (currentJson[j].key === key) {
  //             currentJson[j].value = "";
  //             updated = true;
  //             break;
  //           }
  //         }
  //         if (updated) {
  //           results[i].questionjson = currentJson;
  //           // sessionStorage.setItem('results', JSON.stringify(results));
  //           setValueInSession('results', JSON.stringify(results));
  //         }
  //         return currentJson;
  //       }
  //     }
  //   }
  //   // console.log("outside if");
  //   return getJson(JSON.parse(JSON.stringify(this.state.questions)));
  // }
  const evaluate = (model, exid, level) => {

    console.log("modelZ", model);
    console.log("exid", exid);
    console.log("level", level);

    // let exid = props.data.exid;
    // let level = props.data.level;
    let newModal = [];
    for (let [k, v] of Object.entries(model)) {
      newModal.push({
        key: k,
        value: v,
      });
    }

    function getAnswerJsonFromStorage() {
      // let results = JSON.parse(sessionStorage.getItem('results'));
      let results = JSON.parse(getValueFromSession('results'));
      let ansArr = [];
      if (results.length === 0) {
        return ansArr;
      } else {
        for (let i = 0; i < results.length; i++) {
          if (results[i].exercise === exid) {
            let currentJson = getJson(
              JSON.parse(JSON.stringify(results[i].questionjson))
            );
            for (let j = 0; j < currentJson.length; j++) {
              if (currentJson[j].value !== undefined) {
                ansArr.push({
                  key: currentJson[j].key,
                  value: currentJson[j].value,
                });
              }
            }
            break;
          }
        }
        return ansArr;
      }
    }

    function containskey(combined, key) {
      for (let i = 0; i < combined.length; i++) {
        if (combined[i].key === key) {
          return true;
        }
      }
      return false;
    }

    function getCombinedValues(currValArray) {
      let combined = getAnswerJsonFromStorage();
      if (combined.length === 0) {
        return currValArray;
      }
      for (let i = 0; i < combined.length; i++) {
        for (let j = 0; j < currValArray.length; j++) {
          if (combined[i].key === currValArray[j].key) {
            combined[i].value = currValArray[j].value;
            break;
          }
        }
      }
      for (let j = 0; j < currValArray.length; j++) {
        if (!containskey(combined, currValArray[j].key)) {
          combined.push({
            key: currValArray[j].key,
            value: currValArray[j].value,
          });
        } else {
        }
      }
      return combined;
    }

    function getMaxScore(level) {
      let totalmarks = 0.0;
      if (level >= 1 && level <= 3) {
        totalmarks = 10.0;
      } else if (level >= 4 && level <= 6) {
        totalmarks = 20.0;
      } else if (level >= 7 && level <= 10) {
        totalmarks = 30.0;
      } else {
        totalmarks = 40.0;
      }
      return totalmarks;
    }

    let newValArr;
    let updated
    if (newModal.length === 0) {
      let totalmarks = 0;
      let score = 0;
      let no_of_correct_answers = 0;
      let no_of_questions = 0;
      // let results = JSON.parse(sessionStorage.getItem('results'));
      let results = JSON.parse(getValueFromSession('results'));
      totalmarks = getMaxScore(level);
      no_of_questions = questions.length;
      for (let i = 0; i < results.length; i++) {
        if (results[i].exercise === exid) {
          score = results[i].score;
          no_of_correct_answers = results[i].correct;
        }
      }
      newValArr = getJsonFromResults();
      updated = updateExerciseResults(exid, results, no_of_correct_answers, no_of_questions, score, totalmarks, newValArr);
      // sessionStorage.setItem('results', JSON.stringify(updated));
      setValueInSession('results', JSON.stringify(updated));

    } else {
      let totalmarks = 0;
      let score = 0;
      let no_of_correct_answers = 0;
      let no_of_questions = 0;
      newValArr = getJsonWithValues(getJsonFromResults(), newModal);

      let updatedModel = getCombinedValues(newModal);
      console.log("Updated Model", updatedModel);

      var correct_value = [];
      var wrong_value = [];
      for (var i = 0; i < updatedModel.length; i++) {
        let toMatchingIndex = getMatchingIndex(updatedModel[i].key);
        if (Array.isArray(updatedModel[i].value)) {
          if (
            toMatchArray(
              updatedModel[i].value,
              answers[toMatchingIndex].answer
            )
          ) {
            correct_value.push({
              key: answers[toMatchingIndex].key,
              value: updatedModel[i].value,
            });
          } else {
            wrong_value.push({
              key: answers[toMatchingIndex].key,
              value: updatedModel[i].value,
            });
          }
        }
        // console.log("answers cheker", toMatchingIndex)
        // console.log("answers cheker", answers)
        // console.log("answers[toMatchingIndex]", answers[toMatchingIndex])
        // console.log("answers[toMatchingIndex]?.answer", answers[toMatchingIndex]?.answer)
        // console.log("answers[toMatchingIndex]?.key", answers[toMatchingIndex]?.key)
        if (toMatchingIndex !== 'undefined' || toMatchingIndex !== undefined) {
          if (answers[toMatchingIndex]?.answer === updatedModel[i]?.value) {
            correct_value.push({
              key: answers[toMatchingIndex]?.key,
              value: updatedModel[i]?.value,
            });
          } else {
            wrong_value.push({
              key: answers[toMatchingIndex]?.key,
              value: updatedModel[i]?.value,
            });
          }
        }
      }


      let results = JSON.parse(getValueFromSession('results'));
      no_of_correct_answers = correct_value.length;
      no_of_questions = questions.length;
      totalmarks = getMaxScore(level);
      score = parseFloat(Math.ceil(no_of_correct_answers * (getMaxScore(level) / questions.length)));
      updated = updateExerciseResults(exid, results, no_of_correct_answers, no_of_questions, score, totalmarks, newValArr);
      // sessionStorage.setItem('results', JSON.stringify(updated));
      setValueInSession('results', JSON.stringify(updated));

    }

    // function grand(evaluationResult) {
    //   setValueInSession('evaluationResult', JSON.stringify(evaluationResult));
    // };

    // if (finished !== undefined && finished) {
    //   let results = JSON.parse(getValueFromSession('results'));
    //   console.log("results", results)
    //   let module_name = sessionStorage.getItem('module_name');
    //   var evaluationResult = getGrandTotal(results, module_name);
    //   setState({
    //     notAttempted: evaluationResult.notAttempted,
    //     noQuestions: evaluationResult.questions,
    //     score: evaluationResult.score,
    //     maxscore: evaluationResult.maxscore,
    //   });

    //   grand(evaluationResult);
    //   setState({ isOpen: true });
    // }
    return updated
  };

  return { evaluate }
}

export default useEvalFormutils

