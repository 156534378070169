import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
export default function InputDialog({onClose,onNodeSelect,codedata,nodeid,updateData}) {

let condtionsarray=['==','<=','>=','>','<']

const [message,setMessage]=useState('')
const [open,setOpen]=useState(true)

function handlemessage(e){
  setMessage(e)
  codedata[nodeid.id]={
    id:nodeid.id,
    type:nodeid.type,
    data:{
      variable:e
    }
  }
}


  const handleClickOpen = () => {
    setOpen(true);
  };


  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
 
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Input</DialogTitle>
        <DialogContent>
          <DialogContentText>
        
          </DialogContentText>     
        <div style={{display:'flex',flexDirection:'column',width:'25rem'}}>
        <label style={{marginBottom:'0.5rem'}}>Enter the variable name:</label>
        <input   type='text' className='input' placeholder='Enter the variable' style={{marginBottom:'0.5rem'}} value={message} onChange={(e)=>{handlemessage(e.target.value)}} />
        </div>
        </DialogContent>
        <DialogActions>
   <div className='flowchart_btn'>
        <button 
        className='button primary'
        onClick={(e)=>{
         let newlabel="Input "+ message + " ";
         console.log(newlabel)
         onNodeSelect(newlabel,codedata)
        }}>add</button>
         <button className='button secondary' onClick={onClose}>close</button>
         </div>
         </DialogActions>

      </Dialog>

    </React.Fragment>
  );
}