export function modifiedResults(resultarr) {
  // console.log("first", resultarr.length);
  let result = [];
  for (let i = 0; i < resultarr.length; i++) {
    result.push({
      exercise: resultarr[i].exercise,
      correct: resultarr[i].correct,
      questions: resultarr[i].questions,
      maxscore: resultarr[i].maxscore,
      attempted: resultarr[i].attNtAttp,
      answers: modifyq(resultarr[i].questionjson)
      // answers: JSON.stringify(resultarr[i].questionjson)
    });
    console.log("modifiedResults", modifyq(resultarr[i].questionjson))
  }
  return result;
}

function modifyq(q) {
  let temp = [];
  for (let i = 0; i < q.length; i++) {
    console.log("modifiedResults", 'resultarr' in q[i])
    if ('resultarr' in q[i] && 'code' in q[i]) {
      return [{ resultarr: q[i].resultarr, code: q[i].code }]
    } else if ('value' in q[i]) {
      console.log("evalform")
      temp.push({ key: q[i].key, value: q[i].value })
    }
  }
  return temp
}