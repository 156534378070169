import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

export default function IfnodeDialog({onClose,onNodeSelect,codedata,nodeid,updateData}) {

let condtionsarray=['==','<=','>=','>','<']
const [open,setOpen]=useState(true)
const [left,setLeft]=useState('')
const [right,setRight]=useState('')
const [condition,setCondtion]=useState('==')


  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleLeftvalue(e){
    setLeft(e)
    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        right:right,
        left:e,
        condition:condition
      }
    }
  }

  function handlerightvalue(e){
  setRight(e)
  codedata[nodeid.id]={
    id:nodeid.id,
    type:nodeid.type,
    data:{
      right:e,
      left:left,
      condition:condition
    }
  }
  }

  function handlecondition(e){
    setCondtion(e)

    codedata[nodeid.id]={
      id:nodeid.id,
      type:nodeid.type,
      data:{
        right:right,
        left:left,
        condition:e
      }
    }

  }


  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
 
      </Button>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Decision </DialogTitle>
        <DialogContent>
          <DialogContentText>
        
          </DialogContentText> 
          <div style={{display:'flex',gap:'13.5rem',marginBottom:'0.5rem'}}>
            <label>Variable:</label>
            <label>value:</label>
          </div>
          <div style={{display:'flex',alignItems:'center',gap:'0.5rem'}}>
       <input   type='text' placeholder='variable' className='input' style={{width:'50%'}}  value={left} onChange={(e)=>{handleLeftvalue(e.target.value)}}></input>&nbsp; <label> 
        <select value={condition} onChange={(e)=>handlecondition(e.target.value)}>
            {condtionsarray.map((item)=>(<option>{item}</option>))}
        </select>
        {/* {condition}  */}
       
       
       
       </label> &nbsp;<input   type='text' placeholder='value'  className='input' style={{width:'50%'}}  value={right} onChange={(e)=>{handlerightvalue(e.target.value)}}></input>
       </div>
        </DialogContent>
        <DialogActions>

        <div
         className='flowchart_btn' >
        <button className='button secondary' onClick={onClose}>close</button>

        <button  className='button primary' onClick={(e)=>{
         let newlabel=left+condition+right
         console.log(newlabel)
         onNodeSelect(newlabel,codedata)
        }}>ok</button>
        </div>

        </DialogActions>

      
      </Dialog>
    </React.Fragment>
  );
}