import React, { useEffect, useState, useRef } from 'react'
import './DropDown.scss'


export default function DropDown({ options, setSlectedOption, selectedOption, label }) {

  const [isActive1, setIsActive1] = useState(false);

  let menuRef1 = useRef();
  // console.log("menuRef", menuRef)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef1.current && !menuRef1.current.contains(event.target)) {
        setIsActive1(false);
        // setIsDateActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef1]);
  return (
    <>
      <div className='dropdown-wrapper'>
        <sapn>{label ? label : 'Select '} : </sapn>
        <div ref={menuRef1} className="pyui_session">
          <div
            className={`pyui_session-inner ${isActive1 ? "expanded option-outline" : ""}`}
            onClick={(e) => setIsActive1(!isActive1)}
          >
            <span >{selectedOption ? selectedOption : 'Select option'}</span>
          </div>

          {isActive1 && (
            <div>
              <ul className="pyui_session-list option-wrapper"
                style={{
                  height: `min(10rem,${options.length * 39}px)`

                }}

              >

                {options.map(labels => (
                  <li key={labels.label}
                    className="pyui_session-list-item"
                    onClick={(e) => {
                      setSlectedOption(e.target.textContent);
                      setIsActive1(false)
                    }}>
                    {labels}
                  </li>
                ))}
              </ul>
            </div>
          )}

        </div>
      </div>
    </>
  );
}