import React, { useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import './contextmenu.css'
import { MdDeleteOutline } from "react-icons/md";

export default function ContextMenu({
  id,
  top,
  left,
  right,
  bottom,
  ...props
  
}) {
    console.log("top",top)
  const { getNode, setNodes, addNodes, setEdges } = useReactFlow();
//   const duplicateNode = useCallback(() => {
//     const node = getNode(id);
//     const position = {
//       x: node.position.x + 50,
//       y: node.position.y + 50,
//     };

//     addNodes({ ...node, id: `${node.id}-copy`, position });
//   }, [id, getNode, addNodes]);

//   const deleteNode = useCallback(() => {
//     setNodes((nodes) => nodes.filter((node) => node.id !== id));
//     setEdges((edges) => edges.filter((edge) => edge.source !== id));
//   }, [id, setNodes, setEdges]);

const deleteNode=()=>{
    let node=getNode(id)
    if(node.type!=='start' && node.type!=='end'){

    if(node.type == "if" || node.type == "for" || node.type == "while"){
     props.deleteAllnodesandEdge(props.onNodesifdelete(node.id,node.id+'1'),node.id,node.id+'1')
    }
    else{
        props.onNodesDelete([node])
    }
}
}

  return (
    <div
      style={{ top, left, right, bottom }}
      className="context-menu"
      {...props}
    >
       
        <button style={{background:"white",color:'red'}} onClick={deleteNode}>
        <div style={{display:'flex',alignItems:'center',padding:'0.1rem'}}>
        <MdDeleteOutline 
      style={{fill:'red'}} size={18} />  
         Delete
         </div>

      </button>
    </div>
  );
}
