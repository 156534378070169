// import react component and files
import { useState } from 'react';
// import libraries
import DatePicker from 'react-date-picker';
// import styles
import './datepicker.scss'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const CustomeDatePicker = ({ value, setDateValue, label ,min}) => {

  const dateValue = (date) => {
   const newDate = new Date(date)
   setDateValue(newDate.toISOString())
  }
  return (
    <div className='schedule_data_picker'>
      <label htmlFor="">{label !== undefined? label:'Select Date'}:</label>
      <DatePicker
        onChange={dateValue}
        value={value}
        // minDate={new Date()}
        maxDate={new Date("02-29-2999")}
        minDate={min}
        clearIcon={null}
        format='dd-MM-yyyy'
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
      />
    </div>
  )
}

export default CustomeDatePicker