import React, { createContext, useState } from 'react'
// import libraries

export const AppContext = createContext()
export const AppContextProvider = ({ children }) => {

  const [coins, setCoins] = useState(0);
  const [navbtns, setnavbtn] = useState(true);
  const [isLoadingEval, setIsloadingEval] = useState(false);
  const [state, setstate] = useState(false);
  // const [timer,setTimer]=useState({show:false,duration:0})
  const [timer, setTimer] = useState(false)

  const [badgePoints, setBadgePoints] = useState(0)
  const [bonusPoints, setBonusPoints] = useState(0)
  const [showBP, setShowBP] = useState(false)
  const [selected, setSelected] = useState(() => {
    let defaultlang = "C";
    let language = sessionStorage.getItem('language') || defaultlang;
    if (language !== null || language !== undefined) return language
  });

  const updateBadgePoints = (bonuspoints, badegpoints, showBP, cb) => {
    console.log("bonuspoints", bonuspoints)
    console.log("badegpoints", badegpoints)
    console.log("showBP", showBP)
    setBadgePoints(badegpoints)
    setBonusPoints(bonuspoints)
    setShowBP(showBP)

    if (cb && typeof cb === 'function') {
      cb();
    }
  }
  // function for update user coins 
  let updateCoins = (coins) => {
    // if(coins!==undefined)
    // {
    // setCoins(coins)
    // }
    // else
    // {
    setCoins(sessionStorage.getItem('coinsEarned'))
    // }
  }

  let updatenavbtn = (value) => {
    setnavbtn(value);
  }


  let updatestate = () => {
    setstate(!state);
  }

  let value = {
    coins: coins,
    updateCoins,
    updatenavbtn,
    navbtns,
    isLoadingEval,
    setIsloadingEval,
    setSelected,
    selected,
    updatestate,
    state,
    setTimer,
    timer,
    updateBadgePoints,
    badgePoints,
    bonusPoints,
    showBP,
    setShowBP
  }
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContext