// import react components and files
import React, { useState } from 'react'
// import styles
import './showresult.scss'
const ShowResult = ({ isChecked, setIsChecked }) => {

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <section className='schedule_showresult'>
      <input
        id='result'
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor='result'>
        Show Result to Participants
      </label>
    </section>
  )
}

export default ShowResult