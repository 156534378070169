import * as React from "react";
import * as XLSX from "xlsx";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { FiUpload } from "react-icons/fi";
import xlfile from "./Custom_Add.xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFetchWithCacheData from "../hooks/useFetchData";
import { useState } from "react";
import axios from "axios";
import ApiConfig from "../../ApiConfig";
import LoaderUtility from "../Loader/Loader";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogAdd({
  open,
  setOpen,
  onClose,
  onClick,
  questionsarr,
  setQuestionsarr,
}) {
  //   const [open, setOpen] = React.useState(false);
  const [filename, setFilename] = useState("");
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const toastId = React.useRef(null);
  function getQuestionsAdd() {
    let Api = new ApiConfig();
    let data1 = data.map((item) => Object.values(item)).join(",");
    setLoading(true);
    axios
      .get(Api.addApiKey(`${Api.BaseURI}/getNewExercises?exid=${data1}`))
      .then((res) => {
        if (res.data && res.data.result.length > 0) {
          setQuestionsarr((item) => [...item, ...res.data.result]);
        }
        setLoading(false);
        onClose();
      });
  }

  async function ReadFile(e) {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsondata1 = XLSX.utils.sheet_to_json(worksheet);
    let jsondata = jsondata1.map((el) =>
      Object.fromEntries(
        Object.entries(el).map(([key, value]) => [
          key.replace(/\s+/g, ""),
          value,
        ])
      )
    );
    if (jsondata.length > 0 && jsondata[0].hasOwnProperty("Exid")) {
      setFilename(e.target.files[0].name);
      setData(jsondata);
    } else {
      toast.error("Given file structure doesn’t match the required format", {
        position: "top-center",
      });
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Add exercises"}</DialogTitle>

        <DialogContent>
          {Loading && <LoaderUtility loading={Loading} />}
          <span className="fileInputField">
            <label>Upload a file from your computer:</label>

            <input
              type="file"
              id="file-input"
              className="input"
              onChange={(e) => {
                ReadFile(e);
              }}
            ></input>
            <label
              for="file-input"
              className="input-file-label"
              // style={{ color: "white", border: "1px solid" }}
            >
              <FiUpload size={14} /> &nbsp;Choose a file
            </label>
            <label className="no-file">
              {filename.length <= 0 ? "No file choosen" : filename}
            </label>
          </span>
          <a
            href={xlfile}
            download="Sample_User_Data"
            target="_blank"
            rel="noreferrer"
          >
            <button
              style={{ display: "flex", alignItems: "center" }}
              className="link-button small"
            >
              {/* <CgAsterisk color="red"/>-All Fields Required */}
              Download sample file
            </button>
          </a>
        </DialogContent>
        <DialogActions>
          <button className="button secondary" onClick={onClose}>
            Close
          </button>
          <button className="button primary" onClick={() => getQuestionsAdd()}>
            Add
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
